const i18n = {
    US: {
        name: "United States",
        domain: "Amazon.com",
        currency: "$"
    },
    CA: {
        name: "Canada",
        domain: "Amazon.ca",
        currency: "$"
    },
    GB: {
        name: "United Kingdom",
        domain: "Amazon.co.uk",
        currency: "£"
    },
    DE: {
        name: "Germany",
        domain: "Amazon.de",
        currency: "€"
    }
}

export default {
    getCurrency(region) {
        let currency = ""
        
        if (region === undefined || region === "undefined") {
            currency = i18n["US"]["currency"]
        } else {
            currency = i18n[region.toUpperCase()]["currency"]
        }

        return currency;
    }
}