import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from "prop-types";
import trackingLoader from '../../utils/tracking-loader';
import './how-to-video.scss';

export default class HowToVideo extends Component {
    constructor(props) {
		super(props);
		this.state = {
            show: false
        }
    }
    handleClose = () => this.setState({show:false});
    handleShow = () => {
        this.setState({show:true});

        trackingLoader.trackEvent("video", {
            event_category: "engagement",
            event_label: this.props.title,
            value: 1
        });
    }

    render() {

        let instruction = null;
        let video = null

            if (this.props.instruction !== null || this.props.instruction !== "") {
                instruction = this.props.instruction
            } 
            if (this.props.type === "image") {
                video = 
                <img src={this.props.source} alt={this.props.source}/>
            } else {
                video = 
                <video width="100%" height="435" controls autoPlay>
                        <source id="mp4" src={this.props.source} type="video/mp4"></source>     
                    Your browser does not support the video tag.
                </video>
            }

        return (
            <React.Fragment>
                <div className="how-to-video_wrapper ">
                    <Button variant="link"  onClick={this.handleShow} >
                        <i className={this.props.show_icon === "true" || this.props.show_icon === true ? "icon icon-play" : "d-none"}></i> How to video 
                    </Button>
                </div>

                <Modal size="lg" show={this.state.show} onHide={this.handleClose} className="modal-video" centered>
                    <Modal.Header closeButton>
                        <div className="header_sub-title">How To</div> 
                        <div className="header_title">{this.props.title}</div>
                    </Modal.Header>
                    <Modal.Body>
                        {video}
                        {instruction}
                    </Modal.Body>
                 
                </Modal>
            </React.Fragment>
        );
    }

    static propTypes = {
        show_icon: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        type: PropTypes.string,
        instruction: PropTypes.object
    }
}
