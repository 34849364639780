import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button"
import Body from '../../../utils/utils-body'
import trackingLoader from '../../../utils/tracking-loader';
import Modal from '../../snippets/modal';
import Logo from '../../../assets/images/logo.svg'


import './error.scss';

export default class errorCookie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page : this.props.page,
            url: this.props.url,
            modal: {
                className:"",
                show: false,
                header: "",
                body:"",
                footer:""
            }
        }
    }

 
    componentDidMount = () => {
        // Add Class
        Body.pageClass("add", `page_error --cookie`);
        window.history.replaceState("", "Cookie", this.state.url);

        trackingLoader.trackEvent(`cookie_disabled`, {
            event_category: `user_settings`
        }); 
    }

    componentDidUpdate(prevProps, prevState)  {
        Body.pageClass("add", `page_error  --cookie`);
       
    }

    componentWillUnmount = () => {
        Body.pageClass("remove", `page_error  --cookie`);   
           }

    onRefresh = (e) => {
        window.location.href = this.state.url ;
    }

    handleShowModal = () => {
        

        let body = 
            <div>
                <section>
                    <h2>Chrome (Desktop)</h2>
                    <ol>
                        <li>
                            Goto settings > Privacy and security > General settings
                        </li>
                        <li>
                            You can choose to enable "Allow all cookies" or "Block third-party cookies in Incognito"
                        </li>
                    </ol>
                    <img src="https://s3.amazonaws.com/dealpanda.com/static/media/errror-cookie_chrome-desktop.jpg" alt="error"/>
                </section>

                <section>
                    <h2>Chrome (Mobile + Tablet)</h2>
                    <ol>
                        <li>
                            Goto settings > Site settings > cookies
                        </li>
                        <li>
                            Uncheck "Prevent cross-site tracking" and "Block all cookies"
                        </li>
                    </ol>
                    <img src="https://s3.amazonaws.com/dealpanda.com/static/media/errror-cookie_chrome-mobile.jpg" alt="error"/>
                </section>

                <section>
                    <h2>Safari (Desktop)</h2>
                    <ol>
                        <li>
                            Goto Safari > Preference > Privacy
                        </li>
                        <li>
                            Uncheck "Prevent cross-site tracking" and "Block all cookies"
                        </li>
                    </ol>
                    <img src="https://s3.amazonaws.com/dealpanda.com/static/media/errror-cookie_safari-desktop.jpg" alt="error"/>
                </section>
                
                <section>
                    <h2>Safari (Mobile + Tablet)</h2>
                    <ol>
                        <li>
                            Goto Settings > Safari > Privacy & Security
                        </li>
                        <li>
                            Uncheck "Prevent cross-site tracking" and "Block all cookies"
                        </li>
                    </ol>
                    <img src="https://s3.amazonaws.com/dealpanda.com/static/media/errror-cookie_+safari-mobile.jpg" alt="error"/>
                </section>
            </div>
        

            this.setState({
                modal: {
                    ...this.state.modal,
                    show: true,
                    body:body
                }
            })

    }

    handleModalClose = () => {
        
        this.setState({
            modal: {
                ...this.state.modal,
                show: false
            }
        })
    }

    render() {
       return (
                <>
                    <div className="logo">
                        <img src={Logo} rel="Deal Panda" alt="Deal Panda Logo"/>
                    </div>
                    <div className="dialog">
                        <div className="dialog-header">
                            <img src="https://s3.amazonaws.com/dealpanda.com/static/media/error-cookie.png" alt="cookie-icon"/>
                             <h1>Please enable cookies in your broswer to continue</h1>
                        </div>
                        <div className="dialog-body">
                            <p>To ensure you get the best experience, this website requires that you enable cookies in your browser.</p>
                            <p>Once you have enabled cookies, please click on the button below to refresh this page.</p>

                             <Button size="sm" variant="link"  onClick={this.handleShowModal}>Instructions</Button>
                        </div>
                        <div className="dialog-footer">
                                <Button size="sm"  variant="success"  onClick={this.onRefresh}>Refresh</Button>
                        </div>
                    </div>
                     <Modal modal={this.state.modal} closeModal={this.handleModalClose}/>
                </>
            )
    }

    static propTypes = {
        url:PropTypes.string,
        page:PropTypes.string
    }
}