import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Currency from '../../../utils/currency'
import RETAILS from '../../../utils/retails'

import '../Compare.scss';

export default class PayPalStep extends Component {
    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    componentDidUpdate(){
        this.focus('input#email')
    }

    componentDidMount(){
        this.focus('input#email')
    }
    
    render() {
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--paypal">
                    
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper --paypal" style={{backgroundImage: `url(${RETAILS.getLogo("paypal", "lg")})`}}>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current}</h2>
                            <p className="steps_description">In the next step you’ll be asked to purchase the test product. You will receive a full reimbursement* to your Paypal account associated with the email address you provide below.</p>
                            <Form.Group >
                                <Form.Label>Confirm your Paypal account.</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    name="paypalEmail"
                                    placeholder="PayPal Email" 
                                    onChange={this.props.handleInputChange}
                                    value={this.props.step.paypalEmail}
                                    required
                                    id="email"
                                />
                            </Form.Group>
                            <div className="step_additiona-note">You may need to browse through several pages of search results. Scroll to the bottom and click through to the next page if you can't find the product.</div>
                            {/* <p className="steps_description_footnote">If you don’t have a PayPal account yet, no worries! You’ll receive an email from PayPal to create an account when you receive your first reward payout or gift reimbursement. </p> */}
                            <p className="steps_description_footnote">
                                <span className="important-text">*Reimbursement is in the amount {Currency.getCurrency(this.props.userProfile.region)}{this.props.product.deal_amount} plus applicable taxes. Shipping charges are not included. (Remember, Prime members get free shipping!) Limit of one per household.</span>
                            </p>
                        </Col>
                </Row>
            </React.Fragment>   
        );
    }
    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        product: PropTypes.object,
        step: PropTypes.object.isRequired
    };

}
