import React, { Component } from 'react';
import PropTypes from "prop-types";
import Body from '../../utils/utils-body'
import GeneralContent from '../snippets/ContentFAQ';
import DealContent from '../snippets/content-faq-deal';
import BonusContent from '../snippets/content-faq-bonus';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Faq.scss';

export default class FAQ extends Component {
    componentDidMount = () => {
        Body.pageClass("add", `page_faq`);
        if (this.props.location.hash !== "") {
            let section = this.props.location.hash.replace('#','');
            this.jumpToSection(section)
        }
    }

    jumpToSection = (id) => {
        let elementTarget = document.getElementById(id);
        elementTarget.scrollIntoView({behavior: "smooth", block:"center"});
    }

    render() {
        return (
            <Container as="main" className="page-narrow">
                <Row>
                    <Col  className="page-header">
                        <h1 className="display-3">FAQ</h1>
                        <p className="lead">General Member Questions</p>
                    </Col>
                </Row>
                <Row>
                    <h3 id="general" > General Questions</h3>
                    <GeneralContent/>
                </Row>
                <Row>
                    <h3 id="deals" > Test Questions</h3>
                    <DealContent/>
                </Row>
                <Row>
                    <h3 id="bonus" > Panda Points Questions</h3>
                    <BonusContent/>
                </Row>
                
            </Container>
        );
    }

    static propTypes = {
        location: PropTypes.object
    }

}

