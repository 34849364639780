import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import PropTypes from "prop-types";
import trackingLoader from '../../utils/tracking-loader';
// import './how-to-video.scss';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default class HowToVideo extends Component {



    render() {
        trackingLoader.trackEvent("video", {
            event_category: "engagement",
            event_label: this.props.title,
            value: 1
        });
        return (
            <React.Fragment>
                <Modal size="lg" show={this.props.show} onHide={this.props.handleClose} className="modal-video --borderless" centered>
                    <div className="close-button" onClick={this.props.handleClose}>
                        <FontAwesomeIcon  size="1x" icon={faTimesCircle} fixedWidth />
                    </div>
            
                    <Modal.Body>
                        <video width="100%" height="435" controls autoPlay webkitallowfullscreen mozallowfullscreen >
                                <source id="mp4" src={this.props.source} type="video/mp4"></source>     
                            Your browser does not support the video tag.
                        </video>
                    </Modal.Body>
                 
                </Modal>
            </React.Fragment>
        );
    }

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        show : PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired
    }
}
