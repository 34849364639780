import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import amzURLBuilder from '../../../utils/admin-regions'
import CopyDisabledComponent from './../../../utils/CopyDisabledComponent'


export default class ReviewStepShareNotSubmitted extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reviewOnAmazon : false
        }
    }

    handleScroll = () => {

        this.setState({
            reviewOnAmazon : true
        }, () => {
                let anchor = document.getElementById("anchor");

                window.scrollTo({
                    'behavior': 'smooth',
                    'left': 0,
                    'top': (anchor.offsetTop - 100)
                });
        })
 
    }
       

    render() {

        let submitText = "Review Submitted"
        let skipText = "Skip this step"

            if(this.props.disableSubmit) {
                submitText = "Processing"
                skipText = "Loading"
            }

        return (
            <>

            <Row as="section" className="instruction share-by-email" >
                <Container>
                    <Row>
                        <div className="content-header">
                            <h1>Share Your Experience through the Amazon Feedback E-mail</h1>
                            <p className="lead">
                                Submitting your review through the <strong>Amazon feedback e-mail</strong> is the quickest and easiest way to share your opinion. The steps below will guide you through the process.
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="instruction_content">
                                <h5>Step 1</h5>
                                <p className="instruction_content-copy">
                                    Open your mail client. This could be your <strong>Gmail, Apple Mail, Outlook, Yahoo,</strong> or any other mail client. Log in to the email address associated with your Amazon account.
                                </p>
                            </div>
                            
                        </Col>
                        <Col md={6} >
                            <div className="instruction_image">
                                <Image src={`https://dealpanda-public.s3.ca-central-1.amazonaws.com/review-instruction-email-step-1.jpg`} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="instruction_content">
                                <h5>Step 2</h5>
                                <p className="instruction_content-copy">
                                    In the search box, search for ‘<strong>{this.props.userProfile.name}, will you rate your transaction at Amazon.</strong>’ Your results should include an email from ‘<strong>Amazon Marketplace</strong>’ with that subject line.
                                </p>
                                {/* <Button variant="link" className="scroll-to" onClick={this.handleScroll}>I can't locate the e-mail</Button> */}

                            </div>
                            
                        </Col>
                        <Col md={6} >
                            <div className="instruction_image">
                                 <Image src={`https://dealpanda-public.s3.ca-central-1.amazonaws.com/review-instruction-email-step-2.jpg`} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="instruction_content">
                                <h5>Step 3</h5>
                                <p className="instruction_content-copy">
                                    Click to open the email. Click on <strong>Rate and Review Purchase</strong> and follow the instructions to submit your review. Return to this survey and let us know you've submitted your review by clicking the '<strong>Review Submitted</strong>' button below.
                                </p>
                                <div className="instruction_content-inspiration">
                                    <h6>Need some inspiration?</h6>
                                    <div className="inspirational-text">
                                        <p>Here’s what you had to say to us:</p>
                                        <CopyDisabledComponent content={this.props.reviewText} tagname="blockquote"/>
                                    </div>
                                </div>
                                
                            </div>

                        
                            
                        </Col>
                        <Col md={6} >
                            <div className="instruction_image">
                                 <Image src={`https://dealpanda-public.s3.ca-central-1.amazonaws.com/review-instruction-email-step-3.jpg`} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                            <div className="important-msg">
                                {/* Were you not able to find the email ? */}
                                <Button variant="link" className="scroll-to" onClick={this.handleScroll}>I can't locate the e-mail</Button>
                            </div>
                           <div className="instruction_content-navigation">
                                <Button variant="success" className="btn-submit"  onClick={this.props.handleReviewSubmittedClicked.bind()} disabled={this.props.disableSubmit}>{submitText}</Button>
                                <Button variant="link" className="skip-btn" onClick={this.props.handleJumpStep.bind(this, 5)}>{skipText}</Button>
                            </div>
                    </Row>
                </Container>
            </Row>
            

     
            <Row id="anchor" as="section" className={`instruction share-through-amazon ${this.state.reviewOnAmazon ? "--show" : ""}` } >
                <Container>
                    <Row  >
                        <div className="content-header">
                            <h1>Share Your Experience through the Amazon Orders Page</h1>
                            <p className="lead">
                                If you can’t locate the Amazon feedback email, you can also submit a review through the '<strong>Your Orders</strong>' page in your Amazon account. The steps below will help you submit your feedback.
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="instruction_content">
                                <h5>Step 1</h5>
                                <p className="instruction_content-copy">
                                    Open up a new tab and head to <strong>{amzURLBuilder.getDomain(this.props.userProfile.region)}</strong>. You may have to sign in to your Amazon account to continue. Click on ‘<strong>Returns & Orders</strong>.’
                                </p>
                            </div>
                            
                        </Col>
                        <Col md={6} >
                            <div className="instruction_image">
                                <Image src={`https://dealpanda-public.s3.ca-central-1.amazonaws.com/review-instruction-desktop-return-step-1.jpg`} />

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="instruction_content">
                                <h5>Step 2</h5>
                                <p className="instruction_content-copy">
        Locate the <strong>{this.props.bonusDeal.product_name}</strong>with an order date of <strong>{this.props.purchaseDate}</strong>. Click on the ‘<strong>Write a product review</strong>’ button to the right of the product.
                                </p>
                            </div>
                            
                        </Col>
                        <Col md={6} >
                            <div className="instruction_image">
                                <Image src={`https://dealpanda-public.s3.ca-central-1.amazonaws.com/review-instruction-desktop-return-step-2.jpg`} />
                            </div>
                        </Col>
                    </Row>
                     <Row>
                        <Col md={6}>
                            <div className="instruction_content">
                                <h5>Step 3</h5>
                                <p className="instruction_content-copy">
                                    Select the options that best reflects your experience and click '<strong>Submit</strong>' once you’re done. Return to this survey and let us know you've submitted your review by clicking the '<strong>Review Submitted</strong>' button below.
                                </p>
                                <div className="instruction_content-inspiration">
                                    <h6>Need some inspiration for your review?</h6>
                                    <div className="inspirational-text">
                                        <p>Here’s what you had to say to us:</p>
                                        <CopyDisabledComponent content={this.props.reviewText} tagname="blockquote"/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} >
                            <div className="instruction_image">
                                <Image src={`https://dealpanda-public.s3.ca-central-1.amazonaws.com/review-instruction-desktop-return-step-3.jpg`} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                           <div className="instruction_content-navigation">
                                <Button variant="success" className="btn-submit" onClick={this.props.handleReviewSubmittedClicked.bind()} disabled={this.props.disableSubmit}>{submitText}</Button>
                                <Button variant="link" className="skip-btn" onClick={this.props.handleJumpStep.bind(this, 5)} disabled={this.props.disableSubmit}>{skipText}</Button>
                            </div>
                    </Row>
                </Container>
            </Row>
            </>
        );
    }

    static propTypes = {
        bonusDeal: PropTypes.object.isRequired,
        reviewText: PropTypes.string.isRequired,
        userProfile: PropTypes.object.isRequired,
        handleJumpStep: PropTypes.func.isRequired,
        handleReviewSubmittedClicked: PropTypes.func.isRequired,
        disableSubmit:PropTypes.bool
    };
}