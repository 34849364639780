import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Body from '../../utils/utils-body'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import trackingLoader from '../../utils/tracking-loader';
import '../pages/Contact.scss';

class ContactPage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            modal: {
                show: false, 
                title:"",
            },
            form: {
                validate : false,
                isLoading: false,
                name: props.userProfile.name,
                email: props.userProfile.email,
                subject: "",
                message: ""
            },
            feedback: null
        };
    }

    componentDidMount = () => {
        Body.pageClass("add", `page_contact-us`);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.userProfile !== this.props.userProfile) {
            let form = this.state.form;
            form.email = this.props.userProfile.email;
            form.name = this.props.userProfile.name;
            this.setState({userProfile: this.props.userProfile, form: form});
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }

        this.setState({ 
            form: {
                ...this.state.form,
                validated: true,
                isLoading: true
            }
        });

       this.props.post("/api/v1/contact", new FormData(form)).then(response => {
           
            let alert = <Alert variant="success">Thank you for contacting us. <br />
                We will get back to you as soon as we review your message.</Alert>;

            this.setState({
                form: {
                    validate : false,
                    isLoading: false,
                    name: this.props.userProfile.name,
                    email: this.props.userProfile.email,
                    subject: "",
                    message: ""
                },
                feedback: alert
            });

            trackingLoader.trackEvent("contact", {
                event_category: "engagement",
                event_label: "contact_us"
            });

       }).catch(err => {
           let alert = <Alert variant="danger">There was an issue processing your request. Please try again later.</Alert>;
           this.setState({isLoading: false, feedback: alert, error: err});
       });
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let form = this.state.form;
        form[inputName] = inputValue;
        this.setState({form: form});
    }

    render() {
        const formValidated = this.state.form.validate;
        const buttonLabel = this.state.form.isLoading ? "Loading..." : "Submit";

        return (
            <Container as="main" className="page-narrow page-contact">
                <Row>
                    <Alert className="page-alert" variant="primary">
                        <strong className="alert-title">PLEASE READ: COVID-19 UPDATE</strong>
                        <p>Our delivery partners are currently experiencing delays in their processing and shipping times. They are working as fast as they can to ship test products out to you as soon as possible. If it’s been more than <strong>20</strong> business days since completing a survey and the test product has still not arrived, please <Link to="/contact">contact us.</Link></p>
                    </Alert>
                </Row>
                <Row>
                    <Col  className="page-header">
                        <h1 className="display-3">Contact Us</h1>
                        <p className="lead">We’re happy to answer any questions you may have. <br/>Just send us a message below.</p>
                    </Col>
                </Row>
                <Row className="form-wrapper">
                    <Form
                        className="sign-in_form"
                        validated={formValidated}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        {this.state.feedback}
                        <Form.Group as="div">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            placeholder="John Smith"
                            defaultValue={this.state.form.name}
                            readOnly={true}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as="div">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                            required
                            type="email"
                            placeholder="panda@gmail.com"
                            defaultValue={this.state.form.email}
                            readOnly={true}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as="div">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            name="subject"
                            placeholder="Please Help"
                            value={this.state.form.subject}
                            onChange={this.handleInputChange}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                 

                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name="message" rows="6" value={this.state.form.message} onChange={this.handleInputChange} required />
                        </Form.Group>

                        <Button className="btn btn-lg btn-dp mt-2 btn-block" type="submit" disabled={this.state.form.isLoading}>{buttonLabel}</Button>
                        
                    </Form>
                </Row>
            </Container>
        );
  }

  static propTypes = {
      userProfile: PropTypes.object.isRequired,
      post: PropTypes.func.isRequired
  }
}

export default ContactPage;
