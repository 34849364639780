const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const { fromCognitoIdentityPool } = require("@aws-sdk/credential-provider-cognito-identity");
const { S3Client, PutObjectCommand } = require("@aws-sdk/client-s3");

export default class S3Uploader {

    constructor(identityPoolId, region) {
        // Initialize the Amazon Cognito credentials provider
        this.s3 = new S3Client({
            region: region,
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: region }),
                identityPoolId: identityPoolId,
            }),
        });
    }

    upload = async (bucketName, file, userId) => {
        // const searchParams = new URLSearchParams({
        //     orignalName: file.name
        // });
        const fileExt = file.type.split("/");
        const now = new Date();
        const month = `${now.getMonth() + 1}`.padStart(2, "0");
        const keyPrefix = `uploads/${now.getFullYear()}${month}`;
        const bucketKey = `${keyPrefix}/${userId}_${now.getTime()}.${fileExt[1]}`;
        const params = {
            Bucket: bucketName,
            Key: bucketKey,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
            // Tagging: searchParams.toString() //FIX ME LUKE
        }
        const data = await this.s3.send(new PutObjectCommand(params));
        // console.log("uploaded file", data);
        data._url = `https://s3.amazonaws.com/${bucketName}/${bucketKey}`;
        return data;
    }
}