import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Flags from '../snippets/flags' 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faRedoAlt,  faUserSecret, faEllipsisV, faSearch} from '@fortawesome/free-solid-svg-icons'
import PreLoader from '../snippets/PreLoader'
import ModalFetch from '../snippets/modal-fetch';
import ModalImage from '../snippets/modal-image';
import RETAILS from '../../utils/retails'
import './admin-deals-manage.scss';
import dayjs from 'dayjs'

export default class AdminManageDeals extends Component {

    status = ["ALL", "SUBMITTED", "PAID"];

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/deals/manage/:id",
            exact: true,
            strict: false
        });
		this.state = {
            id: match ? match.params.id : null,
            deal: {},
            deals: [],
            page: 1,
            status: "SUBMITTED", //submitted, paid, ""
            resultsPerPage: 20,
            showMore: true,
            isBtnLoading: false, 
            isLoading: true,
            dealAlerts: {},
            isCheckingPaypal: false,
            amazonSellerName: [],
            amazonUpdate: "",
            isProcessing:false,
            modal: null,
            modalTemp: {
                show : false, 
                body: "",
                header: "", 
                class: "",
                size: "",
                footer: ""
            }
        }
    }

     componentDidMount = () => {
        Promise.all([this.fetchDeal(this.state.id), this.fetchUserDeals(this.state.page, this.state.resultsPerPage, this.state.status)]).then(values => {
            this.setState({
                deal: values[0],
                deals: values[1],
                showMore: values[1].length >= this.state.resultsPerPage ? true : false,
                
            }, () => {
                this.fetchMWSCredentials().then(items => {
                    // need to redo this get one object from api endpoint that get's one result instead of looping
                    let amazonSellerNames = []
                    items.forEach(function(item){
                        let param = {
                            sellerId: item.seller_id,
                            accessKey: item.access_key,
                            secretKey: item.secret_key,
                            name:item.name
                        }

                        if (this.state.deal.hasOwnProperty("deal_amazon_seller_name") && (this.state.deal.deal_amazon_seller_name.some(e => e.seller_id === item.seller_id) ) ) {
                            amazonSellerNames.push(param)
                        } else if(this.state.deal.deal_amazon_brand_id === item.seller_id) {                             
                            amazonSellerNames.push(param)
                        }
                    }.bind(this))

                    this.setState({
                        amazonSellerName :  amazonSellerNames,
                        isLoading: false
                    })
                })
            });
        });
    }
    
    fetchMWSCredentials() {
        return this.props.get("/api/v1/settings/mwscredentials", {}).then(response => {
            return Promise.resolve(response);
        }).catch(err => {
            console.warn(err);
            return Promise.reject(err);
        });
    }

    fetchDeal = (dealId) => {
        const params = {
            deal_id: dealId,
            filter: "admin"
        }
        return this.props.get("/api/v1/deal", params).then(response => {
            return Promise.resolve(response.deal);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    fetchUserDeals = (page, resultsPerPage, status) => {
        const params = {
            page: page,
            limit: resultsPerPage,
            status: status.toLowerCase() === "all" ? "" : status.toLowerCase(),
            filter: "admin"
        }
        return this.props.get("/api/v1/deal/" + this.state.id + "/claims", params).then(response => {
            console.log(response.deals)
            return Promise.resolve(response.deals);
        }).catch(err  => {
            console.error(err);
            return Promise.reject(err);
        });
    }
    
    fetchFraudCheck = (userId, paypalEmail) => {
        const params = {
            user_id: userId,
            email: paypalEmail
        }
        return this.props.get("/api/v1/user/fraudcheck", params).then(response => {
            return Promise.resolve(response.results);
        });
    }

    dismissFraudAlert = (itemId) => {
        let dealAlerts = this.state.dealAlerts;
        delete dealAlerts[itemId];
        this.setState({dealAlerts: dealAlerts});
    }

    handlePaypalCheck = (itemId, userId, paypalEmail) => {
        this.setState({
            isCheckingPaypal: true
        });
        this.fetchFraudCheck(userId, paypalEmail).then(data => {
            let dealAlerts = this.state.dealAlerts;
            
            const ip = data.hasOwnProperty("ip") && data.ip !== {} ? true : false;
            const pp = data.hasOwnProperty("pp") && data.pp.length > 0 ? true : false;
            dealAlerts[itemId] = ip || pp ? data : false;
            
            this.setState({
                dealAlerts: dealAlerts,
                isCheckingPaypal: false
            });
        });
    }


    //  <Button variant="link" onClick={() => this.updateStatus("paid", item.user_id)} data-userid={item.user_id}>
    //                     <span title="paid"><FontAwesomeIcon icon={faDollarSign} size="lg" fixedWidth /></span>
    //                 </Button>
     renderReimbursementModal(data) {
        let StatusCode = data.StatusCode;

        // console.log(data)
        let modal = {
            button:"",
            className: "user-reimbursement",
            title: "",
            type: "success",
            size: "md",
            content: ""
        }

        if(StatusCode === 200) {
            let item = data.result; 
            let shippedValue = item.shipped
            let totalAmount = shippedValue ? `$${item.amount.toFixed(2)}` : "-"
            let itemPriceValue = shippedValue ? `$${item.breakdown.itemPrice}` : "-"
            let itemTaxValue = shippedValue ? `$${item.breakdown.itemTax}` : "-"
            let promotionDiscountValue = shippedValue ? `$${item.breakdown.promotionDiscount}` : "-"
            let sellerName = item.hasOwnProperty("seller_name") ? item.seller_name : "n/a"
            // Comoponent
            let shipped = ""

            let currency = shippedValue ? item.breakdown.currency : ""
            let promotionDiscount = ""
                modal.button = "payment"
                modal.type = "success"
                modal.title = "Total Reimbursemnt"
                modal.className = "amazon_reimbursement"
                
                if(promotionDiscountValue > 0 ) {
                    promotionDiscount = 
                    <div className="reimbursement-breakdown_item --promotion-discount">
                        <div className="item-label">
                            Promotion Discount
                        </div>
                        <div className="item-cost">
                            - {item.breakdown.promotionDiscount}
                        </div>
                    </div>
                }

                if(shippedValue) {
                    shipped = 
                    <div className={"shipping"}>Shipped</div>
                } else {
                    shipped = 
                    <div className={"shipping not-shipped"}>Not shipped yet</div>
                }
            
            let breakdown = ""
                if(item.hasOwnProperty("breakdown")) {
                    breakdown = 
                     <div className="reimbursement-breakdown">
                            <div className="reimbursement-breakdown_item">
                                <div className="item-label">
                                    Product Price
                                </div>
                                <div className="item-cost">
                                    {itemPriceValue}
                                </div>
                            </div>
                            <div className="reimbursement-breakdown_item">
                                <div className="item-label">
                                    Product Tax
                                </div>
                                <div className="item-cost">
                                    {itemTaxValue}
                                </div>
                            </div>
                            {promotionDiscount}
                        </div>

                }




                modal.content = 
                    <>
                        <div className="reimbursement-amount">
                            {totalAmount} {currency}
                        </div>
                        {breakdown}
                       
                        <div className="reimbursement-breakdown_additional-info">
                            {/* <h5>Additional Info</h5> */}
                            <div className="reimbursement-breakdown_item">
                                <div className="item-label">
                                    Shipping Status
                                </div>
                                <div className="item-cost">
                                    {shipped}
                                </div>
                            </div>
                            <div className="reimbursement-breakdown_item">
                                <div className="item-label">
                                    PayPal Email
                                </div>
                                <div className="item-email">
                                    {this.state.amazonUpdate.email}
                                </div>
                            </div>
                            <div className="reimbursement-breakdown_item">
                                <div className="item-label">
                                    Seller Name
                                </div>
                                <div className="item-email">
                                    {sellerName}
                                </div>
                            </div>
                            
                        </div>
                         
                </>
                
        } else {
            modal.button =""
            modal.className = "amazon_reimbursement --error"
            modal.title = "Oops, Something is wrong"
            modal.type = "error"
            modal.content = 
            <>
                <p>"{data.Message}"</p>
            </>
        }
        

        this.setState({
            modal: {...modal},
            isProcessing: false
        });
        
    }
    
    handleAmazonReimbursement = (object) => {
        this.setState({
            isProcessing: true
        })

        let orderNumber = ""
        let email = ""
        let userId = ""
        // let sellerName = ""

            if (this.state.amazonUpdate === "") {
                userId = object.id
                orderNumber = object.order_number
                email = object.email
                // sellerName = object.email
            } else {
                userId = this.state.amazonUpdate.id
                orderNumber = this.state.amazonUpdate.order_number
                email = this.state.amazonUpdate.email
            }

            let param = {
                "sellers": this.state.amazonSellerName,
                "orderId": orderNumber,
                "asins" : [this.state.deal.deal_asin]  
            }
            if (this.state.deal.alternate_asins){
                let alternate_asins = this.state.deal.alternate_asins.split(',');
                alternate_asins.forEach(element => {
                    param.asins.push(element.trim());
                });
            }

            this.props.getAmazonReimbursement(param).then((response) => {
                    this.renderReimbursementModal(response)       
                }).catch(err => {
                    this.renderReimbursementModal(err)     
                    console.log(err)
                    console.log(err.body)
            });
        
        this.setState({
            amazonUpdate: {
                id: userId,
                order_number: orderNumber,
                email: email
            }
        })

    }

    handleStatusChange = (e) => {
        e.preventDefault();
        const status = e.target.value;
        this.fetchUserDeals(this.state.page, this.state.resultsPerPage, status).then(results => {
            this.setState({
                status: status,
                deals: results,
                showMore: results.length === this.state.resultsPerPage ? true: false
            });
        });
    }

    loadMore = (e) => {
        e.preventDefault();
        const page = this.state.page + 1;
        this.setState({isBtnLoading: true})
        this.fetchUserDeals(page, this.state.resultsPerPage, this.state.status).then(results => {
            if (results.length > 0) {
                const moreResults = this.state.deals.concat(results);
                this.setState({
                    deals: moreResults,
                    page: page,
                    isBtnLoading: false,
                    showMore: results.length === this.state.resultsPerPage ? true: false
                });
            } else {
                this.setState({
                    showMore: false,
                    isBtnLoading: false
                });
            }
        });
    }

    updateStatus = (status, userId) => {
        this.setState({isProcessing: true})
            let formData = new FormData();
            formData.append("status", status);
            formData.append("user_id", userId);
            return this.props.put("/api/v1/deal/" + this.state.id + "/status", formData).then(response => {
                if (response.result) {
                    
                    let newDeals = [];
                    this.state.deals.forEach(d => {
                        if (d.user_id === userId) {
                            d.status = status;
                            d.paid_at = status === "paid" ? "now" : "-";
                        }

                        newDeals.push(d);
                    });
                    this.setState({
                        deals: newDeals,
                        isProcessing: false
                    },this.closeModal());
                    
                    return Promise.resolve(response.result);
                }
            }).catch(err  => {
                console.error(err);
                return Promise.reject(err);
            });
    
    }

    renderQuestionAndAnswer = (deals, deal, userName) => {
        let output = []
        let comp = deals.answers
        let viva = deals.deal_answer

        comp.concat([viva]).forEach(function (item, idx) {
            let question = "";
            if (idx + 1 > comp.length) {
                question = deal.deal_question;
            } else {
                question = deal.competitors[idx].question;
            }

            // Question : What page is our product in
            if (deals.hasOwnProperty("deal_our_product_page")) {
                output.push( 
                    <div key={idx}>
                        <div className="question">What page is our product listed in? </div> <span className="answer">{deals.deal_our_product_page}</span>
                    </div>
                )
            }
            output.push( 
                <div key={idx}>
                    <div className="question">{question} </div> <span className="answer">{item}</span>
                </div>
            )
        })

        // Put in modal
        let modal = {
            button:"",
            className: "deal-manage_qanda",
            title: userName + "'s answer",
            type: "success",
            size: "md",
            content: <div className="deal-manage_qanda-wrapper">{output}</div>
        }    

        this.setState({
            modal: {...modal}
        });
    }

    showImage = (title, images) => {
        let output = []
            
            images.forEach((image) => {
                output.push(
                    <img src={image} alt={title}/>
                )
            })
            
            this.showModal("screenshots", "xl", title, <div classes="proof-screenshots">{output}</div>, "")
    }

    hideModal = () => {
        this.setState({
            modalTemp: {
                show : false, 
                body: "",
                header: "", 
                class: "",
                size: "",
                footer: ""
            }
        });
    }

    showModal = ( classes, size, header, body, footer) => {
        this.setState({
            modalTemp: {
                show : true, 
                body: body,
                header: header, 
                class: classes,
                size: size,
                footer: footer
            }
        });
    }

    renderToolTip = (body, button) => {
        let output = 
            <>
                <OverlayTrigger
                    placement={"top"}
                    overlay={
                    <Tooltip id={`tooltip-top`}>
                        {body}
                    </Tooltip>
                    }
                >
                    <Button variant="link">{button}</Button>
                </OverlayTrigger>
            </>

        return output
    }

    fetchUserPrimaryEmail =(userId)=> {
        const params = {}
        return this.props.get("/api/v1/user/" + userId, params).then(response => {
            return Promise.resolve(response.result.email);
        }).catch(err  => {
            return Promise.reject([]);
        });

    }

    renderList = () => {
        let lst = [];

        this.state.deals.forEach(item => {
            // let QandA = this.renderQuestionAndAnswer(item.answers,item.deal_answer, this.state.deal);
            let cancelLink = null;
            let paidLink = null;
            let removeLink = null;
            let checkLink = null;
            let paypalEmail = item.paypal_email !== "" ? item.paypal_email : "Please use there primary email";
            let purchaseScreenshot = "-"
            let reviewScreenshot = "-"
            let status = item.status

            // TODO uncomment to use new code instead of legacy
            if (item.hasOwnProperty("deal_proof_of_purchase")) {
                purchaseScreenshot = 
                    <Button size="sm" variant="link" className="mr-0 proof-of-image" style={{backgroundImage: "url("+item.deal_proof_of_purchase[0]+")"}} disabled={this.state.isProcessing} onClick={this.showImage.bind(this, "Proof of purchase", item.deal_proof_of_purchase)} >
                    </Button>
            }

            if (item.hasOwnProperty("deal_proof_of_review")) {
                reviewScreenshot = 
                <Button size="sm" variant="link" className="mr-0 proof-of-image" style={{backgroundImage: "url("+item.deal_proof_of_review[0]+")"}} disabled={this.state.isProcessing} onClick={this.showImage.bind(this, "Proof of review", item.deal_proof_of_review)} >
                </Button> 
            } else {
                let futureDate = dayjs(item.proof_of_purchase_date).add(7, "day").format();
                    reviewScreenshot = `Enabled for user on ${dayjs(futureDate).format("MMM DD") }`                   
                    // This is fail safe incase date is NAN
                    if(isNaN(Date.parse(futureDate))) {
                        futureDate = "Missing Live Review"
                    }

                    status = "Pending Review"
            }

            if (item.status === "submitted") {

                if (status === "Pending Review") {
                    paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={true}  onClick={() => this.updateStatus("paid", item.user_id)} data-userid={item.user_id} >
                        Paid
                    </Button>
                } else {
                    paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={this.state.isProcessing}  onClick={() => this.updateStatus("paid", item.user_id)} data-userid={item.user_id} >
                        Paid
                    </Button>
                }
              
                checkLink = 
                    <Button variant="link" className="dropdown-item" onClick={() => {this.handlePaypalCheck(item._id, item.user_id, item.paypal_email)}} disabled={this.state.isCheckingPaypal}>
                        {/* {this.state.isCheckingPaypal ? <FontAwesomeIcon icon={faSpinner} size="sm" fixedWidth spin /> : checkedLabel} */}
                        <FontAwesomeIcon icon={faUserSecret} size="sm" fixedWidth /> Fraud Check
                    </Button>

                cancelLink = 
                    <Button variant="link" className="dropdown-item" onClick={() => this.updateStatus("cancelled", item.user_id)} data-userid={item.user_id}>
                        <FontAwesomeIcon icon={faBan} size="sm" fixedWidth />Cancel 
                    </Button>

                removeLink =    
                    <Button variant="link" className=" dropdown-item" onClick={() => this.updateStatus("remove", item.user_id)} data-userid={item.user_id}>
                        <FontAwesomeIcon icon={faRedoAlt} size="sm" fixedWidth /> Reset
                    </Button>
            } else if (item.status === "remove") {
                paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={true}  >
                        Removed
                    </Button>
            } else {
                paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={true}  >
                        Completed
                    </Button>
            }

            lst.push(
                // inactive
                <tr key={item._id} data-id={item._id}>
                    <td className="table-date">
                        {item.created_at}
                    </td>
                    <td className="table-data_name">
                        <Link to={"/admin/users/detail/" + item.user_id}>{item.user_name}</Link>
                        <p className="small">{paypalEmail}</p>
                    </td>
                    <td className="text-center">{item.deal_keyword}</td>
                    <td className="text-center">
                        <Button size="sm" className="mr-0" disabled={this.state.isProcessing} onClick={() => this.renderQuestionAndAnswer(item, this.state.deal, item.user_name)} >
                            Answers
                        </Button>
                    </td>

                    <td className="text-center table-data_screenshot">
                        {purchaseScreenshot}
                    </td>
                    
                    <td className="text-center table-data_screenshot">
                        {reviewScreenshot}
                    </td>

                    {/* <td className="table-date">
                        ${this.state.dea.deal_amount}
                    </td> */}

                    <td className="table-data_status">
                        <Badge variant={item.status === "paid" ? "success" : "warning"}>{status}</Badge>
                    </td>

                    <td className="table-date text-center">
                        <strong>{item.paid_at === "" ? "-" : item.paid_at}</strong>
                    </td>

                    <td>
                             <div className="table-data_action-bar">
                                {paidLink}
                            
                                <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {checkLink}
                                        {cancelLink}
                                        {removeLink}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                    </td>
                    
              
                </tr>
            );

            // show fraud alerts
            if (this.state.dealAlerts.hasOwnProperty(item._id) && this.state.dealAlerts[item._id] !== false) {
                let otherUsers = [];
                this.state.dealAlerts[item._id].pp.forEach(i => {
                    otherUsers.push(<div key={i.email}>{i.name} -- {i.email}</div>);
                });
                if (otherUsers.length) {
                    otherUsers.unshift(<strong key={"header_pp"}>This paypal email was used by other accounts:</strong>);
                }
                let otherIP = [];
                Object.keys(this.state.dealAlerts[item._id].ip).forEach(i => {
                    let ipdata = this.state.dealAlerts[item._id].ip[i];
                    let ipusers = [];
                    ipdata.users.forEach(u => {
                        ipusers.push(<div key={"ipuser_" + u.user_id}>
                            &mdash; <Link to={"/admin/users/detail/" + u.user_id}>{u.email}</Link>
                        </div>);
                    });
                    otherIP.push(<div key={i}>
                        <div>{i} - {ipdata.city}, {ipdata.region} - {ipdata.org} - {ipdata.isp}</div>
                        {ipusers}
                    </div>);
                });
                if (otherIP.length) {
                    otherIP.unshift(<strong key={"header_ip"}>This account logged in from ips used by other accounts:</strong>);
                }

                if (otherUsers.length || otherIP.length) {
                    lst.push(
                        <tr key={"alert_" + item._id}>
                            <td colSpan="9">
                                <Alert variant="warning" onClose={() => {this.dismissFraudAlert(item._id)}} dismissible>
                                    {otherUsers}
                                    <br />
                                    {otherIP}
                                </Alert>
                            </td>
                        </tr>
                    );
                }
            }
        });

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="9">No results</td>
                </tr>                
            );
        }

        return lst;
    }

    renderListBuyFirst = () => {
        let lst = [];

        this.state.deals.forEach(item => {
            let cancelLink = null;
            let paidLink = null;
            let removeLink = null;
            let checkLink = null;
            let paypalEmail = item.paypal_email !== "" ? item.paypal_email : "Please use there primary email";
            let amazonStatus = ""
            let reimbursement = ""
            let sellerName = "n/a"


            // TODO uncomment to use new code instead of legacy
            if (item.status === "submitted") {
                paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={this.state.isProcessing}  onClick={() => this.updateStatus("paid", item.user_id)} data-userid={item.user_id} >
                        Paid
                    </Button>
                checkLink = 
                    <Button variant="link" className="dropdown-item" onClick={() => {this.handlePaypalCheck(item._id, item.user_id, item.paypal_email)}} disabled={this.state.isCheckingPaypal}>
                        {/* {this.state.isCheckingPaypal ? <FontAwesomeIcon icon={faSpinner} size="sm" fixedWidth spin /> : checkedLabel} */}
                        <FontAwesomeIcon icon={faUserSecret} size="sm" fixedWidth /> Fraud Check
                    </Button>

                cancelLink = 
                    <Button variant="link" className="dropdown-item" onClick={() => this.updateStatus("cancelled", item.user_id)} data-userid={item.user_id}>
                        <FontAwesomeIcon icon={faBan} size="sm" fixedWidth />Cancel 
                    </Button>

                removeLink =    
                    <Button variant="link" className=" dropdown-item" onClick={() => this.updateStatus("remove", item.user_id)} data-userid={item.user_id}>
                        <FontAwesomeIcon icon={faRedoAlt} size="sm" fixedWidth /> Reset
                    </Button>

                amazonStatus =    
                    <Button variant="link" className=" dropdown-item" disabled={this.state.isProcessing} onClick={() => this.handleAmazonReimbursement({order_number:item.order_number, email: item.paypal_email, id: item.user_id })}>
                        <FontAwesomeIcon icon={faSearch} size="sm" fixedWidth />  Amz Check
                    </Button>    
            } else if (item.status === "remove") {
                paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={true}  >
                        Removed
                    </Button>
            } else {
                paidLink = 
                    <Button size="sm" className="mr-0 " variant="success" disabled={true}  >
                        Completed
                    </Button>
            }
            
            if(item.hasOwnProperty("order_status")) {
                let amzOrder = item.order_status;
                let error = ["input_invalid"];
                let lastUpdate =  <li className="date">{dayjs(item.order_status_last_update).format("MMM DD") } - {dayjs(item.order_status_last_update).format("HH:MM A") }</li>
                if (amzOrder.shipped) {
                    let promotionDiscount = amzOrder.breakdown.promotionDiscount
                    let body = 
                        <div className="data_live_amount_tooltip">
                            <div className="item">
                                <p className="label">Cost Breakdown</p>
                                <ul className="breakdown">
                                    <li><strong>Price</strong>:  ${amzOrder.breakdown.itemPrice.toFixed(2)}</li>
                                    <li><strong>Tax</strong>:  ${amzOrder.breakdown.itemTax.toFixed(2)}</li>
                                    {promotionDiscount > 0 ? (
                                        <li><strong>Tax</strong>: ${promotionDiscount.toFixed(2)}</li>
                                    ) : null} 
                                </ul>
                            </div>
                            <div className="item">
                                <p className="label">Last Update</p>
                                <ul className="breakdown">
                                    {lastUpdate}
                                </ul>
                            </div>
                        </div>
                    reimbursement = this.renderToolTip(body, `$${amzOrder.amount.toFixed(2)}`)
                } else if (error.includes(amzOrder)) {
                    let body = 
                        <div className="data_live_amount_tooltip">
                            <div className="item">
                                <p className="label">Message</p>
                                <p className="shipping">{amzOrder}</p>
                            </div>
                            <div className="item">
                                <p className="label">Last Update</p>
                                <ul className="breakdown">
                                    {lastUpdate}
                                </ul>
                            </div>
                        </div>
                    reimbursement = this.renderToolTip(body, "Error")
                } else {
                    let body = 
                        <div className="data_live_amount_tooltip">
                            <div className="item">
                                <p className="label">Last Update</p>
                                <ul className="breakdown">
                                    {lastUpdate}
                                </ul>
                            </div>
                        </div>
                    reimbursement = this.renderToolTip(body, "Not Shipped")
                }
            } else {
                reimbursement =  
                    <Button variant="link" className=" dropdown-item" disabled={this.state.isProcessing} onClick={() => this.handleAmazonReimbursement({order_number:item.order_number, email: item.paypal_email, id: item.user_id })}>
                        Manual Check
                    </Button>    
            }

            if (item.hasOwnProperty("order_status")) {
                if (item.order_status.hasOwnProperty("seller_name")) {
                    sellerName  = item.order_status.seller_name
                }
            }

            lst.push(
                // inactive
                <tr key={item._id}>
                    <td className="table-date">
                        {item.created_at}
                    </td>
                    <td className="table-data_name">
                        <Link to={"/admin/users/detail/" + item.user_id}>{item.user_name}</Link>
                        <p className="small">{paypalEmail}</p>
                    </td>
                    <td className="text-center">{sellerName}</td>
                    <td className="text-center">{item.deal_keyword}</td>
                    <td className="text-center">
                        <Button size="sm" className="mr-0" disabled={this.state.isProcessing} onClick={() => this.renderQuestionAndAnswer(item, this.state.deal, item.user_name)} >
                            Answers
                        </Button>
                    </td>

                    <td className="table-data_order-number">{item.order_number}</td>

                    <td className="table-date">
                        <strong>{item.paid_at === "" ? "-" : item.paid_at}</strong>
                    </td>

                    <td className="text-center table-data_live_amount">
                        {reimbursement}
                    </td>

                    <td className="table-data_status">
                        <Badge variant={item.status === "paid" ? "success" : "warning"}>{item.status}</Badge>
                    </td>
                    
                    <td>
                             <div className="table-data_action-bar">
                                {paidLink}
                            
                                <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {amazonStatus}
                                        {checkLink}
                                        {cancelLink}
                                        {removeLink}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                    </td>
                </tr>
            );

            // show fraud alerts
            if (this.state.dealAlerts.hasOwnProperty(item._id) && this.state.dealAlerts[item._id] !== false) {
                let otherUsers = [];
                this.state.dealAlerts[item._id].pp.forEach(i => {
                    otherUsers.push(<div key={i.email}>{i.name} -- {i.email}</div>);
                });
                if (otherUsers.length) {
                    otherUsers.unshift(<strong key={"header_pp"}>This paypal email was used by other accounts:</strong>);
                }
                let otherIP = [];
                Object.keys(this.state.dealAlerts[item._id].ip).forEach(i => {
                    let ipdata = this.state.dealAlerts[item._id].ip[i];
                    let ipusers = [];
                    ipdata.users.forEach(u => {
                        ipusers.push(<div key={"ipuser_" + u.user_id}>
                            &mdash; <Link to={"/admin/users/detail/" + u.user_id}>{u.email}</Link>
                        </div>);
                    });
                    otherIP.push(<div key={i}>
                        <div>{i} - {ipdata.city}, {ipdata.region} - {ipdata.org} - {ipdata.isp}</div>
                        {ipusers}
                    </div>);
                });
                if (otherIP.length) {
                    otherIP.unshift(<strong key={"header_ip"}>This account logged in from ips used by other accounts:</strong>);
                }

                if (otherUsers.length || otherIP.length) {
                    lst.push(
                        <tr key={"alert_" + item._id}>
                            <td colSpan="9">
                                <Alert variant="warning" onClose={() => {this.dismissFraudAlert(item._id)}} dismissible>
                                    {otherUsers}
                                    <br />
                                    {otherIP}
                                </Alert>
                            </td>
                        </tr>
                    );
                }
            }
        });

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="9">No results</td>
                </tr>                
            );
        }

        return lst;
    }

    renderTable = () => {

        let loadMoreButton = null ;
        
        if(this.state.deals.length >= this.state.resultsPerPage ) {
            loadMoreButton =    <Button className="btn-load-more" onClick={this.loadMore} disabled={!this.state.showMore || this.state.isBtnLoading} size="sm">
                                    {this.state.isBtnLoading ? 'Loading…' : 'Load More'}
                                </Button>
        }

        return (
            <React.Fragment>
                    <Table>
                        <thead>
                            <tr>
                                <th>Submit Date</th>
                                <th>User Paypal</th>
                         
                                <th className="text-center">Keyword</th>
                                <th className="text-center">Q&A</th>
                                <th className="text-center">Receipts</th>
                                <th className="text-center">Live Review</th>
                                {/* <th >Rebate Amount</th> */}
                                <th className="table-data_status">Status</th>
                                <th className="text-center">Paid Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th colSpan="10" className="load-more_wrapper">
                                    {loadMoreButton}
                                </th>
                            </tr>
                        </tfoot>
                        <tbody>
                            {this.renderList()}
                        </tbody>
                    </Table>
            </React.Fragment>
        )
    }

    renderTableBuyFirst = () => {

        let loadMoreButton = null ;
        
        if(this.state.deals.length >= this.state.resultsPerPage ) {
            loadMoreButton =    <Button className="btn-load-more" onClick={this.loadMore} disabled={!this.state.showMore || this.state.isBtnLoading} size="sm">
                                    {this.state.isBtnLoading ? 'Loading…' : 'Load More'}
                                </Button>
        }

        return (
            <React.Fragment>
                    <Table>
                        <thead>
                            <tr>
                                <th>Submit Date</th>
                                <th>User Paypal</th>
                                <th className="text-center">Seller Name</th>
                                <th className="text-center">Keyword</th>
                                <th className="text-center">Q&A</th>
                                <th className="table-date">Order #</th>
                                <th>Paid Date</th>
                                <th className="text-center">AMZ Status</th>
                                {/* <th className="text-center">AMZ Status</th> */}
                                <th className="table-data_status">Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th colSpan="10" className="load-more_wrapper">
                                    {loadMoreButton}
                                </th>
                            </tr>
                        </tfoot>
                        <tbody>
                            {this.renderListBuyFirst()}
                        </tbody>
                    </Table>
            </React.Fragment>
        )
    }

    closeModal = () => {
        this.setState({
            modal: null,
            amazonUpdate: ""
        });
    }


    render = () => {
        let modal = null;

        if (this.state.modal !== null) {
            modal = <ModalFetch 
                        {...this.state}
                        className={this.state.modal.className} 
                        show={true} 
                        button={this.state.modal.button}
                        closeModal={this.closeModal} 
                        type={this.state.modal.type}
                        title={this.state.modal.title} 
                        size={this.state.modal.size} 
                        content={this.state.modal.content} 
                        submit={this.updateStatus.bind(this, "paid", this.state.amazonUpdate.id)}
                        retry={this.handleAmazonReimbursement.bind(this, this.state.amazonUpdate)}
                        // updateStatus={}
                        modalIsProcessing={this.state.isProcessing}
                        />;
        }  

        if (this.state.modalTemp.show) {
            modal = <ModalImage 
                        modal={this.state.modalTemp} 
                        downloadImages={this.downloadImages}
                        handleClose={this.hideModal}
                        />
        }
        const statusOptions = [];
            this.status.forEach(item => {
                statusOptions.push(<option key={item} value={item}>{item}</option>);
            });
        
        let buyFirstAdditionalDetail = ""
        let table = ""

            if (this.state.deal.deal_type === "buy_first") {
                let sellerName = []

                this.state.amazonSellerName.forEach((item, idx) => {
                    sellerName.push(<div key={idx}>{item.name}</div>);
                });
                
                table = this.renderTableBuyFirst()
                buyFirstAdditionalDetail =
                <>
                    <li className="product_detail-item">
                        <div className="item-title">Seller Name :</div>
                        {sellerName}
                    </li>
                    <li className="product_detail-item">
                        <div className="item-title">Brand Name :</div>
                        {this.state.deal.hasOwnProperty("deal_amazon_sister_brand_name") ?  this.state.deal.deal_amazon_sister_brand_name : "n/a"}
                    </li>
                </>
            } else {
                table = this.renderTable()
            }

        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <>
                    <Container fluid={true} className="deals_manage">
                        <Row>
                            <Col xs={12} lg={2} className="d-none d-sm-none d-md-none d-lg-block">
                                <section className="deals_manage_product">
                                    <div className="product_image">
                                         <img src={this.state.deal.product_image_url} alt={this.state.deal.product_name} />
                                    </div>
                                    <ul className="product_detail">
                                        <li className="product_detail-item --retail-logo">
                                            <div className="item-title">Channel :</div>
                                            <img alt="retail logo" src={RETAILS.getLogo(this.state.deal.deal_channel, "sm")}/> 
                                        </li>
                                        <li className="product_detail-item">
                                            <div className="item-title">Product Name :</div>
                                            {this.state.deal.deal_name}
                                        </li>
                                        <li className="product_detail-item">
                                            <div className="item-title">ASIN :</div>
                                            {this.state.deal.deal_asin}
                                        </li>
                                        <li className="product_detail-item">
                                            <div className="item-title">Region :</div>
                                            <Flags region={this.state.deal.deal_region}/>
                                        </li>
                                        
                                        <li className="product_detail-item">
                                            <div className="item-title">Amount :</div>
                                            ${this.state.deal.deal_amount}
                                        </li>

                                        <li className="product_detail-item">
                                            <div className="item-title">User Type :</div>
                                            {this.state.deal.deal_risk_level === "NOT_RISKY" ? "Regular" : this.state.deal.deal_risk_level}
                                        </li>

                                        {buyFirstAdditionalDetail}
                                   
                                        
                                        {/* <li className="product_detail-item">
                                            <div className="item-title">Amount :</div>
                                            
                                        </li> */}
                                    </ul>

                                </section>
                            </Col>
                            <Col xs={12}  lg={10}>
                                <section className="deals_overview">

                                    <div className="section-header">
                                         <p className="header-title">Manage Deal's Redeemers</p>
                                    
                                        <div className="table-search_wrapper">
                                  
                                            <div className="table-search_filter-wrapper">
                                                <span className="label">Filter Status By:</span>
                                                <div className="custom-select">
                                                    <Form.Control className="table-search_filter" as="select"  value={this.state.status} name="status" onChange={this.handleStatusChange}>
                                                        {statusOptions}
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {table}
                                </section>
                            </Col>
                        </Row>
                    </Container>
                    {modal}
                    </>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        getAmazonReimbursement: PropTypes.func.isRequired,
        get: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        location: PropTypes.object
    }
}