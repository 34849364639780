import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from 'react-bootstrap/Badge'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './admin-mobile-nav.scss';


export default class AdminMobileSideNav extends Component {

    constructor(props) {
		super(props);
		this.state = {
            navExpanded: false
        }
    }

    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
    }

    handleNavClose = () => {
        this.setState({ navExpanded: false });
    }

    renderItems = () => {
        let lst = [];
        this.props.links.forEach(item => {
            let links = [];
            
            item.links.forEach(pathname => {
                let currentPage = this.props.location.pathname === pathname.path ? "active" : "";
                if (pathname.path.startsWith("http")) {
                    links.push(
                        <ListGroup.Item key={pathname.path} className={currentPage}>
                            <a href={pathname.path} target="_blank" rel="noopener noreferrer">{pathname.title}<span className="small"><FontAwesomeIcon  size="xs" fixedWidth  icon={faExternalLinkAlt} /></span></a>
                        </ListGroup.Item>
                    );
                } else {
                    let badge = null;
                    if (pathname.badge) {
                        badge = <Badge pill variant="info">{pathname.badge}</Badge>
                    }
                    links.push(
                        <ListGroup.Item key={pathname.path} className={currentPage}>
                            <Link to={pathname.path} onClick={this.handleNavClose.bind()}>{pathname.title} &nbsp;{badge}</Link>
                        </ListGroup.Item>
                    );
                }
            })
            
            lst.push(
                <div variant="flush" key={item.category}>
                    <h6>{item.category}</h6>
                    {links}
                </div>
            );
        });
        return lst;
    }

  
    render() {
        let theme = this.props.darkMode ? "nav-mobile--dark-mode " : "";
        let pageName = this.props.location.pathname.split("/")[2]

        return (
            <Navbar expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className={"nav-mobile d-block d-none d-sm-block d-md-none " + theme + (this.state.navExpanded ? " nav-mobile--expanded" : "")} fixed="top" >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Brand >{pageName}</Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                            {this.renderItems()}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
    static propTypes = {
        darkMode: PropTypes.bool.isRequired,
        links: PropTypes.array.isRequired,
        location:   PropTypes.object
    }
}