import React, { Component } from "react";
import Flags from '../snippets/flags' 
import UserTypeBadge from '../snippets/user-type' 
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faTasks,faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import PreLoader from '../snippets/PreLoader'
import Form from 'react-bootstrap/Form'
import adminRegions from '../../utils/admin-regions'
import AMAZON from '../../utils/amazon'


import './admin-bonus.scss';


export default class AdminBonus extends Component {

    constructor(props) {
		super(props);
		this.state = {
            deals: [],
            pendingCounts: {},
            elligibleUsers: {},
            limit: 30,
            page: 1,
            pageTotal:"",
            resultsTotal : "",
            filterByRegion: "all",
            isLoading: true
        }
    }

    componentDidMount = () => {
        this.fetchBonusDeals(this.state.page, this.state.limit, this.state.filterByRegion).then(data => {
            this.setState({
                deals: data.results,
                page: data.current_page,
                pageTotal : data.num_pages,
                pendingCounts: data.pending_users,
                elligibleUsers: data.elligible_users,
                isLoading: false 
            });
        });
    }

    fetchBonusDeals = (page, limit, filterByRegion) => {
        const params = {
            page: page,
            limit: limit,
            filter: "admin"
        }

        if (filterByRegion !== "all") {
            params["region"] = filterByRegion;
        }

        return this.props.get("/api/v1/deals/bonus", params).then(response => {
            return Promise.resolve(response);
        }).catch(err  => {
            console.error(err);
            return Promise.reject(err);
        });
    }

    loadMore = (e) => {
        e.preventDefault();
        const page = this.state.page + 1;
        this.setState({isBtnLoading: true})
        this.fetchBonusDeals(page, this.state.limit, this.state.filterByRegion).then(response => {
            if (response.results.length > 0) {
                const moreDeals = this.state.deals.concat(response.results);
                this.setState({
                    deals: moreDeals,
                    page: response.current_page,
                    pageTotal : response.num_pages, 
                    isBtnLoading: false
                });
            }
        });
    }

    handleFilterSortChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let filterRegion = inputName === "filterByRegion" ? inputValue : this.state.filterByRegion;
            this.setState({
                [inputName]: inputValue,
                isBtnLoading: true
            });
            this.fetchBonusDeals(1, this.state.limit, filterRegion).then(response => {
                if (response.results.length > 0) {
                    this.setState({
                        deals: response.results,
                        page: response.current_page,
                        pageTotal : response.num_pages, 
                        isBtnLoading: false
                    });
                }else {
                    this.setState({
                        deals: [],
                        isBtnLoading: false
                    });
                }
            });    
    }

    renderToolTip = (item) => {
        let output = ""

            if(item.auto_invite ) {
                output =
                <>
                    <OverlayTrigger
                        placement={"right"}
                        overlay={
                        <Tooltip id={`tooltip-top`}>
                            <div className="auto-invite-tooltip">
                                <p>Duration </p>
                                <span>{item.auto_invite_days}</span>
                                <p>Invites/Day</p>
                                <span> {item.auto_invite_max_invite_per_day}</span>
                            </div>
                        </Tooltip>
                        }
                    >
                        <div></div>
                    </OverlayTrigger>
                </>
            } else {
                 output = <div></div>
            }
            
        return output
    }


    renderList = () => {
        let lst = [];
        this.state.deals.forEach(item => {
            let elligibleCount = 0;
            if (this.state.elligibleUsers.hasOwnProperty(item.deal_id)) {
                this.state.elligibleUsers[item.deal_id].forEach(user => {
                    if (item.acl.indexOf(user.email) < 0) {
                        elligibleCount += 1;
                    }
                });
            }

            let pendingCount = 0;
            if (this.state.pendingCounts.hasOwnProperty(item._id)) {
                pendingCount = this.state.pendingCounts[item._id];
            }

            let autoInvite = ""

                if(item.auto_invite) {
                    autoInvite = "on"
                } else {
                    autoInvite = "off"
                }
            
            let riskLevel = "n/a"

                if(item.hasOwnProperty("deal_risk_level")) {
                    riskLevel = <UserTypeBadge type="badge" user={item.deal_risk_level}/>  
                }

            lst.push(
                // inactive
                <tr key={item._id}>
                    <td className="table-data_image" >
                        <div className="image-wrapper">
                            <img src={item.product_image_url} alt={item.name}></img>
                        </div>
                    </td>

                    
                    <td className="table-data_name">
                        <span>{item.name}</span>
                        <p className="small text-muted">{item.product_name}</p>
                        {/* <small>Type: {item.type}</small> */}

                    </td>
                    <td className="text-center">
                         {riskLevel}
                    </td>
                    <td className="text-center table-data_asin"><a href={`https://${AMAZON.getDomain(item.region)}/dp/${item.asin}`}  target="_blank" rel="noopener noreferrer" alt={item.name}>{item.asin}</a></td>
                    <td className="text-center">
                         <Flags region={item.region}/> 
                    </td>
               
                    <td className={`text-center table-data_street-light --${autoInvite}`}>
                        {this.renderToolTip(item)}
                        
                    </td>
                    <td className="table-data_status"><Badge variant={item.status === "active" ? "success" : "warning"}>{item.status}</Badge></td>
                    <td >
                        <div className="table-data_action-bar">
                            
                            <Link to={{
                                pathname:"/admin/bonus/invite/" + item._id, 
                                state: "invite"
                                }}>
                                <span title="invite"><FontAwesomeIcon icon={faUserPlus} size="sm" fixedWidth />
                                {elligibleCount === 0 ? null : <Badge pill variant="primary">{elligibleCount}</Badge>}
                                </span>
                            </Link>

                            <Link to={{
                                pathname:"/admin/bonus/manage/" + item._id, 
                                state: "manage"
                                }}>
                                <span title="manage"><FontAwesomeIcon icon={faTasks} size="sm" fixedWidth />
                                {pendingCount === 0 ? null : <Badge pill variant="primary">{pendingCount}</Badge>}
                                </span>
                            </Link>

                            <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link className="dropdown-item" to={{
                                        pathname:"/admin/bonus/edit/" + item._id, 
                                        state: "edit"
                                        }}>
                                        Edit
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </td>
                </tr>
            );
        });

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="6"><em>No results found.</em></td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {
        let button = null ;
    
        if(this.state.page !== this.state.pageTotal || this.state.deals.length === 0) {
            button = <Button className="btn-load-more" onClick={this.loadMore} disabled={this.state.isBtnLoading } size="sm">
                {this.state.isBtnLoading ? 'Loading…' : 'Load More'}
            </Button>
        }

        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <>
                    {/* <h5> Manage Bonus</h5> */}
                    <div className="table-search_wrapper --no-search">
                        <div className="table-search_filter-wrapper">
                            <span>Filter by region:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByRegion} name="filterByRegion"  onChange={this.handleFilterSortChange}>
                                    <option value="all">All</option>
                                    {adminRegions.options()}
                                </Form.Control>
                            </div>
                        </div>
                    </div>
                    <section className="bonus_overview">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th></th>   
                                    <th className="table-data_name">Name</th>
                                    <th className="text-center">User Type</th>
                                    <th className="text-center table-data_asin">ASIN</th>
                                    <th className="text-center">Region</th>
                                    <th className="text-center">Auto Invite</th>
                                    <th className="table-data_status">Status</th>
                                    <th className="table-data_action-bar"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderList()}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan="10" className="load-more_wrapper">
                                        {button} 
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    </section>
                    </>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired
    }
}