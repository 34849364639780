import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Image from 'react-bootstrap/Image';

import '../Compare.scss';

export default class CompleteStep extends Component {

    render() {
        let msg =  
            <>
                <div className="product-detail_image">
                    <Image src={this.props.product.product_image_url} rel={this.props.product.deal_name} />
                </div>
                <h1>Thank you for participating in this test!</h1>
                <p className="lead"> The <strong> {this.props.product.deal_name}</strong> is yours to keep as a gift for being a part of this testing panel.</p>
                <p className="title">Please allow 5 business days for us to process shipping the item and applying the {this.props.product.points} Panda Points to your Deal Panda account</p>
                <Link to="/deals" className="btn btn-dp see-more">See more tests</Link>
           
            </>
        return (
            <React.Fragment>
                <Container as="section" className="step-detail step-detail--last-step">
                    {msg}
                </Container>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        user: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        step: PropTypes.object.isRequired
    };

}
