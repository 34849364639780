import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import StarRating from "../../snippets/star-rating";
import PointsStatus from "../profile/points-status";
import PasteDisabledInput from "./../../../utils/PasteDisabledInput";

export default class ReviewStepStart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasReviewed: props.hasReviewed,
            reviewText: props.reviewText
        }
    }
    render() {

        let buttonText = "Submit"
        let disabledButton = true
            if (this.props.reviewText.length >= this.props.reviewTextLength.min) {
                disabledButton = false
            }
            if (this.props.loading) {
                buttonText = "loading"
                disabledButton = true
            }
            
        return (
            <Row>
                <Col md={5} className="product-image">
                    <Image src={this.props.bonusDeal.product_image_url} />
                    <div className="product-image_description">
                        <strong>Product Name</strong> 
                        {this.props.bonusDeal.product_name}
                    </div>
                </Col>
                <Col md={7} className="product-detail">
                    <h1>Share your Experience with Product Developers and Market Researchers</h1>
                    <p>Share your thoughts about the product you received and earn <PointsStatus  points={this.props.bonusDeal.points}/> Panda Points. Panda Points can be redeemed for gift cards of your choice! </p>
                    <p>We’ll review your submission for clarity before awarding your <PointsStatus  points={this.props.bonusDeal.points}/> Panda Points. </p>
                    
                    <Form noValidate={!this.state.hasReviewed} onSubmit={this.props.handleSubmit}>
                         <Form.Group>
                                <Form.Label>Please provide an overall rating</Form.Label>
                                <StarRating inputName="reviewRating" showText={true} value={this.props.reviewRating} onChange={this.props.handleInputChange} />
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>Does the product meet your expectations ? <br/><small>(Minimum 50 Characters )</small></Form.Label>
                            <PasteDisabledInput as="textarea" name="reviewText" minLength="50" value={this.props.reviewText} 
                                onChange={this.props.handleInputChange}  placeholder="What is most important to know? &#10;What did you like or dislike? &#10;What did you use this product for?" isInvalid={this.props.reviewTextError} required={true}/>
                            <Form.Control.Feedback type="invalid">{this.props.reviewTextError}</Form.Control.Feedback>
                            <div className={`textarea-count ${!disabledButton ? "--success" : ""}`}>{this.props.reviewText.length}/{this.props.reviewTextLength.min}</div>
                        </Form.Group>
                        <Button variant="success" type="submit" disabled={disabledButton} block >{buttonText}</Button>
                    </Form>
                </Col>
            </Row>
        );
    }

    static propTypes = {
        loading : PropTypes.bool.isRequired,
        bonusDeal: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hasReviewed: PropTypes.number.isRequired,
        reviewRating: PropTypes.number.isRequired,
        reviewTextLength:PropTypes.object.isRequired,
        reviewText: PropTypes.string.isRequired,
        reviewTextError: PropTypes.string.isRequired,
        reviewLink: PropTypes.string.isRequired,
        userProfile: PropTypes.object.isRequired
    };
}