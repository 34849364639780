import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Util from './../../../utils/utils'
import PasteDisabledInput from './../../../utils/PasteDisabledInput'
import CopyDisabledComponent from '../../../utils/CopyDisabledComponent';
import Form from 'react-bootstrap/Form'
import '../Compare.scss';

export default class CompetitorStep extends Component {
    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    componentDidUpdate(){
        // window.scrollTo(0, 0);
        // this.focus('input[name="compAnswer"]')
    }

    componentDidMount(){
        // this.focus('input[name="compAnswer"]')
    }
    
    render() {

        let extraStep = ""
        let firstCompetitor = ""
        let currentCompetitor = this.props.product.competitors[this.props.step.currentCompetitor]

            if (this.props.step.currentCompetitor > 0) {
                extraStep = 
                <li>
                    Go back to the <CopyDisabledComponent content={this.props.step.dealKeyword} tagname="strong" /> {this.props.product.deal_type === "CFBR" ? "category" : "search"} results page.
                </li>
            } else {
                firstCompetitor = <><CopyDisabledComponent content={this.props.step.dealKeyword} tagname="strong" /> {this.props.product.deal_type === "CFBR" ? "category" : "search"} </>
            }

        
        
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--competitors">
                    
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper --portrait" style={{backgroundImage: `url(${currentCompetitor.image})`}}>
                                {/* <CopyDisabledComponent content={<Image src={currentCompetitor.image} />} tagname="strong" /> */}
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step  {this.props.step.current} </h2>
                            <ol>
                                {extraStep}
                                <li>
                                    <p>On the {firstCompetitor} results page, browse through the products to locate the &quot; <CopyDisabledComponent content={Util.trunc(currentCompetitor.title, 100)} tagname="strong" />.</p>
                                    <div className="step_additiona-note">You may need to browse through several pages of {this.props.product.deal_type === "CFBR" ? "category" : "search"} results. Scroll to the bottom and click through to the next page if you can't find the product.</div>
                                </li>
                                <li>
                                    Click on the product.
                                </li>
                                <li>
                                    Explore the product page and answer the survey question below.
                                </li>
                            </ol>
                            <Form.Group controlId={this.props.step.currentCompetitor}>                            
                                <Form.Label>{currentCompetitor.question}</Form.Label>
                                <PasteDisabledInput 
                                    type="text" 
                                    name="compAnswer"
                                    placeholder="Type your answer here" 
                                    value={this.props.step.competitorAnswers[this.props.step.currentCompetitor].answer}
                                    onChange={this.props.handleInputChange}
                                    dataNum={this.props.step.currentCompetitor}
                                    required={true}
                                />
                            </Form.Group>
                            {/* <div className="step_help">Need Help? <HowToVideo show_icon={true} title="Search for Amazon products" source="https://dealpanda-public.s3.ca-central-1.amazonaws.com/3.mp4"/></div> */}
                        </Col>
                </Row>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired,
    };

}
