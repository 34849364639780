

import AMAZON from './amazon'

export default {

    amazon(region, value) {
        let output = {}
        
        let pattern = value.match(/(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:(?!\/gp\/profile\/amzn1\.account\.)(?:.|\n))*?([^:\/\n?]+)\/gp\/profile\/amzn1\.account/)
        let protoCallPattern = /^((http|https|ftp):\/\/)/;

            if(pattern === null ) {
                    output = {
                        inValid: true,
                        msg : `Sorry the link you provided is incorrect, please see the video`

                    }
                
            } else if (!protoCallPattern.test(value)) {
                output = {
                    inValid: true,
                    msg : `You are missing http:// or https://`
                }
               
            } else if (pattern[1].toLowerCase() !== (AMAZON.getDomain(region)).toLowerCase()) {
                output = {
                    inValid: true,
                    msg : `Please provide an ${AMAZON.getDomain(region)} account`
                }
            } else {
               output = {
                    inValid: false,
                    msg : ``
                }
            }
        return output
    }
}