import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Image from 'react-bootstrap/Image';
// import Currency from '../../../utils/currency'

import '../Compare.scss';

export default class CompleteStep extends Component {

    render() {

        const status_text = (this.props.product.deal_status === 'paid' ? "Paid." : "Payment processing" )

        let msg =  ""

            if (this.props.nda_agreement === "disagree") {
                msg =
                <>
                        
                    <h1>Thank You For Your Interest.</h1>
                    <p> Unfortunately, you do not qualify to join this test panel.</p>
                    <Link to="/deals" className="btn btn-dp see-more">Close Test</Link>
                </>
            } else {
                msg =
                <>
                     <div className="product-detail_image">
                        <Image src={this.props.product.product_image_url} rel={this.props.product.deal_name} />
                    </div>
                    <h1>Thank you for participating in this test panel!</h1>
                    <p className="lead"> The <strong> {this.props.product.deal_name}</strong> is yours to keep for free and you’ll be reimbursed for ${this.props.product.deal_amount} plus applicable taxes and shipping. </p>
                    <p className="title"><strong>Please allow up to 5 business days for us to:</strong></p>
                    <ol>
                        <li>Validate your screenshots of the completed order and published review.</li>
                        <li>Process the reimbursement to your Paypal account ({this.props.userProfile.payPalAccount})</li>
                        <li>Apply the {this.props.product.points} Panda Points to your Deal Panda account</li>
                    </ol>
      
                    <div className="step-detail--last-step_status"><strong>Status</strong> : <span className={this.props.product.deal_status}>{status_text}</span></div>
                    <Link to="/deals" className="btn btn-dp --invite">See more surveys</Link>
                    <p className="steps_description_footnote">
                    </p>
                </>
            }
        

        return (
            <React.Fragment>
                <Container as="section" className="step-detail step-detail--last-step">
                    {msg}
                </Container>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        user: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        nda_agreement: PropTypes.string.isRequired,
        step: PropTypes.object.isRequired,
        userProfile: PropTypes.object.isRequired
    };

}
