import React, { Component } from "react";
import Flags from '../snippets/flags' 
import PropTypes from "prop-types";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import PreLoader from '../snippets/PreLoader'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import adminRegions from '../../utils/admin-regions'


import './admin-users.scss';

export default class AdminInvites extends Component {

    sortValue = ["created_at", "region", "source"];
    filterStatusValue = ["all", "pending", "rejected"]
    filterAnalysisValue = ["all", "pass", "fail", "unknown"]

    constructor(props) {
		super(props);
		this.state = {
            invites: [],
            page: 1,
            pageTotal : "",
            limit: 30,
            resultsPerPage : "",
            resultsTotal : "",
            showMore: true,
            isBtnLoading: false, 
            isLoading: true,
            deletedInvites: [],
            approvedInvites: [],
            sortBy: "created_at",
            filterByRegion:"all",
            filterByStatus:"pending",
            filterByAnalysis:"pass",
            modal: {
                show: false,
                size: "md",
                body: "",
                header: "",
                class: ""
            },
            // modalData: "",
            amazonData: ""
        }
    }

    // showModal = (name, data) => {
    //     this.setState({
    //         modal: name,
    //         modalData: data
    //     }, this.fetchAmazonReviews(data));
    // }

    showModal = ( classes, size, header, body, footer) => {
        this.setState({
            modal: {
                show : true, 
                body: body,
                header: header, 
                class: classes,
                size: size,
                footer: footer
            }
        });
    }

    hideModal = () => {
        this.setState({
            modal: {
                ...this.state.modal,
                show : false
            },
            // modalData: "",
            amazonData:""
        });
    }

    componentDidMount = () => {
        this.fetchInvites(this.state.page, this.state.limit, this.state.sortBy, this.state.filterByRegion, this.state.filterByStatus, this.state.filterByAnalysis).then(results => {
            this.setState({
                    resultsPerPage : this.state.limit,
                    resultsTotal : results.num_results,
                    limit : this.state.limit,
                    pageTotal : results.num_pages,
                    invites: results.results,
                    showMore: results.results.length === this.state.limit ? true : false,
                    isLoading: false
            });
        });
    }

    getAverageAmazonReview = (maxReview, response) => {
        console.log(maxReview, response)

        if (response.length > 0 ) {
            let totalReview = response.length;
            let reviewLength =  totalReview >= maxReview ? maxReview : totalReview;
            let values = []
                for (let i = 0; i < reviewLength; i++) {
                    if (response[i].rating !== undefined || response[i].rating !== "undefined") {
                        values.push(response[i].rating)
                    }
                }
                
                let sum = values.reduce((previous, current) => current += previous);
                let avg = sum / values.length;
                let result = {
                    maxReviews: reviewLength,
                    totalReviews : totalReview,
                    averageStarReview : avg
                }

                return result
        } else {
            let result = {
                    maxReviews: maxReview,
                    totalReviews : 0,
                    averageStarReview : 0
                }

                return result
        }
        
    }

    fetchAmazonReviews = (data) => {
        const minReviews = 100
        const maxReviews = 100
        const avgReview = 4.3
        const userAmzProfile = data.amz_profile_url

        // let output = null; 
        let params = JSON.stringify({
            "secret": "TerryRichardLuke",
            "profile_url": userAmzProfile,
            "limit": 110
        })

        this.props.getAmazonReview(params).then((response) => {
            let result = this.getAverageAmazonReview(maxReviews, response.reviews)
            this.setState({
                amazonData : {
                    status: "success",
                    minReviews: minReviews, 
                    maxReviews: maxReviews, 
                    avgReview: avgReview,
                    ...result
                }    
            })
        }).catch(err => {
           
            let message  = "Oops, Something went wrong try again later"
            
            if (err.hasOwnProperty("message")) {
                message = err.message
            }
            
            this.setState({
                amazonData : {
                    status: "error",
                    message: message
                }    
            })
        });

    }


    fetchInvites = (page, limit, sortBy, filterByRegion, filterByStatus, filterByAnalysis) => {
        let params = {
            sort: sortBy,
            status: "",
            page: page,
            limit: limit
        }

        if (filterByRegion !== "all") {
            params["region"] = filterByRegion;
        }
    
        if (filterByStatus !== "all" && filterByStatus !== "") {
            params["status"] = filterByStatus;
        }

        if (filterByAnalysis !== "all") {
            params["analysis_result"] = filterByAnalysis;
        } else {
            params["analysis_result"] = ""
        }

        return this.props.get("/api/v1/invites", params).then(response => {
            return Promise.resolve(response);
        }).catch(err  => {
            console.error(err);
            return Promise.reject("");
        });
    }

    loadMore = (e) => {
        e.preventDefault();
        const page = this.state.page + 1;
        this.setState({isBtnLoading: true})
        this.fetchInvites(page, this.state.limit, this.state.sortBy, this.state.filterByRegion, this.state.filterByStatus, this.state.filterByAnalysis).then(results => {
            if (results.results.length > 0) {
                const moreResults = this.state.invites.concat(results.results);
                this.setState({
                    resultsPerPage : this.state.resultsPerPage + this.state.limit,
                    invites: moreResults,
                    page: page,
                    isBtnLoading: false
                });
            } 
        });
    }

    deleteInvite = (e, inviteId) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to reject this invite?")) {
            this.setState({
                isBtnLoading: true
            });
            this.props.delete("/api/v1/invites/" + inviteId).then(response => {
                if (response.result) {
                    let deleted = this.state.deletedInvites;
                    deleted.push(inviteId);
                    this.setState({
                        deletedInvites: deleted,
                        isBtnLoading: false
                    },this.hideModal());
                }
            });
        }
    }

    approveInvite = (e, inviteId) => {
        e.preventDefault();
            this.setState({
                isBtnLoading: true
            });
            let formData = new FormData();
            formData.append("action", "APPROVE");
            this.props.put("/api/v1/invites/" + inviteId, formData).then(response => {
                if (response.result) {
                    let approved = this.state.approvedInvites;
                    approved.push(inviteId);
                    this.setState({
                        approvedInvites: approved,
                        isBtnLoading: false
                    },this.hideModal());
                }
            });
    }

    handleFilterSortChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let sort = inputName === "sortBy" ? inputValue : this.state.sortBy 
        let filterRegion = inputName === "filterByRegion" ? inputValue : this.state.filterByRegion
        let filterStatus = inputName === "filterByStatus" ? inputValue : this.state.filterByStatus
        let filterAnalysis = inputName === "filterByAnalysis" ? inputValue : this.state.filterByAnalysis

        

            this.setState({[inputName]: inputValue});

            this.fetchInvites(this.state.page, this.state.limit, sort, filterRegion, filterStatus, filterAnalysis).then(results => {
                if (results.results.length > 0) {
                    this.setState({
                        resultsPerPage : this.state.limit,
                        resultsTotal : results.num_results,
                        limit : this.state.limit,
                        pageTotal : results.num_pages,
                        invites: results.results,
                        showMore: results.results.length === this.state.limit ? true : false,
                        isLoading: false
                    });
                } 
            })
        
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({[inputName]: inputValue});
    }


    renderAnalysisData = (profile) => {
        console.log(profile)

       
        let classes = "analysis-data"
        let size = "lg"
        let renderAnalysisResults = []
        let body = 
            <Modal.Body>
                <table className="table  table-striped">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Expected</th>
                            <th scope="col">value</th>
                            <th scope="col">result</th>
                        </tr>
                    </thead>
                    <tbody>
                       
                        {renderAnalysisResults}
                    
                    </tbody>
                </table>
            </Modal.Body>
            
            Object.entries(profile.amazon_profile_analysis).forEach(function(item, idx){
                let name = item[0].replace(/_/g, " ").trim();
                let expected = "N/A";
                let value = "N/A";
                let result = "N/A";

                if (item[1] !== null) {
                    expected = item[1].expected !== undefined ? item[1].expected : "-"
                    value = item[1].value !== undefined ? item[1].value : "-"
                    result = item[1].result !== undefined ? item[1].result : "-"
                }


                if(result === "N/A") {
                    result = "N/A"
                } else if (result) {
                    result = <FontAwesomeIcon className="text-success" icon={faCheckCircle} fixedWidth  />
                } else if (!result) {
                    result = <FontAwesomeIcon   className="text-danger"  icon={faTimesCircle} fixedWidth  />
                } 

                if(name !== "result") {
                    renderAnalysisResults.push(
                        <tr key={idx} className={result === "N/A" ? "disabled" : ""}>
                            <td>{name}</td>
                            <td>{expected}</td>
                            <td>{value}</td>
                            <td>{result}</td>
                        </tr>
                    )
                }
                
            })

        let profileImage = "https://i.imgur.com/pe5pRFc.png"

            if (profile.fb_id !== undefined && profile.fb_id !== "") {
                profileImage = `http://graph.facebook.com/${profile.fb_id}/picture?type=square`
            }

        let approvalBtn = ""
        let secondaryBtn = ""
             if (profile.amazon_profile_analysis._result.result) {
                approvalBtn =          
                    <Button className="--approve" variant="success" onClick={(e) => { this.approveInvite(e, profile._id)}} disabled={this.state.isBtnLoading}>Send Invite</Button>
                secondaryBtn = 
                    <Button className="--reject" variant="link" size="sm"  onClick={(e) => { this.deleteInvite(e, profile._id)}}  disabled={this.state.isBtnLoading}>Reject</Button>
            
            } else {
                approvalBtn = 
                    <Button className="--reject" variant="danger" size="sm"  onClick={(e) => { this.deleteInvite(e, profile._id)}}  disabled={this.state.isBtnLoading}>Reject</Button>
               
                secondaryBtn = 
                    <Button className="--approve" variant="link" onClick={(e) => { this.approveInvite(e, profile._id)}} disabled={this.state.isBtnLoading}>Send Invite</Button>
             
            }
        
        let header = 
            <Modal.Header closeButton>
                <div>
                    <div className="user-profile">
                        <div className="user-profile_image"  style={{backgroundImage: "url(" + profileImage + ")"}} >
                            {/* user image */}
                        </div>
                        <div className="user-profile_detail">
                            <div className="name">{profile.amazon_profile_data.name}</div>
                            <div className="email">{profile.invite_email}</div>
                            <div className="user-score">
                                <div className={profile.amazon_profile_analysis._result.result.toString()}>
                                    {(profile.amazon_profile_analysis._result.value * 100).toFixed(2)}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-approval">
                        {approvalBtn}
                        <div className="secondary-approval">{secondaryBtn}</div>
                    </div>
                    
                </div>
            </Modal.Header>

        this.showModal(classes, size, header, body, "")
    }

    // renderAnalyzeModal = () => {
    //         let output = ""
    //         const userEmail = this.state.modalData.email

    //         if( this.state.amazonData !== "") {
    //             if (this.state.amazonData.status !== "error") {
    //                 let totalReview = ""
    //                 let minReviewResult =  <i className="failed"><FontAwesomeIcon   className="text-danger"  icon={faTimesCircle} fixedWidth  /></i>
    //                 let avgReviewResult =  <i className="failed"><FontAwesomeIcon   className="text-danger"  icon={faTimesCircle} fixedWidth  /></i>

    //                     if (this.state.amazonData.totalReviews >= this.state.amazonData.minReviews) {
    //                         minReviewResult = 
    //                         <i className="success"><FontAwesomeIcon className="text-success" icon={faCheckCircle} fixedWidth  /></i>
    //                     }

    //                     if (this.state.amazonData.averageStarReview >= this.state.amazonData.avgReview) {
    //                         avgReviewResult  =
    //                         <i className="success"><FontAwesomeIcon className="text-success" icon={faCheckCircle} fixedWidth  /></i>
    //                     }

    //                     if (this.state.amazonData.totalReviews > 0) {
    //                         totalReview = 
    //                         <React.Fragment>
    //                             <p className="searching-result_total-review"> Based on <strong>{this.state.amazonData.totalReviews}</strong> recent reviews.</p>
    //                         </React.Fragment>
    //                     } else {
    //                         totalReview = 
    //                         <React.Fragment
    //                             ><p> This user has <strong>0</strong> reviews</p>
    //                         </React.Fragment>
    //                     }

                        
    //                 output = 
    //                 <div className="analyzer_result --success">
    //                     <Modal.Body>
    //                         {totalReview}
    //                         <div className="searching-result">
    //                             {minReviewResult}
    //                             <span>Minimum of <strong>{this.state.amazonData.minReviews}</strong> reviews. </span>
    //                         </div>
    //                         <div className="searching-result">
    //                            {avgReviewResult}
    //                             Last {this.state.amazonData.maxReviews} reviews had an average of <strong>4.3</strong> and higher.
    //                         </div>
    //                     </Modal.Body>
    //                     <Modal.Footer>
    //                         <Button className="--approve" variant="success" onClick={(e) => { this.approveInvite(e, this.state.modalData._id)}} disabled={this.state.isBtnLoading}>Invite</Button>
    //                         <Button className="--reject" variant="link" size="sm" onClick={(e) => { this.deleteInvite(e, this.state.modalData._id)}}  disabled={this.state.isBtnLoading}>Reject</Button>
    //                     </Modal.Footer>
    //                 </div>
    //             } else {
    //                 output = 
    //                     <div className="analyzer_result --error">
    //                         <Modal.Body>
    //                             <p className="error-message"><em><strong>Error:</strong> {this.state.amazonData.message}</em></p>
    //                             <p>Contact a developer with Amazon account ID</p>
    //                         </Modal.Body>
    //                         <Modal.Footer>
    //                             <Button  className="--approve" variant="success" size="sm"  onClick={this.hideModal} disabled={this.state.isBtnLoading}>Close</Button>
    //                         </Modal.Footer>
    //                     </div>
    //                 // <p>{this.state.amazonData.message}</p>
    //             }
                
    //         } else {
    //             output = 
    //             <React.Fragment>
    //                 <div className="invite-modal_searching">
    //                     <span className="spinner-wrapper"><FontAwesomeIcon className="text-success" icon={faSpinner} spin fixedWidth  /></span>
    //                     <p>Analyzing</p>
    //                     {/* <Button variant="success">Cancel</Button> */}
    //                 </div>
    //             </React.Fragment>
    //         }

    //     return (
    //         <Modal size="sm" show={this.state.modal === "analyze-user"} onHide={this.hideModal}  backdrop={false} centered>
    //             <div className="admin_manage-invite-modal">
    //                 <Modal.Header closeButton>
    //                     <h4>{userEmail}</h4>
    //                 </Modal.Header>
    //                 {output}
    //             </div>
    //         </Modal>
    //     );
    // }

    renderList = () => {
        let lst = [];
        for (let i = 0; i < this.state.invites.length; i++) {
            let item = this.state.invites[i];

            let deleted = this.state.deletedInvites.indexOf(item._id) >= 0;
            let approved = item.approved_at !== null || this.state.approvedInvites.indexOf(item._id) >= 0 ? true : false;

            let rowClass = "";
            // if (deleted) {
            //     rowClass += " bg-danger";
            // }
            // if (approved) {
            //     rowClass += " bg-success";
            // }

            let status = "warning"
            let statusText = "pending"

            if (deleted || item.status === "rejected") {
                status = "danger";
                statusText = "rejected"
            }
            if (approved) {
                status = "info";
                statusText = "invited"
            }

            let region = "-"
                if(item.region !== undefined ) {
                    region = 
                    <Flags region={item.region}/>
                } else {
                    region = ""
                }

            let questions = [];
            item.questions.forEach( function(q, idx) {
                if (q.name !== undefined && q.name !== "amz_profile_url") {
                    questions.push(
                        <div className="question-item" key={idx}>
                            <strong>{q.name}</strong> 
                            <span>{q.answer}</span>
                        </div>
                    );
                }
            });

            let amazonURL = ""
            let amazonProfile = item.amazon_profile;
            if (amazonProfile) {
                const profilePattern = /.*(amzn1\.account\.(\w+))/;
                const profileMatches = profilePattern.exec(amazonProfile);
                if (profileMatches !== null && profileMatches.length > 1) {
                    amazonProfile = profileMatches[2];
                    amazonURL = `http://www.${adminRegions.getDomain(item.region)}/gp/profile/amzn1.account.${profileMatches[2]}`
                } else {
                    console.warn("regex failed", amazonProfile);
                }
            }

            let source = "Direct"
                if (item.source !== "unknown") {
                    let campaign = (item.source.split("--"))
                    source = 
                    <React.Fragment>
                        <div className="source-item">
                            <strong>Source</strong> 
                            <span>{campaign[0]}</span>
                        </div>
                        <div className="source-item">
                            <strong>Campaign</strong> 
                            <span>{campaign[1]}</span>
                        </div>
                        <div className="source-item">
                            <strong>Content</strong> 
                            <span>{campaign[2]}</span>
                        </div>
                    </React.Fragment>
                } else {
                    source =
                    <React.Fragment>
                        <div className="source-item">
                            <strong>Source</strong> 
                            <span>Direct</span>
                        </div>
                        <div className="source-item">
                            <strong>Campaign</strong> 
                            <span>-</span>
                        </div>
                        <div className="source-item">
                            <strong>Content</strong> 
                            <span>-</span>
                        </div>
    
                    </React.Fragment>
                }
            
            let approveButtonText = "Send Invite";
            // let invitedDate = "-"
                if(item.approved_at !== null) {
                    approveButtonText = "Resend Invite"
                    // invitedDate =  <div>{item.approved_at ? dayjs(item.approved_at).format("MMM D, YYYY") : ""}</div>
                }
            
            let location = ""
                if (item.address !== undefined) {
                    let address =  item.address
                    let suite = address.suite !== "undefined"  ? `${address.suite}-` : "";
                    let fullAddress = `${address.street_number} ${address.street}`;
                     
                        location = <span>{suite + fullAddress}<br/>{address.city + ", " + address.state + ", " + address.zip}</span>
                        
                }

            let birthday = "-";
                if (item.hasOwnProperty("birthday") && item.birthday !== null) {
                    let birthdate = dayjs(item.birthday).format("YYYY-MM-DD");
                    let age =  dayjs().format("YYYY") - dayjs(item.birthday).format("YYYY")
                    birthday = (<time dateTime={birthdate} title="dob">{birthdate} ({age}yr)</time>)
                }

            let facebookID = "-"
                if(item.fb_id !== null || item.fb_id !== "") {
                    facebookID = item.fb_id
                }

            let userType = ""
            if (item.risk_level === "TRIAL") {
                userType = <Badge variant={"warning"}>Trial</Badge> 
            } else if (item.risk_level === "VIVA_REVIEWERS") {
                userType = <Badge variant={"primary"}>Viva Reviewers</Badge>
            } else {
                userType =  <Badge variant={"light"}>{item.risk_level}</Badge>
            }

            let profileAnalysis = <Badge variant={"warning"}>Pending</Badge>
                if (item.amazon_profile_analysis !== undefined) {
                    let successText = "Passed"
                        if(!item.amazon_profile_analysis._result.result) {
                            successText = "Failed"
                        }
                    let successValue = (item.amazon_profile_analysis._result.value * 100).toFixed(2)
                    let buttonText = `${successText} :  ${successValue}%`
                    let badge = <Badge onClick={this.renderAnalysisData.bind(this, item)} variant={item.amazon_profile_analysis._result.result ? "success" : "danger"}>{buttonText}</Badge>
                        profileAnalysis = 
                        <div>
                            {badge} <Button size="sm" className="mr-0" disabled={this.state.isBtnLoading} variant="link" onClick={this.renderAnalysisData.bind(this, item)}>More Detail</Button>
                        </div>
                }
            
            let profileImage = "https://i.imgur.com/pe5pRFc.png"

            if (item.fb_id !== undefined && item.fb_id !== "") {
                profileImage = `http://graph.facebook.com/${item.fb_id}/picture?type=large`
            }

            let userName = "Unknown"
                
                if (item.name !== "" &&  item.name !== undefined) {
                    userName = item.name
                }



            lst.push(

                <tr key={item._id} className={rowClass}>
                    
                    <td className="data-profile">
                        <div className="data-profile_img" style={{backgroundImage: "url(" + profileImage + ")"}} >
                            <div className="region">
                                {region}
                            </div>
                        </div>
                        <div className="data-profile_user">
                            <div className="data-profile_user-detail">
                                <div className="user-type">
                                    {userType}
                                </div>
                                <div className="user-name">
                                    {userName}
                                </div>
                                <div className="user-email">
                                    {item.invite_email}
                                </div>
                            </div>
                        
                            <ul className="data-profile_additional-info">
                                <li  className="location"><i className="icon-location"></i> {location}</li> 
                                <li  className="birthday"><i className="icon-birthday-cake"></i> {birthday}</li> 
                                <li  className="facebook"><i className="icon-facebook"></i> {facebookID}</li>

                                
                                <li  className="amz"><i className="icon-amz-logo"></i> <a href={amazonURL} target="_blank" rel="noreferrer noopener">{amazonProfile}</a></li> 
                            </ul>
                        </div>
                    </td>
                    <td className="text-center">
                        {dayjs(item.created_at).format("MMM D, YYYY")}
                    </td>
                    {/* <td className="text-center">
                        {region}
                    </td> */}
                    <td >
                        {questions}
                    </td>
                    <td>{source}</td>
                    {/* <td className="text-center">
                        {userType}
                     
                    </td> */}
                  
                    <td className="table-data_status">
                        <Badge variant={status}>{statusText}</Badge>
                    </td>
                    <td className="text-center table-data_analysis">
                        {profileAnalysis}
                    </td>
                    <td>
                        <div className="table-data_action-bar">
                            {/* onClick={this.handleMarkPaid(item._id)} */}
                     
                            <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {/* <Button onClick={this.showModal.bind(this, "analyze-user", {_id:item._id, email:item.invite_email, amz_profile_url: item.amazon_profile })} className="dropdown-item" disabled={this.state.isBtnLoading}>Analyze</Button> */}
                                    <Button onClick={(e) => { this.approveInvite(e, item._id)}} className="dropdown-item" disabled={this.state.isBtnLoading}>{approveButtonText}</Button>
                                    <Button onClick={(e) => { this.deleteInvite(e, item._id)}} className="dropdown-item text-danger" disabled={this.state.isBtnLoading}>Reject</Button>
                                </Dropdown.Menu>
                            </Dropdown>   
                        </div>
                    </td>   
                </tr>
            );
        }

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="6"><em>No items found</em></td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {
         const sortOptions = [];
            this.sortValue.forEach(item => {
                let text = ""

                if (item === "created_at") {
                    text = "Date"
                } else {
                    text = item
                }

                sortOptions.push(<option key={item} value={item}>{text}</option>);
            });

        const statusOptions = [];
            this.filterStatusValue.forEach(item => {
                statusOptions.push(<option key={item} value={item}>{item}</option>);
            });

        const analysisOptions = []
            this.filterAnalysisValue.forEach(item => {
                analysisOptions.push(<option key={item} value={item}>{item}</option>);
            });


        let button = null ;
            if(this.state.page !== this.state.pageTotal ) {
                button =    
                        <div>
                            <Button className="btn-load-more" onClick={this.loadMore} disabled={!this.state.showMore || this.state.isBtnLoading} size="sm">
                                {this.state.isBtnLoading ? 'Loading more…' : 'Load More'}
                            </Button>
                            <p className="text-small">{this.state.resultsPerPage} / {this.state.resultsTotal} Results</p>
                        </div>
            }

      
        
        return (
            
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <React.Fragment>
                    <div className="table-search_wrapper --no-search">
                        <div className="table-search_filter-wrapper">
                            <span>Sort By:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.sortBy} name="sortBy"  onChange={this.handleFilterSortChange}>
                                    {sortOptions}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="table-search_filter-wrapper">
                            <span>Filter By Region:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByRegion} name="filterByRegion"  onChange={this.handleFilterSortChange}>
                                    <option value="all">All</option>
                                    {adminRegions.options()}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="table-search_filter-wrapper">
                            <span>Filter By Status:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByStatus} name="filterByStatus"  onChange={this.handleFilterSortChange}>
                                    {statusOptions}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="table-search_filter-wrapper">
                            <span>Filter By Analysis:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByAnalysis} name="filterByAnalysis"  onChange={this.handleFilterSortChange}>
                                    {analysisOptions}
                                </Form.Control>
                            </div>
                        </div>
                    </div>

                    <section className="admin_manage-invite">
                        {/* <Container fluid as="section" className="manage-invite_legend">
                            <ul>
                                <li>Approved</li>
                                <li>Pending</li>
                            </ul>
                        </Container> */}
                        <Table >
                            <thead>
                                <tr>
                                    <th className="table-data_name">Profile</th>
                                    <th className="text-center">Created</th>
                                    {/* <th className="text-center">Region</th> */}
                                    <th >Questions</th>
                                    <th >Referer</th>
                                    {/* <th className="text-center">User Type</th> */}
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Analysis</th>
                                    {/* <th className="text-center">Invited</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th colSpan="10"  className="load-more_wrapper">
                                        {button}
                                    </th>
                                </tr>
                            </tfoot>
                            <tbody>
                                {this.renderList()}
                            </tbody>
                        </Table>
                        {/* {modal} */}
                        <Modal size={this.state.modal.size} show={this.state.modal.show} onHide={this.hideModal}  backdrop={true} centered>
                            <div className={this.state.modal.class}>
                                {this.state.modal.header}
                                {this.state.modal.body}
                                {this.state.modal.footer}
                            </div>
                        </Modal>
                        {/* {this.renderAnalyzeModal()} */}
                    </section>
                    
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        getAmazonReview: PropTypes.func.isRequired,
        get: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        history: PropTypes.object,
        userProfile: PropTypes.object.isRequired
    }
}