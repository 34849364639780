import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import smartlookClient from 'smartlook-client'
import ScrollToTop from './utils/scroll-to-top'
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import CookieConsent from './components/layout/cookie-consent';
import ModalGlobalMessage from './components/layout/modal-global-message';
import Login from './components/pages/Login';
import Deal from './components/pages/Deal';
// import Bonus from './components/pages/bonus';
// import Invite from './components/pages/Invite';
import Register from './components/pages/Register';
import Compare from './components/pages/Compare';
import CompareDemo from './components/pages/Compare-Demo';
import Faq from './components/pages/Faq';
import Tnc from './components/pages/Tnc';
import Policy from './components/pages/PrivacyPolicy';
import Contact from './components/pages/Contact';
import Profile from './components/pages/profile';
import Redeem from './components/pages/redeem'
import Review from './components/pages/review'
import Admin from './components/admin/admin'
import Apply from './components/apply/apply';
import ApplyRedirection from './components/apply/apply-redirection';
import Cookie from './components/pages/error/cookie';
import trackingLoader from './utils/tracking-loader';
import secruityCheck from './utils/security-check';
import './App.css';
import platform from 'platform';


// const countries = [
//     "US",
//     "CA",
//     "GB",
//     "DE"
// ]

const publicPages = [
    "/login",
    "/register",
    "/error/cookie",
    "/apply",
    "/us/apply",
    "/ca/apply",
    "/gb/apply",
    "/de/apply"
];


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cookieEnabled : this.checkCookieSettings(),
            region: null,
            device: {
                isMobile: "",
                browser:"",
                os: {},
            },
            geoLocation : {
                ip: "",
                country: ""
            },
            showModal: true,
            userProfile: {
                riskLevel:""
            },
            utm: {
                content:"",
                source:"",
                campaign:""
            },
            security: [],
            softBan: false,
            authToken: false,
            showAnnoucement: false,
            page: (window.location.pathname).replace('/',''),
            pageFlow: this.checkPageFlow(),
            intialUrl: window.location.href,
            csrfToken: "",
            // csrfToken: localStorage.getItem("XSRF-TOKEN"),
            gdpr : false,
            cookieConsent: true,
            cookieConsentPopUp: false,
            trackingLoaded: false,
            gdprRegion:["GB", "DE"]
        }
    }

    handleSmartLookIdentify = (userProfile) => {
        if (this.state.pageFlow !== "admin") {
                smartlookClient.identify(userProfile._id, {
                name: userProfile.name,
                email: userProfile.email,
                user_type: userProfile.riskLevel
                // other custom properties
            })
         } 
            
        
    }

    componentDidMount = () => {
        this.checkUserAgent();
         /*
            This is diabled on a global level cuz we only needed for apply page, redirection happens on apply-redirection page
        */
        // this.checkUseGeoLocation();
        this.checkUTM()
        this.disableBroswerNativeBehaviour() 
        
        // User Broswer Extension Check ///
            // let userBroswerExtension = ["jung","keepa", "richard"]
            // let userBroswerExtension = []
            // let broswerExtension = secruityCheck.browserExtension(userBroswerExtension)
            //     if(Object.entries(broswerExtension).length !== 0){
            //         console.log("extension-check")//
            //         this.softBan(null, broswerExtension)
            //     } 
        // 

        /*
            Check if cookie is enabled
        */
        if (this.state.cookieEnabled) {
            this.setState({
                csrfToken: localStorage.getItem("XSRF-TOKEN")
            })
        }

        let isPublic = false;
            publicPages.forEach((path) => {
                if (window.location.pathname.startsWith(path)) {
                    isPublic = true;
                    return isPublic;
                }
            });

            if (isPublic === false) {
                this.refreshToken().then(response => {
                    if (response) {
                        this.fetchProfile().then(profile => {
                            this.getStorage(profile);
                            if (this.state.cookieConsent) {
                                trackingLoader.identify(profile._id, profile.email, profile.name);
                            }
                        });
                    }
                });
            } else {
                this.getStorage(null);  
            }

    }

    componentDidUpdate = (prevProps, prevState) => {

        if (!this.state.trackingLoaded && this.state.cookieConsent) {
            // console.log("loading tracking");
            trackingLoader.initGtag(process.env.REACT_APP_GA_TRACKING_ID, {});
            // trackingLoader.initFullstory("NBHZ3"); // DELETE this after APRIL 2020
            trackingLoader.initPinterest("2614463506089");
            trackingLoader.initSmartlook(smartlookClient, "d1a43517314e5f96e8906da24190a6851cfbad82");
            
            
            if (this.state.pageFlow !== "admin" && this.state.pageFlow !== "sign-up") {
                trackingLoader.initOlark("9256-918-10-3753");
                trackingLoader.initHotjar("693686");
            } 
            
            this.setState({trackingLoaded: true});
        }
    }

    checkPageFlow() {
        let flow = ""

            if (window.location.pathname.startsWith("/admin")) {
               flow = "admin"
            } else if (window.location.pathname.includes('apply'))  {
                flow = "sign-up"
            } else if (window.location.pathname.includes('login')) {
                flow = "login"
            } else  {
                flow = "post-login"
            }

            return flow
    }

    checkCookieSettings() {
        // Check if user is blocking cookie on the there broswer
        let cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled){ 
            //Can do something if cookie is disabled
        }
        return cookieEnabled
    }

    disableBroswerNativeBehaviour() {
        // DISABLE RIGHT CLICK
        document.oncontextmenu=function(){return false};
        // document.addEventListener('contextmenu', event => event.preventDefault());

        //DISABLED TEXT HIGHTLIGHT
        // check _globa.scss and remove styles on body
    }

    checkUserAgent = () => {
        let usersBroswer = platform.name
        let userPlatform = platform.os.family.toLowerCase();
        let isMobile = false

            if (userPlatform === ('android') || userPlatform === ('ios') || window.screen.width < 480) {
                isMobile = true 
            } 

            this.setState({
                device: {
                    isMobile: isMobile,
                    browser: usersBroswer,
                    os: platform.os
                }
            })
    }   

    checkUTM = () => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get("utm_campaign") !== null) {
            this.setState({
                utm:{
                    source : searchParams.get("utm_source"),
                    campaign : searchParams.get("utm_campaign"),
                    content : searchParams.get("utm_content")
                }
            })
        }
        
    }


    checkUseGeoLocation = () => {
        this.get("/api/v1/user/country", {} ).then(response => {
            let country =  response.country !== null ? response.country : "US"
            this.setState({
                geoLocation : {
                    ip: "",
                    country: country
                }
            })            
        }).catch(err => {
            console.log(err)
        });
    }

    setStorage = () => {
        localStorage.setItem(`_dp_${this.state.userProfile._id}_annoucement`, 'true'); 
    }

    getStorage = (profile) => {
        if (profile !== null) {
            this.profileStorage(profile)
        } 
    }

    profileStorage = (profile) => {
        let userID = profile._id;
        let showAnnoucement = localStorage.getItem(`_dp_${userID}_annoucement`) === null ? true : false;
        let cookieConsentStorage = localStorage.getItem(`_dp_${userID}_cookie_consent_answer`) === null ? null : JSON.parse(localStorage.getItem(`_dp_${userID}_cookie_consent_answer`));
        let cookieConsentPopUpStorage = this.state.cookieConsentPopUp;
        let gdpr = this.state.gdprRegion.includes(profile.region.toUpperCase()) ? true : false ;

            /*
               Keep record of cookie consent
            */
            if (!gdpr && cookieConsentStorage === null) {
                //  Not GDPR auto accpet cookie
                localStorage.setItem(`_dp_${userID}_cookie_consent_answer`, true);
                cookieConsentStorage = true;
                
            } else if(gdpr && cookieConsentStorage === null) {
                // GDPR Country
                cookieConsentPopUpStorage = true;
            } 

            /*
                Keep record for multiple user on the same computer 
            */
            if (localStorage.getItem(`_dp_${userID}_session_in_progress`) === null) {
                localStorage.setItem(`_dp_${userID}_session_in_progress`, 1);
            }

            /*
                Check for multiple user for softbannning
            */
            let cookie = secruityCheck.cookies(this.state.userProfile._id, "multiple-users", "_session_in_progress")//(ID, TYPE, KEY)//

                if(Object.entries(cookie).length !== 0 && this.state.userProfile.riskLevel !== "RISKY" && this.state.userProfile.role !== "ADMIN"){
                    this.softBan(this.state.userProfile,cookie)
                }

            this.setState({
                showAnnoucement:showAnnoucement,
                gdpr : gdpr,
                cookieConsent: cookieConsentStorage,
                cookieConsentPopUp: cookieConsentPopUpStorage
            });
    }
    
    handleCookieConsentAnswer = (bool) =>{
        let userID = this.state.userProfile._id !== undefined ? this.state.userProfile._id : "facebook_id"
        this.setState(() => ({
            cookieConsent:bool,
            cookieConsentPopUp: false
            }
        ));
        localStorage.setItem(`_dp_${userID}_cookie_consent_answer`, bool);
        if (bool){
            console.log('answered yes, do xyz');
        }else{ 
            console.log('answered no, do xyz')
        }
    }

    modalShow = () => {
        // Use if needed
        console.log("show modal ")
    }

    modalHide = () => {
        this.setState({showModal:false}, () => this.setStorage());
    }

    refreshToken = () => {
        let csrfToken = this.state.csrfToken;
        if (!csrfToken) {
            // temp workaraound since setState may not have updated state yet
            csrfToken = localStorage.getItem("XSRF-TOKEN");
        }
        return window.fetch(process.env.REACT_APP_API_DOMAIN + "/api/v1/auth/refresh", {
            headers: new Headers({
                'X-XSRF-TOKEN': csrfToken
            }),
            method: "POST",
            credentials: "include"
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }).then(response => {
            this.setState({
                authToken: response.auth_token,
                csrfToken: csrfToken
            }, () => {
                return Promise.resolve(response);
            });
            return Promise.resolve(response);

        }).catch(err => {
            if (window.location.pathname.startsWith("/login") === false) {
                window.location = "/login";
            } else {
                return Promise.reject(err);
            }
        });
    }

    authenticate = (formData) => {
        return window.fetch(process.env.REACT_APP_API_DOMAIN + "/api/v1/auth/login", {
            method: "POST",
            credentials: "include",
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }).then(response => {
            localStorage.setItem("XSRF-TOKEN", response.csrf_token);
            this.setState({
                authToken: response.auth_token,
                csrfToken: response.csrf_token
            }, () => {
                this.fetchProfile().then(profile => {
                    this.getStorage(profile);
                    if (this.state.cookieConsent) {
                        trackingLoader.identify(profile._id, profile.email, profile.name);
                    }
                    return Promise.resolve(profile);
                });
            });
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    getAmazonReview = (json) => {
        return window.fetch("https://us-central1-viva-naturals.cloudfunctions.net/cf_get_reviews", {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: json
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }).then(response => {
            return Promise.resolve(response);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    getAmazonReimbursement = (json) => { 
        return window.fetch("https://us-central1-viva-naturals.cloudfunctions.net/amz_get_reimbursement", {
            method: "POST",
            body: JSON.stringify(json)
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }).then(response => {
            return Promise.resolve(response);
        }).catch(err => {
            console.log(err)
            return Promise.reject(err);
        });
    }

    setPassword = (formData) => {
        return window.fetch(process.env.REACT_APP_API_DOMAIN + "/api/v1/auth/forgotpassword", {
            headers: new Headers({
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }),
            credentials: "include",
            method: "PUT",
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }
        }).then(response => {
            localStorage.setItem("XSRF-TOKEN", response.csrf_token);
            this.setState({
                authToken: response.auth_token,
                csrfToken: response.csrf_token
            });
            return Promise.resolve(response);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    logout = () => {
        let formData = new FormData();
        let endpoint = "/api/v1/auth/logout";
        return window.fetch(process.env.REACT_APP_API_DOMAIN + endpoint, {
            headers: new Headers({
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }),
            credentials: "include",
            method: "POST",
            body: formData
        }).then(response => {
            if (response.ok) {
                this.setState({
                    authToken: null,
                    csrfToken: null
                }, () => {
                    window.localStorage.removeItem("XSRF-TOKEN");
                    return Promise.resolve(true);
                });
            } else if (response.status === 401) {
                return this.refreshToken().then(this.logout);
            } else {
                throw response;
            }
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    softBan(profile, rule) {
        let softBan = this.state.softBan
        let securityList = this.state.security
        let formData = new FormData();

        if (rule !== null) {
            securityList = [...this.state.security, rule ]
            this.updateSecurityList(rule)
        }

        if (this.state.userProfile._id !== undefined && !softBan) {
            softBan = true
            if (securityList[0].name === "Multiple Users") {
                formData.append("user_ids", securityList[0].value);
                this.updateProfile(this.state.userProfile._id, formData)
            }
        }
        
        // console.log(rule)
        this.setState({
            userProfile: {
                ...profile,
                riskLevel: "RISKY",
            },
            softBan : softBan,
            security: securityList
        })
    }

    updateSecurityList(rule) {
        // we can update user database with this rule to keep track
        console.log(rule)
      
    }

    updateProfile(userId , entry) {
        // update user for cookie only
        this.put("/api/v1/users/b", entry).then(response => {
            // console.log(response)
            // Use this for the future
        }).catch(err => {
            console.log(err);
        });
    }
    
    showCookieConsentPopup = (isGdpr, isConsent, show) => {
        this.setState({
            gdpr : isGdpr,
            cookieConsent: isConsent,
            cookieConsentPopUp: show
        })
    }

    fetchProfile = () => {
        return window.fetch(process.env.REACT_APP_API_DOMAIN + "/api/v1/profile", {
            headers: new Headers({
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }),
            credentials: "include"
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                window.location = "/login";
                return Promise.reject(response);
            }
        }).then(response => {

            this.setState({
                userProfile: response
            },this.handleSmartLookIdentify(response));

            return Promise.resolve(response);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    post = (endpoint, data, type = "FORM") => {

        let header = {
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }

        let body = data

            // check if type is JSON
            if (type === "JSON") {
                body = JSON.stringify(data)
                header["Content-Type"] = "application/json"
            }

        return window.fetch(process.env.REACT_APP_API_DOMAIN + endpoint, {
            headers: new Headers(header),
            credentials: "include",
            method: "POST",
            body: body
        }).then(response => {
            if (response.ok) {
                return Promise.resolve(response.json());
            } else if (response.status === 401) {
                return this.refreshToken().then(() => {
                    return this.post(endpoint, data);
                });
            } else {
                throw response;
            }
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    put = (endpoint, data , type = "FORM") => {

        let header = {
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }

        let body = data

            // check if type is JSON
            if (type === "JSON") {
                body = JSON.stringify(data)
                header["Content-Type"] = "application/json"
            }

            
        return window.fetch(process.env.REACT_APP_API_DOMAIN + endpoint, {
            headers: new Headers(header),
            credentials: "include",
            method: "PUT",
            body: body
        }).then(response => {
            if (response.ok) {
                return Promise.resolve(response.json());
            } else if (response.status === 401) {
                return this.refreshToken().then(() => {
                    return this.put(endpoint, data);
                });
            } else {
                throw response;
            }
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    delete = (endpoint, formData) => {
        return window.fetch(process.env.REACT_APP_API_DOMAIN + endpoint, {
            headers: new Headers({
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }),
            credentials: "include",
            method: "DELETE",
            body: formData
        }).then(response => {
            if (response.ok) {
                return Promise.resolve(response.json());
            } else if (response.status === 401) {
                return this.refreshToken().then(() => {
                    return this.delete(endpoint, formData);
                });
            } else {
                throw response;
            }
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    get = (endpoint, params, file=false) => {
        let url = new URL(process.env.REACT_APP_API_DOMAIN + endpoint);
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        return window.fetch(url, {
            headers: new Headers({
                'Authorization': "Bearer " + this.state.authToken,
                'X-XSRF-TOKEN': this.state.csrfToken
            }),
            credentials: "include"
        }).then(response => {
            if (response.ok) {
                if (file){
                    return Promise.resolve(response.blob());                    
                }else{
                    return Promise.resolve(response.json());
                }
            } else if (response.status === 401) {
                return this.refreshToken().then(() => {
                    return this.get(endpoint, params);
                });
            } else {
                throw response;
            }
        }).catch(err => {
            if (err.status === 404) {
                window.location.replace("/tests");
            }
            return Promise.reject(err);
        });
    }

 


    render() {
        return (
                <BrowserRouter basename="/">
                    <ScrollToTop />
                    <Route render={({ location }) => {
                            let output = ""
                            let cookiePopup = ""

                                if (location.pathname !== "/login") {
                                    cookiePopup =
                                    <CookieConsent show={this.state.cookieConsentPopUp} cookieConsent={this.state.cookieConsent}  handleCookieConsentAnswer={this.handleCookieConsentAnswer} userProfile={this.state.userProfile}/>
                                }

                                /*
                                    All ERROR pages 
                                */
                                if (!this.state.cookieEnabled) {
                                    output = 
                                    <React.Fragment key={location}>
                                        <Switch location={location}>
                                            <Route path="/error/cookie" exact render={(props) => <Cookie {...props}  page={this.state.page} url={this.state.intialUrl} />} />                                           
                                            <Route render={() => <Redirect to={`/error/cookie`} />} />
                                        </Switch>
                                    </React.Fragment>
                                } 
                                /*
                                    Pages that doesn't need authentication 
                                */
                                else if(!this.state.authToken) {
                                    output = 
                                    <React.Fragment key={location}>
                                        <Switch location={location}>
                                            <Route path="/login" exact render={(props) => <Login {...props} authenticate={this.authenticate} post={this.post} put={this.put} setPassword={this.setPassword} />} />
                                            <Route path="/register" exact render={(props) => <Register {...props}  get={this.get} post={this.post} />} />
                                            <Route path="/apply" exact render={(props) => <ApplyRedirection {...props} get={this.get} post={this.post}  device={this.state.device}  /> } />
                                            {/* <Route path="/apply" exact render={(props) => <Apply {...props}     get={this.get} post={this.post} utm={this.state.utm} cookieConsent={this.state.cookieConsent} gdpr={this.state.gdpr} gdprRegion={this.state.gdprRegion} device={this.state.device}  showCookieConsentPopup={this.showCookieConsentPopup} handleCookieConsentAnswer={this.handleCookieConsentAnswer}/> } /> */}
                                            <Route path="/us/apply" exact render={(props) => <Apply {...props}     get={this.get} post={this.post} utm={this.state.utm} cookieConsent={this.state.cookieConsent} gdpr={this.state.gdpr} gdprRegion={this.state.gdprRegion} device={this.state.device}  showCookieConsentPopup={this.showCookieConsentPopup} handleCookieConsentAnswer={this.handleCookieConsentAnswer}/> } />
                                            <Route path="/ca/apply" exact render={(props) => <Apply {...props}     get={this.get} post={this.post} utm={this.state.utm} cookieConsent={this.state.cookieConsent} gdpr={this.state.gdpr} gdprRegion={this.state.gdprRegion} device={this.state.device}  showCookieConsentPopup={this.showCookieConsentPopup} handleCookieConsentAnswer={this.handleCookieConsentAnswer}/> } />
                                            <Route path="/gb/apply" exact render={(props) => <Apply {...props}     get={this.get} post={this.post} utm={this.state.utm} cookieConsent={this.state.cookieConsent} gdpr={this.state.gdpr} gdprRegion={this.state.gdprRegion} device={this.state.device}  showCookieConsentPopup={this.showCookieConsentPopup} handleCookieConsentAnswer={this.handleCookieConsentAnswer}/> } />
                                        </Switch>
                                        {cookiePopup}
                                    </React.Fragment>
                                } 
                                /*
                                    Pages that need authentication
                                */
                                else if (this.state.authToken){
                                    let globalMessage = null 
                                        if(this.state.showAnnoucement) {
                                            globalMessage = <ModalGlobalMessage
                                                                {...this.state}
                                                                modalShow = {this.modalShow}
                                                                modalHide = {this.modalHide}
                                                                userProfile={this.state.userProfile} 
                                                            />
                                        }
                                        
                                    if (this.state.cookieConsent) {
                                        // Track Page View
                                        trackingLoader.trackPageview(location.pathname + location.hash);
                                    }

                                    if (location.pathname.startsWith("/admin")) {
                                        output = 
                                            <Admin {...this.props} location={location} userProfile={this.state.userProfile} gdpr={this.state.gdpr} get={this.get} post={this.post} put={this.put} getAmazonReview={this.getAmazonReview} getAmazonReimbursement={this.getAmazonReimbursement} delete={this.delete} />
                                     
                                    } else {
                                        // let inviteRoute = null 

                                        // if (["RISKY"].indexOf(this.state.userProfile.riskLevel) === -1) {
                                        //     inviteRoute = <Route path="/invite" exact render={(props) => <Invite {...props} userProfile={this.state.userProfile} gdpr={this.state.gdpr} post={this.post} />} />
                                        // }

                                        output = 
                                        <React.Fragment key={location} >
                                                {globalMessage}
                                                <Header userProfile={this.state.userProfile} logout={this.logout} get={this.get} gdpr={this.state.gdpr}/>
                                                <Switch location={location}>
                                                    <Route path="/tests/:id" from={location} exact render={(props) => <Compare {...props} from={location} userProfile={this.state.userProfile} gdpr={this.state.gdpr} get={this.get} put={this.put}  post={this.post} fetchProfile={this.fetchProfile} device={this.state.device}/>} />
                                                    <Route path="/survey-test/:id" from={location} exact render={(props) => <CompareDemo {...props} from={location} userProfile={this.state.userProfile} gdpr={this.state.gdpr} get={this.get} post={this.post}/>} />
                                                    <Route path="/tests" exact from={location} render={(props) => <Deal {...props} from={location} fetchProfile={this.fetchProfile}  userProfile={this.state.userProfile} gdpr={this.state.gdpr} device={this.state.device} get={this.get} />} />
                                                    <Route path="/bonus" exact from={location} render={(props) => <Deal {...props} from={location} userProfile={this.state.userProfile} gdpr={this.state.gdpr} device={this.state.device} get={this.get} />} />
                                                    {/* <Route path="/bonus" exact render={(props) => <Bonus {...props} from={location} userProfile={this.state.userProfile} get={this.get} />} /> */}
                                                    <Route path="/review/:id" from={location} exact render={(props) => <Review {...props} from={location} userProfile={this.state.userProfile} gdpr={this.state.gdpr} get={this.get} post={this.post} put={this.put} fetchProfile={this.fetchProfile} /> } />
                                                    <Route path="/redeem" exact from={location} render={(props) => <Redeem {...props} from={location} userProfile={this.state.userProfile} gdpr={this.state.gdpr} get={this.get} post={this.post} fetchProfile={this.fetchProfile} resultsPerPage={10} />} />
                                                    {/* {inviteRoute} */}
                                                    <Route path="/faq" exact component={Faq} />
                                                    <Route path="/tnc" exact component={Tnc} />
                                                    <Route path="/privacy" exact component={Policy} />
                                                    <Route path="/contact" exact render={(props) => <Contact {...props} userProfile={this.state.userProfile} post={this.post}  />} />
                                                    <Route path="/profile" exact render={(props) => <Profile {...props} userProfile={this.state.userProfile} gdpr={this.state.gdpr} get={this.get} put={this.put} delete={this.delete} post={this.post} fetchProfile={this.fetchProfile}  />} />
                                                    <Route render={() => <Redirect to="/tests" />} />
                                                </Switch>
                                                
                                                <Footer gdpr={this.state.gdpr} />
                                                {cookiePopup}
                                        </React.Fragment>
                                    }
                                } 

                            return output
                        }}
                    />
                </BrowserRouter>

        );
    }

}

export default App;
