import React, { Component } from "react";
import PropTypes from "prop-types";
import PointsStatus from './../profile/points-status'
import { Link } from "react-router-dom";

export default class ReviewStepComplete extends Component {

    render() {
        return (
            <div className="text-center">
                <h1>Thank you for Sharing your Thoughts!</h1>
                <div className="disclaimer">
                    <p>Your submission is being processed. </p>
                    <p>It may take several days to review, so we appreciate your patience.</p>
                    <p>Once your feedback is reviewed, you’ll see <PointsStatus  points={this.props.bonusDeal.points}/> Panda Points added to your account.</p>
                </div>
                <div className="bonus-review_footer">
                    <Link to="/invite" className="btn btn-dp --invite">Invite a Friend to join Deal Panda</Link>
                    <Link to="/tests" className="btn btn-link">Return Home</Link>
                </div>
            </div>

        
        );
    }

    static propTypes = {
        bonusDeal: PropTypes.object.isRequired
    };
}