import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import '../layout/cookie-consent.scss';


export default class CookieConsent extends Component {

   
    render() {
        return (
            <div className="cookie-consent" style={!this.props.show ? {display:'none'} : {}}>
                <Container>
                    <Row>
                        <Col md={6} lg={7} >
                            <p>
                                We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume you agree to our <Link to={{pathname:"/privacy"}} href="/privacy">Privacy Policy</Link>.
                            </p>
                        </Col>
                        
                        <Col md={6}  lg={5} >
                            <Button size="sm" variant="outline-success"  onClick={()=>this.props.handleCookieConsentAnswer(false)}>
                                No, I do not agree
                            </Button>
                            <Button size="sm" variant="success" onClick={()=>this.props.handleCookieConsentAnswer(true)}>
                                Yes, I agree
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
    static propTypes = {
        show: PropTypes.bool,
        cookieConsent: PropTypes.bool,
        handleCookieConsentAnswer: PropTypes.func.isRequired,
    };
}
    
