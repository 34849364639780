import React, { Component } from "react";
import Flags from '../snippets/flags' 
import PropTypes from "prop-types";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import PreLoader from '../snippets/PreLoader'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner } from '@fortawesome/free-solid-svg-icons'


import './admin-users.scss';

export default class AdminUsers extends Component {

    statuses = ["ALL", "ACTIVE", "INVITED", "LOCKED", "DELETED"];

    constructor(props) {
		super(props);
		this.state = {
            users: [],
            page: 1,
            resultsPerPage: 20,
            search: "",
            status: "ACTIVE",
            showMore: true,
            isProcessing : false ,
            loadMore: false, 
            isLoading: true
        }
    }

    componentDidMount = () => {
        this.fetchUsers(this.state.page, this.state.resultsPerPage, this.state.search, this.state.status).then(users => {
            this.setState({
                users: users,
                showMore: users.length === this.state.resultsPerPage ? true : false,
                isLoading: false
            });
        });
    }

    fetchUsers = (page, resultsPerPage, search, status) => {
        const params = {
            page: page,
            limit: resultsPerPage,
            filter: "admin",
            q: search,
            status: status
        }
        return this.props.get("/api/v1/users", params).then(response => {
            return Promise.resolve(response.results);
        }).catch(err  => {
            console.error(err);
            return Promise.reject([]);
        });
    }

    loadMore = (e) => {
        e.preventDefault();
        const page = this.state.page + 1;
        this.setState({loadMore: true})
        this.fetchUsers(page, this.state.resultsPerPage, this.state.search, this.state.status).then(users => {
            if (users.length > 0) {
                const moreResults = this.state.users.concat(users);
                this.setState({
                    users: moreResults,
                    page: page,
                    loadMore: false,
                    showMore: users.length === this.state.resultsPerPage ? true: false
                });
            } else {
                this.setState({
                    showMore: false
                });
            }
        });
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({[inputName]: inputValue});
    }

    handleStatusChange = (e) => {
        e.preventDefault();
        const status = e.target.value;
        this.fetchUsers(this.state.page, this.state.resultsPerPage, this.state.search, status).then(results => {
            this.setState({
                status: status,
                users: results,
                showMore: results.length === this.state.resultsPerPage ? true: false
            });
        });
    }

    handleSearch = (e) => {
        e.preventDefault();
        const page = 1;
        this.setState({isProcessing: true});
        this.fetchUsers(page, this.state.resultsPerPage, this.state.search, this.state.status).then(results => {
            this.setState({
                isProcessing: false,
                page: page,
                users: results,
                showMore: results.length === this.state.resultsPerPage ? true: false
            })
        });
    }

    toDetailPage = (id) => {
        this.props.history.push({
            pathname: "/admin/users/detail/" + id,
            state: "detail"
        });
    }


    renderList = () => {
        let lst = [];
      
        this.state.users.forEach(item => {
            let status = ""

                if (item.status === "ACTIVE") {
                    status = "success"
                }  else if (item.status === "INVITED") {
                    status = "warning"
                } else if (item.status === "DELETED") {
                    status = "danger"
                } else {
                    status = "secondary"
                }

            lst.push(
                // inactive
                <tr key={item._id} onClick={this.toDetailPage.bind(this, item._id)}>
                    
                    <td className="table-data_name">
                        {item.name === "" ? "Unknown" : item.name}<p className="small text-muted">{item.email}</p>
                    
                    </td>
                    <td className="table-data_status">
                        <Badge variant={status}>{item.status}</Badge>
                    </td>
                    <td className="text-center">
                        <Flags region={item.region}/>
                    </td>
                    <td className="text-center">{item.referer === null ? "-" : item.referer}</td>
                    <td className="text-center">{item.riskLevel}</td>
                    <td className="text-center">
                        {dayjs(item.creationDate).format("MMMM D, YYYY")}
                        <p className="small text-muted">{item.last_login ? dayjs(item.last_login).format("MMMM D, YYYY") : ""}</p>
                    </td>
                    {/* <td>
                        <div className="table-data_action-bar">
                            
                        </div>
                    </td> */}
                </tr>
            );
        });

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="6"><em>No users found</em></td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {
        const statusOptions = [];
        this.statuses.forEach(item => {
            statusOptions.push(<option key={item} value={item}>{item}</option>);
        });

        let button = null ;
            if(this.state.users.length > 0 ) {
                button =    <Button className="btn-load-more" onClick={this.loadMore} disabled={!this.state.showMore || this.state.loadMore} size="sm">
                                {this.state.loadMore ? 'Loading…' : 'Load More'}
                            </Button>
            }

        let searchLoader = null;
            if (this.state.isProcessing) {
                searchLoader = 
                <i><FontAwesomeIcon icon={faSpinner} size="lg" fixedWidth spin /> </i>
            }

        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <React.Fragment>
                    <div className="table-search_wrapper">
                        <Form className="search" onSubmit={this.handleSearch}>
                            <Form.Control className="table-search_input" name="search" onChange={this.handleInputChange} value={this.state.search} disabled={this.state.isProcessing} placeholder="search by : name / email / amazon id" />
                            {searchLoader}
                        </Form>
                        <div className="table-search_filter-wrapper">
                            <span>Filter By:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.status} name="status"  onChange={this.handleStatusChange}>
                                    {statusOptions}
                                </Form.Control>
                            </div>
                        </div>
                    </div>
                    <section className={"admin-users " + (this.state.isProcessing ? "--loading": null)}>
                        {/* <h3>Deal Panda Users</h3> */}
                        <Table className="-highlight">
                            <thead>
                                <tr>
                                    <th>
                                        {/* <div className="table-search_wrapper">
                                            <Form onSubmit={this.handleSearch}>
                                                <Form.Control name="search" onChange={this.handleInputChange} value={this.state.search} placeholder="search name / email" />
                                            </Form>
                                            <Form.Control as="select" value={this.state.status} name="status"  onChange={this.handleStatusChange}>
                                                {statusOptions}
                                            </Form.Control>
                                        </div> */}
                                    </th>
                                    {/* <th colSpan="2">
                                        
                                    </th>
                                    <th colSpan="4"></th> */}
                                </tr>
                                <tr>
                                    <th className="table-data_name">Name</th>
                                    <th className="table-data_status">Status</th>
                                    <th className="text-center">Region</th>
                                    <th className="text-center">Referer</th>
                                    <th className="text-center">Risk Level</th>
                                    <th className="text-center">Date</th>
                                    {/* <th></th> */}
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th colSpan="10"  className="load-more_wrapper">
                                        {button}
                                    </th>
                                </tr>
                            </tfoot>
                            <tbody>
                                {this.renderList()}
                            </tbody>
                        </Table>
                    </section>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        history: PropTypes.object,
        userProfile: PropTypes.object.isRequired
    }
}