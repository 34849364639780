import React from "react";

const REGIONS = {
    US: {
        name: "United States",
        site: "Amazon.com"
    },
    CA: {
        name: "Canada",
        site: "Amazon.ca"
    },
    GB: {
        name: "United Kingdom",
        site: "Amazon.co.uk"
    },
    DE: {
        name: "Germany",
        site: "Amazon.de"
    }
}

export default {
    
    options(key) {
        let regions = [];
        for (let k in REGIONS) {
            let text = REGIONS[k].hasOwnProperty(key) ? REGIONS[k][key] : REGIONS[k].name;
            regions.push(
                <option value={k} key={k}>{text}</option>
            );
        }
        return regions;
    },

    region() {
        return REGIONS;
    },

    getDomain(region) {
        let domain = ""
        
        if (region === undefined || region === "undefined") {
            domain = REGIONS["US"]["site"]
        } else {
            domain = REGIONS[region.toUpperCase()]["site"]
        }

        return domain.toLowerCase();
    }
}