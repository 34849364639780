export default {

    browserExtension(arr) {
        let output = {}
        const extensions = ["jungle-scout","keepa","richard"] // This could be dynamic if we create an API for admin to add to the list of extensions
        let userExtensions = []

            // Check if User Broswer Has extensions
            arr.some(function (extension) {
                if (extensions.indexOf(extension) >= 0) {
                    userExtensions.push(extension)
                }
                return null //Some expected to return a value in function
            });

            if (userExtensions.length > 0 ) {
                output =    {
                                name : "Browser Extension",
                                reason : `User has ${userExtensions} installed in there broswer`,
                                value: userExtensions
                            }
            }
 
            return output
    },
    
    cookies(id, type, key) {
        let output = {}
        let dpEntries = []
            Object.keys(localStorage).some(function (key) {
                if (key.includes("_dp_")) {
                    dpEntries.push(key)
                }
                return null //Some expected to return a value in function
            });

        // Check if multiple users on the same computer
        let multipleUsers = []
            if(type === "multiple-users") {
                dpEntries.some(function (entry) {
                    if (entry.includes(key)) {
                        // console.log((key.split("_"))[2])
                        multipleUsers.push((entry.split("_"))[2])
                    }
                    return null //Some expected to return a value in function
                });

                if (multipleUsers.length > 1) {
                    output =    {
                                    name : "Multiple Users",
                                    reason : `${multipleUsers} users are banned becuase they are using the same computer base on cookie`,
                                    value: multipleUsers
                                }
                }
            }

        return output
    }
}