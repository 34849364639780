import React, { Component } from 'react';
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container'

import Body from '../../utils/utils-body'

import Form from 'react-bootstrap/Form'

import PreLoader from '../snippets/PreLoader'
import CompareStepComplete from './compare/step-complete-demo'
import CompareStepConfirmShipping from './compare/step-confirm-shipping'
import  { Redirect } from 'react-router-dom'
import trackingLoader from './../../utils/tracking-loader'
import './Compare.scss';
             
export default class Compare extends Component {
    constructor(props) {
		super(props);
		this.state = {
            validated: false,
            preloader: true,
            id: this.props.match.params.id,
            shipping: {
                fullName: this.props.userProfile.name,
                address1: "",
                address2:"",
                city: "",
                state: "",
                zip: ""
            },
            step : {
                firstTime:"true",
                current: 1,
                totalSteps: 0
            },
            pages : [
                "shipping-address",
                "complete"
            ],
            currentPage: "shipping-address",
            product: {},
            userProfile: this.props.userProfile,
            submitted: false
        }
    }
    
    componentDidMount = () => {
        Body.pageClass("add", `page_compare page_compare-${this.state.currentPage}`)
        this.fetchDeal();  
    }
    
    componentDidUpdate(prevProps, prevState) {
        Body.pageClass("add", `page_compare page_compare-${this.state.currentPage}`)
        if (this.props.userProfile !== prevProps.userProfile && this.props.userProfile){
            this.setState({
                userProfile: this.props.userProfile,
                shipping:{
                    ...this.state.shipping,
                    fullName: this.props.userProfile.name
                }
            });
        }

        const pageChanged = this.state.currentPage !== prevState.currentPage ? true : false;
        if (pageChanged || (!pageChanged && this.state.currentPage === this.state.pages[0] && !this.state.preloader)) {
            trackingLoader.trackPageview(window.location.pathname + "/" + this.state.currentPage, this.state.currentPage);
        }
    }

    clearStorage = () => {
        localStorage.removeItem(`${this.props.userProfile._id}_${this.state.id}_v1`);        
    }
    
    setStorage = () => {
        localStorage.setItem(`${this.props.userProfile._id}_${this.state.id}_v1`, JSON.stringify({ 
            id: this.state.id,
            step: this.state.step.current , 
            current_page:this.state.currentPage,
        })); 
    }

    getStorage = () => {
        let savedDeal = localStorage.getItem(`${this.props.userProfile._id}_${this.state.id}_v1`) != null ? JSON.parse(localStorage.getItem(`${this.props.userProfile._id}_${this.state.id}_v1`)) : null;
        
        // console.log("get storage: ",  savedDeal.deal_keyword)
        if (savedDeal !== null) {
            let step = {...this.state.step}
                step.firstTime = false
                step.current = savedDeal.step !== null ? savedDeal.step : 1                
                this.setState({
                    step,
                    currentPage : savedDeal.current_page !== null ? savedDeal.current_page : "walkthrough"
                });
        }
    }

    updateStep = (value) => {
        let step = {...this.state.step}
        step.current = value;
        this.setState({step}, () => this.setStorage());

        trackingLoader.trackEvent("checkout_progress", {
            deal_id: this.props.match.params.id,
            deal_title: this.state.product.deal_name,
            checkout_step: value,
            event_category: "engagement",
            event_label: value,
            currency: "USD",
            value: this.state.product.deal_amount
        });        
    }

    updateStepCount = (count) => {
        this.setState({
            step : {
                ...this.state.step, 
                current: count
            }
        }, () => this.setStorage());    
    }

    fetchDeal = () => {
        return this.props.get("/api/v1/deal", {deal_id: this.state.id}).then(response => {            
            if ((response.cool_off && response.deal.deal_status === 'active') || response.deal.deal_status === "out_of_stock" || response.deal.deal_coming_soon){
                this.setState({
                    redirect_to: '/'
                });
            }
            let totalSteps = 2 
          
            let state = this.state;
                state.product = response.deal
                state.preloader = false
                state.step = {...state.step}
                state.step.totalSteps = totalSteps

                // Send user to last page
                if(["submitted", 'paid'].indexOf(response.deal.deal_status) >= 0) {
                    this.setState({
                        submitted : true,
                        currentPage:"complete"
                    })
                } else  {
                    this.setState({state}, () => this.getStorage());
                }
        });
    }

    getNextPage(count) {
        let pages = this.state.pages
        let nextPage = ""
            pages.forEach(function(step, idx){                
                if (step === this.state.currentPage) {
                    nextPage = pages[idx+count]
                }
            }.bind(this))

        
        window.scrollTo(0, 0);

        return nextPage
    }

    getPreviousPage(count) {
        let pages = this.state.pages
        let nextPage = ""

            pages.forEach(function(step, idx){
                
                if (step === this.state.currentPage) {
                    nextPage = pages[idx-count]
                }
             }.bind(this))

            return nextPage
    }

    handleNextStep = (event) => {
        let nextPage = this.getNextPage(1)
            event.preventDefault();        
            window.scrollTo(0,0);
            this.updateStep(this.state.step.current + 1);
            this.setState({currentPage: nextPage})
    }

    handleOrderNumberSubmit = (event) =>{
        event.preventDefault();
        const ret = this.validateOrderNumber(this.state.step.orderNumber);
        this.setState({
            validated: true
        });
        if (ret){
            this.setState({
                submitted: true,
            }); 
            let formData = new FormData();
            formData.append("order_number", "0");
            formData.append("paypal_email", "email");
            formData.append("deal_answer", []);
            formData.append("deal_keyword", "word");    
            formData.append("answers", []);
        
            this.props.post("/api/v1/deal/" + this.state.id + "/claim", formData).then(response => {
                if (response.result) {
                    this.setState({
                        step:{
                            ...this.state.step,
                            current: this.state.step.current + 1
                        },
                        currentPage: "complete"
                    }, this.clearStorage())

                    trackingLoader.trackEvent("complete_compare_deal", {
                        deal_id: this.props.match.params.id,
                        deal_title: this.state.product.deal_name,
                        event_category: "engagement",
                        event_label: this.state.product.deal_name,
                        currency: "USD",
                        value: this.state.product.deal_amount
                    });

                    trackingLoader.trackPageview("/tests/complete_compare_deal"); 
            
                }
            }).catch(err => {
                this.setState({
                    submitted: false,
                    validated: false
                }); 

                trackingLoader.trackEvent("exception", {
                    description: "deal submit failed",
                    fatal: false
                });

                // FIXME something went wrong, show the user some feedback
                if (err instanceof Response) {
                    if (err.status === 409) {
                        // duplicate, let them go through
                        let step = {...this.state.step}
                        step.current = this.state.step.current + 1
                        this.setState({step}, () =>  this.clearStorage());
                    } else {
                        console.warn(err.status);
                        // FIXME show proper error modal or alert
                        alert("There was an error processing your request, please try again later or contact us for help.");
                    }
                } else {
                    console.log(err);
                }
            }); 

        }else{
            document.querySelector('input#orderNumber').setCustomValidity("Invalid URL.")
        }
    }

    handleShippingSubmit = (event) => {
        event.preventDefault();

        this.setState({
                submitted: true,
            }); 
            let formData = new FormData();
            formData.append("order_number", "n/a");
            formData.append("paypal_email", "n/a");
            formData.append("deal_answer", []);
            formData.append("deal_keyword", "n/a");    
            formData.append("answers", []);
            this.props.post("/api/v1/deal/" + this.state.id + "/claim", formData).then(response => {
                if (response.result) {
                    this.setState({
                        step:{
                            ...this.state.step,
                            current: this.state.step.current + 1
                        },
                        currentPage: "complete"
                    }, this.clearStorage())

                    trackingLoader.trackEvent("complete_compare_deal", {
                        deal_id: this.props.match.params.id,
                        deal_title: this.state.product.deal_name,
                        event_category: "engagement",
                        event_label: this.state.product.deal_name,
                        currency: "USD",
                        value: this.state.product.deal_amount
                    });

                    trackingLoader.trackPageview("/tests/complete_compare_deal"); 
            
                }
            }).catch(err => {
                this.setState({
                    submitted: false,
                    validated: false
                }); 

                trackingLoader.trackEvent("exception", {
                    description: "deal submit failed",
                    fatal: false
                });

                // FIXME something went wrong, show the user some feedback
                if (err instanceof Response) {
                    if (err.status === 409) {
                        // duplicate, let them go through
                        let step = {...this.state.step}
                        step.current = this.state.step.current + 1
                        this.setState({step}, () =>  this.clearStorage());
                    } else {
                        console.warn(err.status);
                        // FIXME show proper error modal or alert
                        alert("There was an error processing your request, please try again later or contact us for help.");
                    }
                } else {
                    console.log(err);
                }
            }); 

        // let nextPage = this.getNextPage(1)
        //     this.setState({
        //         currentPage: nextPage
        //     },this.updateStep(this.state.step.current + 1))
    }

    handleShippingChange = (event) => {
        let inputName = event.target.name;
        let inputValue = event.target.value;
        
     
        this.setState({
            shipping: {
                ...this.state.shipping,
                [inputName]: inputValue
            }
        });
    }

    renderFlow = () => {
        let currentStep = this.state.currentPage 
        let body = null;
        let renderNextPage = null
            if (currentStep === "shipping-address" ){
       
                renderNextPage = this.handleOrderNumberSubmit.bind(this)
                body =  <CompareStepConfirmShipping
                    {...this.state} 
                    handleShippingSubmit={this.handleShippingSubmit.bind()}
                    handleShippingChange={this.handleShippingChange} 
                />
            } else if (currentStep === "complete") {
                body = 
                <CompareStepComplete 
                    // {...this.state}
                    user={this.props.userProfile}
                    step = {{currentPage: this.state.currentPage}}
                    product = {this.state.product}       
                />
            }
            return ( 
                <Form onSubmit={renderNextPage} noValidate={currentStep === "order-id" ? true : false} validated={this.state.validated}>
                    {body}
                </Form> )
    }

    render() {

        if (this.state.redirect_to){
            this.setState({
                redirect_to: false
            });    
            return <Redirect to={this.state.redirect_to}/>;
        }

        let output = null

        if (this.state.preloader) {
            output = <PreLoader/>
        } else {
            output = this.renderFlow();
        }
        return (
            <Container as="main" className={!this.state.preloader ? "questionnaire deal_compare" : "questionnaire deal_compare loading"}>
                {output}
            </Container>
        );
    }

    static propTypes = {
        userProfile : PropTypes.object.isRequired,
        match:  PropTypes.object.isRequired,
        post: PropTypes.func.isRequired,
        get : PropTypes.func.isRequired
    };

}
