import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

import './admin-sidebar.scss';


export default class AdminSidebar extends Component {

    constructor(props) {
		super(props);
		this.state = {
            activePath: props.activePath,
            checked: false
        }
    }

    renderItems = () => {
        let lst = [];
        this.props.links.forEach(item => {
            let links = [];
            
            item.links.forEach(pathname => {
                let currentPage = this.props.location.pathname === pathname.path ? "active" : "";
                if (pathname.path.startsWith("http")) {
                    links.push(
                        <ListGroup.Item key={pathname.path} className={currentPage}>
                            <a href={pathname.path} target="_blank" rel="noopener noreferrer">{pathname.title} <span className="small"><FontAwesomeIcon  size="xs" fixedWidth  icon={faExternalLinkAlt} /></span></a>
                        </ListGroup.Item>
                    );
                } else {
                    let badge = null;
                    if (pathname.badge) {
                        badge = <Badge pill variant="info">{pathname.badge}</Badge>
                    }
                    links.push(
                        <ListGroup.Item key={pathname.path} className={currentPage}>
                            <Link to={pathname.path} >{pathname.title} &nbsp;{badge}</Link>
                        </ListGroup.Item>
                    );
                }
            })
            
            lst.push(
                <ListGroup variant="flush" key={item.category}>
                    <h6>{item.category}</h6>
                    {links}
                </ListGroup>
            );
        });
        return lst;
    }

    render() {
        // let switchMode = this.props.darkMode ? "on" : "off";
        return (
            <React.Fragment>
                <Nav className={this.props.subPage ? "side-bar_nav side-bar_nav--level-2" : "side-bar_nav"}>
                    {this.props.subPage ? (
                        
                        <Link className="btn-back" 
                            to={{
                            pathname:this.props.location.pathname.split("/").slice(0, 3).join("/"), 
                            state: null
                            }}>
                                <span className="small"><FontAwesomeIcon  size="3x" fixedWidth  icon={faArrowAltCircleLeft} /> Back</span>
                        </Link>

                    ) : (
                        this.renderItems()
                    )}
                </Nav>
                <div className="side-bar_footer"> 
                    {/* <div className={"toggle-switch toggle-switch--" + switchMode}>
                        <Switch  
                            onChange={this.props.handleDarkMode} 
                            checked={this.props.darkMode} 
                            handleDiameter={15}
                            height={25}
                            width={40}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                        <span className="toggle_switch_label">Dark Mode {switchMode}</span>
                    </div> */}

                    {/* <ButtonToolbar className={"toggle-switch " + this.props.darkMode}>
                        <ToggleButtonGroup type="radio" name="options" value={this.props.darkMode} onChange={this.props.handleDarkMode}>
                            <ToggleButton value={"light"} ></ToggleButton>
                            <ToggleButton value={"dark"} ></ToggleButton>
                        </ToggleButtonGroup>
                        
                    </ButtonToolbar> */}
                </div>
            </React.Fragment>
            
        );
    }

    static propTypes = {
        links: PropTypes.array.isRequired,
        handleDarkMode: PropTypes.func.isRequired,
        darkMode: PropTypes.bool.isRequired,
        subPage: PropTypes.bool,
        activePath: PropTypes.string,
        location:   PropTypes.object
    }
}