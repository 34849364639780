import React, { Component } from 'react';
import Body from '../../utils/utils-body'
import Content from '../snippets/ContentTNC';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Tnc.scss';

class TNC extends Component {

    componentDidMount = () => {
        Body.pageClass("add", "page_contact-us")
    }

    render() {
        return (
            <Container as="main" className="page-narrow">
                <Row>
                    <Col  className="page-header">
                        <h1 className="display-3">Terms and Conditions</h1>
                    </Col>
                </Row>
                <Row>
                    <Content/>
                </Row>
            </Container>
        );
    }
}

export default TNC;
