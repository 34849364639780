import React, { Component } from "react";
import PropTypes from "prop-types";


import Autosuggest from 'react-autosuggest';



// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.name}
  </div>
);


export default class AutoSuggest extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            question: "What is your industry title?",
            value: '',
            suggestions: []
        }
    }


    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.props.autosuggestProps.list.filter(lang =>
            lang.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        );
    };



    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
        suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
        suggestions: []
        });
    };
    

    render() {

        // const { value, suggestions } = this.state;
        const { suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
        placeholder: this.props.placeholder,
        value: this.props.value,
        onChange: this.props.onChange
        };

        return(

                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
            
        )
    }
    
    static propTypes = {
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        autosuggestProps: PropTypes.object.isRequired
    }
}