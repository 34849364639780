import React, { Component } from 'react';
import smartlookClient from 'smartlook-client'
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Body from '../../utils/utils-body'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import PreLoader from '../snippets/PreLoader'
import CompareProofOfReview from './compare/step-proof-of-review'
import CompareProofOfPurchase from './compare/step-proof-of-purchase'
import CompareStepWalkthrough from './compare/step-walkthrough'
import CompareStepNda from './compare/step-nda'
import CompareStepKeywordSearch from './compare/step-keyword-search'
import CompareStepCompetitors from './compare/step-competitors'
import CompareStepOurProduct from './compare/step-our-product'
import CompareStepOurProductLocation from './compare/step-our-product-location'
import CompareStepComplete from './compare/step-complete'
import CompareStepPaypalVerification from './compare/step-paypal-verification'
import trackingLoader from './../../utils/tracking-loader'
import LOCAL_STORAGE from '../../utils/local-storage'
import RETAILS from '../../utils/retails'

import  { Redirect } from 'react-router-dom'
import './Compare.scss';
             
export default class CompareSearchFindBuyReview extends Component {
    constructor(props) {
		super(props);
		this.state = {
            loading: false,
            validated: false,
            preloader: true,
            id: this.props.match.params.id,
            step : {
                // need to clean this after launch and remove unnessary stuff
                nda_agreement:"",
                current: 1,
                competitorStart : 2,
                competitorEnd : 0,
                competitorAnswers: [],
                orderNumber: "",
                paypalEmail: "",
                dealAnswer: "",
                dealKeyword:"",
                totalQuestions: "",
                totalSteps: 0,
                deal_product_page:"",
                proofOfPurchase:[],
                proofOfReview: []
            },
            pages : [
                "walkthrough",
                "nda",
                "keyword-search",
                "competitor",
                "our-product-location",
                "our-product",
                "paypal",
                "proof-of-purchase",
                "proof-of-review",
                "complete"
            ],
            currentPage: "walkthrough",
            trackPage: "",
            product: {},
            proofOfPurchaseSubmitted:"", //New 
            proofOfReviewSubmitted:"",
            reviewWaitTime: 7, //Number of days before review step is enabled for users
            requestAmzUrl: false,
            submitted: false
        }
    }
    
    componentDidMount = () => {

        // Work on remove fetch deals from here and pull from parent level
        Body.pageClass("add", `page_compare page_compare-${this.state.currentPage}`)
        
        if (this.props.device.isMobile) {
            this.props.history.push({
                pathname: "/tests"
            });
        } else {
           this.updateState(this.props.deal)
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
    
        Body.pageClass("add", `page_compare page_compare-${this.state.currentPage}`)

        if (this.props.userProfile !== prevProps.userProfile && this.props.userProfile){
            this.setState({
                userProfile: this.props.userProfile
            });
        } else if (this.props.isAdmin !== prevProps.isAdmin) {
             this.setState({
                reviewWaitTime: this.props.isAdmin ? 0 : 7
            });
        }

        const pageChanged = this.state.currentPage !== prevState.currentPage ? true : false;
        if (pageChanged || (!pageChanged && this.state.currentPage === this.state.pages[0] && !this.state.preloader)) {
            trackingLoader.trackPageview(window.location.pathname + "/" + this.state.currentPage, this.state.currentPage);               
        }

    }

    updateStorage = () => {
         let param =  { 
                step: this.state.step.current , 
                deal_keyword: this.state.step.dealKeyword,
                deal_answer: this.state.step.dealAnswer, 
                comp_answer: this.state.step.competitorAnswers, 
                deal_product_page: this.state.step.deal_product_page,
                order_number:this.state.step.orderNumber,
                current_page:this.state.currentPage,
                current_competitor:this.state.step.currentCompetitor,
            }

            this.props.setStorage(param)
    }

    getStorage = () => {
        let savedDeal = LOCAL_STORAGE.get("survey", {id: this.state.id , profileId: this.props.userProfile._id });
        
        if (savedDeal !== null) {
            let step = {...this.state.step}
                step.current = savedDeal.step !== null ? savedDeal.step : 1
                step.orderNumber = savedDeal.order_number !== null ? savedDeal.order_number : ""
                step.dealAnswer = savedDeal.deal_answer !== undefined ? savedDeal.deal_answer : ""
                // step.dealKeyword = savedDeal.deal_keyword
                step.competitorAnswers = savedDeal.comp_answer !== null ? savedDeal.comp_answer : []
                step.currentCompetitor = savedDeal.current_competitor !== null ? savedDeal.current_competitor : 0
                step.currentCompetitor = savedDeal.current_competitor !== null ? savedDeal.current_competitor : 0
                step.deal_product_page = savedDeal.deal_product_page !== null ? savedDeal.deal_product_page : ""
                this.setState({
                    step,
                    currentPage : savedDeal.current_page 
                });
        } else {
            let step = {...this.state.step}
            this.setState({
                    step,
                    currentPage :  "walkthrough"
                }); 
        }
    }

    updateStep = (value) => {
        let step = {...this.state.step}
            step.current = value;
            this.setState({step}, () => this.updateStorage());

            trackingLoader.trackEvent("checkout-progress", {
                user_type:this.props.userProfile.riskLevel,
                deal_id: this.props.match.params.id,
                deal_title: this.state.product.deal_name,
                checkout_step: value,
                event_category: "Compare Flow",
                event_label: value,
                currency: "USD",
                value: this.state.product.deal_amount
            });   

            trackingLoader.trackEvent(`user-type`, {
                deal_id: this.state.id,
                deal_title: this.state.product.deal_name,
                event_category: "Compare Flow",
                event_label: this.props.userProfile.riskLevel,
            });   
             
    }

    countTotalPages = (totalPages, competitorsQuestions, excludePages) => {
        let output = totalPages.length + competitorsQuestions - excludePages.length

        return output
        
    }

    handlePageRedirect(state) {
        if (state.product.hasOwnProperty("proof_of_purchase_date")) {
            if (state.product.proof_of_purchase_date !== "" && !state.product.hasOwnProperty("proof_of_review_date")) {
                this.setState({
                    currentPage:"proof-of-review"
                })
            } else if ((state.product.proof_of_purchase_date !== "" && state.product.proof_of_review_date !== "")|| [ 'paid'].indexOf(state.deal_status) >= 0) {
                this.setState({
                    submitted : true,
                    currentPage:"complete"
                })
            } 
        } else {
            this.getStorage()
        }




    }

    updateState = (response) => {
            
            // Uncomment this before push 
            if ((response.cool_off && response.deal_status === 'active') || response.deal_status === "out_of_stock" || (response.deal_coming_soon && this.props.userProfile.role !== "ADMIN")  ){
                this.setState({
                    redirect_to: '/'
                });
            }
            // init competitor answer array
            let numCompetitors = response.competitors.length;
            let currentCompetitor = 0 
            let totalSteps = this.countTotalPages(this.state.pages, numCompetitors, ["walkthrough","nda"])                
            
            let competitorAnswers = [];

                for (let c=0; c<numCompetitors; c++) {
                    competitorAnswers.push({
                        answer: ""
                    });
                }            

            let state = this.state;
                state.reviewWaitTime = this.props.isAdmin ? 0 : 7
                state.preloader = false
                state.step = {...state.step}
                state.step.currentCompetitor = currentCompetitor
                state.step.totalSteps = totalSteps
                state.step.competitorAnswers = competitorAnswers
                state.step.dealKeyword = response.deal_keyword
                state.step.paypalEmail= this.props.userProfile.payPalAccount
                state.step.purchaseCta = "Add to Cart"
                state.product = response

                if(response.hasOwnProperty("purchase_cta")) {
                     state.step.purchaseCta = response.purchase_cta
                } else {
                    // FIXME need to make this dynamic
                    if (response.deal_channel === "TARGET") {
                        state.step.purchaseCta = "Ship it"
                    } else if (response.deal_channel === "IHERB") {
                        state.step.purchaseCta = "Add to Cart"
                    }else if (response.deal_channel === "ANTHROPOLOGIE") {
                        state.step.purchaseCta = "Add to Basket"
                    }else if (response.deal_channel === "WALMART") {
                        state.step.purchaseCta = "Add to Cart"
                    }
                }
         
                
              
                this.setState({state}, () => {
                    this.handleCompetitorSteps(this.state.step.competitorStart)
                    this.handlePageRedirect(state)
                });          
    }

    handleInputChange = (e) => {
        let inputValue = e.target.value;
        let inputName = e.target.name;
        let step = this.state.step;    

            // Competitors 
            if (inputName === "compAnswer") {
                let competitorNum = e.target.getAttribute("data-num");
                    step.competitorAnswers[competitorNum].answer = inputValue;
            } else if (inputName === "paypalEmail") {
                step[inputName] = inputValue.toLowerCase();
            } else {
                step[inputName] = inputValue
            }

            this.setState({
                step: step
            });
            
    }

    handleUpdatePaypal = () => {
         let paypalEmail = this.props.userProfile.payPalAccount;
            
            if (paypalEmail === null || paypalEmail === "") {
                paypalEmail = this.state.userProfile.email;
            }
            
            this.setState({
                step:{
                    ...this.state.step,
                    paypalEmail: paypalEmail
                }
            })
    }

    handleUploadScreenshots = (key, value) => {
        this.setState({
                step: {
                    ...this.state.step,
                    [key]: value
                }
        })
    }

    handleCompetitorSteps = (currentStep) => { 
            this.setState({
                step: {
                    ...this.state.step,
                    competitorEnd: currentStep + this.state.product.competitors.length - 1
                }
            })
    }      

    getNextPage(count) {
        let pages = this.state.pages
        let nextPage = ""
            pages.forEach(function(step, idx){                
                if (step === this.state.currentPage) {
                    nextPage = pages[idx+count]
                }
            }.bind(this))

        
        window.scrollTo(0, 0);

        return nextPage
    }

    getPreviousPage(count) {
        let pages = this.state.pages
        let nextPage = ""

            pages.forEach(function(step, idx){
                
                if (step === this.state.currentPage) {
                    nextPage = pages[idx-count]
                }
             }.bind(this))

            return nextPage
    }

    handlePreviousStep = () => {
        window.scrollTo(0,0);
        if (this.state.validated){
            this.setState({
                validated: false
            });
        }

        // Check if it is competitor Steps
        if (this.state.currentPage === "competitor" ) {
            
            if (this.state.step.current > this.state.step.competitorStart && this.state.step.current <= this.state.step.competitorEnd) {
                this.setState({
                    step : {
                        ...this.state.step,
                        current: this.state.step.current  - 1,
                        currentCompetitor: this.state.step.currentCompetitor - 1
                    }
                }, () => this.updateStorage())
            }  else {
                let previousPage = this.getPreviousPage(1)  
                this.setState({
                    currentPage: previousPage,
                    step : {
                        ...this.state.step,
                        current: this.state.step.current  - 1
                    }
                }, () => this.updateStorage())   
            }

        } else {
            let previousPage = this.getPreviousPage(1)
                if (this.state.step.current !== 1) {
                    this.updateStep(this.state.step.current  - 1);
                }
                this.setState({
                    currentPage: previousPage
                })
        }
    }

    handleNextStep = (event) => {
        window.scrollTo(0,0);
        
        let nextPage = this.getNextPage(1)    
            // Determine if it is form submit or function call
            if (event !== null) {
                event.preventDefault();        
            }

            if (this.state.currentPage === "paypal") {
                this.handleUpdatePaypal()
            }
            
        let lastpage = ["walkthrough","nda", "proof-of-purchase"]
    
            if(!lastpage.includes(this.state.currentPage)) {
                this.updateStep(this.state.step.current + 1);
            } 

        this.setState({currentPage: nextPage})
    }


    handleNdaSubmit = (value) => {

        let nextPage = value === "disagree" ? "complete" : this.getNextPage(1)

            this.setState({
                step: {
                    ...this.state.step, 
                    nda_agreement: value
                },
                currentPage: nextPage
            })

            if (value === "disagree") {
                trackingLoader.trackPageview("/tests/complete_compare_deal_disagree"); 
            }
 
    }

    handleCompetitorSubmit = (event) => {
        event.preventDefault();

        let obj = {}
            if(this.state.step.currentCompetitor + 1 !== this.state.product.competitors.length) {
                obj = {
                    step: {
                        ...this.state.step, 
                        current: this.state.step.current + 1,
                        currentCompetitor: this.state.step.currentCompetitor + 1
                    }
                }
                
            } else {
                obj = {
                    currentPage: this.getNextPage(1),
                    step: {
                        ...this.state.step,
                        current: this.state.step.current + 1
                    }
                }
            }
            this.setState({
                 ...obj
            }, () => this.updateStorage())
    }

    handleAddProductSubmit = (event) => {
        event.preventDefault();        
        let nextPage = this.getNextPage(1)
            
        this.setState({currentPage: nextPage},this.updateStep(this.state.step.current + 1))
    }

    // handlePayPalSubmit = (event) => {
    //     console.log("next step")
    //     event.preventDefault();
    //     let nextPage = this.getNextPage(1)
        
    //     let paypalEmail = this.props.userProfile.payPalAccount;
    //         if (paypalEmail === null || paypalEmail === "") {
    //             paypalEmail = this.props.userProfile.email;
    //         }

    //         this.setState({
    //             step:{
    //                 ...this.state.step,
    //                 paypalEmail: paypalEmail
    //             },
    //             currentPage: nextPage
    //         },this.updateStep(this.state.step.current + 1))
            
    // } 

    handleSetFileName = (key, e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                    let files = this.state.files
                        files[key] = reader.result
                        this.setState({ files : files})

            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    handleSubmitProofOfPurchase = (event) =>{
        event.preventDefault();

        this.setState({
            loading: true
        })

        let competitor_answer = []
        let screenshots = []

        this.state.step.competitorAnswers.forEach(item => {
            competitor_answer.push(item.answer);
        });

        this.state.step.proofOfPurchase.forEach(item => {
            screenshots.push(item.src);
        });


        let param = {
            paypal_email: this.state.step.paypalEmail, // STRING
            deal_answer: this.state.step.dealAnswer, // STRING
            deal_keyword: this.state.step.dealKeyword, // STRING
            deal_our_product_page: parseInt(this.state.step.deal_product_page, 10) , //STRING
            answers: competitor_answer,  // ARRAY,
            deal_proof_of_purchase: screenshots
        }

        this.props.post("/api/v1/deal/" + this.state.id + "/claim", param, "JSON").then(response => {
            if (response.result) {
                
                this.setState({
                    loading: false,
                    product: {
                        ...this.state.product,
                        testing:"testing",
                        proof_of_purchase_date :(new Date()).toString()
                    }
                },this.handleNextStep(null))

                // For tracking
                trackingLoader.trackEvent("complete_compare_deal", {
                    deal_id: this.props.match.params.id,
                    deal_title: this.state.product.deal_name,
                    event_category: `${this.props.deal.deal_type}`,
                    event_label: this.state.product.deal_name,
                    currency: "USD",
                    value: this.state.product.deal_amount
                });
                trackingLoader.trackPageview("/tests/submitted_proof_purpose"); 
        
            }
        }).catch(err => {
            trackingLoader.trackEvent("exception", {
                description: err,
                fatal: false
            });

        }); 
    }

    handleSubmitProofOfReview = () =>{
        
        this.setState({
            loading: true
        })

        let screenshots = []
        
        this.state.step.proofOfReview.forEach(item => {
            screenshots.push(item.src);
        });

        let param = {
            deal_proof_of_review: screenshots // ARRAY
        }

        this.props.put("/api/v1/deal/" + this.state.id + "/claim", param, "JSON").then(response => {
            if (response.result) {
                this.setState({
                    currentPage: "complete",
                    submitted: true,
                    loading: false
                }, this.props.clearStorage())
            }
        }).catch(err => {
            trackingLoader.trackEvent("exception", {
                description: err,
                fatal: false
            });

        });             
    }

    renderFlow = () => {
        let currentStep = this.state.currentPage 
        let body = null;
        let renderNextPage = null
        let progressNumber = this.state.step.current / (this.state.step.totalSteps + 1) * 100
        let progressBar = (Math.round( progressNumber/5 )*5).toFixed();
        let progressBarNext = "Next";
        let formNavigation = null
        let formHeader = null
        let disableSubmit = false

            if(currentStep === "walkthrough") {
                body = 
                <CompareStepWalkthrough 
                    {...this.props}
                    {...this.state}
                    product = {this.state.product}
                    handleWalkthrough={this.handleNextStep.bind(this)}
                />
            } else if (currentStep === "nda") {
                body = 
                <CompareStepNda 
                    // {...this.state}
                    product = {this.state.product}
                    handleNdaSubmit={this.handleNdaSubmit}
                />
            } else if (currentStep === "keyword-search") {
                renderNextPage = this.handleNextStep.bind(this)
                body = 
                    <CompareStepKeywordSearch 
                        {...this.state}
                    />
            } else if (currentStep === "complete") {
                body = 
                <CompareStepComplete 
                    {...this.props}
                    user={this.props.userProfile}
                    nda_agreement= {this.state.step.nda_agreement}
                    step = {{currentPage: this.state.currentPage}}
                    product = {this.state.product}
                    
                />
            } else if (currentStep === "proof-of-purchase" ){
                progressBarNext = "Submit";
                renderNextPage = this.handleSubmitProofOfPurchase.bind(this)
                if (this.state.step.proofOfPurchase.length > 0 && !this.state.loading) {
                    disableSubmit = false
                } else {
                    disableSubmit = true
                }
                body =  <CompareProofOfPurchase 
                    {...this.state} 
                    userProfileId = {this.props.userProfile._id}
                    uploader = {{key: "proofOfPurchase", screenshots: this.state.step.proofOfPurchase}}
                    handleNext={this.handleNextStep.bind(this)}
                    handleOnSuccess={this.handleUploadScreenshots}
                />
            } else if (currentStep === "proof-of-review" ){
                progressBarNext = "Complete";
                body =  <CompareProofOfReview 
                    {...this.state} 
                    userProfileId = {this.props.userProfile._id}
                    uploader = {{key: "proofOfReview", screenshots: this.state.step.proofOfReview}}
                    handleSubmit = {this.handleSubmitProofOfReview}
                    handleOnSuccess={this.handleUploadScreenshots}
                />
            }  else if  (currentStep === "our-product-location" ) {
                renderNextPage = this.handleNextStep.bind(this)
                body = <CompareStepOurProductLocation 
                                {...this.state}
                                handleInputChange={this.handleInputChange}
                            />
            } else if  (currentStep === "our-product" ) {
                renderNextPage = this.handleAddProductSubmit.bind(this)
                body = <CompareStepOurProduct 
                                {...this.props}
                                {...this.state}
                                handleInputChange={this.handleInputChange} 
                            />
            } else if (currentStep === "paypal" ) {
                renderNextPage = this.handleNextStep.bind(this)
                body = <CompareStepPaypalVerification 
                    {...this.props}
                    {...this.state} 
                    handleInputChange={this.handleInputChange} 
                />
            } else if (currentStep === "competitor" ) {
                    renderNextPage = this.handleCompetitorSubmit.bind(this)
                    body = <CompareStepCompetitors
                                    {...this.props}
                                    {...this.state}
                                    handleInputChange={this.handleInputChange} 
                                />          
            } 

            
            let hideNavigation = ["walkthrough","nda", "complete"]
                if (!hideNavigation.includes(currentStep)) {
                    if (currentStep !== "proof-of-review") {
                        formNavigation = 
                        <Row className="instruction-navigation">
                                <Col xs={3} md={{ span: 2, offset: 1 }}  >
                                    <Button className={"btn btn-dp btn-previous" + (this.state.step.current === 1 ? "disabled" : null)} onClick={this.handlePreviousStep.bind(this)} block>Previous</Button>
                                </Col>
                                <Col xs={6} md={7}>
                                    <ProgressBar animated striped now={progressBar} />
                                    <span className={"progress-bar_label progress-bar_label--" + (this.state.step.current === (this.state.step.totalSteps + 1) ? "complete" : null)} >{progressBar}%</span>
                                </Col>
                                <Col xs={3} md={2}>
                                    <Button className={"btn btn-dp btn-next"} type="submit" block  disabled={disableSubmit}>{progressBarNext}</Button>
                                </Col>
                        </Row>
                    }

                    formHeader = 
                    <React.Fragment>
                            <Row  className="product-detail">
                                <Col xs={2} md={1}  className="product-detail_image">
                                    <div className="product-detail_image-wrapper" > 
                                       <div className="product-image" style={{backgroundImage: "url(" + this.state.product.product_image_url + ")"}}>
                                       </div>
                                    </div>   
                                    
                                </Col>
                                <Col xs={10} md={10}  className="product-detail_text">
                                        <img alt={`${this.state.product.deal_channel}-logo`} className={this.state.product.deal_channel} src={RETAILS.getLogo(this.state.product.deal_channel, "sm")}/> 
                                        <h1>{this.state.product.deal_name}</h1>
                                </Col>
                            </Row>
                        </React.Fragment>

                }

            // Track Page Event
            if (this.state.trackPage !== currentStep) {               

                // Tracking Purposes
                let product = this.state.product
                    smartlookClient.track(`Survey: ${currentStep} step`, {
                        id: product._id,
                        region: product.deal_region,
                        channel: product.deal_channel,
                        name: product.product_name,
                        type: product.deal_type,
                        step: currentStep
                    })

                    trackingLoader.trackEvent(`test-product-${currentStep}`, {
                        deal_id: this.state.id,
                        deal_title: this.state.product.deal_name,
                        event_category: "hotjar",
                        event_label: `${this.state.id}: ${this.state.product.deal_name}`
                    });  

                this.setState({
                    trackPage: currentStep
                })
            }

            
            let noValidate = false
                if ((["order-id","request-amz-url"]).includes(currentStep)) {
                    noValidate = true
                } else {
                    noValidate = false
                }

            return ( 
                <Form onSubmit={renderNextPage} noValidate={noValidate} validated={this.state.validated}>
                    {formHeader} {body} {formNavigation}
                </Form> )
    }

    render() {
        
        let output = null

        if (this.state.redirect_to){
            this.setState({
                redirect_to: false
            });    
            return <Redirect to={this.state.redirect_to}/>;
        } else if (this.state.preloader) {
            output = <PreLoader/>
        } else {
            output = this.renderFlow();
        }

  
        return (
            <>
            <Container as="main" className={!this.state.preloader ? "questionnaire deal_compare" : "questionnaire deal_compare loading"}>
                 {/* [New Flow - For testing purpose]  */}
                {output}
            </Container>
            {/* <ModalAmzProfileRequest {...this.props} response={this.requestAmzUrlResponse} show={this.state.requestAmzUrl}/> */}
            </>
        );
    }

    static propTypes = {
        device: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool.isRequired,
        userProfile : PropTypes.object,
        fetchProfile: PropTypes.func.isRequired,
        match:  PropTypes.object.isRequired,
        post: PropTypes.func.isRequired,
        put:PropTypes.func.isRequired,
        get : PropTypes.func.isRequired,
        history: PropTypes.object,
        deal: PropTypes.object,
        clearStorage: PropTypes.func.isRequired,
        setStorage: PropTypes.func.isRequired
    };

}
