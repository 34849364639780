import React, { Component } from "react";
import PropTypes from "prop-types";
import PreLoader from '../../snippets/PreLoader'
import PandaCoin from '../../snippets/panda-coin'

import '../profile/points-status.scss';

export default class PointsStatus extends Component {
    render() { 
        return (
            <React.Fragment>
                <span className="point_status-wrapper">
                    <PandaCoin/>
                    {this.props.points === null ? (
                        <PreLoader/>
                    ) : (                         
                        <span>{this.props.points}</span>
                    )}
                </span>
            </React.Fragment>
        )
    }

    static propTypes = {
        points: PropTypes.number,
        pointsPending: PropTypes.number
    }

    static defaultProps = {
        points: 0,
        pointsPending: 0
    }
}