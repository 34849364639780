import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// Bootstrap 
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Form from 'react-bootstrap/Form'
// Custom Utilities 
import Flags from '../snippets/flags' 
import AsinUrl from '../snippets/asin-url' 
import PreLoader from '../snippets/PreLoader'
import adminRegions from '../../utils/admin-regions'
import RETAILS from '../../utils/retails'
// Third Party 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
// i18n
import userType from '../../i18n/en/user-type.json'
import dealStatus from '../../i18n/en/deal-status.json'




import './admin-deals.scss';

export default class AdminDeals extends Component {

    constructor(props) {
		super(props);
		this.state = {
            deals: [],
            dealCounts: {},
            dealClaims: {},
            limit: 30,
            page: 1,
            pageTotal:"",
            resultsTotal : "",
            isBtnLoading: false, 
            filterByDealStatus: "all",
            filterByRegion: "all",
            filterByUserType: "all"
        }
    }

    componentDidMount = () => {
        Promise.all([this.fetchDeals(this.state.page, this.state.limit, this.state.filterByDealStatus, this.state.filterByRegion, this.state.filterByUserType), this.fetchUserDealsCount("submitted"), this.fetchUserDealsCount("")]).then(results => {
            this.setState({
                deals: results[0].deals,
                page: results[0].current_page,
                pageTotal : results[0].num_pages, 
                dealCounts: results[1],
                dealClaims: results[2]
            });
        });
    }

    fetchDeals = (page, limit, filterByDealStatus, filterByRegion, filterByUserType) => {
        const params = {
            page: page,
            limit: limit,
            filter: "admin"
        }

        if (filterByDealStatus !== "all") {
            params["deal_status"] = filterByDealStatus;
        }

        if (filterByRegion !== "all") {
            params["region"] = filterByRegion;
        }
    
        if (filterByUserType !== "all") {
            params["risk_level"] = filterByUserType;
        }

        console.log(params)

        return this.props.get("/api/v1/deals", params).then(response => {
            console.log(response)
            return Promise.resolve(response);
        }).catch(err  => {
            console.error(err);
            return Promise.reject([]);
        });
    }

    fetchUserDealsCount = (status) => {
        const params = {
            status: status
        }
        return this.props.get("/api/v1/deals/usercount", params).then(response => {
            return Promise.resolve(response.results);
        }).catch(err => {
            console.error(err);
            return Promise.reject(err);
        });
    }

    loadMore = (e) => {
        e.preventDefault();
        const page = this.state.page + 1;
        this.setState({isBtnLoading: true})
        this.fetchDeals(page, this.state.limit, this.state.filterByDealStatus, this.state.filterByRegion, this.state.filterByUserType).then(response => {
            if (response.deals.length > 0) {
                const moreDeals = this.state.deals.concat(response.deals);
                this.setState({
                    deals: moreDeals,
                    page: response.current_page,
                    pageTotal : response.num_pages, 
                    isBtnLoading: false
                });
            }
        });
    }

    renderToolTip = (body, buttonText, status) => {
        let output = 
            <>
                <OverlayTrigger
                    placement={"right"}
                    overlay={
                    <Tooltip id={`tooltip-top`}>
                        {body}
                    </Tooltip>
                    }
                >
                    <Button variant={`link ${"--"+status}`}>${buttonText}</Button>
                </OverlayTrigger>
            </>

        return output
    }

    handleFilterSortChange = (e) => {
     
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let filterStatus = inputName === "filterByDealStatus" ? inputValue : this.state.filterByDealStatus;
        let filterRegion = inputName === "filterByRegion" ? inputValue : this.state.filterByRegion;
        let UserType = inputName === "filterByUserType" ? inputValue : this.state.filterByUserType;
            this.setState({
                [inputName]: inputValue,
                isBtnLoading: true
            });
            this.fetchDeals(this.state.page, this.state.limit, filterStatus, filterRegion, UserType).then(response => {
                if (response.deals.length > 0) {
                    this.setState({
                        deals: response.deals,
                        page: response.current_page,
                        pageTotal : response.num_pages, 
                        isBtnLoading: false
                    });
                } else {
                    this.setState({
                        deals: [],
                    });
                }
            });    
    }

    renderList = () => {
        let lst = [];
        this.state.deals.forEach(item => {
            
            let styleCard = { 
                backgroundColor: item.color , 
                backgroundImage: "url("+ item.product_image_url +")" 
            }

            let dealCount = 0;
                if (this.state.dealCounts.hasOwnProperty(item._id)) {
                    dealCount = this.state.dealCounts[item._id];
                }

            let dealClaims = 0;
                if (this.state.dealClaims.hasOwnProperty(item._id)) {
                    dealClaims = this.state.dealClaims[item._id];
                }

            let riskLevel = ""
                if (item.deal_risk_level === "NOT_RISKY") {
                    riskLevel =
                    <Badge variant="light">Regular </Badge>
                } else if (item.deal_risk_level === "VIVA_REVIEWERS") {
                    riskLevel =
                    <Badge variant="primary">Viva<br/>Reviewers</Badge> 
                } else if (item.deal_risk_level === "TRIAL") {
                    riskLevel =
                    <Badge variant="warning">{item.deal_risk_level}</Badge> 
                } else {
                    riskLevel =
                    <Badge variant="secondary">{item.deal_risk_level} </Badge> 
                }

            let status = ""
                if (item.deal_coming_soon && item.deal_status === "active") {
                    status = 
                    <Badge variant="warning">Coming<br/>Soon</Badge>
                } else if (item.deal_coming_soon && item.deal_status === "active") {
                    status = 
                    <Badge variant="warning">Coming<br/>Soon</Badge>
                } else if (item.deal_coming_soon && item.deal_status === "inactive") {
                    status =
                    <Badge variant="secondary">Coming<br/>Soon</Badge>
                } else if (item.deal_status === "active") { 
                    status =
                    <Badge variant="success">{item.deal_status}</Badge>
                } else { 
                    status =
                    <Badge variant="secondary">{item.deal_status}</Badge>
                }

            

            // let amazonMwsBrand = ""
            //     if (item.deal_amazon_mws_brand !== undefined && item.deal_amazon_mws_brand !== "undefined" ) {
            //         amazonMwsBrand = item.deal_amazon_mws_brand 
            //     } else {
            //         amazonMwsBrand = "Not Set"
            //     }


            let amazonBrand = ""
                if (item.deal_amazon_sister_brand_name !== undefined && item.deal_amazon_sister_brand_name !== "undefined" ) {
                    amazonBrand = item.deal_amazon_sister_brand_name
                } else if (item.deal_amazon_brand_name !== undefined && item.deal_amazon_brand_name !== "undefined" ) {
                    amazonBrand = item.deal_amazon_brand_name 
                } else {
                    amazonBrand = "Not Set"
                }

            
            
            let dealAmount = ""
                if(item.hasOwnProperty('deal_amazon_amount')) {
                    let lastUpdate =  <li className="date">{dayjs(item.deal_amazon_amount.updated_at).format("MMM DD") } - {dayjs(item.deal_amazon_amount.updated_at).format("HH:MM A") }</li>
                    if(item.deal_amazon_amount.amount !== null) {
                        let body = 
                            <div className="data_live_amount_tooltip">
                                <div className="item">
                                    <p className="label">Original amount</p>
                                    <p>${item.deal_amazon_amount.previous_amount}</p>
                                </div>
                                <div className="item">
                                    <p className="label">Amz amount</p>
                                    <p>${item.deal_amazon_amount.amount}</p>
                                </div>
                                <div className="item">
                                    <p className="label">Last Update</p>
                                    <ul className="breakdown">
                                        {lastUpdate}
                                    </ul>
                                </div>
                            </div>
                        dealAmount = this.renderToolTip(body, item.deal_amazon_amount.amount, "success")
                    } else {
                        let body = 
                            <div className="data_live_amount_tooltip">
                                <div className="item">
                                    <p className="label">Original amount</p>
                                    <p>${item.deal_amount}</p>
                                </div>
                                <div className="item">
                                    <p className="label">Amz amount</p>
                                    <p>Unknown</p>
                                </div>
                                <div className="item">
                                    <p className="label">Error Message</p>
                                    <p>{item.deal_amazon_amount.note}</p>
                                </div>
                                <div className="item">
                                    <p className="label">Last Update</p>
                                    <ul className="breakdown">
                                        {lastUpdate}
                                    </ul>
                                </div>
                            </div>
                        dealAmount = this.renderToolTip(body, item.deal_amount, "error")
                    }  
                } else {
                    let body = 
                            <div className="data_live_amount_tooltip">
                                <div className="item">
                                    <p className="label">Message</p>
                                    <p>Deal is currently inactive</p>
                                </div>
                            </div>
                        dealAmount = this.renderToolTip(body, item.deal_amount, "success")
                }
            

            let dealProductAdditionalDetail = ""

                if (item.deal_channel === "AMAZON") {
                    dealProductAdditionalDetail = 
                    <>
                        <p className="small name-amazon"><strong>Amazon Brand</strong> : {amazonBrand}</p>
                        <p className="small name-product"><strong>Asin</strong> : <AsinUrl region={item.deal_region} asin={item.deal_asin} /> </p>
                        {/* <p className="small name-amazon"><strong>Amazon MWS</strong> : {amazonMwsBrand}</p> */}
                    </>
                } else {
                    dealProductAdditionalDetail = <p className="small name-product"><strong>Product ID</strong> : {item.deal_product_id} </p>
                }
          
            lst.push(
                // inactive
                <tr key={item._id}>
                    <td className="table-data_image">
                        <div className="image-wrapper image-wrapper_rewards" style={styleCard}></div>
                    </td>
                    <td className="table-data_name">
                        <div className="name-title"> 
                            {item.deal_name}
                        </div>
                        <p className="small name-product"><strong>ID</strong> : {item._id} </p>
                        {dealProductAdditionalDetail}
                    </td>
                    <td className="text-center">
                        {dayjs(item.created_at).format("MMM DD") }
                    </td>
                    <td className="table-data_retail-logo">
                        <img alt="retail logo" src={RETAILS.getLogo(item.deal_channel, "sm")}/> 
                    </td>
                    <td className="table-data_status">
                        {status}
                    </td>
                    <td className="text-center">
                        <Flags region={item.deal_region}/>
                    </td>
                    <td className="text-center table-data_live_amount">
                        {dealAmount} 
                    </td>
                    <td className="text-center">{dealClaims} / {item.deal_quantity}</td>
                    <td className="text-center">{item.deal_daily_limit} </td>
                    <td className="text-center table-data_status">
                            {riskLevel}
                    </td>
                    <td>
                        <div className="table-data_action-bar">                            
                            <Link to={{
                                pathname: "/admin/deals/manage/" + item._id,
                                state: "manage"
                                }}>
                                <span title="manage redeemers">
                                    <FontAwesomeIcon icon={faTasks} size="sm" fixedWidth />
                                    {dealCount === 0 ? null : <Badge pill variant="primary">{dealCount}</Badge>}
                                </span>
                            </Link>

                            <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link className="dropdown-item" to={{
                                        pathname: "/admin/deals/edit/" + item._id,
                                        state: "edit"
                                        }}>
                                        Edit
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </td>
                </tr>
            );
        });


        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="6"><em>No results found.</em></td>
                </tr>                
            );
        }

        return lst;
    }

    renderOptions = (option) => {
        let output = []
        let list = []

            if (option === "user-type") {
                list = userType
            } 

            if (option === "deal-status") {
                list = dealStatus
            }  

            list.forEach(function (user, i){
                output.push(
                    <option key={i} value={user.key}>{user.value}</option>
                )
            })

            return output
    }


    render = () => {
        let button = null ;
        
            if(this.state.page !== this.state.pageTotal || this.state.deals.length === 0) {
                button = <Button className="btn-load-more" onClick={this.loadMore} disabled={this.state.isBtnLoading } size="sm">
                    {this.state.isBtnLoading ? 'Loading…' : 'Load More'}
                </Button>
            }
            
        return (
            <React.Fragment>
                {this.state.deals.length === 0 ? (
                    <PreLoader />
                ) : (
                    <>
                    <div className="table-search_wrapper --no-search">
                        <div className="table-search_filter-wrapper">
                            <span>Filter by status:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByDealStatus} name="filterByDealStatus"  onChange={this.handleFilterSortChange}>
                                    <option value="all">All</option>
                                    {this.renderOptions("deal-status")}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="table-search_filter-wrapper">
                            <span>Filter by region:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByRegion} name="filterByRegion"  onChange={this.handleFilterSortChange}>
                                    <option value="all">All</option>
                                    {adminRegions.options()}
                                </Form.Control>
                            </div>
                        </div>
                        <div className="table-search_filter-wrapper">
                            <span>Filter by user type:</span>
                            <div className="custom-select">
                                <Form.Control className="table-search_filter" as="select" value={this.state.filterByUserType} name="filterByUserType"  onChange={this.handleFilterSortChange}>
                                    <option value="all">All</option>
                                    {this.renderOptions("user-type")}
                                </Form.Control>
                            </div>
                        </div>
                    </div>
                    <section className="deals_overview">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="deals_img"></th>
                                    <th className="table-data_name">Deal Name</th>
                                    <th className="text-center">Created On</th>
                                    <th className="text-center">Channel</th>
                                    <th className="table-data_status">Status</th>
                                    <th className="text-center">Region</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Claimed / Qty</th>
                                    <th className="text-center">Daily Limit</th>
                                    {/* <th className="text-center">Risk Level</th> */}
                                    <th className="text-center">User Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderList()}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan="10" className="load-more_wrapper">
                                        {button}
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    </section>
                    </>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired
    }
}