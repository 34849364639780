import ScriptLoader from './script-loader';

export default {
    GA_MEASUREMENT_ID: "",
    FULLSTORY : false,

    initSmartlook(client, id) {
        if (window.location.href.indexOf("admin") === -1) {
             client.init(id)
        }
    },

    initFullstory(org) {
        const ns = "FS"
        if (ns in window) {
            return;
        }
 
        if (window.location.href.indexOf("admin") === -1 && window.location.origin === "https://dealpanda.com") {
            this.FULLSTORY = true;
            window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_org'] = org;
            window['_fs_namespace'] = ns;
            (function(m,n,e,t,l,o,g,y){
                if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+window._fs_host+'/s/fs.js';
                y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                g.log = function(a,b) { g("log", [a,b]) };
                g.consent=function(a){g("consent",!arguments.length||a)};
                g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                g.clearUserCookie=function(){};
            })(window,document,window['_fs_namespace'],'script','user');
        }
    },

    initGtag(gaMeasurementId, options) {
        this.GA_MEASUREMENT_ID = gaMeasurementId;
        const loader = new ScriptLoader();
        loader.loadScript("https://www.googletagmanager.com/gtag/js?id=" + gaMeasurementId).then(() => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {window.dataLayer.push(arguments);}
            gtag('js', new Date());
            // init GA and Google Optimize
            // gtag('config', process.env.REACT_APP_GA_TRACKING_ID, {'optimize_id': process.env.REACT_APP_GA_OPTIMIZE_ID});
            gtag('config', gaMeasurementId, options);
            window.gtag = gtag;
            window.dispatchEvent(new CustomEvent("gtag.loaded", {detail: {}}));
        }).catch(err => {
            console.log(err);
        });
    },

    initOlark(id) {
        /* eslint-disable */
        (function(o,l,a,r,k,y){
            if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; 
            y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); 
            y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; 
            y.extend=function(i,j){y("extend",i,j)}; 
            y.identify=function(i){y("identify",k.i=i)}; 
            y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; 
            k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js");
            olark.identify(id)
         /* eslint-enable */
    },

    initHotjar(id) {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:id,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    },

    initFBPixel(pixelId, advancedMatching = {}) {
        /* eslint-disable */
        !(function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          'script',
          'https://connect.facebook.net/en_US/fbevents.js',
        );
        /* eslint-enable */
    
        window.fbq('init', pixelId, advancedMatching);
    },

    initPinterest(tagId) {
        (function(e){
            if(!window.pintrk){
                window.pintrk=function(){
                    window.pintrk.queue.push(
                        Array.prototype.slice.call(arguments))
                };
                var n=window.pintrk;
                n.queue=[]; n.version="3.0";
                var t=document.createElement("script");
                t.async=!0; t.src=e;
                var r=document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t,r)
            }
        })("https://s.pinimg.com/ct/core.js");
        window.pintrk('load', tagId);
        window.pintrk('page');
    },

    trackEvent(eventName, params) {
        try {
            window.gtag("event", eventName, params);
        } catch(ex) {
            console.warn(ex.message, eventName);
        }
        try {
            if (eventName === "sign_up") {
                window.fbq("track", "CompleteRegistration");
            } else {
                window.fbq("trackCustom", eventName, params);
            }
        } catch(ex) {
            console.warn(ex.message);
        }

        try {
            // console.log("trigger", eventName)
            window.hj("trigger", eventName);
        } catch(ex) {
            console.warn(ex.message);
        }

        if (this.FULLSTORY) {
                 try {
                // full story
                window.FS.event(eventName, params);
            } catch(ex) {
                console.warn(ex.message);
            }
        }

        try {
            if (eventName === "sign_up") {
                window.pintrk("track", "checkout", params);
            } else {
                window.pintrk("track", "custom", params);
            }
        } catch(ex) {
            console.warn(ex.message);
        }
    
    },

    trackPageview(path, title, variant) {
        // console.log("pageview", path)
        try {
            window.gtag("config", this.GA_MEASUREMENT_ID, {
                page_path: path,
                page_title: title + " " + variant,
                variant: variant
            });
        } catch(ex) {
            console.warn(ex.message, path, title);
        }
    
        try {
            window.fbq('track', "ViewContent", {
                content_name: path,
                content_category: title + " " + variant,
                variant: variant
            });
        } catch(ex) {
            console.warn(ex.message);
        }
    
        try {
            window.hj('vpv',  path );
        } catch(ex) {
            console.warn(ex.message);
        }

        try {
            if (window.hasOwnProperty('fbq')) {
                window.fbq('track', 'ViewContent', {
                    content_name: path,
                    content_category: title
                });
            }
        } catch(ex) {
            console.warn(ex.message);
        }

        try {
            window.pintrk("track", "pagevisit", {
                page: path,
                title: title
            });
        } catch(ex) {
            console.warn(ex.message);
        }
    },

    identify(userId, email, name) {
        
        if (this.FULLSTORY) {
            try {
                // full story
                window.FS.identify(userId, {
                    displayName: name,
                    email: email
                });
            } catch(ex) {
                console.warn(ex.message);
            }
        }

        try {
            window.gtag('config', this.GA_MEASUREMENT_ID, {
                'user_id': userId
            });
        } catch(ex) {
            console.warn(ex.message);
        }

        try {
            window.hj('identify', userId, {
                email: email,
                name: name
            });
        } catch(ex) {
            console.warn(ex.message);
        }

        try {
            window.Rollbar.configure({
                payload: {
                    person: {
                        id: userId,
                        username: name,
                        email: email
                    }
                }
            });
        } catch(ex) {
            console.warn(ex.message);
        }
    }
}