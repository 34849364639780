import React from 'react'
class CopyDisabledComponent extends React.Component {

    render () {
      const TagName = this.props.tagname;
      return  <TagName 
      className={this.props.className}
      onClick={e=>e.preventDefault()}
      onContextMenu={e=>e.preventDefault()}
      onDoubleClick={e=>e.preventDefault()}
      onDrag={e=>e.preventDefault()}
      onDragEnd={e=>e.preventDefault()}
      onDragEnter={e=>e.preventDefault()}
      onDragExit={e=>e.preventDefault()}
      onDragLeave={e=>e.preventDefault()}
      onDragOver={e=>e.preventDefault()}
      onDragStart={e=>e.preventDefault()}
      onDrop={e=>e.preventDefault()}
      onMouseDown={e=>e.preventDefault()}
      onMouseEnter={e=>e.preventDefault()}
      onMouseLeave={e=>e.preventDefault()}
      onMouseMove={e=>e.preventDefault()}
      onMouseOut={e=>e.preventDefault()}
      onMouseOver={e=>e.preventDefault()}
      onMouseUp={e=>e.preventDefault()}
      style={{"WebkitUserSelect": "none",
              "KhtmlUserSelect": "none",
              "MozUserSelect": "none",
              "msUserSelect": "none",
              "OUserSelect": "none",
              "userSelect": "none"}}
                >{this.props.content}</TagName>
    }
  }
  export default CopyDisabledComponent