import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Body from '../../utils/utils-body'
import Container from 'react-bootstrap/Container'
import ReviewStepStart from './review/step-start'
import ReviewStepShare from './review/step-share'
import ReviewStepShareSubmitted from './review/step-share-submitted'
import ReviewStepShareNotSubmitted from './review/step-share-not-submitted'
import ReviewStepComplete from './review/step-complete'
import PreLoader from '../snippets/PreLoader'
import trackingLoader from './../../utils/tracking-loader'
import './review.scss'


export default class Review extends Component {
    constructor(props) {
        super(props);
        this.blockedWords = ["abuse","ad","advertisement","advertising","ban",
        "banned","biased","cash","collude","collusion","compensation","deal",
        "dealpanda","dealpanda.com","deals","discount","discounted","discounts",
        "endorse","endorsement","free","gift","gifts","giftcard","giftcards",
        "incentive","incentivized","influence","influenced","insightcookie","insightcookie.com",
        "insight cookie","investigate","malicious","manipulate","manipulation","paid","panda",
        "pay","payback","payment","payments","payout","phishing","product panel","productpanel",
        "productpanel.com","profile","url","link","promo code","promotion","promotions","promote",
        "rank","ranking","rebate","rebates","refund","refunds","reimbursement","reimbursements",
        "review","reviews","reviewer","sample","samples","scam","shill","shilling","suspicious",
        "test","tests","unbiased","trial","trials","research","marketing","hair wrap","baby pillow",
        "belly band","door stopper","organizer","headset holder","wash cloth","hairwrap",
        "babypillow","bellyband","doorstopper","headsetholder","washcloth"];
        this.state = {
            preloader: true,
            loading: false,
            id: this.props.match.params.id,
            bonusDeal: {},
            step: 1,
            // Need to conver the Step Flow into compare/application in the future
            // Step 1 = First Page
            // Step 2 = Share your expierence
            // Step 3 = If  user clicked on 'Review has been submitted'
            // Step 4 = If  user clicked on 'Review has not been submitted'
            // Step 5 = Thank you Page
            isCopied: 0,
            reviewButtonClicked:false,
            purchaseDate: '',
            hasReviewed: 2,
            reviewRating: 5,
            disableSubmit: false,
            reviewText: "",
            reviewTextError: "",
            reviewTextLength: {
                min: 50,
                max: ""
            },
            reviewLink: "",
            thankYouVariation: ""
        }
    }

    componentDidMount() {
        Body.pageClass("add", "page_review")
        this.fetchData(this.state.id);
        if (this.props.userProfile._id) {
            this.fetchLocalStorage(this.props.userProfile._id, this.state.id);
        }
        trackingLoader.trackPageview(window.location.pathname + "/" + this.getStepName(this.state.step), this.state.bonusDeal.product_name, this.state.id);
        

        // REMOVE THIS IN OCTOBER
        trackingLoader.trackEvent(this.getStepName(this.state.step), {
            event_category: "hj_review_product"
        }); 
        // REMOVE THIS IN OCTOBER

        // For HJ Tracking
        trackingLoader.trackEvent(`review-product-${this.getStepName(this.state.step)}`, {
            event_category: "hotjar",
            event_label: `${this.state.id}:${this.state.bonusDeal.product_name}`
        }); 
        
    }

    componentDidUpdate = (prevProps, prevState) => {
        // wait for profile to load before fetching from local storage
        if (this.props.userProfile !== prevProps.userProfile) {
            this.fetchLocalStorage(this.props.userProfile._id, this.state.id);
        }

        const stepChanged = this.state.step !== prevState.step ? true : false;
        const inputChanged = (this.state.reviewText === prevState.reviewText && this.state.reviewRating === prevState.reviewRating) ? false : true;
        if (stepChanged || (!stepChanged && this.state.step === 1 && !this.state.preloader && !inputChanged)) {
            const stepName = this.getStepName(this.state.step);
            trackingLoader.trackPageview(window.location.pathname + "/" + stepName, this.state.bonusDeal.product_name, this.state.id); 
            
            trackingLoader.trackEvent(`user-type`, {
                deal_id: this.state.id,
                deal_title: this.state.bonusDeal.product_name,
                event_category: "Review Flow",
                event_label: this.props.userProfile.riskLevel,
            });   
        }
    }

    fetchData = (bonusDealId) => {
        this.props.get("/api/v1/deals/bonus/" + bonusDealId, {}).then(response => {
            if (response){
                this.setState({
                    bonusDeal: response.result,
                    preloader: false,
                    step: response.result.hasOwnProperty("claimed_status") ? 5 : this.state.step
                });
            }            
            return Promise.resolve(response);
        }).then(response=>{
            this.props.get(`/api/v1/deal/${response.result.deal_id}/claim`, {}).then(res => {
                this.setState({
                    purchaseDate: res.result.created_at,
                });
            })
        }).catch(err =>{
            return Promise.reject(err);
        });
    }


    fetchLocalStorage = (userId, bonusId) => {
        const key = this.getCacheKey(userId, bonusId);
        let data = window.localStorage.getItem(key);
        if (data !== null) {
            data = JSON.parse(data);
            this.setState({
                step: data.hasOwnProperty("step") ? data.step : this.state.step,
                reviewText: data.hasOwnProperty("reviewText") ? data.reviewText : this.state.reviewText,
                hasReviewed: data.hasOwnProperty("hasReviewed") ? data.hasReviewed : this.state.hasReviewed,
                reviewRating: data.hasOwnProperty("reviewRating") ? data.reviewRating : this.state.reviewRating
            });
        }
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if (name === "reviewRating" || name === "hasReviewed") {
            value = parseInt(value);
        }
        this.setState({
            [name]: value
        })
    }

    getStepName = (step) => {
        switch(step) {
            case 1:
                return "product-review";
            case 2:
                return "review-share";
            case 3:
                return "review-submitted";
            case 4:
                return "review-not-submitted";
            case 5:
                return `product-review-completed${this.state.thankYouVariation !== "" ? `-${this.state.thankYouVariation}` : ""}`; //skipped, has-not-reviewed, reviewed
            default:
                return "unknown";
        }
    }

    handleJumpStep = (step) => {
        let payload = {
            bonus_id: this.state.bonusDeal._id,
            reviewRating: this.state.reviewRating,
            reviewText:this.state.reviewText,
            hasReviewed: this.state.hasReviewed,
            isCopied: this.state.isCopied,
            reviewButtonClicked: this.state.reviewButtonClicked
        };

        if (step === 5) {
            payload = null
        }

        trackingLoader.trackEvent("skipped", {
            deal_id: this.state.id,
            deal_title: this.state.bonusDeal.product_name,
            event_category: "review_product",
            event_label: `${this.state.id}:${this.state.bonusDeal.product_name}`
        });  

        this.nextStep(payload, step);
    }

    handleReviewSubmittedClicked = () => {
        this.setState({
            reviewButtonClicked: true,
        }, this.nextStep(null, 5))
    }

    customValidation = () => {
        let reviewTextError = "";

        // Check min character
        if(this.state.reviewText.length < this.state.reviewTextLength.min) {
            reviewTextError = "Please lengthen this text to " + this.state.reviewTextLength.min + " characters or more ( you are currently using " + this.state.reviewText.length + " characters)"  
        }

        // Validate
        if (reviewTextError) {
            this.setState({reviewTextError})
            return false
        }

        return true 
    }

    hasBlockedWords(){

        let reviewText = this.state.reviewText.toLocaleLowerCase()
        let words = []
     
        this.blockedWords.forEach(word => {
            let regex = new  RegExp(`\\b${word}\\b`, 'gi')
                if (regex.test(reviewText)) {
              
                    words.push(word)
                }
        });

        return words;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true})
        let payload = {
            bonus_id: this.state.bonusDeal._id,
            reviewRating: this.state.reviewRating,
            reviewText:this.state.reviewText,
            hasReviewed: this.state.hasReviewed,
            isCopied: this.state.isCopied,
            reviewButtonClicked: this.state.reviewButtonClicked
        };
        if (this.state.step === 1) {
            payload.reviewRating = this.state.reviewRating;
            payload.reviewText = this.state.reviewText;
            payload.step = 2;

            if (this.customValidation()) {
                if ( (this.state.reviewRating <= 3) || (this.hasBlockedWords()).length > 0 || this.props.userProfile.riskLevel === "DEMO") {
                    this.nextStep(payload, 5);

                    // Event Tracking
                    if((this.hasBlockedWords()).length > 0) {
                        trackingLoader.trackEvent("Force to last step - Blocked Words", {
                            deal_id: this.state.id,
                            deal_title: this.state.bonusDeal.product_name,
                            event_category: `review_product:${this.state.id}`,
                            event_label: this.hasBlockedWords()
                        }); 
                    } else if (this.state.reviewRating <= 3) {
                        trackingLoader.trackEvent("Force to last step - Less then 3 stars", {
                            deal_id: this.state.id,
                            deal_title: this.state.bonusDeal.product_name,
                            event_category: `review_product:${this.state.id}`,
                            event_label: this.state.reviewRating
                        }); 
                    } else if (this.props.userProfile.riskLevel === "DEMO") {
                        trackingLoader.trackEvent("Force to last step - User Type", {
                            deal_id: this.state.id,
                            deal_title: this.state.bonusDeal.product_name,
                            event_category: `review_product:${this.state.id}`,
                            event_label: this.props.userProfile.riskLevel
                        }); 
                    }
                    
                    // Change Thank you page variation
                    this.setState({
                        thankYouVariation: "skipped"
                    })
                } else {                        
                    this.nextStep(payload, 2);
                }
            }

        } else if (this.state.step === 2) {
             if (this.state.hasReviewed === 1) {
                    payload.step = 3;
                    this.nextStep(payload, 3);

                    // Tracking Event
                    trackingLoader.trackEvent("Clicked on review submitted" , {
                        deal_id: this.state.id,
                        deal_title: this.state.bonusDeal.product_name,
                        event_category: "review_product",
                        event_label: this.state.bonus_id
                    }); 

                    // Change Thank you page variation
                    this.setState({
                        thankYouVariation: "review-submitted"
                    })
                        
            } else {
                    payload.step = 4;
                    this.nextStep(payload, 4);
                    trackingLoader.trackEvent("Clicked on review not submitted", {
                        deal_id: this.state.id,
                        deal_title: this.state.bonusDeal.product_name,
                        event_category: "review_product",
                        event_label: `${this.state.id}:${this.state.bonusDeal.product_name}`
                    }); 
                    
                    // Change Thank you page variation
                    this.setState({
                        thankYouVariation: "review-not-submitted"
                    })
            } 
        } else if (this.state.step === 3) { 
                // Submitted Page
                payload.reviewLink = this.state.reviewLink;
                this.nextStep(payload, 5);
        } else if (this.state.step === 4) {
                this.nextStep(null, 5);
        }
    }

    nextStep = (data, step) => {
        
        try {
            const key = this.getCacheKey(this.props.userProfile._id, this.state.id);
            if (step === 5) {
                // disable here
                this.setState({disableSubmit: true});
                // assume step 5 is last step
                if (data === null || data === "null") {
                    // fetch from state
                    data = {
                        bonus_id: this.state.id,
                        hasReviewed: this.state.hasReviewed,
                        reviewRating: this.state.reviewRating,
                        reviewText: this.state.reviewText,
                        reviewLink: this.state.reviewLink,
                        isCopied: this.state.isCopied
                    }
                }
                
                let formData = new FormData();
                for (let k in data) {
                    formData.append(k, data[k]);
                }
                

                this.props.post("/api/v1/bonus/claim", formData).then(response => {
                    if (response.result) {
                        this.setState({
                           step: step,
                           loading: false,
                           claim_id : response.result
                        });
                        window.localStorage.removeItem(key);
                        
                        // refresh points
                        this.props.fetchProfile();   
                        trackingLoader.trackEvent("product-review-submission-successful", {
                            deal_id: this.state.id,
                            deal_title: this.state.bonusDeal.product_name,
                            event_category: "review_product",
                            event_label: `${this.state.id}:${this.state.bonusDeal.product_name}`,
                            value: this.state.bonusDeal.points,
                        });               
                    }
                }).catch(err => {
                    this.setState({disableSubmit:false});
                    if (err.status === 409) {
                        trackingLoader.trackEvent("409", {
                            deal_id: this.state.id,
                            deal_title: this.state.bonusDeal.product_name,
                            event_category: "review_product",
                            event_label: `${this.state.id}:${this.state.bonusDeal.product_name}`
                        });  

                    } else {
                        console.error(err);
                        //  For testing a new flow
                        trackingLoader.trackEvent("product-review-submission-failed", {
                            deal_id: this.state.id,
                            deal_title: this.state.bonusDeal.product_name,
                            event_category: "review_product",
                            event_label: `${this.state.id}:${this.state.bonusDeal.product_name}`,
                            fatal: false
                        }); 

                    }
                });
                

            } else {

                window.localStorage.setItem(`${key}_v1`, JSON.stringify(data));
                this.setState({
                    step: step,
                    loading: false
                });
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    setCopied = (isCopied) => {
        this.setState({
            isCopied: isCopied
        }, () => {
            const key = this.getCacheKey(this.props.userProfile._id, this.state.id);
            let data = window.localStorage.getItem(key);
            if (data !== null) {
                data = JSON.parse(data);
                data.isCopied = isCopied
                window.localStorage.setItem(key, JSON.stringify(data));
            }
        });
    }

    getCacheKey = (userId, bonusId) => {
        return userId + "_" + bonusId + "_v1";
    }

    render() {
      
        let renderStep = "";

        if (this.state.step === 2) {
            renderStep = <ReviewStepShare
                            loading = {this.state.loading}
                            userProfile={this.props.userProfile}
                            disableSubmit = {this.state.disableSubmit}
                            handleInputChange={this.handleInputChange}
                            setCopied={this.setCopied}
                            handleSubmit={this.handleSubmit}
                            bonusDeal={this.state.bonusDeal} 
                            hasReviewed={this.state.hasReviewed}
                            reviewRating={this.state.reviewRating}
                            handleJumpStep={this.handleJumpStep}
                            reviewText={this.state.reviewText} />

        } else if (this.state.step === 3) {
            renderStep = <ReviewStepShareSubmitted
                            loading = {this.state.loading}
                            disableSubmit = {this.state.disableSubmit}
                            userProfile={this.props.userProfile}
                            handleInputChange={this.handleInputChange}
                            handleJumpStep={this.handleJumpStep}
                            handleSubmit={this.handleSubmit}
                            bonusDeal={this.state.bonusDeal} 
                            hasReviewed={this.state.hasReviewed}
                            reviewLink={this.state.reviewLink} />

        }  else if (this.state.step === 4) {
            renderStep = <ReviewStepShareNotSubmitted
                            loading = {this.state.loading}
                            disableSubmit = {this.state.disableSubmit}
                            purchaseDate={this.state.purchaseDate}
                            userProfile={this.props.userProfile}
                            handleInputChange={this.handleInputChange}
                            handleJumpStep={this.handleJumpStep}
                            bonusDeal={this.state.bonusDeal} 
                            handleReviewSubmittedClicked={this.handleReviewSubmittedClicked}
                            reviewText={this.state.reviewText} />

        } else if (this.state.step === 5) {
            renderStep = <ReviewStepComplete
                            loading = {this.state.loading}
                            handleInputChange={this.handleInputChange} 
                            bonusDeal={this.state.bonusDeal} 
                            hasReviewed={this.state.hasReviewed}
                            reviewRating={this.state.reviewRating} />
        } else {
            renderStep = <ReviewStepStart
                            loading = {this.state.loading}
                            handleInputChange={this.handleInputChange}
                            handleSubmit={this.handleSubmit}
                            bonusDeal={this.state.bonusDeal} 
                            hasReviewed={this.state.hasReviewed}
                            reviewRating={this.state.reviewRating}
                            reviewText={this.state.reviewText}
                            reviewTextError={this.state.reviewTextError}
                            reviewLink={this.state.reviewLink} 
                            reviewTextLength={this.state.reviewTextLength}
                            userProfile={this.props.userProfile} />
        }

        return (
       
            <Container as="main" id="review-page" className={"questionnaire bonus-review review-" + this.state.step}>
                {this.state.preloader ? (
                    <PreLoader/>
                ) : (
                    renderStep
                )}
            </Container>
    
        )
    }
    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        fetchProfile: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired
            })
        })
    }
}