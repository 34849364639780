import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import AMAZON from '../../utils/amazon'
import VALIDATE_URL from '../../utils/validate-url'

export default class ModalAmzProfileRequest extends Component {
     constructor(props) {
		super(props);
		this.state = {
            modal: {
                show : this.props.show, 
                value: "",
                inValid:null,
                msg: ""
            },
            btnLoading: false,
            submitted: false
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.show !== prevProps.show ){
            this.setState({
                modal: {
                    ...this.state.modal,
                    show: this.props.show
                } 
            });
        }
    }

    handleAmzUrlSubmit = (e) => {
        
        e.preventDefault();       

        let formData = new FormData();
            formData.append("amazon_profile", this.state.modal.value);

            this.setState({ 
                btnLoading: true
            })

            this.props.put("/api/v1/profile", formData).then(response => {
               
                this.setState({ 
                    modal: {
                        ...this.state.modal,
                        show: false
                    }
                }, this.props.response(response))

            }).catch(err => {
                // remove this when we fix the no data issue
                this.setState({
                    modal:  {
                        ...this.state.modal,
                        inValid: true,
                        msg: err.statusText
                    },
                    btnLoading: false
                });
                    
                console.log("error", err)
            }); 
      
     
    }


    hideAmzUrlModal = () => {
        this.setState({
            modal: {
                ...this.state.modal, 
                show: false
            }
        }, this.props.response(false))
    }


    handleAmzUrlChange = (e) =>{
        let url = e.target.value.trim()

        this.setState({
            modal:  {
                ...this.state.modal,
                value: url,
                inValid: (VALIDATE_URL.amazon(this.props.userProfile.region, url)).inValid ,
                msg: (VALIDATE_URL.amazon(this.props.userProfile.region, url)).msg
            }
        });
    }

    render() {
        let disableButton = true
            
            if (this.state.btnLoading) {
                disableButton = true
            }else if(!this.state.modal.inValid && this.state.modal.value !== "") {
                disableButton = false
            } 

        let btnText = "Continue" 

            if (this.state.btnLoading) {
                btnText = "...submitting"
            } else {
                btnText = "Continue"
            }

        let videoFile = ""


        if (this.props.userProfile.region !== undefined ) {
            videoFile = `https://dealpanda-public.s3.ca-central-1.amazonaws.com/apply_profile-page_${this.props.userProfile.region.toLowerCase()}.mp4`
        } else {
            videoFile = `https://dealpanda-public.s3.ca-central-1.amazonaws.com/apply_profile-page_us.mp4`
        }

        let header = 
            <>
                <Modal.Title>Please complete your profile to continue.</Modal.Title>
                <p >To continue to test products on Deal Panda, you’ll need to connect to your Amazon profile. We use your public Amazon profile to match you to products and brands we think you’ll love testing and getting for free!</p>
            </>
        
        let body = 
            
           
            <Form.Row className="amz-profile-instruction">
                
                        <Form.Group >
                            <Form.Label>Amazon Profile URL</Form.Label>
                        
                            <Form.Control isInvalid={this.state.modal.inValid}  className="mb-0" 
                            size="lg" 
                            type="url" 
                            placeholder={"https://www."+AMAZON.getDomain(this.props.userProfile.region)+"/gp/profile/amzn1.account..."} 
                            // pattern={"https://www.amazon."+AMAZON.getDomain(this.props.userProfile.region)+"/gp/profile/amzn1.account.*"} 
                            name="amazonUrl" 
                            onChange={this.handleAmzUrlChange}
                            value={this.state.modal.value}
                            />
                            <Form.Control.Feedback type="invalid" className="mt-2">
                                {this.state.modal.msg}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Need help finding your Amazon Profile Page?</Form.Label>

                            <video width="100%" height="auto" controls >
                                    <source id="mp4" src={videoFile} type="video/mp4"></source>     
                                Your browser does not support the video tag.
                            </video>
                        </Form.Group>
                
                </Form.Row>
                        
               
                    
        let footer = 
            <>
                <Button variant="link" onClick={this.hideAmzUrlModal}>Cancel</Button>
                <Button className={"btn btn-dp "} disabled={disableButton} type="submit" >{btnText} </Button>
            </>

            return (
                <Modal show={this.state.modal.show} size="md" backdrop="static" className="modal_request-amz" centered>
                    <Form onSubmit={this.handleAmzUrlSubmit}>
                        <Modal.Header >
                        {header}
                        </Modal.Header>
                        <Modal.Body>
                            {body}
                        </Modal.Body>
                        <Modal.Footer>
                            {footer}
                        </Modal.Footer>
                    </Form>
                </Modal>
            );
    }

    static propTypes = {
        userProfile: PropTypes.object.isRequired,
        put: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        response: PropTypes.func.isRequired
    };
}