import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

class contentFAQ extends Component {
  render() {
    return (
        <div className="content">
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    Is it free to join Deal Panda’s panel of testers?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    Yes, absolutely! And you’ll earn rewards, free gifts and payouts for participating in test panels. 
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    Can anyone join Deal Panda’s testing panels?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    Joining Deal Panda’s panel of testers is by invite only.<br/>
                    Invited members apply to be testers by completing their tester profile in the sign-up process.<br/>
                    Once you complete your profile, we’ll review it to match you up with products and brands to test that suit your lifestyle.<br/>
                    If you haven’t matched for a test yet, don’t worry there will be more coming up!<br/>
                    Deal Panda’s testers can invite their family and friends <a href='/invite'>here</a>.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    Can I register more than one account?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body> 
                    No - Only one account is permitted per household address. Accounts found violating this policy will be banned without notice and will have their rewards cleared from their account.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="5">
                    Can I delete my account once I join?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                    <Card.Body> 
                    Yes. You can delete your account at any time by emailing <a href='/contact'>customer service</a> <br/> 
                    Important: Deleting your account cannot be reversed and you will no longer be eligible to participate in future test panels.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="6">
                    How can I contact customer support?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                    <Card.Body> 
                    You can contact our customer support team <a href='/contact'>here</a>.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="7">
                    What are the policies on inviting family and friends?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="7">
                    <Card.Body> 
                    Deal Panda testers can invite family and friends who do NOT live in the same household.<br/>
                    We do not allow multiple Deal Panda accounts under one household.<br/>
                    Accounts found violating this policy will be banned without notice and will have their rewards cleared from their account.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
  }
}

export default contentFAQ;
