import React, { Component } from "react";
import PropTypes from "prop-types";
import Flags from '../snippets/flags' 
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Body from '../../utils/utils-body'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import { matchPath } from 'react-router'
import PreLoader from '../snippets/PreLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSpinner , faEllipsisV} from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import dayjs from 'dayjs'
import adminRegions from '../../utils/admin-regions'


import userType from '../../i18n/en/user-type.json'
import regions from '../../i18n/en/regions.json'

import './admin-users-detail.scss';

export default class AdminUsersDetail extends Component {

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/users/detail/:id",
            exact: true,
            strict: false
        });
        

		this.state = {
            id: match ? match.params.id : null,
            user: {},
            userForm: {
                status:"",
                region: "",
                riskLevel: "",
                payPalAccount:"",
                notes: ""
            },
            isLoading: true,
            modalData: null,
            ipdata: {},
            ipLoading: false,
            isLoadingUserModal: false, 
            copiedQuickLink : false,
            showEditUserModal: false,
            modal: {
                show: false, 
                title:"",
                body: "",
                footer: "",
                size:"sm",
                class:""
            }
        }
    }

    showEditUserModal = () => {
        this.setState({
            showEditUserModal: true,
        });
    }

    hideModal = () => {
        this.setState({
            modal: {
                show : false, 
                body: "",
                header: "", 
                class: "",
                size: "",
                footer: ""
            }
        });
    }


    hideEditUserModal = () => {
        this.setState({
            showEditUserModal:false
        })
    }


    showModal = ( classes, size, header, body, footer) => {
        this.setState({
            modal: {
                show : true, 
                body: body,
                header: header, 
                class: classes,
                size: size,
                footer: footer
            }
        });
    }


    copyText = (value) => {
        var tempInput = document.createElement("input");
            tempInput.value = value;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);

        this.setState({
            copiedQuickLink: true
        }, () => {
            setTimeout(function(){
                this.setState({copiedQuickLink: false})
            }.bind(this), 1000);
        })
    }

    componentDidMount = () => {
        Body.pageClass("add", "page_admin page_user-detail")
        this.fetchUser(this.state.id).then(result => {
            this.setState({
                user: result,
                isLoading: false,
                userForm: {
                    status:result.status,
                    region: result.region,
                    riskLevel: result.riskLevel,
                    notes: result.notes,
                    payPalAccount: result.payPalAccount
                }
             

            });
        });
    }

    handleUserInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({ 
            userForm : {
                ...this.state.userForm, 
                [inputName] : inputValue
            }
        });
    }

    fetchUser = (userId) => {
        const params = {}
        return this.props.get("/api/v1/user/" + userId, params).then(response => {
            return Promise.resolve(response.result);
        }).catch(err  => {
            console.error(err);
            return Promise.reject([]);
        });
    }

    updateUser = (userId, formData) => {
        return this.props.put("/api/v1/user/" + userId, formData).then(response => {
            return Promise.resolve(response.result);
        });
    }

    handleUpdateUser = (type) => {
        this.setState({isLoadingUserModal: true});
        let formData = new FormData();
        let userStatus = this.state.userForm.status
        let userRiskLevel = this.state.userForm.riskLevel
        let userEmail = this.state.user.email


        if (type === "delete") {
            userStatus = "DELETED"
            userRiskLevel = "RISKY"
            userEmail = `${userStatus}_${this.state.user.email}`
        }
        
        formData.append("status", userStatus);
        formData.append("risk_level", userRiskLevel);
        formData.append("email", userEmail);
        formData.append("region", this.state.userForm.region);
        formData.append("notes", this.state.userForm.notes);
        formData.append("payPalAccount", this.state.userForm.payPalAccount)
        
        this.updateUser(this.state.id, formData).then(response => {
            let user = this.state.user;
            user.status = userStatus;
            user.riskLevel = userRiskLevel;
            user.email = userEmail;
            user.region = this.state.userForm.region;
            user.notes = this.state.userForm.notes;
            user.payPalAccount = this.state.userForm.payPalAccount;
            
            this.setState({
                user: user,
                isLoadingUserModal: false,
                showEditUserModal: response === true ? false : true
            });
            
            if (type === "delete") {
                this.hideModal()
            }
            
         
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoadingUserModal: false,
                showEditUserModal: true
            });
        });
    }

    checkips = (userId) => {
        const params = {
            user_id: userId
        }
        return this.props.get('/api/v1/user/ipcheck', params).then(response => {
            return response.result;
        });
    }

    handleCheckIPs = (e) => {
        e.preventDefault();
        this.setState({ipLoading: true});
        this.checkips(this.state.id).then(data => {
            this.setState({ipdata: data, ipLoading: false});
        });
    }



    showAcivityDetail = (activity) => {
        let deal = activity.deal
        let darkMode = this.props.darkMode ? "modal--dark" : null
        let className = "user_activity-modal " + darkMode
        let size = "md"
        let header = 
            <>
                <Modal.Title>{deal.deal_name}</Modal.Title>
                <div>{deal.created_at}</div>
            </>
        
        let body = 
            <ul>
                <li><strong>Deal Name</strong>{deal.deal_name}</li>
                <li><strong>Product Name</strong>{deal.product_name}</li>
                <li> <strong>Keyword</strong> {activity.deal_keyword} </li>
                <li><strong>Competitors</strong>{deal.competitors.length} </li>
            </ul>
     

        this.showModal(className, size, header, body, "")
    }

    renderOptions = (option) => {
        let output = []
        let list = []

            if (option === "user-type") {
                list = userType
            } else if (option === "regions") {
                list = regions
            }

            list.forEach(function (user, i){
                output.push(
                    <option key={i} value={user.key}>{user.value}</option>
                )
            })

            return output
    }
    
    showDeleteUser = () => {
        const saveBtnLabel = this.state.isLoadingUserModal ? <FontAwesomeIcon icon={faSpinner} fixedWidth spin /> : "Delete";
        let className = "user_delete-confirmation-modal "
        let size = "md"
        let header = 
            <Modal.Header >
                <Modal.Title>Are you sure you want to delte User ?</Modal.Title>
            </Modal.Header>
       let footer = 
            <Modal.Footer>
                <Button variant="danger" onClick={this.handleUpdateUser.bind(this, "delete")} disabled={this.state.isLoadingUserModal}>{saveBtnLabel}</Button>
                <Button variant="link" onClick={this.hideModal}>I change my mind</Button>
             </Modal.Footer>

     

        this.showModal(className, size, header, "", footer)
    }

  
    renderEditUserModal = () => {
        const notes = this.state.userForm.notes === undefined ? "" : this.state.userForm.notes;
        const saveBtnLabel = this.state.isLoadingUserModal ? <FontAwesomeIcon icon={faSpinner} fixedWidth spin /> : "Save";

        let header = 
            <Modal.Title>Edit User</Modal.Title>
        
        let body = 
            <Form onSubmit={this.handleUpdateUser.bind(this, "edit")}>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" name="status" value={this.state.userForm.status} onChange={this.handleUserInputChange}>
                                <option value="ACTIVE">Active</option>
                                <option value="LOCKED">Inactive</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Risk Level</Form.Label>
                            <Form.Control as="select" name="riskLevel" value={this.state.userForm.riskLevel} onChange={this.handleUserInputChange}>
                                {this.renderOptions("user-type")}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Region</Form.Label>
                            <Form.Control as="select" name="region" value={this.state.userForm.region} onChange={this.handleUserInputChange}>
                                {this.renderOptions("regions")}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>PayPal Email</Form.Label>
                            <Form.Control  name="payPalAccount" value={this.state.userForm.payPalAccount} onChange={this.handleUserInputChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" name="notes" value={notes} onChange={this.handleUserInputChange} />
                        </Form.Group>
                    </Form>
        
        let footer = 
            <>
                <Button variant="link" onClick={this.hideEditUserModal}>Close</Button>
                <Button variant="primary" className="btn-dp" onClick={this.handleUpdateUser.bind(this, "edit")} disabled={this.state.isLoadingUserModal}>{saveBtnLabel}</Button>
            </>

            return (
                <Modal show={this.state.showEditUserModal} onHide={this.hideEditUserModal} centered>
                    <Modal.Header closeButton>
                    {header}
                    </Modal.Header>
                    <Modal.Body>
                        {body}
                    </Modal.Body>
                    <Modal.Footer>
                        {footer}
                    </Modal.Footer>
                </Modal>
            );
    }
 

    renderUserActivitiesList = () => {  
            let lst = [];
            for (let activity of this.state.user.deals){
                const  detail = <span title="edit" onClick={this.showAcivityDetail.bind(this, activity)}><FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth /></span>

                lst.push(<tr key={activity.created_at} data-date={dayjs(activity.created_at).format("YYYY-MM-DD")}>
                    <td>{dayjs(activity.created_at).format("MMMM D, YYYY")} </td>
                    <td>{"Deals"}</td>
                    <td>{activity.deal.product_name} <span>{activity.order_number}</span></td>
                    <td>
                        {detail}
                    </td>
                </tr>) 
            }

            for (let activity of this.state.user.bonus) {
                let detail = <span title="edit">
                                <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                            </span>
                lst.push(<tr key={activity.created_at} data-date={dayjs(activity.created_at).format("YYYY-MM-DD")}>
                    <td>{dayjs(activity.created_at).format("MMMM D, YYYY")} </td>
                    <td>{activity.bonus.type}</td>
                    <td>{activity.bonus.name} [{activity.status}]</td>
                    <td>
                        {detail}
                    </td>
                </tr>) 
            }

            for (let activity of this.state.user.redeems) {
                let detail = <span title="edit">
                                <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                            </span>
                lst.push(<tr key={activity.created_at} data-date={dayjs(activity.created_at).format("YYYY-MM-DD")}>
                    <td>{dayjs(activity.created_at).format("MMMM D, YYYY")} </td>
                    <td>{activity.reward.type}</td>
                    <td>{activity.reward.name} {activity.points}pts [{activity.status}]</td>
                    <td>
                        {detail}
                    </td>
                </tr>) 
            }

            lst.sort(this.sortActivity);
            return lst;

    }

    sortActivity = (a, b) => {
        const dateA = a.props["data-date"];
        const dateB = b.props["data-date"];
        let result = 0;
        if (dateA > dateB) {
            result = -1;
        } else if (dateA < dateB) {
            result = 1;
        }
        return result;
    }

    renderIPdata = () => {
        let html = [];
        for (let ip in this.state.ipdata) {
            let users = [];
            this.state.ipdata[ip].users.forEach(u => {
                users.push(<div key={u.user_id}><Link to={"/admin/users/detail/" + u.user_id}>{u.email}</Link></div>);
            });

            html.push(
            <div key={ip} className="mb-3">
                <strong>{ip}</strong>
                <div>
                    {this.state.ipdata[ip].isp}, {this.state.ipdata[ip].org}<br />
                    {this.state.ipdata[ip].city}, {this.state.ipdata[ip].region}<br />
                    <div>&mdash;</div>
                    {users}
                </div>
            </div>
            );
        }
        return html
    }

    renderUserProfile() {
            let user = this.state.user

            let facebookID = "No Data"
                if(user.fb_id !== undefined && user.fb_id !== "") {
                    facebookID = user.fb_id
                }
            
            let amazonURL = ""
            let amazonProfile = user.amazon_profile;
                if (user.amazon_profile !== undefined && user.amazon_profile !== "") {
                    const profilePattern = /.*(amzn1\.account\.(\w+))/;
                    const profileMatches = profilePattern.exec(amazonProfile);
                    if (profileMatches !== null && profileMatches.length > 1) {
                        amazonProfile = profileMatches[2];
                        amazonURL = `http://www.${adminRegions.getDomain(user.region)}/gp/profile/amzn1.account.${profileMatches[2]}`
                    } else {
                        console.warn("regex failed", amazonProfile);
                    }
                }

         


            let userType = ""
                if (user.riskLevel === "TRIAL") {
                    userType = <Badge variant={"warning"}>Trial</Badge> 
                } else if (user.riskLevel === "VIVA_REVIEWERS") {
                    userType = <Badge variant={"primary"}>Viva Reviewers</Badge>
                } else if (user.riskLevel === "NOT_RISKY") {
                    userType = <Badge variant={"light"}>Regular</Badge>
                } else {
                    userType =  <Badge variant={"light"}>{user.riskLevel}</Badge>
                }
            
            // login via login page
            let lastLogged = ""
            const lastLogin = dayjs(user.last_login);
            const lastRefresh = dayjs(user.last_refresh);

                if (lastLogin.isBefore(lastRefresh)) {
                    lastLogged = lastRefresh.format("MMMM D, YYYY")
                } else {
                    lastLogged = lastLogin.format("MMMM D, YYYY")
                }

            let referer = "N/a"
                if(user.referer !== null) {
                    referer = user.referer !== undefined ? user.referer : "Original"
                }

            let profileImage = "https://i.imgur.com/pe5pRFc.png"

            if (user.fb_id !== undefined && user.fb_id !== "") {
                profileImage = `http://graph.facebook.com/${user.fb_id}/picture?type=large`
            }

            let role = ""

                if (user.role === "ADMIN") {
                    role = "( ADMIN )"
                }
             

                
            return (
                <div className="user-profile_wrapper">
                    <div className="user-profile_image"  style={{backgroundImage: "url(" + profileImage + ")"}} >
                            <div className="region">
                                <Flags region={this.state.user.region}/>
                        </div>
                    </div>
                    <div className="user-profile_detail">
                        <div className="data-profile_user-detail">
                            <div className="user-tags">
                                {userType}
                            </div>
                            <div className="user-name">
                                <OverlayTrigger
                                    placement={"right"}
                                    overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        {user._id}
                                    </Tooltip>
                                    }
                                >
                                    <div className="user_profile-item_label">
                                        <Button variant="link" onClick={this.copyText.bind(this, user.payPalAccount)} >
                                            {this.state.user.name !== "" ? this.state.user.name : "Unknown"} {role}
                                        </Button>
                                    </div>
                                </OverlayTrigger> 
                            </div>
                            <div className="user-email">
                                {this.state.user.email}
                            </div>
                        </div>

                        <ul className="user-profile_additional-info">
                           
                            {/* <li className="user_profile-item">
                                <div className="user_profile-item_label">Role</div>
                                {user.role}
                            </li> */}
                            <li className="user_profile-item">
                                <div className="user_profile-item_label">Joined On</div>
                                {dayjs(this.state.user.creationDate).format("MMMM D, YYYY")}
                            </li>
                            <li className="user_profile-item">
                                <div className="user_profile-item_label">Last Logged</div>
                                {lastLogged}
                            </li>
                            <li className="user_profile-item">
                                <div className="user_profile-item_label">Referred By</div>
                                {referer}
                            </li>
                            <li className="user_profile-item">
                                <div className="user_profile-item_label">Panda Points</div>
                                {this.state.user.points} (Pending : {this.state.user.points_pending})
                            </li>
                        </ul>

                        <ul className="user-profile_quick-links">
                    
                            <li className="user_profile-item">
                                <OverlayTrigger
                                        placement={"top"}
                                        overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            {user.payPalAccount}
                                        </Tooltip>
                                        }
                                    >
                                         <div className="user_profile-item_label">
                                            <Button variant="link" onClick={this.copyText.bind(this, user.payPalAccount)} ><i className="icon-paypal"></i></Button>
                                        </div>
                                    </OverlayTrigger> 
                            </li>
                            <li className="user_profile-item">
                                <OverlayTrigger
                                        placement={"top"}
                                        overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            {facebookID}
                                        </Tooltip>
                                        }
                                    >
                                         <div className="user_profile-item_label">
                                            <Button variant="link" onClick={this.copyText.bind(this, facebookID)} ><i className="icon-facebook"></i></Button>
                                       
                                        </div>
                                </OverlayTrigger> 
                          
                            </li>
                            <li className="user_profile-item amz">
                                <OverlayTrigger
                                        placement={"top"}
                                        overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            {amazonProfile}
                                        </Tooltip>
                                        }
                                    >
                                        <div className="user_profile-item_label">
                                            <Button variant="link" onClick={this.copyText.bind(this, amazonURL )} ><i className="icon-amz-logo"></i></Button>
                                        </div>
                                </OverlayTrigger> 
                            </li>
                            
                            {/* This is always last LI */}
                            <li>
                                <div className={`quick-link-copied --${this.state.copiedQuickLink}`}>Copied !</div>
                            </li>
                        </ul>
                    </div>
                </div>
            )
    }

    renderUserFacebookAccount() {
            let output = "No Data"
            let user = this.state.user

            let facebookID = "N/A"
                if(user.fb_id !== undefined && user.fb_id !== "") {
                    facebookID = user.fb_id
                }

            let birthday = "-";
                if (user.hasOwnProperty("birthday") && user.birthday !== null) {
                    let birthdate = dayjs(user.birthday).format("YYYY-MM-DD");
                    let age =  dayjs().format("YYYY") - dayjs(user.birthday).format("YYYY")
                    birthday = (<time dateTime={birthdate} title="dob">{birthdate} ({age}yr)</time>)
                }

            let location = ""
                if (user.address !== undefined) {
                    let address =  user.address
                    let suite = address.suite !== "undefined"  ? `${address.suite}-` : "";
                    let fullAddress = `${address.street_number} ${address.street}`;
                     
                        location = <span>{suite + fullAddress}<br/>{address.city + ", " + address.state + ", " + address.zip}</span>
                }

            if (user.fb_id !== undefined && user.fb_id !== "") {
                output = 
                    <ul className="user-profile_additional-info">
                        <li className="user_profile-item">
                            <div className="user_profile-item_label">ID</div>
                            {facebookID}
                        </li>
                        <li className="user_profile-item">
                            <div className="user_profile-item_label">Address</div>
                            {location}
                        </li>
                        <li className="user_profile-item">
                            <div className="user_profile-item_label">Birthday</div>
                            {birthday}
                        </li>
                    </ul>
            }
        


        return  <section className="user_facebook">{output}</section>
    }

    renderUserAmazonAccount() {
            let output = "No Data"
            let user = this.state.user
            
            let amazonURL = ""
            let amzProfileId = user.amazon_profile;
                if (user.amazon_profile !== undefined && user.amazon_profile !== "") {
                    const profilePattern = /.*(amzn1\.account\.(\w+))/;
                    const profileMatches = profilePattern.exec(amzProfileId);
                    if (profileMatches !== null && profileMatches.length > 1) {
                        amzProfileId = profileMatches[2];
                        amazonURL = `http://www.${adminRegions.getDomain(user.region)}/gp/profile/amzn1.account.${profileMatches[2]}`
                    } else {
                        console.warn("regex failed", amzProfileId);
                    }
                }
            
              
       

            let amzProfile = amzProfileId ? <a href={amazonURL} target="_blank" rel="noopener noreferrer">{amzProfileId}</a> : "---";

            if (amzProfileId) {
                output = 
                <ul className="user-profile_additional-info">
                    <li className="user_profile-item">
                        <div className="user_profile-item_label">ID</div>
                        {amzProfile}
                    </li>
                </ul>
            }


        return (
            <section className="user_facebook">{output}</section>
        )
    }

    renderUserActivities() {
        let output = "No Activities "

           
            if (this.state.user.deals.length > 0) {
                    output = 
                        <Table>
                            <thead>
                                <tr>
                                    <th className="activity_date">Date</th>
                                    <th className="activity_type">Type</th>
                                    <th className="activity_name">Name</th>
                                    <th className="activity_action"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderUserActivitiesList()}
                            </tbody>
                        </Table>
            }

        return  <section className="user_activity"> {output}</section>
           
    }

    renderUserLoginIps() {
        return (
                <section>
                    <div>
                        {this.state.user.login_ip ? this.state.user.login_ip.join(", "): ""}
                    </div>
                    <div>
                        {this.renderIPdata()}
                    </div>
                </section>
        )
    }

    renderUserSecurityListSoftBan = () => {
        return (
            <tr >
                <td>Soft ban</td>
                <td>Unknown</td>
                <td>-</td>
            </tr>
        )
    }
    renderUserSecurityList = () => {  
            let security = this.state.user.security
            if (security.length > 0) {
                console.log("We decided to change to ARRAY")
            } else {
                let lst = [];
                for (const [category, categoryValue] of Object.entries(security)) {
                    if (category !== "updated_at") {
                        for (const [type, typeValue] of Object.entries(categoryValue)) {
                            lst.push(
                                <tr key={type} data-date={dayjs(security.updated_at).format("YYYY-MM-DD")}>
                                    <td>{category}</td>
                                    <td>{type}</td>
                                    <td>{typeValue.message}</td>
                                </tr>
                            ) 
                        }
                    }
                    
                }
                return lst;
            
            }              
    }
    
    renderUserSecurity() {
        let list = ""

            if (this.state.user.security !== undefined ) {
                list =  this.renderUserSecurityList()
            } else {
                list = this.renderUserSecurityListSoftBan()
            }

        return (
            <section className="user_activity">
                <Table>
                    <thead>
                        <tr>
                            {/* <th className="activity_date">Date</th> */}
                            <th className="activity_category">Category</th>
                            <th className="activity_type">Type</th>
                            <th className="activity_name">Message</th>
                        </tr>
                    </thead>
                    <tbody>
                    {list}
                    </tbody>
                </Table>

            
            </section> 
        )
    }

    render = () => {
        let securitySection = ""

            if (this.state.user.riskLevel === "RISKY") {
                securitySection = 
                   <Row>
                        <div className="sub-header">
                            <h3>Security Breach Detected </h3>
                        </div>
                        {this.renderUserSecurity()}
                    </Row>
            }
        
        let userStatus = ""

            if (this.state.user.status === "LOCKED") {
                userStatus = 
                <Alert  variant={"warning"}>
                    {this.state.user.name} has been <strong>locked (Inactive)</strong>
                </Alert>
            } else if (this.state.user.status === "DELETED"){
                userStatus = 
                <Alert  variant={"danger"}>
                    {this.state.user.name} has been <strong>Deleted</strong>
                </Alert>
            }

        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <React.Fragment>
                        <Container>
                           
                        </Container>
                        <Container fluid={true} >
                            <Row as="section" className="user_profile">
                                <Container>
                                    {userStatus}
                                    {this.renderUserProfile()}
                                </Container>
                                <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                        <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Button variant="link" className="dropdown-item edit-user" onClick={this.showEditUserModal.bind(this, this.state.userForm)} >
                                            Edit User
                                        </Button>
                                        <Button variant="link" className="dropdown-item delete-user" onClick={this.showDeleteUser} >
                                            Delete User
                                        </Button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                        </Container>
                        <Container >
                            {securitySection}
                            <Row >
                                <div className="sub-header">
                                    <h3>User's Activity</h3>
                                </div>
                                {this.renderUserActivities()}
                            </Row>
                            <Row>
                                <div className="sub-header">
                                    <h3>User's Facebook Account</h3>
                                </div>
                                {this.renderUserFacebookAccount()}
                            </Row>

                            <Row>
                                <div className="sub-header">
                                    <h3>User's Amazon Account</h3>
                                </div>
                                {this.renderUserAmazonAccount()}
                            </Row>

                            <Row>
                                <div className="sub-header">
                                    <h3>Login Ips <Button variant="link" onClick={this.handleCheckIPs} disabled={this.state.ipLoading}>{this.state.ipLoading ? <FontAwesomeIcon icon={faSpinner} size="sm" fixedWidth spin /> : "check ips"}</Button></h3>
                                </div>
                                {this.renderUserLoginIps()}
                            </Row>

                           

                            

                        </Container>

                        {/* {modal} */}
                        {this.renderEditUserModal()}
                        {/* {this.renderDeleteUserConfirmation(this.state.showDeleteUser)} */}
                        
                        
                        <Modal size={this.state.modal.size} show={this.state.modal.show} onHide={this.hideModal}  backdrop={true} centered>
                            <div className={this.state.modal.class}>
                                {this.state.modal.header}
                                {this.state.modal.body}
                                {this.state.modal.footer}
                            </div>
                        </Modal>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        darkMode: PropTypes.bool.isRequired,
        get: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        location: PropTypes.object
    }
}