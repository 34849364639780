import React, { Component } from "react";
import PropTypes from "prop-types";
import Body from '../../utils/utils-body'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import ReferralHistory from "./profile/referral-history";
import PointsHistory from './profile/points-history'
import PointsStatus from './profile/points-status'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from 'react-bootstrap/ListGroup'
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import HowToVideo from '../snippets/how-to-video'
import dayjs from 'dayjs'
import AMAZON from '../../utils/amazon'
import Modal from 'react-bootstrap/Modal';
import ModalDefault from '../snippets/modal';



import './profile.scss';
import howtoImage from '../../assets/images/amz_profile_howto.gif'


export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:"",
            updating:false,
            activeSection : "section-personal-info",
            referralsPageSize: 10,
            pointsPageSize: 10,
            newFeature: null, 
            userProfile :"",
            modal: {
                className:"",
                show: false, 
                header: "",
                body: "",
                footer: ""
            }, 
            amazonProfile: {
                url : "",
                account: "",
                region: ""
            },
            amazonProfileLink: "",
            amazonProfileDisableButton: false,
            amazonProfileLinkValid: null,
            amazonProfileDelay: false,
            marketing_permission: {
                marketing_emails: '', 
                marketing_data: ''
            },
            edit: false,
            editProfile : {
                payPalAccount: ""
            },
            showEditProfileModal:false,
            marketing_button_enabled: false,
            requestData : false
        }
    }

    componentDidMount = () => {
        Body.pageClass("add", "page_profile");
        this.props.fetchProfile().then(response => {
            if (response.amazon_profile !== null) {
                this.profileAMZ(response.amazon_profile)
            } else {
                this.setState({newFeature: true});
            }
            
            // Marketing Permission with edits
            let marketing_emails = response.marketing_emails;
            let marketing_data = response.marketing_data;
            this.setState({
                marketing_permission : {
                    marketing_emails: marketing_emails,
                    marketing_data: marketing_data
                },
                // for editing purpose
                edit_marketing_permission: {
                    marketing_emails: marketing_emails,
                    marketing_data: marketing_data
                },
                userProfile:response,
                editProfile: response
            });

            if(this.props.location.state !== "" && this.state.newFeature) {
                this.jumpToSection(this.props.location.state)
            }
        }); 

        window.addEventListener('scroll', this.handleScroll);
    }

    newFeatureHighlight = (id) => {
        
        let element =  document.getElementById(id);
        if (element === null) {
            return false;
        }
        element.classList.add("new-feature");
    }

    jumpToSection = (id) => {
        
        let elementTarget = document.getElementById(id);
        if (elementTarget === null) {
            return false;
        }
        this.setState({activeSection: id});

        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = elementTarget.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    handleScroll = () => {

        document.querySelectorAll('section[id]').forEach(function (elm) {
            var elementID = elm.id
            var elementTarget = document.getElementById(elementID)
            var bounding = elementTarget.getBoundingClientRect();
                
                if (
                    bounding.top >= 0 &&
                    bounding.left >= 0  &&
                    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
                    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                ) {
                    this.setState({activeSection: elementID});
                }
        }.bind(this));
        
    };

    handleMarketingPermissionChange = (e) => {
        const inputName = e.target.name;
        const inputChecked = e.target.checked;
        let newValue = {...this.state.marketing_permission, [inputName] : inputChecked}

        this.setState({
                marketing_permission : newValue
        },this.enableSaveSettingButton(newValue, this.state.edit_marketing_permission , "marketing_button_enabled"));
    }

    enableSaveSettingButton = (newValue, clonedValue, button) => {
        let enable = ""
            if( JSON.stringify(newValue) !== JSON.stringify(clonedValue)) {
                enable = true
            } else {
                enable = false
            }

            this.setState({[`${button}`]:enable});
    }

    handleRequestData = () => {

        // ADD API POST HERE 
        this.props.post("/api/v1/user/datarelease").then(response => {
            console.log(response)
        }).catch(err => {
            console.log("error", err)
        }); 
        
        this.setState({requestData:true});

    }

    handleAmazonProfileLinkChange = (e) => {
        const inputName = e.target.name;
        
        if(inputName === "amazonProfileLink") {
            this.setState({
                amazonProfile: {
                    ...this.state.amazonProfile,
                    url: e.target.value
                }
            });            
        }
    }

    objectToString = (value) => {
        let object = {}

        for(var key in value){
            if (value.hasOwnProperty(key)){
                object[key] = (value[key]).toString()
            }
        }
        return object
    }

    handleMarketingPermissionSubmit = (e) =>{
        e.preventDefault();
        let formData = new FormData();
        formData.append("marketing_emails", this.state.marketing_permission.marketing_emails);
        formData.append("marketing_data", this.state.marketing_permission.marketing_data);
        this.setState({updating: true, marketing_button_enabled: false})
        this.props.put("/api/v1/profile", formData).then(response => {
            this.setState({
                updating : false,
                edit_marketing_permission: {
                    ...this.state.marketing_permission
                }
            })
        }).catch(err => {
            console.log("error", err)
        }); 
    }

    enableEditBtn = (data, cloned) => {
        let enable = ""

        if( JSON.stringify(data) !== JSON.stringify(cloned) ) {
            enable = true
        } else {
            enable = false
        }
        this.setState({
            edit : enable
        });
     
    }

    handleProfileChange = (e)=> {
        let inputName = e.target.name;
        let inputValue = e.target.value;


           
            this.setState({
                edit: true, 
                editProfile : {
                    ...this.state.editProfile,
                    [inputName]: inputValue
                }
            })
    }

    showEditProfileModal = () => {
        this.setState({
            showEditProfileModal: true,
        });
    }

    hideModal = () => {
        this.setState({
            showEditProfileModal:false
        })
    }

    renderEditProfileUpdate = () => {
        let saveBtnLabel = "Save"
        let disabledBtn = true

            if (this.state.edit ) {
                disabledBtn = false
            } else if (this.state.updating) {
                disabledBtn = true
                saveBtnLabel =  <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
            } 

        let header = 
            <Modal.Title>My Profile Settings</Modal.Title>
        
        let body = 
            
            <Form.Group className="controller">
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>PayPal Email</Form.Label>
                                <Form.Control name="payPalAccount" type="email" value={this.state.editProfile.payPalAccount} onChange={this.handleProfileChange} required/>
                            </Form.Group>     
                        </Col>
                    </Form.Row>
            </Form.Group>        
                    
        let footer = 
            <>
                <Button variant="link" onClick={this.hideModal}>Cancel</Button>
                <Button variant="primary" className="btn-dp" type="submit" disabled={disabledBtn} required>{saveBtnLabel}</Button>
            </>

            return (
                <Modal show={this.state.showEditProfileModal} hide={this.hideModal}centered>
                    <Form onSubmit={this.handleProfileUpdate}>
                        <Modal.Header >
                        {header}
                        </Modal.Header>
                        <Modal.Body>
                            {body}
                        </Modal.Body>
                        <Modal.Footer>
                            {footer}
                        </Modal.Footer>
                    </Form>
                </Modal>
            );
    }

    handleProfileUpdate = (e) => {
        e.preventDefault();
        this.setState({updating: true})
        
        let formData = new FormData();
        formData.append("payPalAccount", this.state.editProfile.payPalAccount);
        this.props.put("/api/v1/profile", formData).then(response => {
                this.setState({
                    edit: false,
                    updating : false,
                    userProfile: {
                        ...this.state.userProfile,
                        payPalAccount : this.state.editProfile.payPalAccount
                    }
                }, this.hideModal )
        }).catch(err => {
            console.log("error", err)
        }); 
    }

    handleDeleteAccount = (severity) => {
        if (severity === "permanent") {
            this.props.delete("/api/v1/user/" + this.state.userProfile._id).then(response => {
                console.log(response)
            });
            this.closeModal()
        } else {

            // Show Modal 

            let header = 
                <div>
                    Are you sure you want to permanently delete your account? 
                </div>
            
            let body = 
                <div>
                    Deleting your account cannot be reversed and you will no longer be eligible to participate in future test panels. Your account cannot be retrieved.
                </div>

            let footer = 
                <>
                    <Button variant="link" onClick={this.closeModal}>Return to My Profile</Button>
                    <Button variant="primary" className="btn-dp" onClick={this.handleDeleteAccount.bind(this,"permanent")} >Permanently Delete My Account</Button>
                </>

            this.setState({
                    modal: {
                        show: true, 
                        header: header,
                        body: body,
                        size: "md",
                        footer: footer
                    }
            });
        }
    
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
            formData.append("amazon_profile", this.state.amazonProfile.url);
            this.setState({ 
                amazonProfileDelay: true,
                amazonProfileDisableButton: true
            })
        this.props.put("/api/v1/profile", formData).then(response => {
            this.props.fetchProfile()
            this.profileAMZ(this.state.amazonProfile.url);
            this.setState({newFeature:null});
        }).catch(err => {
            this.setState({ 
                amazonProfileLinkValid: false,
                amazonProfileDelay: false,
                amazonProfileDisableButton: false
            })
            console.log("error", err)
        }); 
    }

    profileAMZ = (amz) => {
        const matches = amz.match(/amzn1\.account\.(\w+)(\/)?/);
        const amzProfileId = matches.length > 1 ? matches[1] : matches[0];

        let amazon_profile = {
            url : amz,
            account : amzProfileId,
            region : null
        }   

        this.setState({ 
                amazonProfile: amazon_profile,
        })
    }
    
    closeModal = () => {
        this.setState({
            modal: {
                show: false, 
                header: "",
                body: "",
                footer: ""
            }
        });
    }


    renderAmazonProfileLink = () => {
       let output = null;
       let amazonUrl = ""
            if (this.state.userProfile.region === 'CA') {
                amazonUrl = "www.amazon.ca"
            } else {
                amazonUrl = "www.amazon.com"
            }

        
            if(this.state.userProfile.amazon_profile === null) {
                let buttonText = "Submit";
                let buttonDisabled = false;
                let urlValidity = null;

                    // Button Text 
                    if (!this.state.amazonProfileDelay) {
                        buttonText = "Submit"
                    } else {
                         buttonText = <React.Fragment><FontAwesomeIcon icon={faSpinner} size="sm" spin  /> loading </React.Fragment>
                    }

                    // Disabled Button
                    if (this.state.amazonProfile.url === "" || this.state.amazonProfileDisableButton) {
                        buttonDisabled = true;
                    } else {
                        buttonDisabled = false;
                    }

                    //  URL Validity
                    if (this.state.amazonProfileLinkValid === false) {
                        urlValidity = 
                        <Form.Text className="text-danger">
                            Sorry the url provided is invalid, please try again.
                        </Form.Text>
                    }

                output = 
                <React.Fragment>
                                <p>Connect your Amazon profile and get 300 Panda Points. We use your shopping profile to connect you to products and brands we think you’ll love testing! </p>

                                <Form.Label>Connect your Amazon Profile Page<sup>*</sup></Form.Label>
                                <Form onSubmit={this.handleSubmit} className="quick-submission">
                                    <Form.Group as={"div"} controlId="amzProfileLink">
                                        <Form.Control type="text" name="amazonProfileLink" placeholder={"https://" + amazonUrl + "/gp/profile...."} onChange={this.handleAmazonProfileLinkChange} readOnly={this.state.amazonProfileLinkValid}/>
                                        <Button variant="success" disabled={buttonDisabled} type="submit">
                                            {buttonText}
                                        </Button>
                                    </Form.Group> 
                                </Form>
                                <div className="text-small mt-3"><em>Need help ? <HowToVideo show_icon={true} title="Finding Amazon Link" source={howtoImage} type="image" /></em></div>
                                {urlValidity}
                </React.Fragment>
            } else if (this.state.amazonProfile.url !==""){
                let urlValidity = null;
                if (this.state.amazonProfileLinkValid === true) {
                    urlValidity = 
                    <Form.Text className="text-success">
                        Thank you , we will update your profile
                    </Form.Text>
                }
                output = 
                <React.Fragment>
                                <ul>
                                    {/* <li><strong>Region:</strong> {this.state.amazonProfile.region} </li> */}
                                    <li><strong>Account:</strong> <a href={this.state.amazonProfile.url} target="_blank" rel="noopener noreferrer" > {this.state.amazonProfile.account} </a></li>
                                </ul> 
                                {urlValidity}
                </React.Fragment>
            } else {
                output = 
                <React.Fragment>
                                <ul>
                                    loading
                                </ul> 
                </React.Fragment>
            }


          return output
    }
  
    render() {
        let creationDate = dayjs(this.state.userProfile.creationDate);
        let badge = null;
        if(this.state.newFeature === true) {
            badge = <span className="new-badge">new</span>
        }

        let saveButtonText = "Save Settings"
            if (this.state.updating) {
                saveButtonText = "...Saving"
            }
        
        let inviteLink = null;
        if (["RISKY", "TRIAL", "VIVA_REVIEWERS"].indexOf(this.state.userProfile.riskLevel) === -1) {
            inviteLink = <Link className="redirect-link" to="/invite" >Refer more Friends &gt;</Link>;
        }

        let requestData = null
        let deleteAccount = null

            if(this.props.gdpr) {
                requestData = 
                 <div className="section_item" >
                    <h3>Requesting Data</h3>
                    <p>Click below to email yourself an audit of all personal informations currently on file in 30 days. </p>
                    <Button  size={"sm"} variant="success" disabled={this.state.requestData} onClick={this.handleRequestData.bind()} >
                        {this.state.requestData ? "Submitted" : "Request Data"}
                    </Button>
                </div>

                deleteAccount = 
                <div className="section_item" >
                    <h3>Delete My Account</h3>
                    <p className="mb-0">Deleting your account deletes tour personal identifiable data.</p>
                    <small className="text-small mb-3 d-block"><strong>Important:</strong> Deleting your account cannot be reversed and you will no longer be eligible to participate in future test panels.</small>
                    <Button className="delete-button" variant="link" size={"sm"} onClick={this.handleDeleteAccount.bind(this)} >
                         Permanently Delete my Account
                    </Button>
                </div>
            }

        let amazonProfile = "-"
            if(this.state.amazonProfile.url !== null && this.state.amazonProfile.url !== "") {
                amazonProfile = AMAZON.formatID(this.state.userProfile.region, this.state.amazonProfile.url)
            }

        let amazonProfileRequest = ""

            if(this.state.userProfile.riskLevel !== "DEMO" && this.state.userProfile.amazon_profile === null) {
                amazonProfileRequest = 
                    <section className="profile_detail__submit-amazon-profile">
                        <div className="section_item">
                            <h3>Amazon Profile Page {badge}</h3>
                            {this.renderAmazonProfileLink()}
                        </div>
                    </section>
            }

        return (  
            <main id="profile-page" className="main-content profile"> 
                <Container as="div">
                    <div className="profile_mobile-header">
                        <h1>Welcome back, <span>{this.state.userProfile.name}</span></h1>
                    
                    </div>
                    <Row>
                        <Col xs={12} md={3} className="profile_side-nav">
                            <div className="side-nav box-shadow">
                                <div className="side-nav_point-info">
                                    <div className="point-status">
                                        <p>Current Panda Points</p>

                                        <PointsStatus  points={this.state.userProfile.points ? this.state.userProfile.points : 0} 
                                                       pointsPending={this.state.userProfile.points_pending ? this.state.userProfile.points_pending : 0}></PointsStatus>
                                                       <Link className="point-status_redeem-points" to="/redeem">Redeeming Points?</Link>
                                    </div>
                                </div>
                                <ListGroup className="side-nav_links" variant="flush">
                                    <ListGroup.Item action onClick={() => this.jumpToSection("section-personal-info")} active={this.state.activeSection === "section-personal-info" ? true : false}>General</ListGroup.Item>
                                     {/* <ListGroup.Item action onClick={() => this.jumpToSection("section-amazon-info")} active={this.state.activeSection === "section-amazon-info" ? true : false}>Amazon Information</ListGroup.Item> */}
                                    <ListGroup.Item action onClick={() => this.jumpToSection("section-points-info")} active={this.state.activeSection === "section-points-info" ? true : false}>Points History</ListGroup.Item>
                                    <ListGroup.Item action onClick={() => this.jumpToSection("section-referral-info")} active={this.state.activeSection === "section-referral-info" ? true : false}>Referral History</ListGroup.Item>
                                    <ListGroup.Item action onClick={() => this.jumpToSection("section-account-setting")} active={this.state.activeSection === "section-account-setting" ? true : false}>Account Setting</ListGroup.Item>
                                </ListGroup>

                            </div>
                        </Col>
                        <Col className="profile_detail">

                            <section id="section-personal-info" className="profile_detail__personal-information" >
                                <h2>General </h2>
                                <div className="section_item">
                                    <h3>Personal Information</h3>
                                    <ul>
                                        {this.state.userProfile.role === "ADMIN" ? (
                                            <li className="admin-only"><div className="label">Role:</div> {this.state.userProfile.role} (Only visible to admins)</li>
                                        ) : null }
                                        <li><div className="label">Email:</div> {this.state.userProfile.email} </li>
                                        {this.state.userProfile.riskLevel !== "DEMO" ? (
                                            <>
                                            <li><div className="label">PayPal Email:</div> {this.state.userProfile.payPalAccount} </li>
                                            <li><div className="label">Amazon Profile:</div> {amazonProfile.id} </li>
                                            </>
                                        ) : null }
                                        
                                        <li><div className="label">Member Since:</div> {creationDate.format("MMMM D, YYYY")}</li>
                                    </ul>
                                    <Button className="mt-3" size={"sm"} variant="success" disabled={this.state.updating} onClick={this.showEditProfileModal.bind(this, this.state.editProfile)} >
                                        Edit Profile
                                    </Button>
                                </div>
                            </section>

                            {amazonProfileRequest}
                    

                            <section id="section-points-info" className="profile_detail__point-history">
                                <h2>Points History </h2>
                                <p>
                                    Below is a history of your Panda point activities. 
                                </p>
                                <PointsHistory get={this.props.get} pageSize={this.state.pointsPageSize} />
                                <Link className="redirect-link" to="/redeem" >Redeem Points &gt;</Link>
                            </section>

                            <section id="section-referral-info" className="profile_detail__referral-history">
                                <h2>Referral Information </h2>
                                <p>
                                    Below is a list of friends and family that you&rsquo;ve invited to use this awesome platform. 
                                </p>
                                <ReferralHistory get={this.props.get} pageSize={this.state.referralsPageSize} />
                                {inviteLink}
                            </section>

                            <section id="section-account-setting" className="profile_detail__account-setting">
                                <h2>Account Setting </h2>

                                <div className="section_item">
                                    <h3>Communication Preferences</h3>
                                    {/* <p>At Deal Panda we</p> */}
                                        <Form.Group controlId="marketing_emails">
                                            <Form.Check type="checkbox" name="marketing_emails" checked={this.state.marketing_permission.marketing_emails} onChange={this.handleMarketingPermissionChange} label="I agree to receive promotional email communications from Deal Panda." />
                                        </Form.Group>
                                        <Form.Group controlId="marketing_data">
                                            <Form.Check type="checkbox" name="marketing_data" checked={this.state.marketing_permission.marketing_data}  onChange={this.handleMarketingPermissionChange} label="I consent to allow my data to be used to personalize ads" />
                                        </Form.Group>
                                        <Button className="mt-3" size={"sm"} variant="success" disabled={!this.state.marketing_button_enabled} onClick={this.handleMarketingPermissionSubmit} >
                                            {saveButtonText}
                                        </Button>
                                </div>
                                

                                {requestData}
                                {deleteAccount}
                                
                                
                            </section>

                        </Col>
                    </Row>
                </Container>
                {this.renderEditProfileUpdate()}
                <ModalDefault modal={this.state.modal} closeModal={this.closeModal}/>

            </main>
    )}

    static propTypes = {
        gdpr:PropTypes.bool.isRequired,
        location:PropTypes.object.isRequired,
        userProfile: PropTypes.object.isRequired,
        get: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        fetchProfile: PropTypes.func.isRequired,
        referralPageSize: PropTypes.number
    }
}
