import React, { Component } from 'react';
import trackingLoader from '../../utils/tracking-loader';

class contentPrivacy extends Component {
  render() {
    trackingLoader.trackPageview(window.location.pathname + "/privacy", "privacy-policy");

    return (
        <div className="content">
            <h2>Welcome to Deal Panda (the “Site”).</h2>
            <p className="lead">We understand that privacy online is important to users of our Site, especially when conducting business. This statement governs our privacy policies with respect to those users of the Site (“Visitors”) who visit without transacting business and Visitors who register to transact business on the Site and make use of the various services offered by Deal Panda (collectively, “Services”) (“Authorized Customers”). This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>
            <hr/>
            <h6>Personally Identifiable Information</h6>

            <p>refers to any information that identifies or can be used to identify, contact, or locate the person to whom such information pertains, including, but not limited to, name, address, phone number, fax number, email address, financial profiles, social security number, and credit card information. Personally Identifiable Information does not include information that is collected anonymously (that is, without identification of the individual user) or demographic information not connected to an identified individual.</p>

            <h6>What Personally Identifiable Information is collected?</h6>

            <p>We may collect basic user profile information from all of our Visitors. We collect the following additional information from our Authorized Customers: the names, addresses, phone numbers and email addresses of Authorized Customers, the nature and size of the business, and the nature and size of the advertising inventory that the Authorized Customer intends to purchase or sell.</p>

            <ul>
              <li>Personal identification information (Name, birthday, email address, address, phone number, facebook public profile etc.)</li>
              <li>Consumer demographic information (occupation, income, etc..)</li>
              <li>Shopping behaviour data(online stores, categories shopped, purchasing behaviour, and other information collected through surveys)</li>
              <li>Business information (the nature and size of the business, the nature and size of the advertising inventory that the Authorized Customer intends to purchase or sell, etc.)</li>
            </ul>

            <p>By visiting this site you consent to the use of cookies which are used to facilitate behavioral advertising after you have left this site. You also understand that I can opt out of this anytime by visiting our contact page and emailing us at <a rel="noopener noreferrer" href="mailto:support@dealpanda.com">support@dealpanda.com</a> </p>

            <h6>What organizations are collecting the information and how are they collecting it??</h6>

            <p>You voluntarily and directly provide us with most of the data we collect through sign-up, completing your profile, and participating in test surveys. In addition to our direct collection of information, our third party service vendors (such as credit card companies, clearinghouses and banks) who may provide such services as credit, insurance, and escrow services may collect this information from our Visitors and Authorized Customers. We do not control how these third parties use such information, but we do ask them to disclose how they use personal information provided to them from Visitors and Authorized Customers. Some of these third parties may be intermediaries that act solely as links in the distribution chain, and do not store, retain, or use the information given to them. Using and viewing our website via your browser’s cookies is another way we collect and process your data.</p>

            <h6>How does the Site use Personally Identifiable Information?</h6>

            <p>We use Personally Identifiable Information to customize the Site, to make appropriate service offerings, and to fulfill buying and selling requests on the Site. We may email Visitors and Authorized Customers about research or purchase and selling opportunities on the Site or information related to the subject matter of the Site. We may also use Personally Identifiable Information to contact Visitors and Authorized Customers in response to specific inquiries, or to provide requested information.</p>

            <h6>With whom may the information may be shared?</h6>

            <p>Personally Identifiable Information about Authorized Customers may be shared with other Authorized Customers who wish to evaluate potential transactions with other Authorized Customers. We may share aggregated information about our Visitors, including the demographics of our Visitors and Authorized Customers, with our affiliated agencies and third party vendors. We also offer the opportunity to “opt out” of receiving information or being contacted by us or by any agency acting on our behalf.</p>

            <h6>How is Personally Identifiable Information stored?</h6>

            <p>Personally Identifiable Information collected by Deal Panda is securely stored in a encrypted database and is not accessible to third parties or employees of Deal Panda except for use as indicated above.</p>
            <p>The Site keeps your personal identifiable information until you delete your account.</p>

          
            <h6>What choices are available to Visitors regarding collection, use and distribution of the information?</h6>

            <p>Visitors and Authorized Customers may opt out of receiving unsolicited information from or being contacted by us and/or our vendors and affiliated agencies by responding to emails as instructed, or by contacting us at <a rel="noopener noreferrer" href="mailto:support@dealpanda.com" target="_blank">support@dealpanda.com</a></p>

            <h6>How does Deal Panda use login information?</h6>
            <p>Deal Panda uses login information, including, but not limited to, IP addresses, ISPs, and browser types, to analyze trends, administer the Site, track a user’s movement and use, and gather broad demographic information.</p>

            <h6>What are cookies?</h6>
            <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit allaboutcookies.org.</p>

            <h6>Are Cookies Used on the Site?</h6>
            <p>Cookies are used for a variety of reasons. We use Cookies to obtain information about the preferences of our Visitors and the services they select. We also use Cookies for security purposes to protect our Authorized Customers. For example, if an Authorized Customer is logged on and the site is unused for more than 10 minutes, we will automatically log the Authorized Customer off. We also use cookies to keep you signed in.</p>

            <h6>What types of cookies do we use?</h6>
            <p>There are a number of different types of cookies, however, our website uses:</p>
            <ul>
              <li>Functionality or First-party cookies - The Site uses these cookies so that we recognize you on our
website and remember your previously selected preferences. These could include what
language you prefer and location you are in. A mix of first-party and third-party cookies are used.</li>
              <li>Advertising or Third party cookies - The Site uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</li>
            </ul>

            <h6>How to manage cookies:</h6>
            <p>You can set your browser not to accept cookies, and allaboutcookies.org tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>

            <h6>What partners or service providers have access to Personally Identifiable Information from Visitors and/or Authorized Customers on the Site?</h6>

            <p>Deal Panda has entered into and will continue to enter into partnerships and other affiliations with a number of vendors. Such vendors may have access to certain Personally Identifiable Information on a need to know basis for evaluating Authorized Customers for service eligibility. Our privacy policy does not cover their collection or use of this information. Disclosure of Personally Identifiable Information to comply with law. We will disclose Personally Identifiable Information in order to comply with a court order or subpoena or a request from a law enforcement agency to release information. We will also disclose Personally Identifiable Information when reasonably necessary to protect the safety of our Visitors and Authorized Customers.</p>

            <h6>How does the Site keep Personally Identifiable Information secure?</h6>
            
            <p>All of our employees are familiar with our security policy and practices. The Personally Identifiable Information of our Visitors and Authorized Customers is only accessible to a limited number of qualified employees who are given a password in order to gain access to the information. We audit our security systems and processes on a regular basis. Sensitive information, such as credit card numbers or social security numbers, is protected by encryption protocols, in place to protect information sent over the Internet. While we take commercially reasonable measures to maintain a secure site, electronic communications and databases are subject to errors, tampering and break-ins, and we cannot guarantee or warrant that such events will not take place and we will not be liable to Visitors or Authorized Customers for any such occurrences.</p>

            <h6>How can Visitors correct any inaccuracies in Personally Identifiable Information?</h6>

            <p>Visitors and Authorized Customers may contact us to update Personally Identifiable Information about them or to correct any inaccuracies by emailing us at <a rel="noopener noreferrer" href="mailto:support@dealpanda.com" target="_blank">support@dealpanda.com</a></p>

            <h6>Can a Visitor delete or deactivate Personally Identifiable Information collected by the Site?</h6>

            <p>We provide Visitors and Authorized Customers with a mechanism to delete/deactivate Personally Identifiable Information from the Site’s database by contacting support@dealpanda.com. However, because of backups and records of deletions, it may be impossible to delete a Visitor’s entry without retaining some residual information. An individual who requests to have Personally Identifiable Information deactivated will have this information functionally deleted, and we will not sell, transfer, or use Personally Identifiable Information relating to that individual in any way moving forward.</p>

            <h6>What are your data protection rights?</h6>
            <p>The Site would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <ul>
              <li>The right to access - You have the right to request The Site for copies of your personal data. We may charge you a small fee for this service.</li>
              <li>The right to rectification - You have the right to request that The Site correct anyinformation you believe is inaccurate. You also have the right to request The Site to complete information you believe is incomplete.</li>
              <li>The right to erasure — You have the right to request that The Site erase your personal data, under certain conditions.</li>
              <li>The right to restrict processing - You have the right to request that The Site restrict the processing of your personal data, under certain conditions.</li>
              <li>The right to object to processing - You have the right to object to The Site’s processing of your personal data, under certain conditions.</li>
              <li>The right to data portability - You have the right to request that The Site transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
              <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: support@dealpanda.com</li>
            </ul>

            <h6>What happens if the Privacy Policy Changes?</h6>

            <p>Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 15 July 2020. We will let our Visitors and Authorized Customers know about changes to our privacy policy by posting such changes on the Site. However, if we are changing our privacy policy in a manner that might cause disclosure of Personally Identifiable Information that a Visitor or Authorized Customer has previously requested not be disclosed, we will contact such Visitor or Authorized Customer to allow such Visitor or Authorized Customer to prevent such disclosure.</p>

            <h6>Links:</h6>

            <p>This web site contains links to other web sites. Please note that when you click on one of these links, you are moving to another web site. We encourage you to read the privacy statements of these linked sites as their privacy policies may differ from ours.</p>
            
            <h6>California Online Privacy Protection Act</h6>

            <p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
According to CalOPPA we agree to the following:</p>

            <ul>
              <li>Visitors and Authorized Visitors can visit our site anonymously</li>
              <li>Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website.</li>
              <li>Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page specified above.</li>
            </ul>
            <p>Visitors and Authorized Visitors will be notified of any privacy policy changes:</p>
            <ul>
              <li>On our Privacy Policy Page</li>
            </ul>
            <p>Visitors and Authorized Visitors are able to change their personal information:</p>
            <ul>
              <li>By emailing us</li>
              <li>By logging in to their account</li>
              <li>By chatting with us or sending us a ticket</li>
            </ul>

            <h6>COPPA (Children Online Privacy Protection Act)</h6>
            <p>When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
We do not specifically market to children under 13.</p>

            <h6>Fair Information Practices</h6>
            <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
We will notify the Visitors and Authorized Visitors via email
</p>
            <ul>
              <li>Within 7 business days</li>
            </ul>
            <p>We will notify the Visitors and Authorized Visitors via in site notification</p>
            <ul>
              <li>Within 7 business days</li>
            </ul>
            <p>We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data Visitors and Authorized Visitors, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.</p>
            <h6>CAN SPAM Act</h6>
            <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
We collect your email address in order to:
To be in accordance with CANSPAM we agree to the following: support@dealpanda.com
If at any time you would like to unsubscribe from receiving future emails, you can email us at and we will promptly remove you from ALL correspondence.
</p>

            <h6>Marketing:</h6>
            <p>We would like to send you information about products and services of ours that we think you might like. If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please click <a href="/profile">here</a>.</p>

            <h6>How to contact us</h6>
            <p>If you have any questions about The Site’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us at support@dealpanda.com.</p>
        </div>
    );
  }
}

export default contentPrivacy;
