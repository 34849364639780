import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import Container from "react-bootstrap/Container";
import '../layout/Footer.scss';

class Footer extends Component {
  
    render() {
        return (
        <></>
        //     <footer className="footer text-center">
        //         <Container>
        //             <div className="footer_link">
        //                 <Link className="nav-item" to={{pathname:"/faq"}}>FAQ</Link>
        //                 <Link className="nav-item" to={{pathname:"/contact"}} href="/contact">Contact Us</Link>
        //                 <Link className="nav-item" to={{pathname:"/tnc"}} href="/tnc">Terms &amp; Conditions</Link>
        //                 <Link className="nav-item" to={{pathname:"/privacy"}} href="/privacy">Privacy Policy</Link>
        //             </div>
        //             <p className="footer_copyright">&copy; 2020 Deal Panda. All rights reserved</p>
        //         </Container>
        //   </footer>
        );
    }
}
    
export default Footer;