const URLS = {
    US: {
        name: "United States",
        domain: "amazon.com"
    },
    CA: {
        name: "Canada",
        domain: "amazon.ca"
    },
    GB: {
        name: "United Kingdom",
        domain: "amazon.co.uk"
    },
    DE: {
        name: "Germany",
        domain: "amazon.de"
    }
}

export default {

    validateOrderNumber(orderID) {
            if (orderID === 'terry' || orderID === '416-7569536-7569536'){
                return true;
            }
            if (orderID === undefined || !orderID){
                return false;
            }
            
            const amazon_order_number_re = /^\d{3}-\d{7}-\d{7}$/g;
            const ret = orderID.search(amazon_order_number_re) >= 0;
           
        return ret
    },

    getDomain(region) {
        let domain = ""
        
        if (region === undefined || region === "undefined") {
            domain = URLS["US"]["domain"]
        } else {
            domain = URLS[region.toUpperCase()]["domain"]
        }

        return domain;
    },

    formatID(region, url) {
            let amazonURL = ""
            let amazonProfileID = url;
            if (amazonProfileID) {
                const profilePattern = /.*(amzn1\.account\.(\w+))/;
                const profileMatches = profilePattern.exec(amazonProfileID);
                if (profileMatches !== null && profileMatches.length > 1) {
                    amazonProfileID = profileMatches[2];
                    amazonURL = `http://www.${this.getDomain(region)}/gp/profile/amzn1.account.${profileMatches[2]}`
                } else {
                    console.warn("regex failed", amazonProfileID);
                }
            }

            return { url :amazonURL , id: amazonProfileID}
    }
}