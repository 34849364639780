import React, { Component } from 'react';
import {  Link} from "react-router-dom";
import PropTypes from 'prop-types'
import Body from '../../utils/utils-body'
import Logo from '../../assets/images/logo_green.png'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FooterTNC from '../snippets/ContentTNC';
import FooterPrivacy from '../snippets/ContentPrivacy';
import FooterFaq from '../snippets/ContentFAQ';
import fbLogin from '../../utils/fb-login'
import trackingLoader from '../../utils/tracking-loader';
import './Login.scss';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            modal: {
                show: false, 
                title:"",
                success: null,
                alert: null
            },
            login: {
                validated : true,
                message: "",
                email: "",
                name:"",
                loading: false,
                socialMedia: "",
                label: 'Login'
            },
            forgotPassword: {
                success : null
            },
            contactUs: {
                isLoading: false,
                name: "",
                email: "",
                address:"",
                subject: "",
                message: "",
                feedback:""
            },
            isFormLoading: false,
            searchParams: new URLSearchParams(this.props.location.search)
        };
    }

    componentDidMount = () => {
        Body.pageClass("add", `page_login-in`)

        if (this.state.searchParams.get("token")) {
            this.modalShow("Password Change");
        }

        if(this.props.location.search !== ""){
            const param = new URLSearchParams(this.props.location.search);
            const email = param.get('email');
            if (email != null) {
                 this.setState({ 
                    login: {
                        ...this.state.login,
                        email: email
                    }
                });
            }
        }

        fbLogin.init(process.env.REACT_APP_FB_APPID, "7.0", true, true, "en_US", this.handleFBLogin, "email,name,first_name,last_name,picture");
    }

    modalClose  = () => {
        this.setState({ 
            modal: {
                ...this.state.modal,
                show: false
            },
           login: {
               ...this.state.login,
                validated : true
            },
            contactUs: {
                sLoading: false,
                name: "",
                email: "",
                address:"",
                subject: "",
                message: "",
                feedback:""
            },
            isFormLoading: false
        });
    }
    
    modalShow = (title) => {
        this.setState({ 
            login: {
                ...this.state.login,
                validated: true
            },
            modal : {
                show: true,
                title : title
            }
        });

        if (title === "contact-us_cant-log-in") {
            this.setState({ 
                contactUs: {
                    ...this.state.contactUs,
                       subject: "Unable to log in to my account",
                        message: "I am unable to log into my account. Please confirm my log in method and credentials."
                }
            });
        }

    }

    handleLoginSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;

        /*
            Tracking User login options
        */  
        trackingLoader.trackEvent(`Login Button`, {
            event_category: "Login",
            event_label: "Email",
        });   

        if (form.checkValidity() === false) {
            event.stopPropagation();
            return false;
        }
        this.setState({ 
            login: { 
                validated: this.state.login.validated,
                message: this.state.login.message,
                error: this.state.login.error,
                loading: true,
                label: 'Logging in...'
            }
        });

        let formData = new FormData(form);
        this.props.authenticate(formData).then(() => {
            trackingLoader.trackEvent("login", {
                method: "email"
            });
            this.props.history.push({
                pathname: "/tests"
            });
        }).catch(err => {
            this.setState({ 
                login: { 
                    validated: false,
                    message: "Login failed",
                    error: err,
                    socialMedia:"",
                    loading: false,
                    label: 'Login'
                }
            });
        });
    }

    handleContactUsSubmit(event) {
        event.preventDefault();
   
        this.setState({ 
            contactUs: {
                ...this.state.contactUs,
                isLoading: true,
                feedback:""
            }
        });

       this.props.post("/api/v1/contact/public", new FormData(event.target)).then(response => {           
            this.setState({
                contactUs: {
                    isLoading: false,
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                    address: "",
                    feedback: "success"
                }
            });

            trackingLoader.trackEvent("contact", {
                event_category: "engagement",
                event_label: "contact_us"
            });

       }).catch(err => {
           this.setState({
                form: {
                    ...this.state.feedback,
                    isLoading: false,
                    feedback: "error"
                }
            });
       });
    }
    

    handleForgotPaswordSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData(event.target);
        this.setState({isFormLoading: true});
        this.props.post("/api/v1/auth/forgotpassword", formData).then(response => {
            this.setState({
                modal: {
                    ...this.state.modal,
                    success: response ? true : false
                }
            });
        });
    }

    handleSetPasswordSubmit = (e) => {
        e.preventDefault();
        this.setState({isFormLoading: true});
        let formData = new FormData(e.target);
        formData.append("token", this.state.searchParams.get("token"));
        formData.append("email", this.state.searchParams.get("email"));
        this.props.setPassword(formData).then(response => {
            if (response.result) {
                window.location = "/tests";
            } else {
                this.setState({isFormLoading: false});
            }
        }).catch((err, response) => {

            err.json().then( errorMessage => {
           
                if (errorMessage.error.includes("Signature age")) {
                    let modal = this.state.modal;
                    modal.alert = <Alert key="set-password-alert" variant="danger">
                        Your password reset link has expired.
                    </Alert>
                    this.setState({modal: modal});
                } else if (err.status === 424) {
                    let modal = this.state.modal;
                    modal.alert = <Alert key="set-password-alert" variant="danger">
                        This forgot password link is no longer valid, please request a new 
                        <Alert.Link onClick={() => {this.modalShow('Forget Password')}}>forgot password link</Alert.Link>.
                    </Alert>
                    this.setState({modal: modal});
                }  else {
                    let modal = this.state.modal;
                    modal.alert = <Alert key="set-password-alert" variant="danger">
                        An error occured, please try again.
                    </Alert>
                    this.setState({modal: modal});
                }
                
                
            })
            
        });
    }

    handleFBLogin = (fbResponse, accessToken) => {
        /*
            Tracking User login options
        */  
        trackingLoader.trackEvent(`Login Button`, {
            event_category: "Login",
            event_label: "Facebook",
        });   

        let formData = new FormData();
        formData.append("email", fbResponse.email);
        formData.append("password", accessToken);
        formData.append("fbid", fbResponse.id);
        this.props.authenticate(formData).then(() => {
            trackingLoader.trackEvent("login", {
                method: "facebook"
            });
            this.props.history.push({
                pathname: "/tests"
            });
        }).catch(err => {
            /*
                Tracking User login options
            */  
            trackingLoader.trackEvent(`Facebook Login`, {
                event_category: "Login",
                event_label: `Error: ${err.toString()}`,
            });   

            let errorMsg = 
                <>
                <strong>Login failed</strong>- Please make sure you are using the Facebook account you registered with. If you are unable to log in with Facebook, please try the email login option.
                <span className="contact-us_msg">If you can't resolve your login issue, please click <Button variant="link" onClick={this.modalShow.bind(this, 'contact-us_cant-log-in')} >here</Button>  to request help from our Customer Care team.
                </span>
                </>



            this.setState({ 
                login: { 
                    validated: false,
                    message: errorMsg,
                    socialMedia: "facebook",
                    error: err,
                    loading: false,
                    label: 'Login'
                }
            });
        });
    }
    
    renderForgotPassword () {
        let bodyContent  =  
            <>
                <Form
                    className="forgot-password_form"
                    onSubmit={this.handleForgotPaswordSubmit}
                >
                    <Form.Group as="div"  controlId="validationCustom01">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                        required
                        type="email"
                        name="email"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    {this.state.modal.success ? (
                        <Alert variant="success">
                            Success, please check your inbox for further instructions.
                        </Alert>
                    ) : null }
                    <Button className="btn btn-lg btn-dp mt-5 btn-rounded" type="submit" disabled={this.state.isFormLoading}> {this.state.modal.success ? "Success" : "Submit"}</Button>
                    <Button variant="link" className="sign-in_forgot"  onClick={this.modalClose} block>Close</Button>
                </Form>
            </>

        return (
            <React.Fragment>
               
                <Modal.Header closeButton>
                    <Modal.Title >
                        Password Reset
                    </Modal.Title>
                    <p className="lead">Enter your Deal Panda email that you used to register. We’ll  send you an email with your username and a link to reset your password.</p>
                </Modal.Header>
                <Modal.Body>
                    {bodyContent}
                </Modal.Body>
            </React.Fragment>
        )
    }

    renderSetPassword () {
        return (
            <Modal.Body>
                {this.state.modal.alert}
                <Form
                    size="lg"
                    className="forgot-password_form"
                    onSubmit={this.handleSetPasswordSubmit}
                >
                    <div className="forgot-password_copy mt-3 mb-3">
                        <h2>Password change</h2>
                    </div>
                    <Form.Group as="div"  controlId="set-password">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="password" />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as="div"  controlId="set-password-confirm">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="password_confirm" />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Button className="btn btn-lg btn-dp mt-4" type="submit" disabled={this.state.isFormLoading}>Submit</Button>
                    <Button variant="link" className="text-center" onClick={this.modalClose} block>Close</Button>
                    
                </Form>
            </Modal.Body>
        )
    }

    renderFooterLinks () {
        let bodyContent  =  ""

        if (this.state.modal.title === "FAQ") {
            bodyContent = <FooterFaq/>
        } else if (this.state.modal.title === "Terms and Conditions") {
            bodyContent = <FooterTNC/>
        } else {
            bodyContent = <FooterPrivacy/>
        }

        return (
            <React.Fragment>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.modal.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {bodyContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-dp" onClick={this.modalClose}>Close</Button>
                </Modal.Footer>
            </React.Fragment>
        )
    }

    renderSignInEmail () {


        let emailErrorAlert = ""
            if (!this.state.login.validated && !this.state.login.loading && (this.state.login.socialMedia === "")) {
                emailErrorAlert = 
                <Alert variant="danger" className="mt-4">
                    <strong>Login failed</strong> - Please ensure you are using the correct email address and password. If you are unable to log in with your e-mail, please try the Facebook login option. <Button variant="link" onClick={this.modalShow.bind(this, 'Forget Password')} >Forgot your Password?</Button>.
                    <span className="contact-us_msg">If you can't resolve your login issue, please click <Button variant="link" onClick={this.modalShow.bind(this, 'contact-us_cant-log-in')} >here</Button>  to request help from our Customer Care team.
                    </span>
                
                </Alert>
            } else {
                emailErrorAlert = ""
            }
                                 
        let bodyContent  =  
            <>
                <Form
                        onSubmit={e => this.handleLoginSubmit(e)}
                    >
                        <Form.Group as="div">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                            required
                            type="email"
                            name="email"
                            placeholder="panda@gmail.com"
                            onChange={this.handleContactUsInputChange}
                            defaultValue={this.state.login.email}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as="div" className="sign-in_forgot-password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                            required
                            name="password"
                            type="password"
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Button variant="link" className="sign-in_forgot" rel="Forgot Password" onClick={this.modalShow.bind(this, 'Forget Password')} block>Forgot Password?</Button>
                        </Form.Group>

                        {emailErrorAlert}
                                         
                        <Button className="btn btn-lg btn-dp mt-5 btn-rounded" type="submit" disabled={this.state.login.loading}>{this.state.login.label}</Button>
                        <Button variant="link" className="sign-in_forgot"  onClick={this.modalClose} block>Close</Button>

                    </Form>
            </>

      

        return (
            <React.Fragment>
                <Modal.Header closeButton>
                    <Modal.Title >
                        Use your email address to sign into Deal Panda
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {bodyContent}
                </Modal.Body>
            </React.Fragment>
        )
    }

    renderContactUsForm () {
        let buttonLabel = ""
        let disableBtn = false
        let disableInput = false 

            if (this.state.contactUs.isLoading) {
                disableBtn = true
                disableInput = true 
                buttonLabel = "Loading..."
            } else if (this.state.contactUs.feedback === "success") {
                disableInput = true 
                disableBtn = true
                buttonLabel = "Submitted"
            } else {
                disableBtn = false
                buttonLabel = "Submit"
            }
            
       
        let emailErrorAlert = ""
            if (this.state.contactUs.feedback === "error" ) {
                emailErrorAlert = 
                <Alert variant="danger" className="mt-4">
                    There was an issue processing your request. Please try again later.
                </Alert>
            } else if (this.state.contactUs.feedback === "success") {
                emailErrorAlert =
                 <Alert variant="success" className="mt-4">
                    Thank you for contacting us. <br />
                    We will get back to you as soon as we review your message.
                </Alert>
            } else {
                emailErrorAlert = ""
            }
                                 
        let bodyContent  =  
            <>
                <Form
                        onSubmit={e => this.handleContactUsSubmit(e)}
                    >
                        <Form.Group as="div">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                            required
                            disabled={disableInput}
                            name="name"
                            type="text"
                            placeholder="John Smith"
                            value={this.state.contactUs.name}
                            onChange={this.handleContactUsInputChange}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as="div">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                            required
                            disabled={disableInput}
                            name="email"
                            type="email"
                            placeholder="panda@gmail.com"
                            value={this.state.contactUs.email}
                            onChange={this.handleContactUsInputChange}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as="div">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                            required
                            disabled={disableInput}
                            type="text"
                            name="subject"
                            placeholder="Please Help"
                            value={this.state.contactUs.subject}
                            onChange={this.handleContactUsInputChange}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                 
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                required 
                                disabled={disableInput}
                                as="textarea" 
                                name="message" 
                                rows="6"
                                value={this.state.contactUs.message} 
                                onChange={this.handleContactUsInputChange} />
                        </Form.Group>
                                         
                        <Form.Group as="div">
                            <Form.Control
                            type="hidden"
                            name="address"
                            value={this.state.contactUs.address}
                            onChange={this.handleContactUsInputChange}
                            />
                        </Form.Group>


                        {emailErrorAlert}

                        <Button className="btn btn-lg btn-dp mt-5 btn-rounded" type="submit" disabled={disableBtn}>{buttonLabel}</Button>
                        <Button variant="link" className="sign-in_forgot"  onClick={this.modalClose} block>Close</Button>

                    </Form>
            </>

      

        return (
            <React.Fragment>
                <Modal.Header closeButton>
                    <Modal.Title >
                        Contact Us
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {bodyContent}
                </Modal.Body>
            </React.Fragment>
        )
    }

    


    handleContactUsInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let form = this.state.contactUs;
        form[inputName] = inputValue;
        this.setState({form: form});
    }

	render() {
        let modal = {
            body : "",
            size : "lg",
            className : ""
        }

        if (this.state.modal.title === "Forget Password") {
            modal = {
                body : this.renderForgotPassword(),
                size : "md",
                className : "modal_sign-in "
            }
           
        } else if (this.state.modal.title === "Password Change") {
            modal = {
                body : this.renderSetPassword(),
                size : "md",
                className : "modal_password-change"
            }
        } else if (this.state.modal.title === "sign-in_email"){
            modal = {
                body : this.renderSignInEmail(),
                size : "md",
                className : "modal_sign-in --email"
            }
        }else if (this.state.modal.title === "contact-us" || this.state.modal.title === "contact-us_cant-log-in"){
            modal = {
                body : this.renderContactUsForm(),
                size : "md",
                className : "modal_sign-in --contact-us"
            }
        } else {
            modal = {
                body : this.renderFooterLinks(),
                size : "lg",
                className : "modal_footer-links"
            }
        }

        let socialMediaErrorAlert = ""

            if (!this.state.login.validated && !this.state.login.loading && (this.state.login.socialMedia !== "")) {
                socialMediaErrorAlert = 
                <Alert variant="danger">
                   {this.state.login.message }
                </Alert>
            } else {
                socialMediaErrorAlert = ""
               
            }

       
      

		return (
			<main >
                    <div className="sign-in">

                        <div className="sign-in_copy">
                            <img className="mt-4 mb-4 logo" src={Logo} alt="Deal Panda"></img>
                            <h1>Welcome to Deal Panda!</h1>
                            <p> Test out New Products, Share Your Feedback & Earn Rewards! On Deal Panda you can earn rewards, free gifts and payouts for participating in marketing tests and sharing your feedback with big brands on their newest products.</p>
                        </div>

                        <div className="sign-in_login-methods">
                            <h2>To continue, log in to Deal Panda</h2>
                            <div className="login-methods --facebook">
                              
                                    <Form.Group className="sign-in_facebook">
                                        <div className="fb-login-button" 
                                            data-size="large" 
                                            data-button-type="login_with" 
                                            data-layout="rounded" 
                                            data-auto-logout-link="false" 
                                            data-use-continue-as="true" 
                                            data-width=""
                                            data-scope="public_profile,email"
                                            ></div>
                                    </Form.Group>
                            </div>
                           
                            <Button variant="primary" className="login-methods --email btn-dp btn-rounded" rel="Sign In" onClick={this.modalShow.bind(this, 'sign-in_email')}><i className="icon-envelope"></i><span>Continue with email</span></Button>
                            {/* <Button variant="primary" className="login-methods --email btn-dp btn-rounded" rel="Contact Us" onClick={this.modalShow.bind(this, 'contact-us')}><i className="icon-envelope"></i><span>Continue with email</span></Button> */}
                            {socialMediaErrorAlert}
                        </div>

                        <div className="sign-in_create-account">
                            Don't have an account? <Link to="/apply">Sign up</Link>
                        </div>
                        
                        
                        <div className="footer_link" rel="js-footer-link">
                            <Button variant="link" className="nav-item" rel="FAQ" onClick={this.modalShow.bind(this, 'FAQ')}>FAQ</Button>
                            <Button variant="link" className="nav-item" rel="TNC" onClick={this.modalShow.bind(this, 'Terms and Conditions')}>Terms & Condition</Button>
                            <Button variant="link" className="nav-item" rel="Privacy Policy" onClick={this.modalShow.bind(this, 'Privacy Policy')}>Privacy Policy</Button>
                            <Button variant="link" className="nav-item" rel="Contact Us" onClick={this.modalShow.bind(this, 'contact-us')}>Contact Us</Button>
                        </div>
                        <p className="footer_copyright">© 2020 Deal Panda. All rights reserved</p>
              
                    </div>
                <Modal className={modal.className} show={this.state.modal.show} size={modal.size} onHide={this.modalClose} centered>
                        {modal.body}
                </Modal>
			</main>
		);
      }
      
    static propTypes = {
        setPassword: PropTypes.func.isRequired,
        authenticate: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
}

export default Login;
