import Table from 'react-bootstrap/Table';
import React, { Component } from "react";
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button'
import Badge from "react-bootstrap/Badge";
import PreLoader from '../snippets/PreLoader'
import StarRating from "../snippets/star-rating";
import dayjs from 'dayjs'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import './admin-bonus-manage.scss';


export default class AdminBonusManage extends Component {

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/bonus/manage/:id",
            exact: true,
            strict: false
        });
		this.state = {
            id: match ? match.params.id : null,
            modal: false,
            approveModal: false, 
            noteModal: false,
            alertPopup: false,
            userForm: {
                status: "",
                entry_id : "",
                bonus_id : "",
                user_id: "", 
                review_link: "",
                notes: ""
            },
            entries: [],
            feedback: {},
            isLoading: true,
            inProgress: false
        }
    }

    componentDidMount=()=>{
        this.fetchBonusEntries(this.state.id).then(entries => {
            this.setState({
                entries: entries,
                isLoading: false
            });
        }).catch(err => {
            console.warn(err);
        });
    }

    fetchBonusEntries = (bonusDealId) => {
        return this.props.get(`/api/v1/deals/bonus/${bonusDealId}/entries` , {}).then(response => {
            return Promise.resolve(response);
        });
    }

    handlSubmit = (userBonusId, data, type) => {
        let formData = new FormData();
        formData.append('bonus_id', data.bonus_id);
        formData.append("hasReviewed", data.review_link === null || data.review_link.trim() === "" ? 0 : 1);
        formData.append('notes' , data.notes);
        formData.append('review_link' , data.review_link === null ? "" : data.review_link.trim());

        if (type === "approval") {
            formData.append('status', "approved");
        } else {
            formData.append('status', "pending");
        }

        return this.props.put(`/api/v1/bonus/manage/${userBonusId}`, formData).then(response => {
            return Promise.resolve(response);
        });
    }


    
    approveUser = (userData) => {
        // entry._id, entry.bonus_deal_id, entry.user_id
        this.setState({
            modal: true, 
            approveModal: true,
            userForm: {...userData},
            approveError: false,
        })
    }

    createNote = (userData) => {
        // entry._id, entry.bonus_deal_id, entry.user_id
        this.setState({
            modal: true, 
            noteModal: true, 
            userForm: {...userData},
            approveError: false
        })
    }
    

    closeModel = () => {
        this.setState({
            modal: false,
            approveModal: false, 
            noteModal:false
        })
    }

    handleNoteSubmission = (e) =>{
        e.preventDefault();
        const id = this.state.userForm.entry_id;
        const review_link = this.state.userForm.review_link
        const notes = this.state.userForm.notes
        this.setState({
            inProgress: true
        })

        this.handlSubmit(id, this.state.userForm, "note").then((res)=>{
            this.closeModel()
            let entries = this.state.entries;
            for (let entry of entries){
                if (entry._id === id){
                    entry.review_link = review_link;
                    entry.notes = notes;
                    this.setState({
                        entries: entries,
                        inProgress: false,
                        approveError: false
                    })
                }
            }
        }).catch(err => {
            console.warn("an error occured");
            this.setState({
                inProgress: false,
                approveError: true
            });
        });
        
    }
    
    handleApproval = (e) =>{
        e.preventDefault();
        const id = this.state.userForm.entry_id;
        const review_link = this.state.userForm.review_link
        const notes = this.state.userForm.notes
        this.setState({
            inProgress: true
        })
        this.handlSubmit(id, this.state.userForm, "approval").then((res)=>{
            this.closeModel()
            let entries = this.state.entries;
            for (let entry of entries){
                if (entry._id === id){
                    entry.status = 'approved';
                    entry.review_link = review_link;
                    entry.notes = notes;
                    this.setState({
                        entries: entries,
                        inProgress: false,
                        approveError: false
                        // approveError: false
                    })
                }
            }
        }).catch(err => {
            // handle error
            console.warn("an error occured");
            this.setState({
                inProgress: false,
                approveError: true
            });
        });
        
    }

    handleUserInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({
            userForm: {
                ...this.state.userForm,
                [inputName] : inputValue 
            }
        });
    }

    handleReviewText = (text) => {
        let el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style = {position: 'absolute', left: '-9999px'};
        
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.setState({
                alertPopup : true
            },() =>{
                setTimeout(function(){ 
                    this.setState({
                        alertPopup : false
                    })
                }.bind(this), 2000);
            })
    }

    renderSubmissionModal = () => {
        const notes = this.state.userForm.notes === "" ? "" : this.state.userForm.notes;
        let saveBtn = ""
        let errorMessage = null

                if (this.state.approveError) {
                    errorMessage =
                    <p className="text-danger text-center text-small"><FontAwesomeIcon icon={faExclamationCircle} fixedWidth  />Update failed, please try again</p>
                }

                if(this.state.inProgress ) {
                    saveBtn = 
                    <React.Fragment>
                        <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                          Saving ...
                    </React.Fragment>
                } else if (this.state.approveError) {
                    saveBtn = 
                    <React.Fragment>
                         Try Again 
                    </React.Fragment>
                } else if (this.state.userForm.status === "pending") {
                    saveBtn = "I Approve"
                } else {
                    saveBtn = "Save"
                }

        return (
            <Modal show={this.state.modal && this.state.approveModal} onHide={this.closeModel} centered>
                <Form onSubmit={this.handleApproval}>
                    <Modal.Header closeButton>
                        <Modal.Title>Additional Info</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                        
                            <Form.Group>
                                <Form.Label>Review link</Form.Label>
                                <Form.Control name="review_link" type="url"  placeholder="https://www.amazon.ca/gp/customer-reviews...." value={this.state.userForm.review_link} onChange={this.handleUserInputChange}  />
                            </Form.Group>
                            
                            <Form.Group>
                                <Form.Label>Notes</Form.Label>
                                <Form.Control as="textarea" name="notes" value={notes} onChange={this.handleUserInputChange} />
                            </Form.Group>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={this.closeModel}>Close</Button>
                        <Button variant="primary" className="btn-dp"  type="submit" disabled={this.state.inProgress}>{saveBtn}</Button>
                    </Modal.Footer>
                </Form>
                {errorMessage}
            </Modal>
        );
    }

    renderNotesModal = () => {
        const notes = this.state.userForm.notes === "" ? "" : this.state.userForm.notes;
        let saveBtn = ""
        let errorMessage = null

                if (this.state.approveError) {
                    errorMessage =
                    <p className="text-danger text-center text-small"><FontAwesomeIcon icon={faExclamationCircle} fixedWidth  />Update failed, please try again</p>
                }

                if(this.state.inProgress ) {
                    saveBtn = 
                    <React.Fragment>
                        <FontAwesomeIcon icon={faSpinner} fixedWidth spin />
                          Saving ...
                    </React.Fragment>
                } else if (this.state.approveError) {
                    saveBtn = 
                    <React.Fragment>
                         Try Again 
                    </React.Fragment>
                } else {
                    saveBtn = "Save"
                }

        return (
            <Modal show={this.state.modal && this.state.noteModal} onHide={this.closeModel} centered>
                <Form onSubmit={this.handleNoteSubmission}>
                    <Modal.Header closeButton>
                        <Modal.Title>Notes</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                        <Form.Group>
                            {/* <Form.Label>Notes</Form.Label> */}
                            <Form.Control as="textarea" name="notes" value={notes} onChange={this.handleUserInputChange} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={this.closeModel}>Close</Button>
                        <Button variant="primary" className="btn-dp"  type="submit" disabled={this.state.inProgress}>{saveBtn}</Button>
                    </Modal.Footer>
                </Form>
                {errorMessage}
            </Modal>
        );
    }

    renderList = () =>{
        let lst = [];
        for (let entry of this.state.entries){
            const userData = {
                status : entry.status,
                entry_id : entry._id,
                bonus_id : entry.bonus_deal_id,
                user_id: entry.user_id, 
                review_link: entry.review_link,
                notes: entry.notes === undefined ? "" : entry.notes
            }
            const badge = <td className="table-data_status"><Badge variant={entry.status === "pending" ? "warning" : 'success'}>{entry.status}</Badge></td>;           
            let button =  null
                    if (entry.status === "pending") {
                        button = 
                            <Button variant="success" 
                                size="sm"
                                onClick={this.approveUser.bind(this, userData)} >
                                    Approve
                            </Button>
                    } else {
                        button = 
                            <Button variant="outline-light" 
                                size="sm"
                                onClick={this.approveUser.bind(this, userData)} >
                                    Modify
                            </Button>
                    }
            
            let amzLink = "-"
                if (entry.review_link !== "" && entry.review_link !== null) {
                    amzLink = <a href={entry.review_link} rel="noopener noreferrer" target="_blank" >Visit URL </a>
                }
            
            let orderID = "-"
            if (entry.hasOwnProperty("user_deal") && entry.user_deal.order_number !== "" & entry.user_deal.order_number !== null){
                orderID = entry.user_deal.order_number;
            }

            let reviewContent = ""
                if (entry.review_text !== null) {
                    reviewContent = 
                    <React.Fragment>
                        <StarRating inputName="reviewRating" value={entry.review_rating} />
                        
                        <div className="description-text" onClick={this.handleReviewText.bind(this, entry.review_text)}>
                            {entry.review_text}
                        </div>
                        <div className="description-extra --amz-link">
                            <strong>Panda Points</strong>: {entry.points}
                        </div>
                        <div className="description-extra">
                            <strong>Review was copied ?: </strong>{entry.is_copied ? 'Yes': 'No'}
                        </div>
                        <div className="description-extra --amz-link">
                            <strong>Review Link</strong>: {amzLink}
                        </div>
                        <div className="description-extra --amz-link">
                            <strong>Order ID</strong>: {orderID}
                        </div>
                    
                    </React.Fragment>
                } else {
                    reviewContent = 
                    <React.Fragment>
                        <em>Amazon URL was provided before user was invited.</em>
                    </React.Fragment>   
                }

            let notes = ""
            let notesButton = "Create"
                if (entry.notes !== undefined && entry.notes !== "") {
                    notesButton = "edit"
                    notes = entry.notes
                }   

            let amazon_profile_text = ""
                if (entry.user.amazon_profile !== 'Unknown' && entry.user.amazon_profile !== null && entry.user.amazon_profile !== undefined){
                    amazon_profile_text = <a href={entry.user.amazon_profile} target="_blank" rel="noreferrer noopener">Link to Profile</a> 
                }else{
                    amazon_profile_text = ""
                }
            

            let userName = ""
                if(entry.hasOwnProperty('user') && entry.user.name !== "") {
                    userName = entry.user.name;
                } else {
                    userName = "unkown";
                }

            let userType = ""
                if(!entry.hasOwnProperty('user')) {
                    userType = <Badge variant={"light"}>Not Applicable</Badge>
                } else {
                    if (entry.user.riskLevel === "TRIAL") {
                        userType = <Badge variant={"warning"}>Trial</Badge> 
                    } else if (entry.user.riskLevel === "VIVA_REVIEWERS") {
                        userType = <Badge variant={"primary"}>Viva Reviewers</Badge>
                    } else if(entry.user.riskLevel === "NOT_RISKY") {
                        userType =  <Badge variant={"light"}>Regular</Badge>
                    } else {
                        userType =  <Badge variant={"danger"}>{entry.user.riskLevel}</Badge>
                    }
                }

            
            lst.push(
                <tr key={entry._id}>
                    <td className="table-data_created-at">
                        <span>{dayjs(entry.created_at).format("MMMM D, YYYY")}</span>
                        <span>{dayjs(entry.created_at).format("h:mm:ss a")}</span>
                    </td>
                    <td className="data-profile">
                         <div className="data-profile_user">
                        <div className="data-profile_user-detail">
                                <div className="user-type">
                                    {userType}
                                </div>
                                <div className="user-name">
                                    <Link
                                        to={{
                                            pathname: "/admin/users/detail/" + entry.user_id,
                                            state: "p-level-2"
                                        }}
                                    >
                                        {userName}
                                    </Link>
                                </div>
                                <div className="user-email">
                                    {entry.user_email}
                                </div>

                            </div>                       
                         <ul className="data-profile_additional-info">
                                <li  className={`amz ${amazon_profile_text === "" ? "d-none": ""}`} >
                                    <i className="icon-amz-logo"></i> 
                                    {amazon_profile_text}
                                </li> 
                            </ul>
                        </div>
                    </td>
                    <td>N/A</td>
                    <td className="table-data_description">
                        {reviewContent}
                    </td>
                    <td className={ `table-data_notes ${notesButton === "Create" ? "--empty" : ""}`}>
                        <div className={`data_notes-wrapper`}>
                            {notes}
                        </div>
                        <Button variant="primary" 
                                size="sm"
                                onClick={this.createNote.bind(this, userData)} >
                                    {notesButton}
                            </Button>
                    </td>
                     {badge}
                    <td className="text-center table-data_button">
                        {button}
                    </td>
                </tr>
            )    
        }
        return lst;
    }

    render = () => {
        
        return (

            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <React.Fragment>
                        <div className="deals_manage">
                            <p className="sub-page_breadcrumb">Manage Bonus's Redeemers</p>
                            {/* <h2>{this.state.deal.deal_name}</h2> */}
                            <section className="bonus_manage">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Profile</th>
                                            <th>Seller Name</th>
                                            <th>Review Content</th>
                                            <th>Notes</th>
                                            <th className="table-data_status">Status</th>
                                            <th  className="text-center table-data_link">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderList()}
                                    </tbody>
                                </Table>
                            </section>
                        </div>
                        {this.renderSubmissionModal()}
                        {this.renderNotesModal()}
                        <div className={`copy-paste_alert ${!this.state.alertPopup ? "--hide" : ""}`} >Copied</div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        history: PropTypes.object,
        location: PropTypes.object
    }
}