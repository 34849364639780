import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import ModalVideo from '../../snippets/modal-video';
import './../apply.scss';
import trackingLoader from '../../../utils/tracking-loader';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronRight, faPlayCircle } from '@fortawesome/free-solid-svg-icons'

// import howtoUrl from './../../../assets/images/amz_profile_howto.gif'
 
export default class StepAmzProfileUrlB extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            question: "Connect your Amazon Profile Page",
            modal: {
                show: false,
                source: "",
                title:"",
            }
        }
    }
    
    componentDidMount = () => {
        document.oncontextmenu=function(){return true};
    }

    componentWillUnmount = () => {
        document.oncontextmenu=function(){return false};
    }

    handleModalClose = () => {
        this.setState({
            modal: {
                show: false,
                file: "",
                title:"",
                instruction:""
            }
        })
    }

    handleShowVideo = (obj) => {
        this.setState({
            modal: {
                show: true,
                source: obj.source,
                title: obj.title     
            }
        })
        trackingLoader.trackEvent(`AMZ how to video clicked`, {
            event_category: `How to video`,
            event_label: `sign_up_flow`
        }); 
    }

    render() {
        let submitButton = null
        
            if(this.props.processing) {
                submitButton = "Validating URL"
            } else {
                submitButton = "Next"
            }

            let videoThumbnail = { 
                backgroundImage: "url(https://dealpanda-public.s3.ca-central-1.amazonaws.com/how-to-connect-your-amazon-profile-page.jpg)" 
            }

        let disabledButton = true

            if (this.props.validationFields.amz_profile_url.isInvalid) {
                disabledButton = true
            } else if (this.props.validationFields.amz_profile_url.value === "") {
                disabledButton = true
            } else {
                disabledButton = false
            }

        let videoFile = ""

        if (this.props.device.isMobile) {
            videoFile = `https://dealpanda-public.s3.ca-central-1.amazonaws.com/apply_profile-page_mobile_${this.props.i18n.region.toLowerCase()}.mp4`
        } else if (this.props.i18n.region !== "") {
            videoFile = `https://dealpanda-public.s3.ca-central-1.amazonaws.com/apply_profile-page_${this.props.i18n.region.toLowerCase()}.mp4`
        } else {
            videoFile = `https://dealpanda-public.s3.ca-central-1.amazonaws.com/apply_profile-page_us.mp4`
        }

        return(
            <Container as="section" className="page-header">
                <h1 className="display-3">{this.state.question}</h1>
                <p className="lead">We use your profile to connect you to products and brands we think you’ll love.</p>
                <Form.Row className="amz-profile-instruction">
                    <Col md={12}>
                        <div className="instruction-video">
                            <p><strong>Need help finding your Amazon Profile Page?</strong></p>
                            <div className="instruction-video_thumbnail" style={videoThumbnail} onClick={this.handleShowVideo.bind(this, {source: videoFile,  title:"How to find link"})}>
                                <div className="instruction-video_control">
                                    <FontAwesomeIcon  size="5x" icon={faPlayCircle} fixedWidth />
                                    <div className="text"> Hit play to watch the walkthrough</div>
                                </div>
                                <div className="instruction-video_overlay"></div>
                            </div>
                            {/* <video width="100%" controls autoPlay>
                                    <source id="mp4" src="https://dealpanda-public.s3.ca-central-1.amazonaws.com/amzon_url-profile.mp4" type="video/mp4"></source>     
                                Your browser does not support the video tag.
                            </video> */}
                        </div>
                        <Form.Group className="mb-3 mt-3"controlId="formGroupEmail">
                            <Form.Label>Amazon Profile URL</Form.Label>
                            <Form.Control isInvalid={this.props.validationFields.amz_profile_url.isInvalid}  className="mb-0" size="lg" type="url" placeholder={"https://www.amazon."+this.props.i18n.domain+"/gp/profile/amzn1.account..."} pattern={"https://www.amazon."+this.props.i18n.domain+"/gp/profile/amzn1.account.*"} name="amz_profile_url" onChange={this.props.handleInputChange} data-question={this.state.question} required/>
                             <Form.Control.Feedback type="invalid">
                                {this.props.validationFields.amz_profile_url.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="apply-step_navigation apply-step_navigation-buttons">
                            {/* <Button type="submit" variant="link">Next</Button> */}
                            <Button type="submit" variant="success" disabled={disabledButton}>{submitButton} <FontAwesomeIcon icon={faChevronRight} fixedWidth /></Button>
                        </Form.Group>  

                    </Col>
                </Form.Row>
                <ModalVideo title={this.state.modal.title} source={this.state.modal.source}  show={this.state.modal.show} handleClose={this.handleModalClose}/>
            </Container>
        )
    }
    
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        device: PropTypes.object.isRequired,
        validationFields: PropTypes.object.isRequired,
        validation: PropTypes.object,
        // exitIntent: PropTypes.func.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        processing: PropTypes.bool.isRequired
    }
}