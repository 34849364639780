import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import Body from '../../utils/utils-body'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import PreLoader from '../snippets/PreLoader'
import PointsStatus from './profile/points-status'
import trackingLoader from '../../utils/tracking-loader';

import './redeem.scss';

export default class Redeem extends Component {

    static POINTS_VALUE = 100;

    constructor(props) {
		super(props);
		this.state = {
            preloader: true,
            page: 1,
            resultsPerPage: this.props.resultsPerPage !== null ? this.props.resultsPerPage : 10,
            rewards:[],
            modal: {
                show: false,
                loading: false,
                type: "form",
                reward: {},
                transaction: {
                    redeeming: 0,
                    balance: 0
                }
            }
        }
    }

    componentDidMount = () => {
        Body.pageClass("add" , "page_redeem")
        this.fetchRewards(this.state.page, this.state.resultsPerPage);
    }

    fetchRewards = (page, resultsPerPage) => {
        const params = {
            page: page,
            limit: resultsPerPage
        };
        return this.props.get("/api/v1/rewards", params).then(response => {
            this.setState({rewards: response.results, preloader: false});
        });
    }

    handleDenominationChange = (e) => {
        let selectedValue = e.target.value;
        let points = parseFloat(selectedValue);
        let modal = {
            ...this.state.modal,
            transaction: {
                redeeming: points * -Redeem.POINTS_VALUE,
                balance: this.props.userProfile.points - (points * Redeem.POINTS_VALUE)
            }
        }
        this.setState({modal});
    }

    handleSubmitRedeem = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        this.props.post("/api/v1/rewards/redeem", formData).then(response => {
            let modal = {
                ...this.state.modal,
                loading: false,
                type: "success"
            }
            if (response.result) {
                this.props.fetchProfile();
            }
            this.setState({modal});

            trackingLoader.trackEvent("redeem_reward", {
                event_category: "engagement",
                event_label: this.state.modal.reward.name,
                value: formData.get("value")
            });

        }).catch(err => {
            console.error(err);
            let modal = {
                ...this.state.modal,
                loading: false
            }
            this.setState({modal});
        })
    }

    showRewardModal = ( reward) => {
        let modal = {
            ...this.state.modal,
            reward: reward,
            show: true
        }
        this.setState({modal});
        trackingLoader.trackEvent("show_modal", {
            event_category: "engagement",
            event_label: reward.name
        });
    }

    closeModal = () => {
        let modal = {
            ...this.state.modal,
            show: false,
            type: "form",
            reward: {}
        };
        this.setState({modal});
    }

    renderList = () => {
        let lst = [];
        this.state.rewards.forEach(reward => {
            let denominationsMin = reward.denominations[0]
            let denominationsMax = reward.denominations[reward.denominations.length -1]
            let theme = reward.color === "#ffffff" ? "light" : "dark";

            let styleCard = { 
                backgroundColor: reward.color, 
                backgroundImage: "url("+reward.image+")"
            }

            let styleSelector = {
                backgroundColor: reward.color, 
            };

            lst.push(
                <Card key={reward._id} onClick={() => {this.showRewardModal(reward)}} className="rewards-card card-deck--3-col">
                    <Card.Body className={theme} style={ styleCard }>
                        <div className="rewards-card_selector" >
                            <div className="card_selector-indicator" style={styleSelector}></div>
                        </div>
                        <div className="rewards-card_value">
                            ${denominationsMin}-${denominationsMax}
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="rewards-card_title">{reward.name}</div>
                        <div className="rewards-card_redemption">
                            <i className="icon-points">
                                <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span>
                            </i>
                            {denominationsMin * Redeem.POINTS_VALUE}-{denominationsMax * Redeem.POINTS_VALUE}
                        </div>
                    </Card.Footer>
                </Card>
            );
        });

        return lst;
    }

    renderEmptyList = () => {
        return (
            <div className="empty-state">Sorry, there are no rewards available right now. Please check back later.</div>
        );
    }

    renderModal = () => {
        let styleCard = { 
            backgroundColor: this.state.modal.reward.color, 
            backgroundImage: "url("+this.state.modal.reward.image+")"
        }

        let theme = this.state.modal.reward.color === "#ffffff" ? "light" : "dark";
        const userPoints = this.props.userProfile.hasOwnProperty("points") ? this.props.userProfile.points : 0;
        const userPointsPending = this.props.userProfile.hasOwnProperty("points_pending") ? this.props.userProfile.points_pending : 0;

        let modalContent = "";
        if (this.state.modal.type === "success") {
            modalContent = (
                <React.Fragment>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h3>Success!</h3>
                        <p>We will be sending you a digital {this.state.modal.reward.name} {this.state.modal.reward.type} within 3 business days.</p>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="redemption_current-points">
                            <PointsStatus  points={userPoints} pointsPending={userPointsPending}></PointsStatus>
                            <span>Remaining Balance</span>
                        </div>
                        <Button variant="success" className="btn btn-dp" onClick={this.closeModal}>Close</Button>
                    </Modal.Body>
                </React.Fragment>
            );
        } else {
            modalContent = (
                <React.Fragment>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="redemption_title"> 
                            {this.state.modal.reward.name}
                        </div>
                        <div className="redemption_current-points">
                            <PointsStatus  points={userPoints} pointsPending={userPointsPending}></PointsStatus>
                            <span>Point Balance</span>
                        </div>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={theme}>
                        <div className="redemption_card" style={ styleCard }></div>
                        <Form onSubmit={!this.state.modal.loading ? e => this.handleSubmitRedeem(e) : null}>
                            <h6 className="title">Select the amount</h6>
                            <Form.Group className="form_select-amount">
                                {this.renderDenominations(this.state.modal.reward.denominations)}
                            </Form.Group>
                            <Form.Group className="form_transaction-breakdown">
                                <div className="transaction-breakdown_item">
                                    <span className="transaction-breakdown_item-title">Redeeming</span>
                                    <span className={this.state.modal.transaction.redeeming < 0 ? "transaction-breakdown_item-value deduction" : "transaction-breakdown_item-value"}>{this.state.modal.transaction.redeeming}</span>
                                </div>
                                <div className="transaction-breakdown_item">
                                    <span className="transaction-breakdown_item-title">Remaining Balance</span>
                                    <span className="transaction-breakdown_item-value">{this.state.modal.transaction.balance}</span>
                                </div>
                            </Form.Group>
                            <Form.Group className="form_footer">
                                <Form.Control type="hidden" name="reward_id" defaultValue={this.state.modal.reward._id} />
                                <Button variant="outline-secondary" onClick={this.closeModal}>Close</Button>
                                <Button variant="success" type="submit" className={this.state.modal.transaction.redeeming === 0 ? "btn btn-dp disabled" : "btn btn-dp"} disabled={this.state.modal.loading}>{this.state.modal.loading ? 'Loading…' : 'Redeem'}</Button>
                            </Form.Group>
                        </Form>
                    
                    </Modal.Body>
                </React.Fragment>

            );
        }

        return modalContent;
    }

    renderDenominations = (denominations) => {
        let lst = [];

        if( denominations !== undefined){
            for (let i=0; i< denominations.length; i++) {
                let denom = denominations[i];
                let pointValue = denom * Redeem.POINTS_VALUE; 
                let cssStyle = "form-check form-check-inline select-amount_option";
                let cssToggle =  pointValue <= this.props.userProfile.points ? cssStyle : cssStyle + " disabled"
               
                lst.push(
                    <div className={cssToggle} key={"option-" + i}>
                        <input className="form-check-input" type="radio" name="value" id={"denomination-" + [i]} value={denom} onChange={this.handleDenominationChange} />
                        <label className="form-check-label" htmlFor={"denomination-" + i}  key={"option-" + i} >
                            <div className="select-amount_option-value">${denom}</div>
                            <div className="select-amount_option-points">
                                <i className="icon-points">
                                    <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span>
                                </i>
                                <span>{pointValue}</span>
                            </div>
                        </label>
                    </div>
                )
            }
        }
        return lst;
    }

    render() {
        const userPoints = this.props.userProfile.hasOwnProperty("points") ? this.props.userProfile.points : 0;
        const userPointsPending = this.props.userProfile.hasOwnProperty("points_pending") ? this.props.userProfile.points_pending : 0;
        return (
            <Container as="main" className="rewards-page">
                <Row>
                    <Col  className="page-header">
                        <h1 className="display-3">Rewards</h1>
                        <p className="lead">Here are some gift cards you can get by using your Panda Points.<br/>
                        Learn how you can earn more Panda Points <Link to="/bonus">here.</Link></p>
                    </Col>
                </Row>
                <Row>
                    <div className="point-status">
                        <p>Current Panda Points</p>
                        <PointsStatus points={userPoints} pointsPending={userPointsPending}></PointsStatus>
                    </div>
                </Row>
                <Row>
                    <CardDeck className={this.state.preloader ? "rewards-deck preloader" : "rewards-deck"}>
                        {this.state.preloader ? (
                            <PreLoader/>
                        ) : (
                            this.state.rewards.length > 0 ? this.renderList() : this.renderEmptyList()                            
                        )}
                    </CardDeck>
                </Row>
                <Modal
                    className={this.state.modal.type !== "success" ? "modal-redemption" : "modal-redemption modal-redemption--success" }
                    backdrop={"static"}
                    show={this.state.modal.show}
                    onHide={this.closeModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>   
                    {this.renderModal()}
                </Modal>
            </Container>

        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        fetchProfile: PropTypes.func.isRequired,
        resultsPerPage: PropTypes.number.isRequired,
        userProfile: PropTypes.object.isRequired
    }
}