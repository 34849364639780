import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import UPLOAD from '../../snippets/upload-files'
import CopyDisabledComponent from '../../../utils/CopyDisabledComponent';
import ModalReviewCountdown from './../compare/step-proof-of-review-modal-countdown'
import RETAILS from '../../../utils/retails'
import dayjs from 'dayjs'

import '../Compare.scss';

export default class ProofOfReviewStep extends Component {

        
    constructor(props) {
		super(props);
		this.state = {
            ...props,
            showModal: false,
            submittedDate: "",
            remainingDate: 0,
            futureDate: ""
        }
    }
    
    componentDidMount = () => {
        this.handleCalculateDate(this.props.product.proof_of_purchase_date)
    }
    

    componentDidUpdate = (prevProps) => {
        if (this.props.product.proof_of_purchase_date !== prevProps.product.proof_of_purchase_date){
            this.handleCalculateDate(this.props.product.proof_of_purchase_date)
        }

        // ADMIN by-pass
        if (this.props.reviewWaitTime !== prevProps.reviewWaitTime){
            if (this.props.reviewWaitTime === 0) {
                this.setState({
                    reviewWaitTime: this.props.reviewWaitTime,
                    showModal: false
                })
            }
        }
    }

    handleCalculateDate = (date) => {

        let futureDate = dayjs(date).add(this.props.reviewWaitTime, "day").format()

            // fail safe incase date is NAN
            if(isNaN(Date.parse(futureDate))) {
                    futureDate = dayjs(new Date()).add(this.props.reviewWaitTime, "day").format()
            }

        let remainingDate = dayjs(futureDate).diff(dayjs(), "days")
        let showCountdown = true

            if (dayjs().isAfter(dayjs(futureDate)) || remainingDate <= 0 ) {
                showCountdown = false
            } else {
                showCountdown = true
            }
        
            this.setState({
                showModal:showCountdown,
                submittedDate: date,
                remainingDate: remainingDate,
                futureDate:futureDate
            })
    }

    handleClick = (e) => {
        e.preventDefault();
        // Need to revist this to change url
        window.location.replace("/");
    }

    render() {
        let disabledButton = true

            if (this.props.loading) {
                disabledButton = true
            }else if (this.props.uploader.screenshots.length > 0) {
                disabledButton = false
            }

        return (
            <React.Fragment>
                <Row className="step-detail step-detail--proof-of-review">
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper" style={{backgroundImage: `url(${this.props.product.product_image_url})`}}>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Final Step</h2>
                            <p>
                                Once you receive your <CopyDisabledComponent content={this.props.product.product_name} tagname="strong" /><em>--which is yours to keep!</em> -- return to this survey and complete these steps to get your reimbursement:.
                            </p>
                            <ol className="mb-4">
                                <li>Leave a review on <strong>{RETAILS.getDomain(this.props.product.deal_channel)}</strong> on the product page. When writing your review, use the name that 
   matches the one on your completed order.</li>
                                <li>
                                    Take a screenshot of the published review and upload it here.
                                    <UPLOAD handleOnSuccess={this.props.handleOnSuccess}  disabled={this.props.state.loading}  title="Published Review" agreement={true} objectKey={this.props.uploader.key} data={this.props.uploader.screenshots} userProfile={this.props.userProfileId} />
                                </li>
                                <li>
                                    You’ll be reimbursed for ${this.props.product.deal_amount} plus applicable taxes and shipping through Paypal to <strong>{this.props.state.step.paypalEmail}</strong> in 2-5 business days. 
                                </li>
                            </ol>
                            <div className="step-detail_cta">
                                <Button className="btn-dp" disabled={disabledButton} onClick={this.props.handleSubmit} block>Complete</Button>
                                <Link to="/test">I'll be back to complete this later</Link>
                                {/* <Button variant="link"> I'll be back to complete this later</Button> */}
                            </div>
                            <div className="step_additiona-note"><strong>Questions about the process?</strong>  No worries, we’re here to help and make sure you get reimbursed. <Link to="/contact">Contact us</Link>.</div>
                        </Col>
                </Row>
                <ModalReviewCountdown {...this.state} handleClick={this.handleClick}  />
            </React.Fragment>   
        );
    }
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        handleOnSuccess: PropTypes.func.isRequired,
        product: PropTypes.object,
        state: PropTypes.object.isRequired,
        userProfileId : PropTypes.string.isRequired,
        uploader: PropTypes.object.isRequired,
        proofOfPurchaseSubmitted: PropTypes.string.isRequired,
        reviewWaitTime: PropTypes.number.isRequired,
        history: PropTypes.object,
        handleSubmit: PropTypes.func.isRequired
    };

}
