import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PasteDisabledInput from './../../../utils/PasteDisabledInput'
// import Util from './../../../utils/utils';
// import HowToVideo from '../../snippets/how-to-video'
import '../Compare.scss';

export default class OurProductStep extends Component {
    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    componentDidUpdate(){
        this.focus('input[name="dealAnswer"]')
    }

    componentDidMount(){
        this.focus('input[name="dealAnswer"]')
    }
    

    render() {

        let buyFirstCondition = ""
            if (this.props.product.deal_type === "buy_first") {
                buyFirstCondition =  <>
                        <li>Go back to the search results page.</li>
                        <li>
                            <p>On the results page, browse through the products to locate the <strong>&quot;{this.props.product.product_name}&quot;</strong> </p>
                            <p>You may need to browse through several pages of search results. Scroll to the bottom and click through to the next page if you can't find the product.</p>
                        </li>
                    </>
            }
           
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--competitors">
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper --portrait" style={{backgroundImage: `url(${this.props.product.product_image_in_search_url})`}}>
                                {/* <CopyDisabledComponent content={<Image src={this.props.competitor.image} />} tagname="strong" /> */}
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current} </h2>
                            <ol>
                                {buyFirstCondition}
                                <li>
                                    <p>Click on the product.</p>
                                     <div className="step_additiona-note">
                                       <strong>Important:</strong> Confirm you’ve selected the correct product for purchase. Is the selected product’s name and image an exact match to the photo and name provided on this page? -- Reimbursements will only be issued for the correct test product.
                                     </div>
                                </li>
                                <li>
                                    Explore the product page and answer the survey question below.
                                </li>
                            </ol>
                            <Form.Group controlId={this.props.step.current}>                            
                                <Form.Label>{this.props.product.deal_question}</Form.Label>
                                <PasteDisabledInput 
                                    type="text" 
                                    name="dealAnswer"
                                    placeholder="Type your answer here" 
                                    onChange={this.props.handleInputChange}
                                    value = {this.props.step.dealAnswer}
                                    required
                                />
                            </Form.Group>                   
                            {/* <div className="step_help">Need Help? <HowToVideo show_icon={true} title="Search for Amazon products" source="https://dealpanda-public.s3.ca-central-1.amazonaws.com/4.mp4"/></div> */}
                        </Col>
                </Row>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired
    };

}

