import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

class contentFAQ extends Component {
  render() {
    return (
        <div className="content">
            <Accordion >
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    How do I participate in a market researcher or product test?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    Participating in a test is very simple! <br/>
                    Tests that you have been matched with based on your profile will appear on the ‘Available Tests’ page.<br/>
                    Select a test, follow the instructions, and complete the survey questionnaire.<br/>
                    Help videos will be provided along the way to guide you.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    What does "1 test per house hold" mean exactly?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    As a dealpanda tester, you may only use ONE Deal Panda account per household address to complete tests and earn rewards. <br/>
                    It is a policy violation to use multiple Deal Panda accounts per household address to complete a test more than once.<br/>
                    Accounts found violating this policy will be banned without notice and will have their rewards cleared from their account.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    Why is there a wait time between tests?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body>
                    Test panels on Deal Panda fill up fast. Our community of testers are highly engaged.<br/>
                    The wait-time feature helps make tests more available to all of our panelists rather than reserving them for a select few.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                    My reward hasn't been issued or arrived yet. When will I get it?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                    <Card.Body>
                    Once you’ve completed a test, the Deal Panda team processes your rewards, free gifts and payouts manually. <br/>
                    This process can take 3 to 5 business days. If you still haven't received your rebate after 5 business days, please <a href='/contact'>contact our customer support team</a>.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
  }
}

export default contentFAQ;
