import React, { Component } from "react";
import PropTypes from "prop-types";
import Footer from "./common/footer"
import Body from '../../utils/utils-body'
import StepCategories from "./steps/categories"
import StepWebsites from "./steps/websites"
import StepIndustry from "./steps/industry"
import StepJobTitle from "./steps/job-title"
import StepLandingPage from "./steps/landing-page"
import StepUserInformation from "./steps/user-information"
import StepNda from "./steps/nda"
import StepPpAccount from  "./steps/pp-account"
import StepAmzProfileUrl from  "./steps/amz-profile-url"
// import StepConfirmation from  "./steps/confirmation"
import StepThankYou from  "./steps/thank-you"
// import StepThankYouNdaDisagree from  "./steps/thank-you-nda-disagree"
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Container from "react-bootstrap/Container";
import PreLoader from '../snippets/PreLoader'
import ScriptLoader from '../../utils/script-loader'
import { ConflictError } from '../../utils/exceptions'
import trackingLoader from '../../utils/tracking-loader'

import './apply.scss';

export default class Apply extends Component {
    constructor(props) {
        super(props);
        this.autoComplete_obj = null;
        const searchParams = new URLSearchParams(window.location.search);
        const utmSource = searchParams.get("utm_source");
        const utmCampaign = searchParams.get("utm_campaign");
        const utmContent = searchParams.get("utm_content");
        let source = utmSource === null ? "unknown" : utmSource;
        if (utmCampaign !== null) {
            source += " -- " + utmCampaign;
        }
        if (utmContent !== null) {
            source += " -- " + utmContent;
        }
        
        // expected path is /{region}/apply
        let region = null;
        const pathParts = window.location.pathname.split("/");

        if (pathParts.length > 2) {
            region = pathParts[1].toUpperCase();
        } else {
            region = "CA"
        }

        const regionTLD = {
            CA: "ca",
            US: "com",
            DE: "de",
            GB: "co.uk"
        };

        this.state = {
            abTest : 0,
            referral : {
                source: source,
                content: utmContent
            },
            i18n: {
                region: (region === null ? "CA": region).toUpperCase(),
                domain: regionTLD.hasOwnProperty(region) ? regionTLD[region] : "ca"
            },
            progressBar : 0, 
            processing: false,
            steps: [
                // This is the order of the flow that will appear on the frontend
                "landing-page",
                "user-information",
                "websites",
                "categories",
                "industry",
                "job-title",
                "pp-account",
                "amz-profile-url",
                "nda",
                "thank-you",
                "thank-you-nda-disagree"
            ],
            removeFromSteps : ["thank-you","landing-page","thank-you-nda-disagree" ],
            currentStep: "landing-page",
            stepResults: [],
            currentStepAnswer : {},
            applicationResult: {
                status: ""
            },
            user_name: "",
            user_birthday: "",
            user_age: "",
            user_location:"",
            user_address: {
                country:region,
                city:"",
                latitude:"",
                longitude:"",
                state:"",
                street:"",
                street_number:"",
                zip:"",
                suite:""
            },
            user_email:"",
            user_nda_agreement:"",
            user_paypal: {
                need_paypal_account: false,
                email: ""
            },
            user_industry: "",
            user_job_title: "",
            optin_promotional_email : false, 
            profile: {},
            amz_profile_url: "",
            validationFields : {
                amz_profile_url : {value: '', isInvalid: false, message: "", required: false},
                user_paypal: {value: '', isInvalid: false, message: "", required: false},
                user_email : {value: '', isInvalid: false, message: "", required: false},
                user_birthday: { value: '', isInvalid: false, message: "", required: false},
                user_location: { value: '', isInvalid: false, message: "", required: false}
            },
            formIsInvalid: false,
            gdpr: this.props.gdprRegion.includes((region === null ? "CA": region).toUpperCase()) ? true : false,
            facebookLogin: {
                successful: true,
                msg:""
            },
            facebookScope: "public_profile,email",
            facebookProfileFields: "email,name,first_name,last_name,picture"
        }

        console.log("loc state", this.props.location, this.props.location.state);
        if (this.props.location.state !== undefined && this.props.location.state.hasOwnProperty("fbProfile")) {
            if (this.props.location.state.fbProfile.hasOwnProperty("email")) {
                this.state.profile = this.props.location.state.fbProfile;
                this.state.user_name = this.props.location.state.fbProfile.name;
                this.state.user_email = this.props.location.state.fbProfile.email;
                this.state.currentStep = "user-information";
            } else {
                this.state.facebookLogin = {
                    successful: false,
                    msg: "Your Email address is required to Log in to Deal Panda with Facebook. Please edit the information you provide to include an email address."
                }
            }
        }
    }

    componentDidMount = () => {     
        /*
            Cookie Consent 
        */      
        this.cookieConsentPopup();
        // console.log("mounted")
        /*
            Add Class
        */
        Body.pageClass("add", `page_apply step_${this.state.currentStep}`);        
        /*
            Load Third Party
        */
        // fbLogin.init(process.env.REACT_APP_FB_APPID, "8.0", true, true, "en_US", this.handleFBLogin, this.state.facebookProfileFields);
        trackingLoader.initOlark("9256-918-10-3753");
        this.loader = new ScriptLoader();
        this.loader.loadScript("https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_CAPTCHA_SITE_KEY);
        this.loader.loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`);

        if (this.props.cookieConsent) {
            this.loadFBPixel();
            trackingLoader.initHotjar("693686");
        }

  
        // console.log(window.location.pathname)
        // trackingLoader.trackPageview(window.location.pathname, "");
     
    }


    componentDidUpdate(prevProps, prevState)  {
        // console.log("apply is updated")
        Body.pageClass("add", `page_apply step_${this.state.currentStep}`);
        const consented = this.props.cookieConsent === true && this.props.cookieConsent !== prevProps.cookieConsent;

        if (this.state.currentStep !== prevState.currentStep || (consented && this.state.currentStep === this.state.steps[0]) ) {
            // use timeout to allow tracking js to finish loading
            setTimeout(() => {
                if (this.state.currentStep !== "landing-page") {
                    trackingLoader.trackPageview(window.location.pathname + "/" + this.state.currentStep, this.state.currentStep);
                    // console.log("didUpdate", window.location.pathname + "/" + this.state.currentStep, this.state.currentStep)
                } 
            }, 500);   
            if (this.state.currentStep !== "landing-page") {
                document.body.style.backgroundColor = ""
                document.body.style.backgroundImage = ""
            }
           
        }

        
        // init fb pixel if cookie consent was changed
        if (consented) {
            this.loadFBPixel();
        }
    }

    componentWillUnmount = () => {
        Body.pageClass("remove", `page_apply ${this.state.currentStep}`);
    }

    reset = () => {
        this.setState({
            stepResults: [],
            currentStepAnswer: {},
            currentStep: "landing-page"
        }, this.resetValidation())
    }
    
    progressBar = () => {
        // let steps = Object.values(this.state.step);
        let totalSteps = this.state.steps.length - this.state.removeFromSteps.length + 1
        let percentage = 0; 
        let counter = ""

            if(!this.state.removeFromSteps.includes(this.state.currentStep ) ) {
                counter = <div className="progress-counter"> {this.state.steps.indexOf(this.state.currentStep)} / { totalSteps }</div>
            }

            this.state.steps.forEach(function(step, idx){
                if (this.state.currentStep === "landing-page") {
                    percentage = 0 ;
                } else if (this.state.currentStep === "thank-you") {
                    percentage = 100 ;
                }  else if (step === this.state.currentStep) {
                    percentage = (idx) /  totalSteps * 100 ;
                } 
            }.bind(this))

        return <div className="progress-container"><ProgressBar now={percentage} /> {counter}</div>
    }

    debugger = () => {
        // ...?debug=true&abtest=b
        const searchParams = new URLSearchParams(window.location.search);
        const debug = searchParams.get("debug"); // true 
        const abTest = searchParams.get("abtest"); //a or b

        let s = {
            debug: debug
        }

        if (debug ) {
            if (abTest !== null) {
                try {
                    let abVal = parseInt(abTest);
                    s.abTest = abVal;
                } catch(ex) {
                    // pass
                }
            }
        }

        return s;
    }

    
    cookieConsentPopup  = () => {
        const pathParts = window.location.pathname.split("/");
        const region = pathParts[1].toUpperCase();
        let userID = "facebook_id";
        let isGdpr = this.props.gdprRegion.includes(region.toUpperCase()) ? true : false 
        let cookieConsent = localStorage.getItem(`_dp_${userID}_cookie_consent_answer`) === null ? null : JSON.parse(localStorage.getItem(`_dp_${userID}_cookie_consent_answer`));
        let showPopup = false

        if (!isGdpr && cookieConsent === null) {
            //  Not GDPR
            localStorage.setItem(`_dp_${userID}_cookie_consent_answer`, true);
            cookieConsent = true;
            showPopup = false;
        } else if(isGdpr && cookieConsent === null) {
            // GDPR Country
            showPopup = true;
        } 

        this.props.showCookieConsentPopup(isGdpr, cookieConsent, showPopup)
    }


    loadFBPixel = () => {
        console.log("loading fb pixel");
        if (this.state.i18n.region === "CA") {
            trackingLoader.initFBPixel(process.env.REACT_APP_FB_PIXEL_ID_CA)
        } else {
            trackingLoader.initFBPixel(process.env.REACT_APP_FB_PIXEL_ID_US)
        }
    }

    handleMissingAddressInfo = () => {
        
        new window.google.maps.Geocoder().geocode({ 'address': this.state.user_address.zip }, function (results, status) {
            let lat = 0
            let long = 0
            let googleResults = {};
            
            const _map = {'street_number': 'street_number',
                'route': 'street',
                'administrative_area_level_1': 'state',
                'postal_code': 'zip',
                'country': 'country',
                'locality': 'city'};

            if(status === "OK") {
                let data = results[0]
                    lat = data.geometry.location.lng()
                    long = data.geometry.location.lat()
                    
                        console.log(data)
                  
                    for (var i = 0; i < data.address_components.length; i++) {
                        var addressType = data.address_components[i].types[0];
                        var val = data.address_components[i].short_name;
                        
                        if (Object.keys(_map).includes(addressType)){
                            let human_key = _map[addressType];
                                googleResults[human_key] = val;
                        }
                    }
            }


            this.setState({
                user_address: {
                    ...this.state.user_address,
                    country: this.state.i18n.region.toUpperCase(),
                    longitude : lat,
                    latitude: long,
                    google:googleResults
                }
            })
        }.bind(this));
        
    }

    
    formatPostalCode = (value) => {
        let output = ""
        let string = value.replace(/[^a-zA-Z0-9]/g, '');

            if (string !== "") {
                output = string.match(/.{1,3}/g).join(' ').toUpperCase()
            }                   
        
        return output


    }

    formatZipCode = (value) => {
        let output = ""
        let string = value.replace(/\D/g, '');

            if (string !== "") {
                output = string.match(/.{1,5}/g).join('-').toUpperCase()
            }                   
        
        return output

    }

    handleAddressChange = (e) =>{
        const name = e.target.name;
        let value = e.target.value;  
        const required = e.target.required

        if (name === "zip" ) {
            if (this.state.i18n.region === "US") {
                value = this.formatZipCode(value)
            } else {
                value = this.formatPostalCode(value)
            }
        }
        
        
        this.setState({
            applicationResult:{status:""},
            validationFields: {
                ...this.state.validationFields,
                [name]: { ...this.state.validationFields[name], value: value, required : required}
            },
            user_address: {
                ...this.state.user_address,
                [name]: value
            }
        })
    }

    handleAddressSuiteChange = (e) => {
        const value = e.target.value;  
          this.setState({
            user_address: {
                ...this.state.user_address,
                suite: value
            }
        })
    }

    handleInputChange = (e) => {
        const required = e.target.required
        const name = e.target.name;
        const value = e.target.value;  
            
            if (e.target.getAttribute("data-question")) {
                let question = e.target.getAttribute('data-question');
                    this.setState({
                        [name]: value,
                        currentStepAnswer: {
                            name: name, 
                            question: question,
                            answer: value
                        }
                    })
            }  else {
                this.setState({
                    [name]: value,
                    applicationResult:{status:""},
                    validationFields: {
                        ...this.state.validationFields,
                        [name]: { ...this.state.validationFields[name], value: value, required : required}
                    }
                })
            }
    }

    handleNDAInputChange = (value) => {
        // Need to redo this letter
        this.setState({
                "user_nda_agreement" : value,
                currentStepAnswer: {
                    name: "user_nda_agreement", 
                    question: "Do you agree to ",
                    answer: value
                }
        })
    }

    handleAmazonUrlPage = (e) => {
        const type = e.target.type; 
        const value = e.target.value; 
        const name = e.target.name;  
        const required = e.target.required

        if (type === "checkbox") {     
            this.setState({
                [name] : e.target.checked
            })
        } else {
            this.setState({
                [name] : value,
                validationFields: {
                    ...this.state.validationFields,
                    [name]: { ...this.state.validationFields[name], value: value, required : required }
                },
                currentStepAnswer: {
                    name: name, 
                    question: "amazon profile url",
                    answer: value
                }
            })
            this.handleFieldValidation(name, value )
        }
    }

    handlePayPalAccount  = (e) => { 
        const type = e.target.type;
        const value = e.target.value;  
        const name = e.target.name; 
        const required = e.target.required

        if (type === "checkbox") {
            this.setState({
                user_paypal : {
                    need_paypal_account: e.target.checked,
                    email: ""
                },
                validationFields: {
                    ...this.state.validationFields,
                    user_paypal: { ...this.state.validationFields.user_paypal, value: "", required : false }
                }
            })

            if(e.target.checked) {
                trackingLoader.trackEvent(`User clicked on (They do not have PayPal acount)`, {
                    event_category: `engagment`,
                    event_label: `sign_up_flow`
                }); 
            }

        } else {
            this.setState({
                user_paypal: {
                    need_paypal_account: false,
                    email: value
                },
                validationFields: {
                    ...this.state.validationFields,
                    [name]: { ...this.state.validationFields[name], value: value, required : required }
                },
                currentStepAnswer: {
                    name: name, 
                    question: "paypal email",
                    answer: value
                }
            })
        }

        if (this.state.formIsInvalid) {
            this.handleFieldValidation(name, value)
        }
    }

    handleUserInformation  = (e) => { 
        const type = e.target.type; 
        const value = e.target.value; 
        const name = e.target.name;  
        const required = e.target.required

        if (type === "checkbox") {     
            this.setState({
                [name] : e.target.checked
            })
        } else {
            this.setState({
                [name] : value,
                validationFields: {
                    ...this.state.validationFields,
                    [name]: { ...this.state.validationFields[name], value: value, required : required }
                }
            })
            this.handleFieldValidation(name, value )
        }
    }

    checkUsersAge = (dob) => {
        const diff =  Date.now() - new Date(dob).getTime();
        const ageDate = new Date(diff);
        const age = isNaN(Math.abs(ageDate.getUTCFullYear() - 1970)) ? 0 : Math.abs(ageDate.getUTCFullYear() - 1970);
        return age
    }

    resetValidation = () => {
        this.setState({
            validation: {
                name:"",
                value:"",
                valid : true, 
                message : ""
            }
        })
    }

    handleSetValidateField = (obj) => {
        this.setState({
                validationFields: {
                    ...this.state.validationFields,
                    ...obj
                }
        }) 
    }

    handleResetValidateField = () => {
        let fields = this.state.validationFields;

        Object.keys(fields).map(key => {
            if (fields[key].hasOwnProperty('isInvalid')) {
                fields[key].isInvalid = false;
                fields[key].message = '';
            }
            return null;
        });
        
        this.setState({
            validationFields : fields
        })
    }

    handleFormValidation = () => {
        let fields = this.state.validationFields;
        let formIsInvalid = false  
        // let validatedfFields = []
   
            Object.keys(fields).forEach((key, index) => {
                  let   result = this.validationRule(key, fields[key].value, fields[key].required);
                        if (result.isInvalid) {
                            formIsInvalid = true;
                        }
                        fields[key] = result
            })

            this.setState({
                validationFields : {
                    ...fields
                },
                formIsInvalid: formIsInvalid
            })

            return formIsInvalid
    }

   
    handleFieldValidation = (field, value) => {
        let result = this.validationRule(field, value);
        this.setState({
                validationFields : {
                    ...this.state.validationFields,
                    [field]: result
                }
        })
    }

    validationRule = (field, value, required) => {
        let fieldName = field
        let fieldValue = value
        let obj = { value: fieldValue , message:"", isInvalid:false, required: required};

        
        const email = ["user_email",  "user_paypal", "email"] 
            
            
            if ( email.includes(fieldName) && fieldValue !== "" ) {
                let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // check if @ symbol exist
                    if(!pattern.test(fieldValue)) {
                        obj.isInvalid = true
                        obj.message = "An email address must contain a @ symbol and domain."
                    } else {
                        obj.isInvalid = false
                        obj.message = ""
                    }
            
            } else if (fieldName === "user_birthday" && fieldValue !== "") {
                    let year = fieldValue.split('-')[0];
                    let minAge = 18; 
                    if (year > 2020 || year <= 1900){
                        obj.isInvalid = true
                        obj.message = "Sorry, please check your birthday";
                    }else if(this.checkUsersAge(fieldValue) < minAge && fieldValue !== "") {
                        obj.isInvalid = true
                        obj.message = "Sorry, you're not old enough to register for Deal Panda."
                    } else {
                        obj.isInvalid = false
                        obj.message = ""
                    }
            } else if (fieldName === "amz_profile_url" && fieldValue !== "") {
                /* eslint-disable */
               
                let pattern = fieldValue.trim().match(/(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:(?!\/gp\/profile\/amzn1\.account\.)(?:.|\n))*?([^:\/\n?]+)\/gp\/profile\/amzn1\.account/)
                let country = this.state.i18n.domain === "ca" ? "a Canadian " : "an American "
                    
                    if(pattern === null ) {
                        obj.isInvalid = true
                        obj.message = "Sorry the link you provided is incorrect, please see the video"
                    } else if (pattern[1] !== "amazon." + this.state.i18n.domain) {
                        obj.isInvalid = true
                        obj.message =  `Please provide Amazon.${this.state.i18n.domain} account`
                    } else {
                        obj.isInvalid = false
                        obj.message = ""  
                    }
            } else if (fieldName === "user_location" && fieldValue !== "") {
                /* eslint-disable */
                let pattern = fieldValue.match(/(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:(?!\/gp\/profile\/amzn1\.account\.)(?:.|\n))*?([^:\/\n?]+)\/gp\/profile\/amzn1\.account/)
                let country = this.state.i18n.domain === "ca" ? "a Canadian " : "an American "
                    
                    if(pattern === null ) {
                        obj.isInvalid = true
                        obj.message = "Sorry the link you provided is incorrect, please see the video"
                    } else if (pattern[1] !== "amazon." + this.state.i18n.domain) {
                        obj.isInvalid = true
                        obj.message =  `Please provide Amazon.${this.state.i18n.domain} account`
                    } else {
                        obj.isInvalid = false
                        obj.message = ""  
                    }
            } 

            
            return obj
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let formIsInvalid = this.handleFormValidation();
   
        if(!formIsInvalid) {
            // let steps = Object.values(this.state.step)
            let currentStep = this.state.currentStep
            let nextStep = ""
            
            this.state.steps.forEach(function(step, idx){
                
                if(step === currentStep && this.state.steps.indexOf(currentStep) === this.state.steps.length - 3) {
                    this.setState({processing: true}) 
                    // Submission page 
                    window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {action: "submit"}).then(captchaToken => {
                        this.captchaToken = captchaToken;
                        this.submitApplication(e).then(result => {
                            if (result && !(result instanceof Error)) {
                                // Successful
                                nextStep = this.state.steps[idx+1]
                                result = {status:"successful"};
                            } else if (result instanceof ConflictError) {
                                // account already exists
                                nextStep = this.state.steps[idx+1];
                                result = {status:"account-exist"};
                            } else {
                                // TODO a generic error occurred
                                nextStep = currentStep
                                result = {status:"technical-issue"};
                                console.error(result);
                                trackingLoader.trackEvent("exception", {
                                    description: result,
                                    fatal: false
                                });
                            }
                            
                            trackingLoader.trackEvent("sign_up", {
                                method: "email",
                                event_category: "engagement",
                                event_label: this.state.referral.source + " -- v" + this.state.abTest,
                                variant: this.state.abTest,
                                value: 1
                            })

                            this.setState({
                                applicationResult: result,
                                currentStep: nextStep,
                                processing: false
                            }) 
                            return true;
                        });
                    });

                } else if (currentStep === step) {
                    nextStep = this.state.steps[idx+1]
                    
                   
                    if(currentStep==="user-information") {
                        this.handleMissingAddressInfo()
                    }

                    if(Object.keys(this.state.currentStepAnswer).length === 0) {
                        this.setState({
                            currentStep: nextStep
                        }) 
                    } else {
                        this.setState({
                            currentStep: nextStep,
                            stepResults: [...this.state.stepResults, this.state.currentStepAnswer]
                        })

                    }
                    
                    return true;
                }
            }.bind(this))


        }
    }

    submitApplication = () => {
        let formData = new FormData();
        formData.append("email", this.state.user_email);
        formData.append("source", this.state.referral.source);
        formData.append("questions", JSON.stringify(this.state.stepResults));
        formData.append("captcha_token", this.captchaToken);
        formData.append("region", this.state.i18n.region);
        formData.append("document_referrer", document.referrer);
        // formData.append("ab_variant", this.state.abTest);
        formData.append("name", this.state.user_name);
        formData.append("fb_id", this.state.profile.id);
        formData.append("first_name", this.state.profile.first_name);
        formData.append("last_name", this.state.profile.last_name);
        formData.append("fb_picture", this.state.profile.picture.data.url);
        formData.append("birthday", this.state.user_birthday);
        formData.append("nda", this.state.user_nda_agreement);
        formData.append("optin_email", this.state.optin_promotional_email);

        // new address data
        formData.append("longitude", this.state.user_address.longitude);
        formData.append("latitude", this.state.user_address.latitude);

        formData.append("city", this.state.user_address.city);
        formData.append("state", this.state.user_address.state);
        formData.append("street", this.state.user_address.street);
        formData.append("street_number", this.state.user_address.street_number);
        formData.append("country", this.state.user_address.country);
        formData.append("zip", this.state.user_address.zip);
        formData.append("suite", this.state.user_address.suite);

        // formData.append("google_address", this.state.user_address.googleResults);

        

        this.setState({processing: true})
        return window.fetch(process.env.REACT_APP_API_DOMAIN + "/api/v1/apply", {
            headers: new Headers({
                'Authorization': "Bearer no-auth"
            }),
            credentials: "include",
            method: "POST",
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else if (response.status === 409) {
                let err = new ConflictError("account exists");
                err.email = this.state.user_email;
                throw err;
            } else {
                throw response.text();
            }
        }).then(response => {
            if (response.result) {
                return Promise.resolve(response.result);
            } else {
                return Promise.resolve(new Error("an error occurred"));
            }
        }).catch(err => {
            if (err instanceof Promise) {
                err.then(p => {
                    return Promise.resolve(new Error(p));
                });
            } else {
                console.warn(err);
                return Promise.resolve(err);
            }
        });
    }

    exitIntent = () => {
        
        let delay = 5 //in seconds

        setTimeout(() => {
            document.addEventListener("mouseout", evt => {
                if(evt.toElement === null && evt.relatedTarget === null) {
                    // console.log("custom intent mouse out")
                }
            });
        }, delay * 1000);

        // Hotjar Survey
        trackingLoader.trackEvent("hj_survey");
    }

    // handleFBLogin = (response, accessToken) => {
    //     let result = true;        
    //     const idx = this.state.steps.indexOf(this.state.currentStep);
    //     let nextStep = this.state.steps[idx+1];
    //     let facebookLogin = {...this.state.facebookLogin}
    //     let userEmail = response.email;
        
    //     // console.log("before", response, accessToken)
    //     if (!response.hasOwnProperty("email")) {
    //         nextStep = "landing-page"
    //         facebookLogin = {
    //             successful: false,
    //             msg: "Your Email address is required to Log in to Deal Panda with Facebook. Please edit the information you provide to include an email address."
    //         }
    //         result = false;
    //     } else {
    //         userEmail = response.email;
    //     }

    //     let param = {
    //         currentStep: nextStep,
    //         profile: response,
    //         user_name: response.name,
    //         user_email: userEmail,
    //         facebookLogin: facebookLogin
    //     }

    //     this.setState({
    //         ...param
    //     }, () => {
    //         console.log("handleFBLogin state set");
    //     });

    //     // console.log("param", param)
        

    //     // A TEMP FIX FOR SAFRI 
    //     // if (navigator.userAgent.match(/AppleWebKit/) && this.state.user_email === "") {
    //     //     console.log("refresh if needed cuz it's iphone and set state did not get updated")
    //     //     setTimeout(function(){ 
    //     //         this.setState({ param }, () => {
    //     //             console.log(this.state.facebookLogin, 'dealersOverallTotal1');
    //     //         }); 
    //     //         location.reload(true);
    //     //         console.log("timer")
    //     //     }.bind(this), 2000);
    //     // }
    // }

    renderFlow = () => {
        let currentStep = this.state.currentStep 
        let output = null;
             
            if(currentStep === "nda") {
                output = 
                <StepNda
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    ndaAgreement = {this.state.user_nda_agreement}
                    handleInputChange = {this.handleNDAInputChange}
                    processing = {this.state.processing}
                />
            }
            else if(currentStep === "industry") {
                output = 
                <StepIndustry
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    handleInputChange = {this.handleInputChange}
                    processing = {this.state.processing}
                />
            } 
            else if(currentStep === "websites") {
                output = 
                <StepWebsites
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    handleInputChange = {this.handleInputChange}
                />
            } else if(currentStep === "categories") {
                output = 
                <StepCategories
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    handleInputChange = {this.handleInputChange}
                />
            } else if(currentStep === "job-title") {
                output = 
                <StepJobTitle
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    handleInputChange = {this.handleInputChange}
                    processing = {this.state.processing}
                />
            }  else if(currentStep === "user-information") {
               
                output = 
                <StepUserInformation
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    user= { { optin_promotional_email: this.state.optin_promotional_email, age:this.state.user_age, email: this.state.user_email, name:this.state.user_name, location: this.state.user_location, address: this.state.user_address, birthday: this.state.user_birthday}}
                    user_paypal = {this.state.user_paypal}
                    handleInputChange = {this.handleUserInformation}
                    handleAddressChange = {this.handleAddressChange}
                    handleAddressSuiteChange = {this.handleAddressSuiteChange}
                    processing = {this.state.processing}
                    
                    // validation={this.state.validation}
                    handleSetValidateField = {this.handleSetValidateField}
                    validationFields={this.state.validationFields}
                />
            } 
            // else if(currentStep === "user-information-bk") {
               
            //     output = 
            //     <StepUserInformationBK
            //         i18n = {this.state.i18n}
            //         gdpr = {this.state.gdpr}
            //         user= { { optin_promotional_email: this.state.optin_promotional_email, age:this.state.user_age, email: this.state.user_email, name:this.state.user_name, location: this.state.user_location, address: this.state.user_address, birthday: this.state.user_birthday}}
            //         user_paypal = {this.state.user_paypal}
            //         handleInputChange = {this.handleUserInformation}
            //         handleAddressChange = {this.handleAddressChange}
            //         handleAddressSuiteChange = {this.handleAddressSuiteChange}
            //         processing = {this.state.processing}
                    
            //         // validation={this.state.validation}
            //         handleSetValidateField = {this.handleSetValidateField}
            //         validationFields={this.state.validationFields}
            //     />
            //     if (this.autoComplete_obj === null && document.getElementById('formGroupLocation')){
            //         this.autoComplete_obj = new window.google.maps.places.Autocomplete(document.getElementById('formGroupLocation'), {});
            //         this.autoComplete_obj.addListener("place_changed", this.handleAddressSelect);
            //     }

            // } 
            else if(currentStep === "pp-account") {
                output = 
                <StepPpAccount
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    user_paypal = {this.state.user_paypal}
                    handleInputChange = {this.handlePayPalAccount}
                    processing = {this.state.processing}
                    validation={this.state.validation}
                     validationFields={this.state.validationFields}
                />
            } else if(currentStep === "amz-profile-url") {
                output = 
                <StepAmzProfileUrl
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    device = {this.props.device}
                    handleInputChange = {this.handleAmazonUrlPage}
                    processing = {this.state.processing}
                    exitIntent={this.exitIntent}
                    validation={this.state.validation}
                    validationFields={this.state.validationFields}
                />
            } else if(currentStep === "thank-you") {
                output = 
                <StepThankYou
                    history = {this.props.history}
                    i18n = {this.state.i18n}
                    gdpr = {this.state.gdpr}
                    ndaAgreement = {this.state.user_nda_agreement}
                    processing = {this.state.processing}
                    stepResults = {this.state.stepResults}
                    reset = {this.reset}
                    result = {this.state.applicationResult}
                    userEmail = {this.state.user_email}
                />
            } else {
                output = 
                    <StepLandingPage
                        i18n = {this.state.i18n}
                        gdpr = {this.state.gdpr}
                        facebookLogin = {this.state.facebookLogin}
                        handleInputChange = {this.handleInputChange}
                        // handleFBLogin = {this.handleFBLogin}
                        processing = {this.state.processing}
                        fbScope = {this.state.facebookScope}
                        fbProfileFields = {this.state.facebookProfileFields}
                        device = {this.props.device}
                />
            }
        return output
    }

    render() {
        // console.log(this.props.location.search)
        if (this.state.referral.source === "") {
            return <PreLoader />;
        } else {
            let page = ""
            let footer = ""

            if( this.state.currentStep === "landing-page") {
                page = <>{this.renderFlow()}</>
            } else {
                page =
                <Container as="main" className={"review-form"}>
                    <Form noValidate validated={this.state.formIsInvalid} onSubmit={this.handleSubmit} autoComplete="none">
                        {this.renderFlow()}
                    </Form>
                </Container>

                footer = <Footer gdpr={this.state.gdpr} />
            }

            return (
                <React.Fragment>
                    {this.progressBar()}
                    {page}
                    {footer}
                    
                </React.Fragment>
            )
        }
    }

    static propTypes = {
        history:PropTypes.object,
        gdpr: PropTypes.bool.isRequired,
        gdprRegion: PropTypes.array.isRequired,
        device: PropTypes.object.isRequired,
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        cookieConsent: PropTypes.bool,
        handleCookieConsentAnswer: PropTypes.func.isRequired,
        utm: PropTypes.object.isRequired,
        showCookieConsentPopup: PropTypes.func.isRequired
    }
}