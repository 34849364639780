import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import RETAILS from '../../../utils/retails'
import PandaCoin from '../../snippets/panda-coin'

import '../Compare.scss';

export default class CompareStepNda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nda_agreement:  ""
        };
    }

    handleCheckbox = (e) => {
        const inputValue = e.target.value;
        const inputName = e.target.name;

        this.setState({
            [inputName] : inputValue
        })
    }

    render() {
        let disableButton = true

            if(this.state.nda_agreement !== "") {
                disableButton = false
            }

        return (
            <React.Fragment>
                <Container as="section" className="step-detail walkthrough ">
                    <Row>
                        <Col xs={12} md={4} className="walkthrough_image d-none d-sm-none d-md-block">
                            <div className="image-tile">
                                <Image src={this.props.product.product_image_url} />
                                
                                <h5>Your Reward for Participation</h5>
                                <ul>
                                    <li>
                                        <em>Free Product -- keep the product you test and get fully reimbursed!</em>
                                    </li>
                                    <li>
                                        Earn <PandaCoin/><strong>{this.props.product.points}</strong> Panda Points
                                    </li>
                                </ul>

                                <h6>Reward Value: ${this.props.product.deal_amount}</h6>
                                <small>*2,500 Panda Points get you $25 in Gift Cards</small>

                            </div>
                        </Col>
                        <Col xs={12} md={7} className="walkthrough_content">
                            {/* <h1>Deal Panda Mutual Non-Disclosure Agreement</h1> */}
                            <div className="content-header">
                            <img alt={`${this.props.product.deal_channel}-logo`} className={this.props.product.deal_channel} src={RETAILS.getLogo(this.props.product.deal_channel, "sm")}/> 
                                 <h1>Deal Panda Mutual Non-Disclosure Agreement</h1>
                            </div>

                            <p >To participate in this test you’ll need to confirm your agreement to the Mutual Non-Disclosure Agreement.</p>
                            <p>Researchers and brands have developed proprietary test methods, products and/or services they wish to keep
                            confidential while testing and launching to market.</p>
                            <p> 
                            This is a mutual agreement in which both parties disclose and protect their information during the testing process.
                            </p>
                            <p>If we have deemed that you have violated this mutual non-disclosure agreement, your tester account will be immediately suspended and you will be banned from all future tests.</p>
                            
                            <div className="nda-agreement">
                                        <Form.Check
                                            onChange={this.handleCheckbox}
                                            type="radio"
                                            label="Agree and Start test"
                                            name="nda_agreement"
                                            value="agree"
                                            id="nda_agreement_agree"
                                        />
                                        <Form.Check
                                            onChange={this.handleCheckbox}
                                            type="radio"
                                            label="Disagree"
                                            name="nda_agreement"
                                            value="disagree"
                                            id="nda_agreement_disagree"
                                        />
                                    
                     
                            </div>
                            <Button className={"btn btn-dp "} disabled={disableButton} onClick={this.props.handleNdaSubmit.bind(this, this.state.nda_agreement)} >Continue </Button>
                        </Col>
                    </Row>   
                </Container>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        product: PropTypes.object.isRequired,
        handleNdaSubmit: PropTypes.func.isRequired
    }
}
