import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ContentTNC from './../../snippets/ContentTNC';
import ContentPrivacyPolicy from './../../snippets/ContentPrivacy';
import './../apply.scss';


export default class CommonFooter extends Component {
    constructor(props) {
		super(props);
		this.state = {
            show: false,
            type: null
        }
    }
    handleClose = () => this.setState({show:false, type: null});
    handleShow = (type) => this.setState({show:true, type: type});

    render() {
        let gdpr = !this.props.gdpr ? "d-none" : ""
        let modalTitle = ""
        let modalContent = ""

            if(this.state.type === "tnc") {
                modalTitle = "Terms and Condition"
                modalContent = <ContentTNC/>
            } else {
                modalTitle = "Privacy Policy"
                modalContent = <ContentPrivacyPolicy/>
            }

        return(
            <React.Fragment>
                <Navbar className={`footer`}>
                    <Container>
                        <small className={"gdpr-disclaimer " + gdpr}>
                            *You can delete your account and personal data at any time.<br/>
                        </small>
                        
                        <div className="footer-links">
                            <Button onClick={this.handleShow.bind(this, "tnc")} variant="link">Terms and Condition</Button>
                            <Button onClick={this.handleShow.bind(this, "privacy-policy")} variant="link">Privacy Policy</Button>
                        </div>
                    </Container>
                </Navbar>
                <Modal size="lg" show={this.state.show} onHide={this.handleClose} className="modal-content--copy" centered>
                    <Modal.Header closeButton>{modalTitle}</Modal.Header>
                    <Modal.Body>
                        <Container as="main" >
                            {modalContent}
                        </Container>
                    </Modal.Body>
                    
                </Modal>
            </React.Fragment>
        )
    }


    static propTypes = {
        gdpr: PropTypes.bool.isRequired,
        customClass:PropTypes.string
    }
}