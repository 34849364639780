import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import HowToVideo from '../../snippets/how-to-video'
import amzURLBuilder from '../../../utils/admin-regions'


export default class ReviewStepSubmitted extends Component {
    

    componentDidMount = () => {
        document.oncontextmenu=function(){return true};
    }

    componentWillUnmount = () => {
        document.oncontextmenu=function(){return false};
    }
    
    render() {

        let disabledButton = true
        let buttonText = "Submit"
            
            if(this.props.reviewLink !== "" &&
               this.props.reviewLink.includes(amzURLBuilder.getDomain(this.props.userProfile.region)) &&
               (this.props.reviewLink.includes('gp/customer-reviews') || this.props.reviewLink.includes('/review/'))) {
                disabledButton = false
            }

            if (this.props.loading) {
                disabledButton = true
                buttonText = "Loading"
            }

        return (
            <Row>
                <Col md={5} className="product-image"><Image src={this.props.bonusDeal.product_image_url} /></Col>
                <Col md={7} className="product-detail">
                    <h1>Brands Want To Know The Experience You’ve Shared</h1>
                    <Form onSubmit={this.props.handleSubmit}>                                                    
                                <Form.Group>
                                    <Form.Label>Please provide the link to your review:
                                    
                                    </Form.Label>
                                    <Form.Control as="input" name="reviewLink" 
                                        value={this.props.reviewLink} 
                                        onChange={this.props.handleInputChange} 
                                        placeholder={`https://www.${amzURLBuilder.getDomain(this.props.userProfile.region)}/gp/customer-reviews`}
                                        required />
                                    {/* <div className="step_help">Still lost? Check out this <HowToVideo show_icon={true} title="How to find link" source="https://dealpanda-public.s3.ca-central-1.amazonaws.com/6.mp4"/></div> */}
                                </Form.Group>
                        <Button type="submit" variant="success" className="btn-submit" disabled={disabledButton} block>{buttonText}</Button>
                        <Button variant="link" className="skip-btn" onClick={this.props.handleJumpStep.bind(this, 2)}>I changed my mind, go back.</Button>
                    </Form>
                </Col>
            </Row>
        );
    }

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        bonusDeal: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        reviewLink: PropTypes.string.isRequired,
        handleJumpStep: PropTypes.func.isRequired
    };
}