import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'

import '../Compare.scss';

export default class CompareStepConfirmShipping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_capability : false, 
            user_participant_agreement: false
        };
    }

    handleCheckbox = (e) => {
        const inputChecked = e.target.checked;
        const inputName = e.target.name;

        this.setState({
            [inputName] : inputChecked
        })
    }

    render() {
        let disableButton = true

            if( this.props.shipping.fullName !== "" && 
                this.props.shipping.address1 !== "" &&  
                this.props.shipping.city !== "" &&
                this.props.shipping.state !== "" &&
                this.props.shipping.zip !== "") {
                    disableButton = false
            }

        return (
            <React.Fragment>
                <Container as="section" className="step-detail walkthrough">
                    <Row>
                        <Col xs={12} md={4} className="walkthrough_image d-none d-sm-none d-md-block">
                            <div className="image-tile">
                                <Image src={this.props.product.product_image_url} />
                                
                                <h5>Your Reward for Participation</h5>
                                <ul>
                                    <li>
                                        Keep the product you test!
                                    </li>
                                    <li>
                                        Plus, earn <strong>{this.props.product.points}</strong> Panda Points
                                    </li>
                                </ul>

                                <h6>Reward Value: ${this.props.product.deal_amount}</h6>

                            </div>
                        </Col>
                        <Col xs={12} md={8}  className="walkthrough_content">
                            <div className="content-header">
                                <h1>{this.props.product.product_name}</h1>
                            </div>
                            <div className="content-body">
                                <h5>Confirm your shipping address</h5>
                                <p >Please confirm the shipping address you’d like us to ship the test product to. </p>
                                <div>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="shippingName">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Your Full Name"  name="fullName" onChange={this.props.handleShippingChange} value={this.props.shipping.fullName}/>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group controlId="shippingAddress1">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control placeholder="1234 Main St" name="address1" onChange={this.props.handleShippingChange} value={this.props.shipping.address1} />
                                    </Form.Group>

                                    <Form.Group controlId="shippingAddress2">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control placeholder="Apartment, studio, or floor" name="address2" onChange={this.props.handleShippingChange} value={this.props.shipping.address2} />
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="shippingCity">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control name="city" onChange={this.props.handleShippingChange} value={this.props.shipping.city} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="shippingState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control as="select" name="state" defaultValue={this.props.shipping.state === "" ? "Choose..." : this.props.shipping.state} onChange={this.props.handleShippingChange} >
                                            <option>Choose...</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                       
                                        </Form.Control>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="shippingZip">
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control name="zip" onChange={this.props.handleShippingChange} value={this.props.shipping.zip} />
                                        </Form.Group>
                                    </Form.Row>
                           

                                </div>
                                <div className="content-agreement">
                                    One week after receiving your Shockproof, Anti-Scratch Clear Apple iPhone Case, you’ll be asked to share your thoughts on the performance of the product with us.
                                </div>
                            </div>

                            <Button className={"btn btn-dp "} disabled={disableButton} onClick={this.props.handleShippingSubmit.bind()} >Submit</Button>
                        </Col>
                    </Row>   
                </Container>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        shipping: PropTypes.object.isRequired,
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        handleShippingSubmit: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        handleShippingChange: PropTypes.func.isRequired
    }
}
