import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
// import CardDeck from 'react-bootstrap/CardDeck';
import PreLoader from '../../snippets/PreLoader'
import LogoAmazon from '../../../assets/images/logo_amazon.png'
import trackingLoader from '../../../utils/tracking-loader'

import './../bonus.scss';

export default class Bonus extends Component {
    constructor(props) {
		super(props);
		this.state = {
            preloader: true,
            page: 1,
            resultsPerPage: 10,
            accordionSelected: 1,
            inProgress: [],
            results: [],
            showInviteTile: false
        }
    }

    accordionToggle = (selected) => {
        this.setState({accordionSelected: selected});
    }

    toReview = (id) => {
        this.props.history.push({
            pathname: "/review/" + id
        });

        trackingLoader.trackEvent(`User selected ${id}`, {
            deal_id: id,
            event_category: "review_product",
            event_label: id
        }); 
    }

    toReferral = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: "/invite"
        });
    }
    
    toProfile = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: "/profile",
            state:"section-amazon-info"
        });
    }

    bonusButton = (id, status) => {
        let title = ""

        if (status === "active") {
            title = "Share My Thoughts"
        } else {
            title = "Processing"
        }

        return (
            <Button variant="success" block>{title}</Button>
        )
    }



    renderTileAmazonProfile() {
        let output = ""

            if((this.props.userProfile.amazon_profile === "" || this.props.userProfile.amazon_profile === null) && this.props.userProfile.riskLevel !== "DEMO" ) {
                 output = 
                 <Card key="amazon-card"  className="bonus-card bonus-card_limited-time bonus-card_amazon" onClick={this.toProfile}>
                    <Card.Body>
                        <div className="card-logo"><img src={LogoAmazon} alt="Amazon Logo"/></div>
                        <div>
                            <div className="card-sub-title">Complete your Profile</div>
                            <Card.Title>Connect your Amazon Profile Page<br/></Card.Title>
                            <Card.Text>
                            We use your shopping profile to connect you to products and brands we think you’ll love testing! Connect your Amazon profile and get <strong>300</strong> panda points.
                            </Card.Text>
                            <Button size="sm" variant="success">Submit Profile</Button>
                            
                        </div>
                    </Card.Body>
                    {/* <Card.Footer>
                        <Link to="/tnc">Terms and Condition</Link>
                    </Card.Footer> */}
                </Card>
            }
            

        return output
    }

    renderTileReferFriend() {
        let output = ""

            if (["RISKY", "TRIAL", "VIVA_REVIEWERS"].indexOf(this.props.userProfile.riskLevel) === -1) {
            output  =     <Card key="referer-card"  className="bonus-card bonus-card_limited-time bonus-card_referral" onClick={this.toReferral}>
                                <Card.Body>
                                    <div>
                                        <div className="card-sub-title">Special Bonus</div>
                                        <Card.Title>
                                            Invite your friends.<br/> Earn more points.
                                        </Card.Title>
                                        <Card.Text>
                                            Sharing is caring. Invite your friends and
                                            get <strong>300</strong> panda points when they claim their first deal.
                                        </Card.Text>
                                        {/* <Button size="sm" variant="success">Invite friends</Button> */}
                                    </div>
                                </Card.Body>
                                {/* <Card.Footer>
                                    <Link to="/tnc">Terms and Condition</Link>
                                </Card.Footer> */}
                            </Card>
            }
            
        return output
    }

    renderEmptyMessage(){
        let msg = "Brands require specific participants for their tests. Don’t worry if you haven’t matched for one yet. There will be more coming up."

            if (this.props.device.isMobile) {
                msg = "Sorry, either there are no mobile tests currently running or you haven’t matched for one yet. Don’t worry, there will be more coming up! Please check back later."
            }

        return (<div className="empty-state">{msg}</div>)
    }

    renderTileReviews() {
        let output = []
            for (let i=0; i<this.props.bonus.results.length; i++) {      
                let platform =  this.props.userProfile.riskLevel !== "DEMO" ? "desktop" : "" //this will be dynamic in the future with either mobile/desktop   
                let disableTileClass = ""
                let buttonText = "Share My Thoughts"
                
                if (platform === "desktop" && this.props.device.isMobile) {
                    disableTileClass = "disabled"
                    buttonText = "Desktop Only"
                }  else if (this.props.bonus.results[i].status !== 'active') {
                    disableTileClass = "disabled"
                }

                output.push(
                    <React.Fragment key={i}>
                        {this.props.bonus.results[i].type === "review" ? (
                            <Card  className={`bonus-card bonus-card_review ${disableTileClass}` } onClick={() => this.toReview(this.props.bonus.results[i]._id)}>
                                <Card.Body id={this.props.bonus.results[i]._id}>
                                <div className="bonus-card_review_top-portion">
                                    <div className="card-sub-title"> {platform} Review</div>
                                    <Card.Title>Collect <span className="bonus-card_point-value">{this.props.bonus.results[i].points}</span> panda points</Card.Title>
                                    <div className="card-img card-img_background-image" style={{backgroundImage: "url(" + this.props.bonus.results[i].product_image_url + ")"}} >
                                        {/* <Card.Img variant="top" src={this.props.bonus.results[i].product_image_url} /> */}
                                    </div>
                                </div>
                                <div className="bonus-card_review_bottom-portion">
                                    
                                    <Card.Subtitle>{this.props.bonus.results[i].name}</Card.Subtitle>
                                    <Card.Text>
                                        {this.props.bonus.results[i].description} 
                                    </Card.Text>
                                </div>
                                </Card.Body>
                                 <Button variant="success" className={`cta`}  block>{buttonText}</Button>
                            </Card>
                        ) : ("") }
                    </React.Fragment>
                );
        }

        return output 
    }

    renderList() {
        let lst = [this.renderTileAmazonProfile(),this.renderTileReviews()];
        if (this.state.showInviteTile) {
            lst.unshift(this.renderTileReferFriend());
        }

        return lst
    }
 
    render() {
        let output = ""

            if(this.props.preloader) {
                output = <PreLoader/>
            } else if (this.props.bonus.results.length > 0 && this.props.userProfile.riskLevel !== "RISKY") {
                output = this.renderList()
            } 

        return output
    }

    static propTypes = {
        device: PropTypes.object,
        userProfile: PropTypes.object, 
        get: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        cool_off: PropTypes.number.isRequired,
        preloader: PropTypes.bool.isRequired,
        bonus: PropTypes.object.isRequired
    };

}