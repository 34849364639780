
export default {
    
    getImageSizeClass(imgURL) {
        const baseURL = process.env.REACT_APP_API_DOMAIN; // Base is used if imgURL is relative 
        const url = new URL(imgURL, baseURL);
        const urlParams = new URLSearchParams(url.search);

        if (urlParams.get('orientation') !== null) {
            return `--${urlParams.get('orientation')}`;
        } else {
            return ""
        }
    },

    getImageSize(url) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject();
            img.src = url;
        });
    },

    async getUrlWithSize(imgURL) {

        let size = await this.getImageSize(imgURL);
        let url = imgURL
            
            if (size.height > size.width) {
                url = `${url}?orientation=tall` 
            } else if (size.width > size.height) {
                url = `${url}?orientation=wide` 
            } else if (size.height === size.width) {
                url = `${url}?orientation=square` 
            } else {
                url = `${url}` 
            }

            return url
    }
}