import React, { Component } from "react";
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Form from "react-bootstrap/Form";

export default class AdminUsersExport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            region: "",
            regions: ["US", "CA", "GB", "DE"]
        }
    }

    handleCindyClick = () =>{
         // call api to download
         this.setState({loading:true});
         this.props.get("/api/v1/user/export_c", {}, true).then(blob=>{
             const url = window.URL.createObjectURL(blob);
             let a = document.createElement("a");
             const now = new Date().toISOString().split('T')[0];
             a.style = "display: none";
             a.href = url;
             a.download = `dealpanda_users_facebook-${now}.csv`; // gives it a name via an a tag
             a.click();
             window.URL.revokeObjectURL(url);
         }).catch((e)=>{
             console.log(e);
         })
         .finally(()=>{
             this.setState({loading:false});
         });
    }

    onClick = () =>{
        // call api to download
        this.setState({loading:true});
        this.props.get("/api/v1/user/export", {}, true).then(blob=>{
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            const now = new Date().toISOString().split('T')[0];
            a.style = "display: none";
            a.href = url;
            a.download = `dealpanda_users_export-${now}.csv`; // gives it a name via an a tag
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch((e)=>{
            console.log(e);
        })
        .finally(()=>{
            this.setState({loading:false});
        });
    }

    handleAmzProfileClick = () => {
        this.setState({loading:true});
        const params = {
            filename: `amazon_profile_stats_${this.state.region}.csv`,
            region: this.state.region
        };
        this.props.get("/api/v1/reports/amzprofilestats/export", params, true).then(blob=>{
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            // const now = new Date().toISOString().split('T')[0];
            a.style = "display: none";
            a.href = url;
            a.download = params.filename; // gives it a name via an a tag
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch((e)=>{
            console.log(e);
        })
        .finally(()=>{
            this.setState({loading:false});
        });
    }

    handleAmzVerifiedReviewClick = () => {
        this.setState({loading:true});
        const now = new Date().toISOString().split('T')[0];
        const params = {
            filename: `amazon_verified_ratios_${now}.csv`,
            region: this.state.region,
            report_type: "verified_reviews"
        };
        this.props.get("/api/v1/reports/amzprofilestats/export", params, true).then(blob=>{
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.style = "display: none";
            a.href = url;
            a.download = params.filename; // gives it a name via an a tag
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch((e)=>{
            console.log(e);
        })
        .finally(()=>{
            this.setState({loading:false});
        });
    }

    handleRegionChange = (e) => {
        e.preventDefault();
        this.setState({region: e.target.value});
    }

    render = () => {
        let regionOptions = [];
        this.state.regions.forEach(r => {
            regionOptions.push(
                <option key={`region-${r}`} value={r}>{r}</option>
            );
        });
        return (
            <div>
                <section className="export" >
                    <h1 style={{color:'white'}}> Export all Users</h1>
                    <p >Use below button to export all users and their related attributes</p>
                    <Button  variant="success" onClick={this.onClick} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Export'}</Button>
                </section>


                <section className="export" >
                    <h1 style={{color:'white'}}> Export Users (Source, First redeem)</h1>
                    <p >Use below button to export all users and their related source and first redeem info</p>
                    <Button  variant="success" onClick={this.handleCindyClick} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Export'}</Button>
                </section>

                <section className="export" >
                        <h1 style={{color:'white'}}> Export Amazon Profile Stats</h1>
                        <p >Use below button to export amazon profile data for existing users and invites</p>                        
                        <Form.Group as={Col} md={{span: 2, offset: 5}}>
                            <Form.Control as="select" name="region" value={this.state.region} onChange={this.handleRegionChange}>
                                <option value=""> - All Regions - </option>
                                {regionOptions}
                            </Form.Control>
                        </Form.Group>
                        <Button  variant="success" onClick={this.handleAmzProfileClick} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Export'}</Button>
                </section>

                <section className="export" >
                        <h1 style={{color:'white'}}> Export Verified Review Ratios</h1>
                        <p >Use below button to export amazon profile review data</p>                        
                        <Button  variant="success" onClick={this.handleAmzVerifiedReviewClick} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Export'}</Button>
                </section>
            </div>
        );   
    }

}