import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import CopyDisabledComponent from '../../../utils/CopyDisabledComponent';
import RETAILS from '../../../utils/retails'
import '../Compare.scss';

import amzURLBuilder from '../../../utils/admin-regions'

export default class FirstStep extends Component {

    render() {
        let domain = ""

            if (this.props.product.deal_channel === "AMAZON") {
                domain = amzURLBuilder.getDomain(this.props.userProfile.region) 
            } else {
                domain = <strong><a className="link--override" href={`https://${RETAILS.getDomain(this.props.product.deal_channel)}`} target="_blank" rel="noopener noreferrer">{RETAILS.getDomain(this.props.product.deal_channel)}</a></strong> 
            }
            
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--search">   
                        <Col xs={12} md={{ span: 5, offset: 1 }}  className="step-detail_image">
                            <div className="step-detail_image step-detail_image-wrapper">
                                <CopyDisabledComponent content={this.props.step.dealKeyword} tagname='span' className='keywords' />
                                <Image src={`https://s3.amazonaws.com/assets.dealpanda.com/search-bar_${this.props.product.deal_channel.toLowerCase()}.jpg`} />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current}</h2>
                            <ul>
                                <li>Open up a new tab on your browser</li>
                                <li>Head to {domain}. </li>
                                <li>Search for "<CopyDisabledComponent content={this.props.step.dealKeyword} tagname="strong" />"</li>
                            </ul>
                            {/* <div className="step_help">Need Help ? <HowToVideo show_icon={true} title="Search on Amazon" source="https://dealpanda-public.s3.ca-central-1.amazonaws.com/1.mp4"/></div> */}
                        </Col>
                </Row>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        userProfile: PropTypes.object
    }
}
