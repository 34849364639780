import React, { Component } from 'react';
import Body from '../../utils/utils-body'
import Content from '../snippets/ContentPrivacy';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Tnc.scss';

class PrivatePolicy extends Component {

    componentDidMount = () => {
        Body.pageClass("add" , "page_privacy-policy")
    }

    render() {
        return (
            <Container as="main" className="page-narrow">
                <Row>
                    <Col  className="page-header">
                        <h1 className="display-3">Privacy Policy</h1>
                        <p className="lead">General Member Questions</p>
                    </Col>
                </Row>
                <Row>
                    <Content/>
                </Row>
            </Container>
        );
    }
}

export default PrivatePolicy;
