import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import './../apply.scss';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default class StepThankYou extends Component {

    render() {
        let output = null;
        
        
        if (this.props.ndaAgreement === "disagree") {
            output = 
                <Container as="section" className="page-header">
                    <h1 className="mb-4">Thank You For Your Interest.</h1>
                    <p> Unfortunately, you do not qualify to join Deal Panda or earn rewards. Agreeing to the Mutual Non-Disclosure Agreement, Terms and Conditions, and Privacy Policy are a requirement.</p>
                    <p>Thanks again for your interest.</p>
                </Container> 
        } else if (this.props.result.status === "account-exist") {
            output = 
                <Container as="section" className="page-header ">
                    <h1 className="mb-4">The email address {this.props.userEmail} is already registered to Deal Panda. </h1>
                    <Button variant="success" onClick={ ()  => (this.props.history.push({pathname: "/login?email="+this.props.userEmail}))}>Log in as <strong><div>{this.props.userEmail}</div></strong> </Button>
                    <small className="mt-2 d-block">Did you <a href={"/login?forgotpassword=true&email="+this.props.userEmail} rel="Deal Panda"> forget your password</a>?</small>
                </Container>
        } else {
             output = 
                <Container as="section" className="page-header">
                    <FontAwesomeIcon  size="4x" icon={faCheckCircle} fixedWidth />
                    <h1 className="mb-4">Thank you</h1>
                    <p> We are reviewing your tester profile.</p>
                    <p>Keep an eye on your email inbox that is associated with your facebook account! Once approved, you will get an email invitation to start participating in tests and earning rewards.</p>
                    <p>We’ve sent you a sign-up confirmation email. Add <strong>support@dealpanda.com</strong> to your address book or safe senders list to ensure you receive our updates.</p>
                </Container> 
        }

        return output
    }
    
    static propTypes = {
        reset: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        ndaAgreement: PropTypes.string.isRequired,
        stepResults: PropTypes.array.isRequired,
        result: PropTypes.object.isRequired,
        userEmail: PropTypes.string
    }
}