import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import PreLoader from '../snippets/PreLoader';
import Button from 'react-bootstrap/Button';
import adminRegions from '../../utils/admin-regions';
import Flags from '../snippets/flags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSpinner } from '@fortawesome/free-solid-svg-icons';


export default class AdminAmzProfileStats extends Component {

    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        
        const now = new Date();
        let startDate = params.get('startDate');
        if (startDate !== null) {
            startDate = this.parseDate(startDate, now);
        } else {
            startDate = new Date(now.getTime());
            startDate.setDate(now.getDate() - 30);
        }
        let endDate = params.get('endDate');
        if (endDate !== null) {
            endDate = this.parseDate(endDate, now);
        } else {
            endDate = now;
        }

        console.log(startDate.toISOString().slice(0,10));

        this.state = {
            isLoading: true,
            isProcessing: false,
            stats: {},
            region: "US",
            inputs: {},
            statsTable: params.get("table") === null ? "": params.get("table"),
            statsDateStart: startDate.toISOString().slice(0, 10),
            statsDateEnd: endDate.toISOString().slice(0, 10)
        }
    }

    componentDidMount = () => {
        this.loadPage();
    }

    fetchProfileStats = () => {
        const params = {
            region: this.state.region,
            table: this.state.statsTable,
            start_date: this.state.statsDateStart,
            end_date: this.state.statsDateEnd
        }
        return this.props.get("/api/v1/reports/amzprofilestats", params).then(response => {
            return Promise.resolve(response.results);
        }).catch(err  => {
            console.error(err);
            return Promise.reject([]);
        });
    }

    loadPage = () => {
        this.fetchProfileStats().then(results => {
            let inputs = this.state.inputs;
            if (!results.criteria.hasOwnProperty("min_rank_override")) {
                results.criteria.min_rank_override = {
                    count: 0,
                    filter: -1
                }
            }
            for (let c in results.criteria) {
                inputs[c] = results.criteria[c].filter;
            }

            this.setState({
                stats: results,
                inputs: inputs,
                isLoading: false,
                isProcessing: false
            });
        });
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let criteria = this.state.inputs;
        criteria[inputName] = inputValue;
        this.setState({
            inputs: criteria
        });
    }

    handleDateChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({
            [inputName]: inputValue,
            isLoading: true
        }, () => {
            this.loadPage()
        });
    }

    handleRegionChange = (e) => {
        this.setState({
            region: e.target.value,
            isLoading: true
        }, () => {
            this.loadPage();
        });
    }

    handleClickAnalyze = (e) => {
        e.preventDefault();
        this.setState({
            isProcessing: true
        });
        let formData = new FormData();
        formData.append("criteria", JSON.stringify(this.state.inputs));
        formData.append("region", this.state.region);
        return this.props.post("/api/v1/reports/amzprofilestats", formData).then(response => {
            this.loadPage();
        }).catch(err  => {
            console.error(err);
            this.setState({
                isProcessing: false
            })
        });
    }

    parseDate = (dateStr, defaultDate) => {
        try {
            return new Date(dateStr);
        } catch(ex) {
            console.warn(ex.message);
        }
        return defaultDate;
    }

    renderList = () => {
        let lst = [];
        
        for (let c in this.state.stats.criteria) {
            if (c === "min_rank_override") {
                continue;
            }
            let ratio = Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.criteria[c].count / this.state.stats.total_processed);
            lst.push(
                <tr key={c}>
                    <td colSpan={2}>{c} <span>({this.state.stats.criteria[c].filter}</span>)</td>
                    <td>
                        <Form.Group>
                            <Form.Control name={c} type="number" value={this.state.inputs[c]} onChange={this.handleInputChange} required readOnly={this.state.isProcessing} />
                        </Form.Group>
                    </td>
                    <td>{this.state.stats.criteria[c].count}</td>
                    <td>{ratio}</td>
                </tr>
            );
        }

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="3"><em>No items found</em></td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {

        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <React.Fragment>
                    
                    <section>
                        <div className="table-search_wrapper --no-search">
                            <div className="table-search_filter-wrapper">
                                <span>Filter By Region:</span>
                                <div className="custom-select">
                                    <Form.Control className="table-search_filter" as="select" value={this.state.region} name="region"  onChange={this.handleRegionChange} disabled={this.state.isLoading}>
                                        {adminRegions.options()}
                                    </Form.Control>
                                </div>
                            </div>
                        </div>
                        <Table className="-highlight">
                            <tbody>
                                <tr>
                                    <td colSpan={3}>Total Analysis Complete <small>(exising users + pending invites)</small></td>
                                    <td>{this.state.stats.total_processed}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Total at least one review</td>
                                    <td>{this.state.stats.total_with_reviews}</td>
                                    <td>{Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.total_with_reviews / (this.state.stats.total_processed + this.state.stats.invites_pending))}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Total Passed Automatic Analysis</td>
                                    <td>{this.state.stats.total_passed}</td>
                                    <td>{Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.total_passed / this.state.stats.total_processed)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Invites Analysis Complete <small>(pending invites)</small></td>
                                    <td>{this.state.stats.invites_processed}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Invites at least one review</td>
                                    <td>{this.state.stats.invites_with_reviews}</td>
                                    <td>{Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.invites_with_reviews / (this.state.stats.invites_processed + this.state.stats.invites_pending))}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <Form.Control type="date" name="statsDateStart" value={this.state.statsDateStart} onChange={this.handleDateChange} className="mr-3" />
                                    </td>
                                    <td>
                                        <Form.Control type="date" name="statsDateEnd" value={this.state.statsDateEnd} onChange={this.handleDateChange} />
                                    </td>
                                    <td colSpan={2}></td>
                                </tr>
                                <tr>
                                    <td colSpan={1}>Invites Passed Automatic Analysis <small>(potential new users)</small></td>
                                    <td>{this.state.statsDateStart}</td>
                                    <td>{this.state.statsDateEnd}</td>
                                    <td>{this.state.stats.invites_passed}</td>
                                    <td>{Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.invites_passed / this.state.stats.invites_processed)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={1}>Passed Manual Analysis <small>(new users)</small></td>
                                    <td>{this.state.statsDateStart}</td>
                                    <td>{this.state.statsDateEnd}</td>
                                    <td>{this.state.stats.invites_approved}</td>
                                    <td>{Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.invites_approved / this.state.stats.invites_processed)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Queued for Analysis | Data Extraction</td>
                                    <td>{this.state.stats.invites_pending} | {this.state.stats.invites_pending_profile}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={5}><Flags region={this.state.region} /> </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>Criteria (all {this.state.region} profiles)</td>
                                </tr>
                                <tr>
                                    <td colspan={2}>Min Rank Override <small>(ignore other criteria)</small></td>
                                    <td>
                                        <Form.Group>
                                            <Form.Control name="min_rank_override" type="number" value={this.state.inputs["min_rank_override"]} onChange={this.handleInputChange} />
                                        </Form.Group>
                                    </td>
                                    <td>{this.state.stats.criteria.min_rank_override.count}</td>
                                    <td>{Intl.NumberFormat("en-US", {style: "percent"}).format(this.state.stats.criteria.min_rank_override.count / this.state.stats.total_processed)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={1}><hr /></td>
                                    <td>OR</td>
                                    <td colSpan={3}><hr /></td>
                                </tr>
                                {this.renderList()}
                            </tbody>
                        </Table>
                    </section>
                    <section>
                        <Button variant="primary" type="button" block onClick={this.handleClickAnalyze} disabled={this.state.isProcessing}>
                            {this.state.isProcessing ? <FontAwesomeIcon icon={faSpinner} fixedWidth spin /> : ""}
                            Run Analyzer
                        </Button>
                        <div className="text-small">
                            * Rerun analyzer on exising profile data. This will not get profile data for new invites.
                        </div>
                    </section>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        history: PropTypes.object,
        userProfile: PropTypes.object.isRequired
    }
}