import React, { Component } from 'react';
import smartlookClient from 'smartlook-client'
import PropTypes from "prop-types";
import Flags from '../../snippets/flags' 
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import dayjs from 'dayjs'
import PreLoader from '../../snippets/PreLoader'
import trackingLoader from '../../../utils/tracking-loader'
import Currency from '../../../utils/currency'
import RETAILS from '../../../utils/retails'
import IMAGE from '../../../utils/image'
import Util from '../../../utils/utils'
import PandaCoin from '../../snippets/panda-coin'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faMobileAlt} from '@fortawesome/free-solid-svg-icons'

import './../Deal.scss'

export default class Deal extends Component {
    constructor(props) {
		super(props);
		this.state = {
            inProgress: []
        }
    }

    componentDidMount = () => {
        // this.fetchDeals(this.state.page, this.state.resultsPerPage);

        if (this.props.userProfile._id) {
            this.fetchLocalStorage(this.props.userProfile._id);
        }
    }
    
    componentDidUpdate = (prevProps) => {
        
        if (prevProps.userProfile !== this.props.userProfile) {
            this.fetchLocalStorage(this.props.userProfile._id);
        }
    }

    fetchLocalStorage = (userId) => {
        let dealId = []
        
        for(let i=0; i<localStorage.length; i++) {
            let key = localStorage.key(i);
            
            // 'include' is not supported in IE 11
            if (key.includes(userId)) {
                let value = JSON.parse(localStorage.getItem(key))
                if (value["comp_answer"] !== undefined ) {
                    dealId.push(key.split('_')[1])
                } 
            }
        }
        this.setState({inProgress:dealId})
    }

    // fetchDeals = (page, limit) => {
    //     return this.props.get("/api/v1/deals", {page: page, limit: limit}).then(response => {
    //         const prevDeals = this.props.deals;
    //         this.setState({
    //             deals: prevDeals.concat(response.deals),
    //             preloader: false,
    //             cool_off: response.cool_off
    //         });
    //     });
    // }

    toDeals = (deal) => {
        let path = `/tests/${deal._id}`

        if(this.props.userProfile.riskLevel === "DEMO") {
            path = `/survey-test/${deal._id}`
        }

        this.props.history.push({
            pathname: path
        });


        // Tracking Purposes
        let slParam = {
            id: deal._id,
            region: deal.deal_region,
            channel: deal.deal_channel,
            name: deal.product_name,
            type: deal.deal_type
            // other custom properties
        }
        
        if (deal.hasOwnProperty("proof_of_review_date")) {
            slParam["proof_of_review_date"] = deal.proof_of_review_date
        }   
        
        smartlookClient.track('Dasboard - Selected Deal', slParam)
    
        trackingLoader.trackEvent(`User selected ${deal._id}`, {
            deal_id: deal._id,
            event_category: "test_product",
            event_label: deal._id
        }); 
     
    }

    dealBadge(deal) {

        let name = "";
        let className = ""
        let output = ""

        if(className !== "") {
            output = 
            <div className={ "deal-badge badge--" + className }>
                {name}
            </div>
        }

        if (deal.deal_badge === "panda_choice") {
            name = "Panda's Choice";
            className = "black";
        } else if  (deal.deal_badge === "new") {
            name = <React.Fragment><strong>New</strong></React.Fragment>
            className = "green";
        } else if  (deal.deal_badge === "recruiting") {
            name = <React.Fragment><strong>Recruiting</strong></React.Fragment>
            className = "black";
        } else if  (deal.deal_badge === "ending_soon") {
            name = <React.Fragment><strong>Ending</strong> Soon</React.Fragment>
            className = "red";
        } else if  (deal.deal_badge === "last_chance") {
            name = <React.Fragment><strong>Last</strong> Chance</React.Fragment>
            className = "orange";
        } else if  (deal.deal_badge === "hot_deal") {
            name = <React.Fragment><strong>Hot</strong> Deal</React.Fragment>
            className = "red";
        } else if  (deal.deal_badge === "best_seller") {
            name = <React.Fragment><strong>Best</strong> Seller</React.Fragment>
            className = "orange";
        }

        return output
    }

    // dealStatus(deal) {
    //     let name = "";
    //     let className = "";

    //     if (deal.deal_status === "submitted") {
    //         name = "Payment Processing";
    //         className = "deal-status--claimed";
    //     } else if  (deal.deal_status === "out_of_stock") {
    //         name = "Test Full"©
    //         className = "deal-status--out-of-stock";
    //     }else if  (deal.deal_coming_soon === true) {
    //         name= "Recruiting Soon"
    //         className = "deal-status--coming-soon";
    //     }else if (deal.deal_status === 'paid'){
    //         name = "Paid";
    //         className = "deal-status--claimed";
    //     } else {
    //         name = "New Test"
    //         className = "deal-status--new"
    //     }

    //     return (
    //         <span className={"deal-status " + className } >{name}</span>
    //     )
    // }

    getTags(deal) {

        let platform = []

        // TODO: backend to include deal.platnform
        deal.platform = 'desktop'
        // TODO: backend to include deal.platnform

        if (deal.platform === 'desktop' ){
            platform.push(
                <OverlayTrigger
                    placement={"top"}
                    key="0"
                    overlay={
                    <Tooltip className="card-body_tags-tooltip" >
                       <div> Desktop</div>
                    </Tooltip>
                    }
                >
                    <FontAwesomeIcon key="2" icon={faDesktop} size="sm" fixedWidth /> 
                </OverlayTrigger>
            )
        } else if (deal.platform === 'mobile'){
            platform.push(
                <OverlayTrigger
                    placement={"top"}
                    key="0"
                    overlay={
                    <Tooltip className="card-body_tags-tooltip" >
                       <div>Mobile</div>
                    </Tooltip>
                    }
                >
                    <FontAwesomeIcon key="2" icon={faMobileAlt} size="sm" fixedWidth /> 
                </OverlayTrigger>
            )
        }
       
        return platform 
    }

    dealButton(deal) {
        // TODO: backend to include deal.platnform
        deal.platform = 'desktop'
        // TODO: backend to include deal.platnform


        let output = ""
        let param = {}


        if (deal.platform === 'desktop' && this.props.device.isMobile && deal.deal_risk_level !== "DEMO"){

            param = {
                variant : "success",
                classValue : "disabled",
                text : "Only on Desktop"
            }
            deal.platform_match = false;
        } else if (deal.platform === 'mobile' && !this.props.device.isMobile && deal.deal_risk_level !== "DEMO"){
             param = {
                variant : "success",
                classValue : "disabled",
                text : "Only on Mobile"
            }
            deal.platform_match = false;
        } else if (deal.deal_status === "submitted") {
            if (["SFBR","CFBR"].includes(deal.deal_type)) {
                let proofOfPurchase = deal.hasOwnProperty("proof_of_purchase_date") ? deal.proof_of_purchase_date : ""
                let proofOfReview = deal.hasOwnProperty("proof_of_review_date") ? deal.proof_of_review_date : ""

                let futureDate = dayjs(proofOfPurchase).add(7, "day").format();
                    // This is fail safe incase date is NAN
                    if(isNaN(Date.parse(futureDate))) {
                        futureDate = dayjs(new Date()).add(7, "day").format()
                    }

                let remainingDate = dayjs(futureDate).diff(dayjs(), "days")
                if (proofOfPurchase !== "" && proofOfReview.toLowerCase() === "none") {
             
                    if (remainingDate < 1) {
                        param = {
                            variant : "success",
                            text : `Upload Review`
                        }
                    } else {
                        param = {
                            variant : "success",
                            text : `Upload Review in ${remainingDate} days`
                        }
                    }
                } else if (proofOfPurchase !== "" && proofOfReview !== "") {
                    param = {
                        variant : "link",
                        classValue : "--processing",
                        text : "Payment Processing"
                    }
                } else {
                    param = {
                        variant : "success",
                        text : "Missing Proof Of Purchase"
                    }
                }
            } else {
                param = {
                    variant : "link",
                    classValue : "--processing",
                    text : "Payment Processing"
                }
            }
           
        } else if (deal.deal_coming_soon) {
            param = {
                variant : "link",
                classValue : "--pending",
                text : "Recruiting Soon"
            }
        } else if (deal.deal_status === "out_of_stock") {
            param = {
                variant : "link",
                text : "Survey Full"
            }
        } else if (deal.deal_status === "cool_down") {
            param = {
                variant : "link",
                text : `Please wait ${this.state.cool_off} minute(s) before starting another product test.`
            }
        } else {
            if (this.state.inProgress.indexOf(deal._id) > -1) {
                param = {
                    variant : "success",
                    text : "Continue Survey "
                }
            } else {
                param = {
                    variant : "success",
                    text : "Start Survey "
                }
            }     
        }

        if(param.variant ==="success") {
            output =
            <Button variant={param.variant} className={`cta ${param.classValue}`} block>{param.text}</Button>
        } else {
            output =
            <Button variant={param.variant} className={`cta ${param.classValue}`} block>{param.text}</Button>
        }
    
        return output 
    }

    dealStockLevelMeter = (deal) => {
        // let percentage = (10 / 10 * 100).toFixed();
        let percentage = (deal.daily_claimed / deal.deal_daily_limit * 100).toFixed();
        let urgencyLevel = ""
        let description = ""

            if (percentage >= 1 && percentage <= 49) {
                urgencyLevel = "success"
                description = "Claim it first"
            } else if (percentage >= 50 && percentage <= 79) {
                urgencyLevel = "warning"
                description = "Hurry before it's all gone"
            } else if (percentage >= 80 && percentage <= 99) {
                urgencyLevel = "danger"
                description = "Almost all claimed"
            } else if (percentage === "100") {
                urgencyLevel = "danger"
                description = "Check back tomorrow"
            }

            return  (   
                    <div className="progress_wrapper">
                        <ProgressBar variant={urgencyLevel} animated striped now={percentage} />
                        <div className="progress_text">
                            <span className="progress_text-percentage">{percentage}% Claimed</span>
                            <span className="progress_text-description">{description}</span>
                        </div>
                    </div>
                        
                    )
                
    }

    dealStockLevel = (deal) => {

            if (deal.stock <= 0 || deal.deal_coming_soon || deal.deal_status === "submitted" ){
                return (
                    <p className="deal-stock-message"></p>
                )
            }else{
                return  (   
                    <p className="deal-stock-message">Recruting <span>{deal.stock}</span> more {deal.stock <= 1 ? "tester" : "testers"} .</p>
                )
                
            } 
    }

    renderEmptyMessage(){
        let msg = "Brands require specific participants for their tests. Don’t worry if you haven’t matched for one yet. There will be more coming up."

            if (this.props.device.isMobile) {
                msg = "Sorry, either there are no mobile tests currently running or you haven’t matched for one yet. Don’t worry, there will be more coming up! Please check back later."
            }

        return (<div className="empty-state">{msg}</div>)
    }

    renderList() {

        let lst = [];

        for (let i=0; i<this.props.deals.length; i++) {
                let deal = this.props.deals[i];
                let dealStatusClass = ""
                // let demo = this.props.userProfile.riskLevel === "DEMO" // If Demo anything we want to be hidden should be hidden 

                // TODO: backend to include deal.platnform
                deal.platform = 'desktop'
                // TODO: backend to include deal.platnform
                
                if ( (deal.platform === 'desktop' && this.props.device.isMobile && deal.deal_risk_level !== "DEMO") ||
                    (deal.platform === 'mobile' && !this.props.device.isMobile && deal.deal_risk_level !== "DEMO")){
                    dealStatusClass = "disabled";
                } else if (['paid', "cool_down"].indexOf(deal.deal_status) >= 0 || deal.deal_status === "out_of_stock") {
                    dealStatusClass = "disabled paid";
                } else if (deal.deal_status === 'submitted') {
                    if (["SFBR","CFBR"].includes(deal.deal_type)) {
                        let proofOfPurchase = deal.hasOwnProperty("proof_of_purchase_date") ? deal.proof_of_purchase_date : ""
                        let proofOfReview = deal.hasOwnProperty("proof_of_review_date") ? deal.proof_of_review_date : ""
                        if (proofOfPurchase !== "" && proofOfReview.toLowerCase() !=="none") {
                            dealStatusClass = "disabled paid";
                        }
                    } else {
                        dealStatusClass = "disabled paid";
                    }
                }  else if  (deal.deal_coming_soon && !this.props.isAdmin) {
                    dealStatusClass = "--inactive";
                } else if ( deal.deal_status === "cool_down" ){
                    dealStatusClass = "--inactive"
                } else if (deal.deal_status === "active"){
                    dealStatusClass = "new-deal"
                } 

                let dealStats = "";
                
                    if (this.props.isAdmin) {
                        dealStats = 
                        <div className="admin_ready-only " >
                            <Flags region={deal.deal_region}/>
                            <p><strong>Admin Only</strong></p>
                            <ul>
                                <li> <strong>Id</strong> : {deal._id}</li>
                                <li> <strong>Risk level</strong> : {deal.deal_risk_level}</li>
                                <li> <strong>Claimed for today</strong> : {deal.daily_claimed}</li>
                            </ul>
                        </div>
                    }

                let deviceSpecficMsg = ""

                    if (this.props.device.isMobile && deal.deal_risk_level !== "DEMO") {
                        deviceSpecficMsg = <small className="d-block text-center mb-2 pr-2 pl-2">This test must be completed on desktop. Please log in from your computer to start this test.</small>
                    }

                let productImageClass = IMAGE.getImageSizeClass(deal.product_image_url)

                let dealDescription = "Temporarily out of stock. Products are re-stocked daily. Check back soon!"

                    if (deal.deal_quantity >= 1 && deal.deal_status !== 'out_of_stock') {
                        dealDescription = deal.deal_description
                    }

                let cornerRibbon = ""

                    // If 25 days has passed don't show ribbon
                    if (deal.deal_status === "active" && dayjs().diff(deal.created_at, 'day') <= 25) {
                        // Need to add a ribbon here to remove it if you clicked through it or a time frame as passed
                        cornerRibbon = <div className="corner-ribbon"><div className="ribbon-green">New</div></div>
                    }        

                let retail = ""
                    if(deal.hasOwnProperty("deal_channel") > 0) {
                        retail = deal.deal_channel
                    }else {
                        retail = "AMAZON"
                    }

                if (deal.deal_status !== "paid" && deal.deal_status !== "cancelled") {
                    lst.push(
                        <React.Fragment key={i}>               
                            <Card  className={"deal-item " + dealStatusClass } onClick={this.toDeals.bind(this, deal)} id={this.props.isAdmin ? deal._id : "-"}>
                                {dealStats}
                                {cornerRibbon}
                                {/* {deal.deal_badge !== "" ? this.dealBadge(deal) : null} */}
    
                                <div className={`card-image ${productImageClass}`} style={{backgroundImage: "url(" + deal.product_image_url + ")"}} >    
                                    {/* <Card.Img variant="top" src={deal.product_image_url} alt={deal.product_name}/> */}
                                </div>
                                
                                <Card.Body id={deal._id}>
                                    <div className="card-body_top-items">
                                        <div className="card-body_top-items_mobile-wrapper">
                                            <div className="card-body_mobile-image">
                                                <Card.Img variant="top" src={deal.product_image_url} alt={deal.product_name}/>
                                            </div>
                                            <div className="card-body_mobile-description">
                                                <div className="card-body_tags">
                                                    <div className="tags_left-side"> 
                                                        <img className={`tags_left-side --${retail}`} alt={retail} src={RETAILS.getLogo(retail, "sm")}/> 
                                                    </div>
                                                    <div className="tags_right-side">
                                                        {/* Need to finish this if there is mobile and desktop surveys */}
                                                        {this.getTags(deal)}
                                                    </div>
                                                </div>
                                                <Card.Title>{Util.trunc(deal.deal_name, 60)}</Card.Title>
                                                <div className="deal_content deal_content--reward">
                                                    <h5 className="deal_content-title">Reward Value</h5>
                                                    <div className="reimburserment">
                                                        <span > {Currency.getCurrency(this.props.userProfile.region)}{deal.deal_amount}<sup>*</sup></span> + <span className="dp-points"><PandaCoin/>{deal.points}</span>
                                                    </div>
                                                    <div className="disclaimer">*Full reimbursement on product cost and earn valuable Panda Points.</div>
                                                </div>
                                                <div className="deal_content deal_content--description">
                                                    <h5 className="deal_content-title">Product Description</h5>
                                                    <p>{Util.trunc(dealDescription, 250)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Card.Body>
                                 <div className="card-body_bottom-items">
                                        {this.dealStockLevel(deal)} 
                                         {deviceSpecficMsg}
                                        {this.dealButton(deal)}
                                       
                                        {/* {this.dealStockLevelMeter(deal)} */}
                                    </div>
                            </Card>
                        </React.Fragment>
                    );
                }
                
        }

        return lst
    }


    render() {
        let output = ""
            if(this.props.preloader) {
                output = <PreLoader/>
            } else if (this.props.deals.length > 0 && this.props.userProfile.riskLevel !== "RISKY") {
                output = this.renderList()
            } 
            
        

        return output
    }

    static propTypes = {
        isAdmin: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        get: PropTypes.func.isRequired,
        userProfile : PropTypes.object.isRequired,
        device: PropTypes.object.isRequired,
        deals: PropTypes.array.isRequired,
        cool_off: PropTypes.number.isRequired,
        preloader: PropTypes.bool.isRequired
    };

}