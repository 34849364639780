import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

export default class ModalForm extends Component {

    render() {
        let msg =  null;
        let modalButton = null;
        let modalClass = this.props.className;
        let modalContent = this.props.content;
        let modalTitle = this.props.title;
        let modalSize = this.props.size === "" ? "sm" : this.props.size;
        // let submitButton = this.props.modalIsProcessing ? "Submitting..." : "Submit"
        
        if (this.props.type === "success" ) {
            // success

            if(this.props.button === "payment") {
                let submitButton = this.props.modalIsProcessing ? "Processing..." : "Paid"
                modalButton = 
                <React.Fragment>
                    <Button variant="primary" className="btn-dp" onClick={ this.props.submit}  disabled={this.props.modalIsProcessing} block>{submitButton}</Button>
                    <Button variant="link" onClick={this.props.closeModal}>Close</Button>
                </React.Fragment>
            } else {
                let submitButton = this.props.modalIsProcessing ? "Submitting" : "Submit"
                modalButton = 
                <React.Fragment>
                    <Button variant="primary" className="btn-dp" onClick={this.props.submit} disabled={this.props.modalIsProcessing} block>{submitButton}</Button>
                    <Button variant="link" onClick={this.props.closeModal}>Close</Button>
                </React.Fragment>
            }
            
        } else if (this.props.type === "error" ) {
            modalButton = 
            <React.Fragment>
                <Button variant="primary" className="btn-dp" onClick={this.props.retry} disabled={this.props.modalIsProcessing} block>Try Again</Button>
                <Button variant="link" onClick={this.props.closeModal}>Close</Button>
            </React.Fragment>
        } else {
            // regular
            modalButton = 
            <React.Fragment>
                {/* <Button variant="link" onClick={this.props.closeModal}>Close</Button> */}
                <Button variant="primary" className="btn-dp" onClick={this.props.closeModal} disabled={this.props.modalIsProcessing} block>Close</Button>
            </React.Fragment>
        }
            

        return (
            
            <Modal className={"modal-form " + modalClass } show={this.props.show} size={modalSize} onHide={this.props.closeModal} centered>
                <Form onSubmit={this.props.submit}>
                    <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalContent}
                    </Modal.Body>
                    <Modal.Footer>
                        {modalButton}
                    </Modal.Footer>
                    {msg}
                </Form>
            </Modal>
        );
    }

    static propTypes = {
        state: PropTypes.object,
        className: PropTypes.string,
        size: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
        retry: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.object,
        button:PropTypes.string,
        modalIsProcessing: PropTypes.bool,
        type: PropTypes.string.isRequired
    };
}