export default class utilsBody{
    static pageClass(state, classValue){

        if (state === "add") {
            document.body.className=classValue
        }else {
            document.body.className=""
        }
        // document.body.classList.add(`page_djksdj ksdj skjd`);
        // document.getElementById('body').class='darktheme dfdfd'

        // let prefix = "page_"
        // let prevPage = (document.body.classList.value).split(prefix).pop();
            
        //     if (document.body.classList.value.includes(prefix)) {
        //         document.body.classList.replace(prefix + prevPage , prefix + currentPage);
        //     } else {
        //         document.body.classList.add(prefix + currentPage);
        //     }
    
    }
}

