import React, { Component } from 'react';
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";

export default class userType extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
            region: "CA"
        }
    }

    renderBadge = (userType) => {
         let output = ""
                if (userType === "NOT_RISKY") {
                    output =
                    <Badge variant="light">Regular </Badge>
                } else if (userType === "VIVA_REVIEWERS") {
                    output =
                    <Badge variant="primary">Viva Reviewers</Badge> 
                } else if (userType === "TRIAL") {
                    output =
                    <Badge variant="warning">{userType}</Badge> 
                } else {
                    output =
                    <Badge variant="danger">{userType} </Badge> 
                }

            return output

    }

    render() {
        let output = ""
            if (this.props.type === "badge") {
                output = this.renderBadge(this.props.user)
            } else {
                output = this.props.user
            }

            return output
    }
    
    static propTypes = {
        type: PropTypes.string,
        user: PropTypes.string
    }
}
