import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PasteDisabledInput from './../../../utils/PasteDisabledInput'
import CopyDisabledComponent from '../../../utils/CopyDisabledComponent';
// import Util from './../../../utils/utils';
import '../Compare.scss';

export default class OurProductStep extends Component {
    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    componentDidUpdate(){
        this.focus('input[name="deal_product_page"]')
    }

    componentDidMount(){
        this.focus('input[name="deal_product_page"]')
    }
    

    render() {
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--competitors">
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper --portrait" style={{backgroundImage: `url(${this.props.product.product_image_in_search_url})`}}>
                                {/* <CopyDisabledComponent content={<Image src={this.props.competitor.image} />} tagname="strong" /> */}
                            </div>
                          
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current} </h2>
                            <ol>
                                <li>Go back to the <CopyDisabledComponent content={this.props.step.dealKeyword} tagname="strong" /> {this.props.product.deal_type === "CFBR" ? "category" : "search"} results page.</li>
                                <li>
                                    <p>On the results page, browse through the products to locate the &quot; <CopyDisabledComponent content={this.props.product.product_name} tagname="strong" />&quot; </p>
                                     <div className="step_additiona-note">
                                        You may need to browse through several pages of {this.props.product.deal_type === "CFBR" ? "category" : "search"} results. Scroll to the bottom and click through to the next page if you can't find the product.
                                     </div>
                                </li>
                            </ol>
                            <Form.Group controlId={this.props.step.current}>                            
                                <Form.Label>On what page of the {this.props.product.deal_type === "CFBR" ? "category" : "search"} results for <CopyDisabledComponent content={this.props.step.dealKeyword} tagname="strong" /> is this product on?</Form.Label>
                                <PasteDisabledInput 
                                    type="number" 
                                    name="deal_product_page"
                                    placeholder="Page number" 
                                    onChange={this.props.handleInputChange}
                                    value = {this.props.step.deal_product_page}
                                    minNumber={1}
                                    required
                                />
                            </Form.Group>                   
                            {/* <div className="step_help">Need Help? <HowToVideo show_icon={true} title="Search for Amazon products" source="https://dealpanda-public.s3.ca-central-1.amazonaws.com/4.mp4"/></div> */}
                        </Col>
                </Row>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired
    };

}

