import React, { Component } from 'react';
// import FormFile from 'react-bootstrap/FormFile'
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import S3Uploader from "../../utils/s3-upload";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage} from '@fortawesome/free-solid-svg-icons'

export default class uploadFiles extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
            files: [],
            showModal: false,
            user_agreement_term: false,
            uploaded: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.data !== prevProps.data) {
            this.setState({
                    files:this.props.data
                })
        }

    }

    handleModalClose = () => {
        this.setState({
            showModal:false,
        }, ()=> {
            // console.log(!this.state.uploaded)
            if (!this.state.uploaded) {
                this.setState({
                    files:[]
                })
            } else {
                this.setState({
                    files : this.props.data
                })
            }
        })
    }

    handleModalShow = () => {
        this.setState({
            showModal:true
        })
    }

    handleCheckbox = (e) => {
        const inputChecked = e.target.checked;
        const inputName = e.target.name;

        this.setState({
            [inputName] : inputChecked
        })
    }

    uploadToS3Bucket (files) {
        const uploader = new S3Uploader(process.env.REACT_APP_AWS_IDENTITY_POOL_ID, "us-east-1");

        let output = []
            files.forEach((file) => {
                let param = file 
                uploader.upload("assets.dealpanda.com", file, this.props.userProfile).then(result => {
                    param["src"] =  result._url
                });
                output.push(param)
            })

        return Promise.resolve(output); 
        
    }

    handleUpload = () => {
        this.setState({uploaded:true})
        this.uploadToS3Bucket(this.state.files).then(result => {
            this.props.handleOnSuccess(this.props.objectKey, result);
            this.setState({files:result})
        },this.handleModalClose());   
    }

    handleSetFileName = (key, e) => {
        if (e.target.files && e.target.files.length > 0) {

            let files = this.state.files
                        files[key] = e.target.files[0]
                        this.setState({ files : files})
        }
    }

    renderList() {
        let output = []

            this.state.files.forEach((file, i) => {
                // if (file.src !== undefined) {
                //     output.push (<li key={i}><div className="upload-image" style={{backgroundImage: "url(" + file.src + ")"}}></div>{file.name}</li>)
                // }else {
                //     output.push (<li key={i}>{file.name}</li>)
                // }
                output.push (<li key={i}>{file.name}</li>)
            });

            return output
    }

    renderInputs() {
        
        let output = []
        let numberOfInputs = 3

            for (let idx = 0; idx < numberOfInputs; idx++) {
                let imageName = this.state.files[idx] !== undefined ? this.state.files[idx].name : ""
                output.push (
                    <React.Fragment key={idx}>            
                        <div className="temp-label"> Image {idx + 1}</div>
                        <Form.Group key={idx} controlId="exampleForm.ControlTextarea1" className="custom custom-file" data-num={idx}>
                            <Form.Control type="file" name="file" size="lg" className="custom-file-input" accept="image/*" onChange={(e) => this.handleSetFileName(idx, e)}/>
                            <Form.Label  className="custom-file-label">{imageName}</Form.Label>
                            
                        </Form.Group>
                    </React.Fragment>
                )

            }
  
            return output
    }

    render() {
        let componentClass = ""
        let content = ""

            if (this.state.files.length > 0 && !this.state.showModal){
                content = 
                <>
                <ul>
                    {this.renderList()}
                </ul>
                <div className="upload-files_list-detail ">
                    <span>{this.state.files.length} {this.state.files.length > 1 ? "Images" : "Image"} </span>
                    <span className="upload-files_edit-link">Re-upload</span>
                </div>
                </>
            } else {
                componentClass = "--empty"
                content = 
                <div className="upload-files_controller">
                    <FontAwesomeIcon icon={faImage} size="3x" fixedWidth />
                    <Button variant="link"> Click to upload</Button>
                    <p>Supports: JPEG, GIF or PNG</p>
                </div>
              
            }
        
        
        
        let disableButton = true

            // User uploaded at least 1 item
            if (this.state.files.length > 0) {
                disableButton = false 
            }

        let agreement = ""

            if (this.props.agreement !== undefined || this.props.agreement) {
                agreement =
                <div className="content-agreement">
                    <Form.Group controlId="user_agreement_term">
                        <div>
                            {/* <Form.Check type="checkbox" name="user_participant_agreement" onChange={this.handleCheckbox} label={`I agree to the one participant per household and no return policies. I understand that violating these policies can result in my account being permanently banned from all future test panels. No one in my household has previously participated in this test and I will not return the product since I am being reimbursed for it.`} /> */}
                            <FormCheck>
                                <FormCheck.Input name="user_agreement_term" onChange={this.handleCheckbox}  type={"checkbox"} />
                                <FormCheck.Label>I understand that removing or editing my review after uploading it violates Deal Panda’s Terms of Service and may result in being banned from future test panels.</FormCheck.Label>
                            </FormCheck>
                           
                        </div>
                    </Form.Group>
                </div>
                
                // User agree to terms so enable button
                if (this.state.user_agreement_term ) {
                    disableButton = false 
                }else {
                    disableButton = true
                }
            }
            
            
            return (
                <>
                    <div className={`upload-files ${componentClass} ${this.props.disabled ? "--disable" : ""}`} onClick={this.handleModalShow.bind()}>
                       {content}
                    </div>
                    <Modal show={this.state.showModal} onHide={this.handleModalClose} className="modal-upload-files">
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.title}</Modal.Title>
                            Add up to 3 screenshots
                        </Modal.Header>
                        <Modal.Body>
                            {this.renderInputs()}
                            {agreement}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="btn-dp" size="lg" disabled={disableButton} onClick={this.handleUpload}>
                                Upload screenshots
                            </Button>
                            <Button variant="link" onClick={this.handleModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
    }

    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        handleOnSuccess: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
        objectKey: PropTypes.string.isRequired,
        title:PropTypes.string.isRequired,
        agreement: PropTypes.bool,
        userProfile: PropTypes.string.isRequired
    };

}


