import React, { Component } from 'react';
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default class flags extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
            region: "CA"
        }
    }


    componentDidMount() {
        if (this.props.region !== null) {
            this.setState({
                region: this.props.region.toUpperCase()
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        // wait for profile to load before fetching from local storage
        if (this.props.region !== null) {
            if (prevState.region !== this.props.region.toUpperCase() ){
            
                    this.setState({
                        region: this.props.region.toUpperCase()
                    })
                
            }
        }
       
    }

    renderList = (num) => {
        let lst = [];

        for (let i = 0; i < num; i++) {
            lst.push(
                <span key={i} className={"path"+(i+1)}></span>
            );
        }

        return lst;
    }

    render() {
        let flag = null
        let name = null

            if (this.state.region === "US") {
                name = "United States"
                flag = 
                    <i className="icon-united-states">
                        {this.renderList(50)}
                    </i>
                    
            } else if (this.state.region === "CA") {
                name = "Canada"
                flag = 
                    <i className="icon-canada">
                        {this.renderList(4)}
                    </i>
            } else if (this.state.region === "DE") {
                name = "Germany"
                flag = 
                    <i className="icon-germany">
                        {this.renderList(3)}
                    </i>
            } else if (this.state.region === "GB") {
                name = "United Kingdom"
                flag = 
                    <i className="icon-united-kingdom">
                        {this.renderList(7)}
                    </i>
            }
            
            else {
                name = this.state.region
                flag = 
                    <div className="unknown">
                        {this.state.region}
                    </div>
            }

            return (
                <OverlayTrigger
                        placement="top"
                        overlay={
                        <Tooltip >
                            <strong>{name}</strong>
                        </Tooltip>
                        }
                    >
                    <div className="flag-container">{flag}</div>
                </OverlayTrigger>
            )
    }
    
    static propTypes = {
        region: PropTypes.string
    }
}
