import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from "prop-types";
// import './how-to-video.scss';

export default class ModalImage extends Component {

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" show={this.props.modal.show} onHide={this.props.handleClose} className={`modal-image ${this.props.modal.className}`} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.modal.header}</Modal.Title>
                        {/* <Button variant="primary" size="md" className="btn-dp download-images"  onClick={this.props.handleClose}>Download Images</Button> */}
                    </Modal.Header>
            
                    <Modal.Body>
                       {this.props.modal.body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="btn-dp"  onClick={this.props.handleClose}>Close</Button>
                        {/* <Button variant="primary" type="submit" className="btn-dp" disabled={this.props.}>{buttonValue}</Button> */}
                    </Modal.Footer>
                    
                </Modal>
            </React.Fragment>
        );
    }

    static propTypes = {
        handleClose: PropTypes.func.isRequired,
        modal : PropTypes.bool.isRequired,

    }
}
