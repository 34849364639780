import React, { Component } from "react";
import Button from 'react-bootstrap/Button'

export default class AdminDealsExport extends Component {
    constructor(props) {
        super(props)
        this.state = {loading: false}
      }

    onClick = () =>{
        // call api to download
        this.setState({loading:true});
        this.props.get("/api/v1/deals/export", {}, true).then(blob=>{
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            const now = new Date().toISOString().split('T')[0];
            a.style = "display: none";
            a.href = url;
            a.download = `dealpanda_redeem_export-${now}.csv`; // gives it a name via an a tag
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch((e)=>{
            console.log(e);
        })
        .finally(()=>{
            this.setState({loading:false});
        });
    }
    render = () => {
        return (
            <section className="export --single">
                <h1 style={{color:'white'}}> Export all <strong>Paid</strong> Deals</h1>
                <p >Use below button to export lifetime deal redeems on all regions that are <strong>paid</strong></p>
                <Button  variant="success" onClick={this.onClick} disabled={this.state.loading}>{this.state.loading ? 'Loading...' : 'Export'}</Button>
            </section>
        );   
    }

}