import React, { Component } from 'react';
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container'
import Body from '../../utils/utils-body'
import PreLoader from '../snippets/PreLoader'
import CompareBuyFirst from './Compare-Buy-First'
import CompareSearchFindBuyReview from './Compare-Search-Find-Buy-Review'
import CompareCategoryFindBuyReview from './Compare-Category-Find-Buy-Review'
import LOCAL_STORAGE from '../../utils/local-storage'
import ADMINSWITCH from '../snippets/admin-switch-toggle'
import './Compare.scss';
             
export default class Compare extends Component {
    constructor(props) {
		super(props);
		this.state = {
            preloader: true,
            id: this.props.match.params.id,
            trackPage: "",
            deal: {},
            userProfile: {},
            isAdmin: false
    
        }
    }
    
    componentDidMount = () => {
        Body.pageClass("add", `page_comparepage_compare-${this.state.currentPage}`)

        if (this.props.device.isMobile) {
            // window.location.replace("/tests");
            this.props.history.push({
                pathname: "/tests"
            });
        } else {
            /*
                Fetch additonal Data
            */
            Promise.all([this.fetchDeal(),  this.props.fetchProfile()]).then(response => {
                let deal = response[0]
                let userProfile = response[1]
        
                if ((deal.cool_off && deal.deal.deal_status === 'active') || 
                    deal.deal.deal_status === "out_of_stock" || 
                    (deal.deal.deal_coming_soon &&  this.props.userProfile.role !== "ADMIN")) {
                    this.props.history.push("/home");
                } else {
                // console.log(deal.deal)
                    // deal.deal["deal_channel"] = "target" // FIXME : Remore this when retail exisit in database
                    this.setState({
                        preloader: false,
                        deal: deal.deal,
                        userProfile :userProfile
                    })
                }

            });
          
        }
    }

    componentDidUpdate(prevProps) {
            if (this.props.userProfile !== prevProps.userProfile && this.props.userProfile){
                this.setState({
                    userProfile: this.props.userProfile
                });
            }
    }

    fetchDeal = () => {
        return this.props.get("/api/v1/deal", {deal_id: this.state.id}).then(response => {
            return response
        });
    }

    clearStorage = () => {
        LOCAL_STORAGE.clear("survey", {id: this.state.id , profileId: this.props.userProfile._id });        
    }
    
    setStorage = (value) => {
        let param = {
            id: this.state.id,
            profileId: this.props.userProfile._id,
            ...value
        }
        LOCAL_STORAGE.set("survey", param)
    }

    renderSurveys(type) {
        let output = ""

            if (type === "buy_first") {
                output =    <CompareBuyFirst 
                                {...this.props} 
                                {...this.state}
                                validateOrderNumber={this.validateOrderNumber}
                                clearStorage={this.clearStorage}
                                setStorage={this.setStorage}
                            /> 
            } else if (type === "SFBR"){
                output =    <CompareSearchFindBuyReview 
                                {...this.props} 
                                {...this.state}
                                validateOrderNumber={this.validateOrderNumber}
                                clearStorage={this.clearStorage}
                                setStorage={this.setStorage}
                            /> 
            } else if (type === "CFBR"){
                output =    <CompareCategoryFindBuyReview 
                                {...this.props} 
                                {...this.state}
                                validateOrderNumber={this.validateOrderNumber}
                                clearStorage={this.clearStorage}
                                setStorage={this.setStorage}
                            /> 
            } else {
                output =    <CompareBuyFirst 
                                {...this.props} 
                                {...this.state}
                                validateOrderNumber={this.validateOrderNumber}
                                clearStorage={this.clearStorage}
                                setStorage={this.setStorage}
                            /> 
            }
        return output
    }
    

    handleAdminPrivileges = (value) => {
        this.setState({isAdmin:value})
    }
    render() {
        let output =  ""
            
            if (this.state.preloader) {
                output = <PreLoader/>
            }else if (Object.keys(this.state.deal).length > 0) {
                output = this.renderSurveys(this.state.deal.deal_type);
            }
        
         let adminToggle = ""

            if (this.props.userProfile.role === "ADMIN") {
                adminToggle = <ADMINSWITCH  userRole={this.props.userProfile.role} isAdmin={this.state.isAdmin} handleAdminPrivileges={this.handleAdminPrivileges} customClasses={"none"} />
            }
        


            return (
                <>
                <Container as="main" className={`questionnaire deal_compare ${this.state.preloader ? "loading": ""}`}>
                    {adminToggle}
                    {output}
                </Container>
                </>
            );
    }

    static propTypes = {
        device: PropTypes.object.isRequired,
        userProfile : PropTypes.object.isRequired,
        fetchProfile: PropTypes.func.isRequired,
        match:  PropTypes.object.isRequired,
        post: PropTypes.func.isRequired,
        get : PropTypes.func.isRequired,
        history: PropTypes.object
    };

}
