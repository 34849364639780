import React, { Component } from "react";
import { matchPath } from 'react-router'
import PropTypes from "prop-types";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import FormControl from 'react-bootstrap/FormControl'
import Switch from "react-switch";
import Flags from '../snippets/flags' 
import Badge from 'react-bootstrap/Badge'


import './admin-bonus-create.scss';


export default class AdminBonusCreate extends Component {

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/bonus/edit/:id",
            exact: true,
            strict: false
        });
		this.state = {
            id: match ? match.params.id : null,
            compareDeals: [],
            dealLookup: "",
            bonusDeal: {
                deal_id: "",
                deal_name: "",
                name: "",
                points: 0,
                description: "",
                type: "review",
                status: "active",
                deal_risk_level:"",
                product_image_url: "",
                acl: [],
                product_name: "",
                min_rating: 4,
                auto_invite: false,
                auto_invite_days: 0,
                auto_invite_max_invite_per_day: 0
            },
            // autoInvite: {
            //     on : false, 
            //     auto_invite_days: 0,
            //     auto_invite_max_invite_per_day: 0
            // },
            feedback: {},
            loading: false
        }

        
    }

    componentDidMount = () => {
        this.fetchDeals(1, 100);
        if (this.state.id) {
            this.fetchBonusDeal(this.state.id);
        }
    }

    fetchDeals = (page, limit) => {
        const params = {
            page: page,
            limit: limit,
            filter: "admin"
        }
        return this.props.get("/api/v1/deals", params).then(response => {
            this.setState({compareDeals: response.deals});
            return Promise.resolve(response.deals);
        }).catch(err => {
            console.error(err);
            err.json().then(response => {
                this.setState({
                    feedback: {
                        type: "danger",
                        message: response.error
                    }
                });

            });
            return Promise.resolve(err);
        });
    }

    fetchBonusDeal(bonusDealId) {
        this.props.get("/api/v1/deals/bonus/" + bonusDealId, {}).then(response => {
            if (response.result) {
                this.setState({bonusDeal: response.result});
            }
        }).catch(err => {
            console.warn(err);
        });
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let bonusDeal = this.state.bonusDeal;
            bonusDeal[inputName] = inputValue;
            
            this.setState({bonusDeal: bonusDeal});
    }
    

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let formData = new FormData(e.target);
            formData.append("auto_invite", this.state.bonusDeal.auto_invite);
            formData.append("auto_invite_days", this.state.bonusDeal.auto_invite_days);
            formData.append("auto_invite_max_invite_per_day", this.state.bonusDeal.auto_invite_max_invite_per_day);
            formData.append("deal_risk_level", this.state.bonusDeal.deal_risk_level);
            formData.append("deal_id", this.state.bonusDeal.deal_id);
        if (this.state.id === null) {
            this.props.post("/api/v1/deals/bonus", formData).then(response => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/admin/bonus"
                    });
                }
            }).catch(err => {
                if (err.json) {
                    err.json().then(response => {
                        this.setState({
                            feedback: {
                                type: "danger",
                                message: response.error,
                            },
                            loading: false
                        });
        
                    });
                } else {
                    console.error(err);
                }
            });
        } else {
            formData.append("deal_id", this.state.bonusDeal.deal_id);
            this.props.put("/api/v1/deals/bonus/" + this.state.id, formData).then(response => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/admin/bonus"
                    });
                }
            }).catch(err => {
                console.log(err);
                err.json().then(response => {
                    this.setState({
                        feedback: {
                            type: "danger",
                            message: response.error,
                        },
                        loading: false
                    });
    
                });
            });
        }
    }

    handleDelete = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        this.props.delete("/api/v1/deals/bonus/" + this.state.id, new FormData()).then(response => {
            console.log(response);
            if (response.result) {
                this.props.history.push({
                    pathname: "/admin/bonus"
                });
            }
        });
    }
    

     renderTilePlaceholder = () => {

            let bonusImageURL = "https://via.placeholder.com/150";
                if (this.state.bonusDeal.product_image_url !== "") {
                    bonusImageURL = this.state.bonusDeal.product_image_url
                }

            let bonusStatusClass = ""
                if (this.state.bonusDeal.status === "active") {
                    bonusStatusClass = "--active";
                } else {
                    bonusStatusClass = "--inactive"
                }

            let bonusName = "Bonus Name"
                if (this.state.bonusDeal.name !== ""){
                    bonusName = this.state.bonusDeal.name
                }

            let bonusDescription = "Bonus Description"
                if (this.state.bonusDeal.description !== "") {
                    bonusDescription = this.state.bonusDeal.description
                }

        return (
            <div className="bonus-deck card-deck">
                
                <Card  className={"bonus-card bonus-card_review " + bonusStatusClass }>
                    <div className="inactive-message">Bonus is inactive , users will not see this on the homepage</div>
                    <Card.Body>
                            <div className="bonus-card_review_top-portion">
                                <div className="card-sub-title">{this.state.bonusDeal.type}</div>
                                <Card.Title>Collect <span className="bonus-card_point-value"> {this.state.bonusDeal.points} </span> panda points</Card.Title>
                                <div className="card-img card-img_background-image" style={{backgroundImage: "url(" + bonusImageURL + ")"}} >
                                    {/* <Card.Img variant="top" src={this.state.results[i].product_image_url} /> */}
                                </div>
                            </div>
                            <div className="bonus-card_review_bottom-portion">
                                <Card.Subtitle>{bonusName}</Card.Subtitle>
                                <Card.Text>
                                    {bonusDescription}
                                </Card.Text>
                            </div>
                    </Card.Body>
                </Card>
                
            </div>


        )
    }

    handleAutoReviewToggle =(checked)=>{

        this.setState({
            bonusDeal: {
                ...this.state.bonusDeal,
                auto_invite: checked,
                auto_invite_days: checked ? this.state.bonusDeal.auto_invite_days : 0,
                auto_invite_max_invite_per_day: checked ? this.state.bonusDeal.auto_invite_max_invite_per_day : 0
            }
        })
    }

    handleAutoEmailInviteChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;

        this.setState({
                bonusDeal: {
                    ...this.state.bonusDeal,
                    [inputName]: inputValue
                }}
            )
    }
    
    handleDealLookUp = (e) => {
        e.preventDefault();
        this.setState({
            dealLookup :   e.target.value.toLowerCase()
        })
    }

    handleDealSelected = (data) => {

        this.setState({
            bonusDeal: {
                ...this.state.bonusDeal,
                ...data
            }
        });
       

    }

    renderAutoEmailInvite = () => {
        let autoReviewToggleClass = ""
        let autoReviewName = "Off"
        let autoReviewOptions = ""

            if(this.state.bonusDeal.auto_invite) {
                autoReviewToggleClass = "--checked"
                autoReviewName = "On"
                autoReviewOptions = 
                <Form.Row>
                    <Col>
                        <Form.Group>

            
                            <Form.Label>Wait days before invite</Form.Label>
                            <Form.Control type="number" name="auto_invite_days" min="0" value={this.state.bonusDeal.auto_invite_days} onChange={this.handleAutoEmailInviteChange} />
                        </Form.Group>     
                        
                    </Col>
                    <Col>
                        <Form.Group>

            
                            <Form.Label>Max invite per day</Form.Label>
                            <Form.Control type="number" name="auto_invite_max_invite_per_day" min="0"  value={this.state.bonusDeal.auto_invite_max_invite_per_day} onChange={this.handleAutoEmailInviteChange} />
                        </Form.Group>     
                        
                    </Col>
                </Form.Row>
            } else {
                autoReviewToggleClass = ""
                autoReviewName = "Off"
            }



        return (
        <>
            <div className="auto-email-invite-header">
                <h4>Auto Email Invites Is {autoReviewName}</h4>
                <div className={"toggle-switch auto-review-toggle " + autoReviewToggleClass}>
                    <Switch  
                        onChange={this.handleAutoReviewToggle} 
                        checked={this.state.bonusDeal.auto_invite} 
                        handleDiameter={15}
                        height={25}
                        width={40}
                        uncheckedIcon={false}
                        checkedIcon={false}
                    />
                    <span className="toggle_switch_label">{this.state.bonusDeal.auto_invite}</span>
                </div>
            </div>
            {autoReviewOptions}
        </>
        )
    }

    renderDeals = () => {
        let output = []

        let filteredData;

        if (this.state.dealLookup !== "") {
             filteredData= this.state.compareDeals.filter(function(deal) {
                 let searchTerm = this.state.dealLookup;
                 let filterName = deal.deal_name.toLowerCase().includes(searchTerm),
                    filterId = deal._id.toLowerCase().includes(searchTerm),
                    filterRegion = deal.deal_region.toLowerCase().includes(searchTerm)
                    if (filterId || filterName || filterRegion){
                        return true;
                    } 
                }.bind(this));
        } else {
            filteredData =  this.state.compareDeals;
        }


        filteredData.forEach(item => {

            let riskLevel = ""
                if (item.deal_risk_level === "NOT_RISKY") {
                    riskLevel =
                    <Badge variant="light">Regular </Badge>
                } else if (item.deal_risk_level === "VIVA_REVIEWERS") {
                    riskLevel =
                    <Badge variant="primary">Viva Reviewers</Badge> 
                } else if (item.deal_risk_level === "TRIAL") {
                    riskLevel =
                    <Badge variant="warning">{item.deal_risk_level}</Badge> 
                } else {
                    riskLevel =
                    <Badge variant="danger">{item.deal_risk_level} </Badge> 
                }

            let params = {
                        product_name : item.product_name,
                        product_image_url : item.product_image_url,
                        deal_description : item.deal_description,
                        deal_region : item.deal_region,
                        deal_risk_level : item.deal_risk_level,
                        deal_id :  item._id,
                        deal_name :  item.product_name
                }


                output.push(
                    <Dropdown.Item as="div" key={item._id}  onClick={this.handleDealSelected.bind(this, params)}>
                        <div className="deal_image" style={{backgroundImage: "url(" + item.product_image_url + ")"}} >

                        </div>
                        <div className="deal_content">
                            <p>{item.product_name}</p>
                            <div className="deal_content-information">
                                <small className="item-region"><Flags region={item.deal_region}/></small>
                                <small className="item-user-type">{riskLevel}</small>
                                <small>{item.deal_asin}</small>
                                {/* <div>{item.deal_id}</div> */}
                            </div>
                        </div>
                        
                    </Dropdown.Item >
                );
        });

        return output
    }


    render = () => {


        let showAlert = Object.keys(this.state.feedback).length !== 0;
        let acl = [];
        this.state.bonusDeal.acl.forEach(item => {
            acl.push(
                <ListGroup.Item as="li" key={item}>{item}</ListGroup.Item>
            );
        });

        let editMode = this.props.location.state === "edit" ? true : false

        let compareDealToggleText = ""
            if(this.state.bonusDeal.deal_name !== "") {
                compareDealToggleText = this.state.bonusDeal.deal_name
            } else if(this.state.compareDeals.length < 1) {
                compareDealToggleText = "Loading deals..."
            }   else {
                compareDealToggleText = "- Select Deal -"
            }
        
        let riskLevel = ""
       
                if (this.state.bonusDeal.deal_risk_level === "NOT_RISKY") {
                    riskLevel =
                    <Badge variant="light">Regular </Badge>
                } else if (this.state.bonusDeal.deal_risk_level === "VIVA_REVIEWERS") {
                    riskLevel =
                    <Badge variant="primary">Viva Reviewers</Badge> 
                } else if (this.state.bonusDeal.deal_risk_level === "TRIAL") {
                    riskLevel =
                    <Badge variant="warning">{this.state.bonusDeal.deal_risk_level}</Badge> 
                } else {
                    riskLevel =
                    <Badge variant="danger">{this.state.bonusDeal.deal_risk_level} </Badge> 
                }
        
        return (
            <section className="bonus_create">
                    {/* {alert} */}
                    <Alert show={showAlert} variant={this.state.feedback.type}>{this.state.feedback.message}</Alert>
                    <Form onSubmit={this.handleSubmit} className="deal_create_form --bonus">
                        { editMode ? (
                                    <h5>Edit "{this.state.bonusDeal.name}" </h5>
                        ) : null}
                        <h4>Bonus Tile</h4>
                        { !editMode ? (
                            <>
                            <Form.Label>Compare Deal</Form.Label>
                            <Dropdown className="bonus_deal-look-up">

                                <Dropdown.Toggle  id="dropdown-custom-components">
                                {compareDealToggleText}
                                </Dropdown.Toggle>

                                <Dropdown.Menu >
                                    <FormControl
                                        autoFocus
                                        className="dropdown-search"
                                        placeholder="Type to filter..."
                                        onChange={this.handleDealLookUp.bind()}
                                        value={this.state.dealLookup}
                                    />
                                    {this.renderDeals()}
                                </Dropdown.Menu>
                            </Dropdown>
                            </>
                        ) : null}
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" value={this.state.bonusDeal.name} onChange={this.handleInputChange} required />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Description (max 100 characters)</Form.Label>
                                    <Form.Control as="textarea" name="description" rows="6" value={this.state.bonusDeal.description} onChange={this.handleInputChange} required maxLength={100} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control as="select" name="type" value={this.state.bonusDeal.type} onChange={this.handleInputChange}>
                                        <option value="review">Review</option>
                                    </Form.Control>
                                </Form.Group>
                            
                                
                                <Form.Group>
                                    <Form.Label>Points</Form.Label>
                                    <Form.Control type="number" name="points" value={this.state.bonusDeal.points} onChange={this.handleInputChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" name="status" value={this.state.bonusDeal.status} onChange={this.handleInputChange}>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <div className="deal-deck-wrapper">
                                    <div>
                                        {this.renderTilePlaceholder()}
                                        {this.props.location.state !=="edit" ? (
                                        <div className="media deal_preview">
                                            {/* <img src={this.state.bonusDeal.product_image_url} alt={this.state.bonusDeal.product_name} className="mr-3" /> */}
                                            
                                            <div className="media-body">
                                                {this.state.bonusDeal.product_image_url !== "" ? (
                                                    <React.Fragment>
                                                        <h6 className="mt-0">{this.state.bonusDeal.product_name}</h6>
                                                        <p>{this.state.bonusDeal.deal_description}</p>
                                                        <div className="deal_additiona-info">
                                                        <div><strong>Region:</strong> <Flags region={this.state.bonusDeal.deal_region}/></div>
                                                        <div><strong>Risk Level:</strong> {riskLevel}</div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}
                                                
                                            </div>
                                            
                                        </div>
                                        ) : null }
                                    </div>
                                    
                                </div>
                            </Col>
                        </Form.Row>

                        <h4>Minimum Review Star Rating</h4>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Rating</Form.Label>
                                    {/* <Form.Control type="number" name="min_rating" value={this.state.bonusDeal.min_rating} onChange={this.handleInputChange} /> */}
                                    <Form.Control as="select" name="min_rating" value={this.state.bonusDeal.min_rating} onChange={this.handleInputChange} >
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted --tooltip">
                                            <p>The minim star rating of a Deal Panda review required to prompt user to leave a review on the retailer website.</p>
                                    </Form.Text>
                                </Form.Group>     
                            </Col>
                        </Form.Row>
                       
                        {this.renderAutoEmailInvite()}
                        
                        {/* { editMode ? (
                            <Form.Group>
                                <Form.Label>Access Control List</Form.Label>
                                <ListGroup as="ul">{acl}</ListGroup>
                            </Form.Group>
                        ) : null} */}
                        <Form.Group className="controller">
                                {/* { editMode ? (
                                    <Link to="/admin/bonus" className="btn btn-outline-dismiss">Cancel</Link>
                                ) : null} */}
                                <Button variant="outline-danger" onClick={this.handleDelete} hidden={!editMode} disabled={this.state.loading}>Delete</Button>
                                <Button variant="success" type="submit" disabled={this.state.loading}>Submit</Button>
                        </Form.Group>
                    </Form>
            </section>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        history: PropTypes.object,
        location: PropTypes.object
    }
}