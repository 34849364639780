import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import PandaCoin from '../../snippets/panda-coin'
import IMAGE from '../../../utils/image'
import RETAILS from '../../../utils/retails'

import '../Compare.scss';

export default class CompareStepWalkthrough extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_capability : false, 
            user_participant_agreement: false
        };
    }

    componentDidMount = () => {
        // Allow Copy and Paste
        document.oncontextmenu=function(){return true};
    }

    componentWillUnmount = () => {
        // Disable Copy and Paste
        document.oncontextmenu=function(){return false};
    }

    handleCheckbox = (e) => {
        const inputChecked = e.target.checked;
        const inputName = e.target.name;

        this.setState({
            [inputName] : inputChecked
        })
    }

    render() {
        let disableButton = true

            if(this.state.user_participant_agreement) {
                disableButton = false
            }

        let productImageClass = IMAGE.getImageSizeClass(this.props.product.product_image_url)
        
        return (
            <React.Fragment>
                <Container as="section" className={`step-detail walkthrough ${this.props.requestAmzUrl ? "--blur" : ""}`}>
                    <Row>
                        <Col xs={12} md={4} className="walkthrough_image d-none d-sm-none d-md-block">
                            <div className={`image-tile ${productImageClass}`}>
                                <Image src={this.props.product.product_image_url} />
                                
                                <h5>Your Reward for Participation</h5>
                                <ul>
                                    <li>
                                        <em>Free Product -- keep the product you test and get fully reimbursed!</em>
                                    </li>
                                    <li>
                                        Earn <PandaCoin/><strong>{this.props.product.points}</strong> Panda Points
                                    </li>
                                    {/* <li>10,000 Panda Points gets you $100 in Gift Cards</li> */}
                                </ul>

                                <h6>
                                    <div>Reward Value: ${this.props.product.deal_amount}</div>
                                    <small>*2,500 Panda Points get you $25 in Gift Cards</small>
                                </h6>
                                <p className="deal-stock-message">Recruting <span>{this.props.product.deal_daily_limit}</span> more {this.props.product.deal_daily_limit <= 1 ? "tester" : "testers"} .</p>

                            </div>
                        </Col>
                        <Col xs={12} md={8}  className="walkthrough_content">
                            <div className="content-header">
                                <img  alt={`${this.props.product.deal_channel}-logo`} className={this.props.product.deal_channel} src={RETAILS.getLogo(this.props.product.deal_channel, "sm")}/> 
                                <h1>{this.props.product.deal_name}</h1>
                            </div>
                            <div className="content-body">
                                <h5>Test Participation Requirements</h5>
                                {/* <p >Complete the tasks below to participate in this test. On average, this will take just&nbsp;7&nbsp;minutes. </p> */}
                                <ol>
                                    <li>Visit <strong><a className="link--override" href={`https://${RETAILS.getDomain(this.props.product.deal_channel)}`} target="_blank" rel="noopener noreferrer">{RETAILS.getDomain(this.props.product.deal_channel)}</a></strong>, follow the instructions provided in this survey and answer some qustions to give us insight into your shopping decisions.</li>
                                    <li><em>Purchase the test product -- which is yours to keep for free! -- try it out for <strong>{this.props.state.reviewWaitTime}</strong> days and return to this survey to submit your review.</em></li>
                                    <li><span className="content-body--new">NEW PAYMENT TERMS</span>: Upload a screenshot of your <strong>receipt and published review</strong>.  You’ll be fully reimbursed for ${this.props.product.deal_amount} plus applicable taxes through PayPal.</li>
                                </ol>

                                <div className="content-agreement">
                                    {/* <Form.Group controlId="user_capability">
                                        <Form.Check type="checkbox" name="user_capability"  onChange={this.handleCheckbox} label="I am capable of completing the test participation requirements (Required)" />
                                    </Form.Group> */}

                                    <Form.Group controlId="user_participant_agreement">
                                        <span>*</span>
                                        <div>
                                            {/* <Form.Check type="checkbox" name="user_participant_agreement" onChange={this.handleCheckbox} label={`I agree to the one participant per household and no return policies. I understand that violating these policies can result in my account being permanently banned from all future test panels. No one in my household has previously participated in this test and I will not return the product since I am being reimbursed for it.`} /> */}
                                            <FormCheck>
                                                <FormCheck.Input name="user_participant_agreement" onChange={this.handleCheckbox}  type={"checkbox"} />
                                                <FormCheck.Label>I agree to the <strong>one participant per household</strong> and no return policies. I understand that violating these policies can result in my account being permanently banned from all future test panels. No one in my household has previously participated in this test and I will not return the product since I am being reimbursed for it.</FormCheck.Label>
                                            </FormCheck>
                                            <Link to={{
                                                pathname:"/faq", 
                                                state: "limited"
                                                }}>
                                                    Learn More
                                            </Link>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>

                            <Button className={"btn btn-dp "} disabled={disableButton} onClick={this.props.handleWalkthrough.bind()} >Let’s Get Started </Button>
                               
                        </Col>
                    </Row>   
                </Container>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        state: PropTypes.object.isRequired,
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired,
        handleWalkthrough: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired, 
        requestAmzUrl: PropTypes.bool.isRequired
    }
}
