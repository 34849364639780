import React, { Component } from "react";
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import adminRegions from '../../utils/admin-regions'

import './admin-rewards-create.scss';

export default class AdminRewardsCreate extends Component {

    static denominationValues = [5, 10, 15, 20, 25, 50, 100];

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/rewards/edit/:id",
            exact: true,
            strict: false
        });
		this.state = {
            id: match ? match.params.id : null,
            compareDeals: [],
            reward: {
                name: "",
                theme: "dark",
                description: "",
                type: "review",
                status: "active",
                image: "",
                color: "",
                denominations: []
            },
            feedback: {},
            loading: false
        }
    }

    componentDidMount = () => {
        if (this.state.id) {
            this.fetchReward(this.state.id);
        }
    }

    fetchReward(rewardId) {
        this.props.get("/api/v1/reward/" + rewardId, {filter: "admin"}).then(response => {
            if (response.result) {
                this.setState({reward: response.result});
            }
        }).catch(err => {
            console.warn(err);
        });
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let reward = this.state.reward;
        reward[inputName] = inputValue;
        this.setState({reward: reward});
    }

    handleCheckChange = (e) => {
        const inputName = e.target.name;
        const inputValue = parseInt(e.target.value);
        let reward = this.state.reward;
        let checked = reward[inputName];
        let idx = checked.indexOf(inputValue);
        if (idx >= 0) {
            checked.splice(idx, 1);
        } else {
            checked.push(inputValue);
        }
        reward[inputName] = checked;
        this.setState({reward: reward});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let formData = new FormData(e.target);
        if (this.state.id === null) {
            this.props.post("/api/v1/rewards/", formData).then(response => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/admin/rewards"
                    });
                }
            }).catch(err => {
                err.json().then(response => {
                    this.setState({
                        feedback: {
                            type: "danger",
                            message: response.error,
                        },
                        loading: false
                    });
    
                });
            });
        } else {
            this.props.put("/api/v1/reward/" + this.state.id, formData).then(response => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/admin/rewards"
                    });
                }
            }).catch(err => {
                console.log(err);
                err.json().then(response => {
                    this.setState({
                        feedback: {
                            type: "danger",
                            message: response.error,
                        },
                        loading: false
                    });
    
                });
            });
        }
    }

    render = () => {
        
        let showAlert = Object.keys(this.state.feedback).length !== 0;
        let editMode = this.props.location.state === "edit" ? true : false
        
        let thumbnail = null;
        if (this.state.reward.image) {
            thumbnail = <img src={this.state.reward.image} alt="thumbnail" height={50} />
        }

        let denoms = [];
        AdminRewardsCreate.denominationValues.forEach(val => {
            let isChecked = this.state.reward.denominations.indexOf(val) >= 0 ? true : false;
            denoms.push(
                <Form.Check inline label={val} value={val} name="denominations" type="checkbox" id={"d-" + val} key={"d-"+val} onChange={this.handleCheckChange} checked={isChecked} />
            );
        });

        // Card Placeholder
        const pointValue = 100;
        let denominationsMin = this.state.reward.denominations.length > 0 ? Math.min(...this.state.reward.denominations) : 0;
        let denominationsMax = this.state.reward.denominations.length > 0 ? Math.max(...this.state.reward.denominations) : 0;

        let styleCard = { 
            backgroundColor: this.state.reward.color , 
            backgroundImage: "url("+this.state.reward.image+")" 
        }

        return (
            <section className="reward_create">
                    <Alert show={showAlert} variant={this.state.feedback.type}>{this.state.feedback.message}</Alert>
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6} className="reward_create_form">
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control name="name" value={this.state.reward.name} onChange={this.handleInputChange} required />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Region</Form.Label>
                                        <Form.Control name="region" as="select" value={this.state.reward.region} onChange={this.handleInputChange}>
                                            {adminRegions.options()}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control as="select" name="type" value={this.state.reward.type} onChange={this.handleInputChange}>
                                            <option value="giftcard">Gift Card</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Image URL</Form.Label>
                                        <InputGroup>
                                            <Form.Control name="image" value={this.state.reward.image} onChange={this.handleInputChange} required />    
                                            <InputGroup.Append className="pl-1">
                                                {thumbnail}
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Background Color</Form.Label>
                                        <Form.Control name="color" type="color" value={this.state.reward.color} onChange={this.handleInputChange} required />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Text Color</Form.Label>
                                        <Form.Control as="select" name="theme" value={this.state.reward.theme} onChange={this.handleInputChange}>
                                            <option value="light">Dark</option>
                                            <option value="dark">Light</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control as="select" name="status" value={this.state.reward.status} onChange={this.handleInputChange}>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Denominations</Form.Label>
                                        <div>{denoms}</div>
                                    </Form.Group>
                                    
                                    <Form.Group className="controller">
                                        { editMode ? (
                                            <Link to="/admin/bonus" className="btn btn-outline-dismiss btn-cancel">Cancel</Link>
                                        ) : null}
                                        <Button variant="success" type="submit" disabled={this.state.loading}>Submit</Button>
                                    </Form.Group>
                                    
                                </Form>
                            </Col>
                            <Col sm={12} md={6} className="reward_create_card-placeholder">
                                <CardDeck className={this.state.reward.color !== "" ? "rewards-deck" : "rewards-deck rewards-deck--empty"}>        
                                    <Card className="rewards-card">
                                        <Card.Body className={this.state.reward.theme} style={ styleCard }>
                                            <div className="rewards-card_value">
                                                ${denominationsMin} - ${denominationsMax}
                                            </div>
                                        </Card.Body>
                                        <Card.Footer>
                                            <div className="rewards-card_title">{this.state.reward.name}</div>
                                            <div className="rewards-card_redemption">{denominationsMin * pointValue} - {denominationsMax * pointValue}</div>
                                        </Card.Footer>
                                    </Card>
                                </CardDeck>
                            </Col>
                        </Row>
                    </Container>
            </section>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        history: PropTypes.object,
        location: PropTypes.object
    }
}