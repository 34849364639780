export default {

    init(appId, version, xfbml, cookie, language, callback, profileFields) {
        window.fbAsyncInit = () => {
            window.FB.init({
                version: `v${version}`,
                appId: appId,
                xfbml: xfbml,
                cookie: cookie,
                status: false
            });

            window.FB.Event.subscribe("xfbml.render", () => {
                console.log("fb finished rendering");
            });
            window.FB.Event.subscribe("auth.statusChange", response => {
                // console.log(response)
                if (response.status === "connected") {
                    window.FB.api("/me", {fields: profileFields}, me => {
                        callback(me, response.authResponse.accessToken);
                    });
                } else {
                    console.log("something happend in fb", response)
                }
            });


            // window.FB.Event.subscribe("auth.statusChange", response => {
            //     console.log("fb status change", response);
            // });

            window.FB.Event.subscribe("auth.authResponseChange", response => {
                console.log("fb auth response change", response);
            });

            window.FB.Event.subscribe("auth.login", response => {
                console.log("fb auth.login", response);
            });
        };

        this.loadSDK(language);
    },

    loadSDK(language) {
        ((d, s, id) => {
            const element = d.getElementsByTagName(s)[0];
            const fjs = element;
            let js = element;
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = `https://connect.facebook.net/${language}/sdk.js`;
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    },

    logout() {
        window.FB.logout(response => {
            console.log("logout", response);
        });
    },

    login(callback, scope, profileFields, rerequest) {
        let opts = {
            scope: scope
        }
        if (rerequest) {
            opts.auth_type = "rerequest"
        }
        window.FB.login(response => {
            // console.log("rerequest response", response);
            if (response.status === "connected") {
                window.FB.api("/me", {fields: profileFields}, me => {
                    let result = callback(me, response.authResponse.accessToken);
                    console.log("callback done", result);
                });
                return true;
            } else {
                return false;
            }
        }, opts);
        return false;
    }
}