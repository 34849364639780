import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
// import Currency from '../../../utils/currency'
import RETAILS from '../../../utils/retails'

import '../Compare.scss';

export default class PayPalStep extends Component {
    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    componentDidUpdate(){
        this.focus('input#email')
    }

    componentDidMount(){
        this.focus('input#email')
    }
    
    render() {
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--paypal">
                    
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper --paypal" style={{backgroundImage: `url(${RETAILS.getLogo("paypal", "lg")})`}}>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current}</h2>
                            <p className="steps_description">
                                In the next step, you’ll be asked to purchase the test product <em>-- which will be yours to keep!</em>&nbsp;&nbsp;<strong>You’ll be reimbursed for ${this.props.product.deal_amount} plus applicable taxes and shipping</strong> to the PayPal account associated with the email  below. Reimbursements are processed once you upload a screenshot of your proof of purchase and published review on <span className="text-capitalize">{this.props.product.deal_channel.toLowerCase()}'s</span> website.
                            </p>
                            <Form.Group >
                                <Form.Label>Confirm your Paypal account.</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    name="paypalEmail"
                                    placeholder="PayPal Email" 
                                    onChange={this.props.handleInputChange}
                                    value={this.props.step.paypalEmail}
                                    required
                                    id="email"
                                />
                            </Form.Group>
                            {/* <div className="step_additiona-note">You may need to browse through several pages of search results. Scroll to the bottom and click through to the next page if you can't find the product.</div> */}
                        </Col>
                </Row>
            </React.Fragment>   
        );
    }
    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired,
        product: PropTypes.object,
        step: PropTypes.object.isRequired
    };

}
