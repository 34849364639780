import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Logo from '../../../assets/images/logo_outline.svg'

import './../apply.scss';


export default class CommonHeader extends Component {
    render() {
        return(
            <Navbar >
                <Container>
                    <Navbar.Brand href="/apply"><img src={Logo} rel="Deal Panda" alt="Deal Panda Logo"/></Navbar.Brand>
                </Container>
            </Navbar>
        )
    }
}