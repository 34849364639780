import React, { Component } from 'react';
import PropTypes from "prop-types";
import Body from '../../utils/utils-body'
import Container from 'react-bootstrap/Container'
import CardDeck from 'react-bootstrap/CardDeck';
import PreLoader from '../snippets/PreLoader'
import ADMINSWITCH from '../snippets/admin-switch-toggle'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


// Pages
import Compare from './deal/deal-compare';
import Bonus from './deal/deal-bonus';


import './Deal.scss'

export default class Deal extends Component {
    constructor(props) {
		super(props);
		this.state = {
            preloader: true,
            page: 1,
            cool_off: 0,
            resultsPerPage: 100,
            inProgress: [],
            deals:[],
            bonus:{},
            isAdmin: false,
            userProfile: {}
        }
    }

    componentDidMount = () => {
        Body.pageClass("add", `page_deal`);
        
        /*
            Fetch Surveys
        */
       
        this.props.fetchProfile().then(response => { 
            Promise.all([this.fetchDeals(this.state.page, this.state.resultsPerPage, response.role),  this.fetchBonus(this.state.page, this.state.resultsPerPage, response.role) ]).then(values => {
            const prevDeals = this.state.deals;
            this.setState({
                deals: prevDeals.concat(values[0].deals),
                preloader: false,
                cool_off: values[0].cool_off,
                bonus: values[1],
                isAdmin: response.role === 'ADMIN' ? true : false,
                userProfile: response
            });
        });

        })

        if (this.props.userProfile._id) {
            this.fetchLocalStorage(this.props.userProfile._id);
        }

     
    }

    fetchDeals = (page, limit, role) => {
        return this.props.get("/api/v1/deals", {page: page, limit: limit, filter: role.toLowerCase()}).then(response => {
            return response
            
        }).catch(err => {
            console.error(err);
        });
    }

    fetchBonus = (page, limit, role) => {
        return this.props.get("/api/v1/deals/bonus", {page: page, limit: limit, filter: role.toLowerCase()}).then(response => {
            return response
        }).catch(err => {
            console.error(err);
        });
    }

    renderEmptyMessage(){
        let msg = "Brands require specific participants for their tests. Don’t worry if you haven’t matched for one yet. There will be more coming up."

            if (this.props.device.isMobile) {
                msg = "Sorry, either there are no mobile tests currently running or you haven’t matched for one yet. Don’t worry, there will be more coming up! Please check back later."
            }

        return (<div className="empty-state">{msg}</div>)
    }

    fetchLocalStorage = (userId) => {
        let dealId = []
        
        for(let i=0; i<localStorage.length; i++) {
            let key = localStorage.key(i);
            
            // 'include' is not supported in IE 11
            if (key.includes(userId)) {
                let value = JSON.parse(localStorage.getItem(key))
                if (value["comp_answer"] !== undefined ) {
                    dealId.push(key.split('_')[1])
                } 
            }
        }
        this.setState({inProgress:dealId})
    }

    scrollTo = (id) => {
        var anchor = document.getElementById("anchor-" + id);

            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': anchor.offsetTop
            });
    }

    handleAdminPrivileges = (value) => {
        this.setState({isAdmin:value})
    }


    render() {
        
        let titleDescription = ""

        let surveys = ""
            if(this.state.preloader) {
                surveys = <PreLoader/>
            } else if ((this.state.deals.length > 0 || this.state.bonus.results.length > 0) && this.props.userProfile.riskLevel !== "RISKY") {
                titleDescription = 
                // <p className={`lead ${this.props.userProfile.riskLevel === "DEMO" ? "mb-5":""}`}>Test panels fill up fast!...</p>
                <p className={`lead ${this.props.userProfile.riskLevel === "DEMO" ? "mb-5":""}`}>Test panels fill up fast! Keep an eye out for new tests and start the survey quickly.</p>
                
                surveys = 
                <CardDeck className={this.state.preloader ? "deal-deck bonus-deck  preloader" : "deal-deck bonus-deck"}>
                        <Compare {...this.props} {...this.state} get={this.props.get} userProfile={this.props.userProfile}/>
                        <Bonus {...this.props} {...this.state} get={this.props.get} userProfile={this.props.userProfile}/>
                </CardDeck>
            } else {
                surveys = this.renderEmptyMessage() 
            }

        let adminToggle = ""

            if (this.props.userProfile.role === "ADMIN") {
                adminToggle = <ADMINSWITCH  userRole={this.props.userProfile.role} isAdmin={this.state.isAdmin} handleAdminPrivileges={this.handleAdminPrivileges} customClasses={"none"} />
            }
        
    
        return (
            <Container as="main" className={this.state.deals.length < 5 ? "full-height" : ""}>
                {adminToggle}
                {/* <Row>
                    <Alert className="page-alert" variant="primary">
                        <strong className="alert-title">PLEASE READ: COVID-19 UPDATE</strong>
                        <p>Our delivery partners are currently experiencing delays in their processing and shipping times. They are working as fast as they can to ship test products out to you as soon as possible. If it’s been more than <strong>20</strong> business days since completing a survey and the test product has still not arrived, please <Link to="/contact">contact us.</Link></p>
                    </Alert>
                </Row> */}
            
                <Row>
                    <Col  className="page-header">
                        <h1 className="display-3">Available Tests</h1>
                        {titleDescription}
                    </Col>
                </Row>
                <Row>
                    {surveys}
                </Row>
            </Container>
        );
    }

    static propTypes = {
        isAdmin:  PropTypes.bool,
        history: PropTypes.object.isRequired,
        get: PropTypes.func.isRequired,
        userProfile : PropTypes.object.isRequired,
        fetchProfile: PropTypes.func,
        device: PropTypes.object
    };

}