import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Link } from "react-router-dom";
import Util from './../../../utils/utils'
import amzURLBuilder from '../../../utils/admin-regions'
import trackingLoader from '../../../utils/tracking-loader'
import '../Compare.scss';
// import HowToVideo from '../../snippets/how-to-video'

export default class CompareStepOrderNumber extends Component {

    constructor(props) {
		super(props);
		this.state = {
            showMore: false,
            method: "order-confirmation"
        }
    }

    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    componentDidMount = () => {
        document.oncontextmenu=function(){return true};
    }

    componentWillUnmount = () => {
        document.oncontextmenu=function(){return false};
    }


    handleValidationMethod =(method)=> {
        this.setState({
            method:method
        })
        trackingLoader.trackEvent("Order validation method", {
            deal_id: this.props.product._id,
            deal_title: this.props.product.name,
            event_category: `test_product:${this.props.product._id}`,
            event_label: method
        }); 
    }

    render() {

        let methods = ""
        let methodImage = ""
        let methodTitle = ""


            if(this.state.method === "track-package") {
                methods = 
                <>
                        <p>
                        If you do not have the URL of the Order Confirmation page, the <strong>Track Package page URL</strong> can be provided instead. To do this:
                        </p>
                        <p><strong>To do this:</strong></p>
                        <ol>
                            <li>
                            Click on ‘<strong>Returns & Orders</strong>‘
                            </li>
                            <li>
                                Locate the order you just placed and click on the ‘<strong>Track Package</strong>’ button.
                            </li>
                            <li>
                                Copy/Paste the URL in the address bar.
                            </li>
                        </ol>
                    <Form.Group>
                        <Form.Label>Provide the URL of the Order Confirmation Page:</Form.Label>
                        <Form.Control 
                            type="text" 
                          
                            placeholder={`https://www.${amzURLBuilder.getDomain(this.props.userProfile.region)}/gp/buy/thankyou/handlers/display.html?ie=UTF8&asins=B00BMJTP54&isRefresh=1&orderId=701-8400822-1900224&purchaseId=703-4808284-0850630&ref_=chk_typ_browserRefresh&viewId=ThankYouCart` }
                            onChange={this.props.handleOrderNumberChange}
                            required
                            id="orderNumber"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid url.
                        </Form.Control.Feedback>
                        <small>We’ll use your order ID from the URL to validate your purchase.</small>
                        {/* <Button variant="link" onClick={() => (this.setState({showMore: true}))}>I do not have the URL of the Order Confirmation Page</Button> */}
                        {/* {showMore} */}
                    </Form.Group>
                </>
                methodImage  = "https://dealpanda-public.s3.ca-central-1.amazonaws.com/compare-instruction-orderid_track-package.gif"
                methodTitle = "Track Package Information"

            } else {
                methods = 
                <>
                    <p>The <strong>Order Confirmation page URL</strong> is available as soon as you’ve completed a purchase.*</p>
                    <Form.Group>
                        <Form.Label>Provide the URL of the Order Confirmation Page:</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder={`https://www.${amzURLBuilder.getDomain(this.props.userProfile.region)}/gp/buy/thankyou/handlers/display.html?ie=UTF8&asins=B00BMJTP54&isRefresh=1&orderId=701-8400822-1900224&purchaseId=703-4808284-0850630&ref_=chk_typ_browserRefresh&viewId=ThankYouCart` }
                            onChange={this.props.handleOrderNumberChange}
                            required
                            id="orderNumber"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid url.
                        </Form.Control.Feedback>
                        <small>We’ll use your order ID from the URL to validate your purchase.</small>
                        {/* <Button variant="link" onClick={() => (this.setState({showMore: true}))}>I do not have the URL of the Order Confirmation Page</Button> */}
                        {/* {showMore} */}
                    </Form.Group>
                </>
                methodImage = "https://dealpanda-public.s3.ca-central-1.amazonaws.com/compare-order-number.jpg"
                methodTitle = "Order Confirmation Information"

            }
        return (
            <React.Fragment>
                <Row className="step-detail">
                    
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div>
                                <div className="step-detail_image-wrapper" >
                                    <Image src={methodImage} />
                                </div>
                                <small>{methodTitle}</small>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current}</h2>
                            <div className="order-confirmation">
                                    <p>In this step, you’ll purchase the product and provide us with the page URL for validation.</p>

                                    <ol>
                                        <li>
                                            Proceed to purchase the <strong>{Util.trunc(this.props.product.product_name, 100)}</strong>
                                        </li>
                                        <li>
                                            Provide the URL of either the ‘<strong>Order Confirmation Page</strong>’ or ‘<strong>Track Package Page</strong>’ for validation. The URL contains the information we need to verify your order.*
                                        </li>
                                        <li>
                                            Once we’ve validated your purchase you will receive a full reimbursement* to your Paypal account. Validation may take up to 5 business days.
                                        </li>
                                    </ol>

                                    <div className="important-msg">
                                            <strong>Important: </strong> 
                                            Once you have completed your order, you’ll need to <strong>provide us with the URL of the confirmation page</strong>. The confirmation page has the message ‘<strong>Thank you, your order has been placed.</strong>’ 
                                            <span className="red"> Please ensure you are shopping on your desktop browser so that you can provide us with the required URL.</span>
                                    </div>
                                    <p className="subtitle">Choose an order validation method:</p>
                                    
                                    <div className="tabular-navigation">
                                        <Button variant="success" size="sm" className={this.state.method === "order-confirmation" ? "--active" : ""} onClick={this.handleValidationMethod.bind(this, "order-confirmation")}> Order Confirmation<br/>Information </Button>
                                        <Button variant="success" size="sm"  className={this.state.method === "track-package" ? "--active" : ""} onClick={this.handleValidationMethod.bind(this, "track-package")}> Track Package<br/>Information</Button>
                                    </div>

                                    <div className="tabular-content">
                                        {methods}
                                    </div>

                                    <small className="need-help"><strong>Still need help</strong>? No worries, we’ll make sure you get your gift reimbursement. <Link to="/contact">Contact us</Link>. </small>
                                  
                            </div>                           
                            {/* <div className="step_help">Need Help? <HowToVideo show_icon="true" title="Search for Amazon products" source="https://dealpanda-public.s3.ca-central-1.amazonaws.com/3.mp4"/></div> */}
                        </Col>
                    
                </Row>
            </React.Fragment>    
        );
    }
    static propTypes = {
        handleOrderNumberChange: PropTypes.func.isRequired,
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired
    };

}
