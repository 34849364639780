import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Logo from '../../assets/images/logo_green.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert';
import '../pages/Register.scss';

export default class Register extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: false,
            numEmailInputs: 1,
            searchParams: new URLSearchParams(this.props.location.search),
            feedback: null
        };
    }

    handleRegisterSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        let formData = new FormData(e.target);
        formData.append("id", this.state.searchParams.get("id"));
        this.props.post("/api/v1/register", formData).then(response => {
            if (response !== false) {
                window.location = "/login";
            } else {
                this.setState({isLoading: false});
            }
        }).catch(err => {
            if (err.status === 424) {
                const alert = <Alert variant="danger">This invite link is no longer valid. You may have already created an account.</Alert>
                this.setState({feedback: alert});
            }
        });
    }

    render() {
        let userEmail = ""
            if (this.state.searchParams.get("email") !== null ) {
                userEmail = this.state.searchParams.get("email")
            }
        let userRegion = "CA"
            if (this.state.searchParams.get("region") !== null ) {
                userRegion = this.state.searchParams.get("region")
            }

        return (
            <main id="login" className="full-background">
				<div className="full-background--valign sign-in_form_wrapper">
                    <Form onSubmit={this.handleRegisterSubmit} className="sign-in_form register_form">
                        <div className="sign-in_copy mb-3">
                        <img className="mt-4 mb-4 logo" src={Logo} alt="Deal Panda"></img>
                            <h1>Sign up !</h1>
                            <p>Join Deal Panda and earn rewards, free gifts and payouts for participating in marketing tests and sharing your feedback with big brands on their newest products.</p>
                            <p>Fill out the form below and let get started.</p>
                        </div>
                        {this.state.feedback}
                        <Form.Group as={Row}>
                            <Col md={9}>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" name="email" defaultValue={userEmail} readOnly />
                            </Col>
                            <Col md={3}>
                                <Form.Label>Region</Form.Label>
                                <Form.Control as="select" name="region" defaultValue={userRegion}>
                                    <option value="CA">CA</option>
                                    <option value="US">US</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col md={6}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control name="first_name" required />
                            </Col>
                            <Col md={6}>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control name="last_name" required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col md={6}>
                                <Form.Label>Password</Form.Label>
                                <Form.Control name="password" type="password" required />
                            </Col>
                            <Col md={6}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control name="passwordConfirm" type="password" required />
                            </Col>
                        </Form.Group>

                        <Button type="submit" className="btn-dp btn-block" disabled={this.state.isLoading}>Submit</Button>
                    </Form>
                </div>
            </main>
        );
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
        post: PropTypes.func.isRequired
    }
}