import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import './../apply.scss';
// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons'

const provinceOptions = [
                            { value:"AB", name:"Alberta"},
                            { value:"BC", name:"British Columbia"},
                            { value:"MB", name:"Manitoba"},
                            { value:"NB", name:"New Brunswick"},
                            { value:"NL", name:"Newfoundland and Labrador"},
                            { value:"NS", name:"Nova Scotia"},
                            { value:"NT", name:"Northwest Territories"},
                            { value:"NU", name:"Nunavut"},
                            { value:"ON", name:"Ontario"},
                            { value:"PE", name:"Prince Edward Island"},
                            { value:"QC", name:"Quebec"},
                            { value:"SK", name:"Saskatchewan"},
                            { value:"YT", name:"Yukon"}
                        ]

const stateOptions = [
                        { value:"AL", name:"Alabama"},
                        { value:"AK", name:"Alaska"},
                        { value:"AZ", name:"Arizona"},
                        { value:"AR", name:"Arkansas"},
                        { value:"CA", name:"California"},
                        { value:"CO", name:"Colorado"},
                        { value:"CT", name:"Connecticut"},
                        { value:"DE", name:"Delaware"},
                        { value:"DC", name:"District Of Columbia"},
                        { value:"FL", name:"Florida"},
                        { value:"GA", name:"Georgia"},
                        { value:"HI", name:"Hawaii"},
                        { value:"ID", name:"Idaho"},
                        { value:"IL", name:"Illinois"},
                        { value:"IN", name:"Indiana"},
                        { value:"IA", name:"Iowa"},
                        { value:"KS", name:"Kansas"},
                        { value:"KY", name:"Kentucky"},
                        { value:"LA", name:"Louisiana"},
                        { value:"ME", name:"Maine"},
                        { value:"MD", name:"Maryland"},
                        { value:"MA", name:"Massachusetts"},
                        { value:"MI", name:"Michigan"},
                        { value:"MN", name:"Minnesota"},
                        { value:"MS", name:"Mississippi"},
                        { value:"MO", name:"Missouri"},
                        { value:"MT", name:"Montana"},
                        { value:"NE", name:"Nebraska"},
                        { value:"NV", name:"Nevada"},
                        { value:"NH", name:"New Hampshire"},
                        { value:"NJ", name:"New Jersey"},
                        { value:"NM", name:"New Mexico"},
                        { value:"NY", name:"New York"},
                        { value:"NC", name:"North Carolina"},
                        { value:"ND", name:"North Dakota"},
                        { value:"OH", name:"Ohio"},
                        { value:"OK", name:"Oklahoma"},
                        { value:"OR", name:"Oregon"},
                        { value:"PA", name:"Pennsylvania"},
                        { value:"PR", name:"Puerto Rico"},
                        { value:"RI", name:"Rhode Island"},
                        { value:"SC", name:"South Carolina"},
                        { value:"SD", name:"South Dakota"},
                        { value:"TN", name:"Tennessee"},
                        { value:"TX", name:"Texas"},
                        { value:"UT", name:"Utah"},
                        { value:"VT", name:"Vermont"},
                        { value:"VA", name:"Virginia"},
                        { value:"WA", name:"Washington"},
                        { value:"WV", name:"West Virginia"},
                        { value:"WI", name:"Wisconsin"},
                        { value:"WY", name:"Wyoming"}
]

export default class StepUserInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableButton: true, 
            question: "Complete Your Profile to Start Earning Rewards!",
            autoComplete:"false"
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({enableEmail: this.props.user.email === undefined ? false : true})
    }

    renderStates = () => {
        let output = [];
        let list = this.props.i18n.region === "CA" ?  provinceOptions : stateOptions;

            list.forEach((data, idx) => {
                output.push(
                 <option  key={idx} value={data.value}>{data.name}</option>
                )
            });

        return output
    }
  

    render() {  
        let disableButton = true;

        if ((this.props.user.email !== "" && this.props.user.email !== undefined)  &&
            this.props.user.name !== "" &&
            this.props.user.birthday !== "" &&
            this.props.user.address.city !== "" &&
            this.props.user.address.country !== "" &&
            this.props.user.address.state !== "" &&
            this.props.user.address.street !== "" &&
            this.props.user.address.street_number !== "" &&
            this.props.user.address.zip !== "" &&
            !this.props.validationFields.user_birthday.isInvalid) {
            disableButton = false
        }

        let country = this.props.user.address.country
            if (this.props.user.address.country === "US") {
                country = "United States"
            } else if (this.props.user.address.country === "CA") {
                 country = "Canada"
            } else if (this.props.user.address.country === "DE") {
                country = "Germany"
            } else if (this.props.user.address.country === "GB") {
                country = "United Kingdom"
            } 

        let stateLabel = "State";
        let zipLabel = "Zip";
        let streetNumberPlaceholder = "45";
        let streetPlaceholder = "Rockefeller Plaza";
        let cityPlaceholder = "Manhattan";
        let zipPlaceholder = "10111";
        let zipMaxlength = "5";

            if (this.props.i18n.region === "CA") {
                stateLabel = "Province";
                zipLabel = "Postal Code";
                streetNumberPlaceholder = "220";
                streetPlaceholder = "Yonge St";
                cityPlaceholder = "Toronto";
                zipPlaceholder = "M5B 2H1"
                zipMaxlength = "7";
            }

        return(
            <>
            <Container as="section" className="page-header">
                <h1 className="display-3">{this.state.question}</h1>
                <p className="lead">Your information is used to help create your tester profile. Once you complete your profile, we can match you up with products and brands that better suit your lifestyle. The more tests we can match you with, the more rewards you can earn.</p>
                <p ><strong><em>You can delete your account and personal data at any time through your user profile.</em></strong> </p>
            </Container>
              <Container  as="section" className="page-form">
                      <Row>
                            <Col md={4}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>Email<sup>*</sup> </Form.Label>
                                    <Form.Control isInvalid={this.props.validationFields.user_email.isInvalid} className="mb-0" size="lg" type="email" name="user_email" value={this.props.user.email} onChange={this.props.handleInputChange}  disabled={this.state.enableEmail} required/>
                                    {/* <Form.Control isInvalid={this.props.validationFields.user_email.isInvalid} className="mb-0" size="lg" type="email" name="user_email" value={this.props.user.email} onChange={this.props.handleInputChange}  required/> */}
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.validationFields.user_email.message}
                                    </Form.Control.Feedback>
                                </Form.Group> 

                                <Row className="optin-mobile">
                                    <div key={"div_" } className="custom-checkbox --small">
                                        <input 
                                            type="checkbox" 
                                            name="optin_promotional_email"
                                            checked={this.props.user.optin_promotional_email}
                                            onChange={this.props.handleInputChange} 
                                            
                                            key={"input_"}
                                            id={"optin_promotional_email"}
                                            htmlFor={"optin_promotional_email"} />
                                            <label key={"label_"} htmlFor={"optin_promotional_email"} >
                                                <div className="label_text">I agree to receive promotional email communications from Deal Panda.</div>
                                            </label>
                                            {/* <div className={`custom-label-msg ${ppCustomMsgClass}`} >No worries! You’ll receive an email from PayPal to create an account when you receive your first reward payout. </div> */}
                                    </div>
                                </Row>
                                
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formGroupName">
                                    <Form.Label>Name<sup>*</sup> </Form.Label>
                                    <Form.Control className="mb-0" size="lg" type="text" placeholder="Full Name" name="user_name" onChange={this.props.handleInputChange} value={this.props.user.name}  required/>
                                    {/* <Form.Control.Feedback type="invalid">
                                        {this.props.validationFields.name.message}
                                    </Form.Control.Feedback> */}
                                </Form.Group> 
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formGroupBirthday">
                                    <Form.Label>Birthday<sup>*</sup></Form.Label>
                                    <Form.Control isInvalid={this.props.validationFields.user_birthday.isInvalid}  className={`mb-0 ${this.props.user.birthday === "" ? "--empty" : ""}`} size="lg" type="date" placeholder="DD/MM/YY" name="user_birthday" value={this.props.user.birthday} onChange={this.props.handleInputChange}  required/>
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.validationFields.user_birthday.message}
                                    </Form.Control.Feedback>
                                </Form.Group> 
                            </Col>
                        </Row>
                        <div className="form-shipping-info">
                            <div className="form-subtitle">
                                <p><strong>Your Shipping Address </strong> </p>
                                <p className="text-small"><em>We'll ship your test products to this address</em></p>
                            </div>
                            <Row>
                                <Col sm={12} md={4} lg={2}>
                                    <Form.Group controlId="formGroupLocationStreetNumber">
                                        <Form.Label>Street #<sup>*</sup></Form.Label>
                                        <Form.Control name="street_number" 
                                            className="mb-0"  
                                            autoComplete="false" 
                                            size="lg" 
                                            type="number" 
                                            placeholder={streetNumberPlaceholder}
                                            value={this.props.user.address.street_number} 
                                            onChange={this.props.handleAddressChange} 
                                            required/>
                                        {/* <Form.Control.Feedback type="invalid">
                                            {this.props.validationFields.user_location.message}
                                        </Form.Control.Feedback> */}
                                        
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={8}  lg={6}>
                                    <Form.Group controlId="formGroupLocationStreet">
                                        <Form.Label>Street Address<sup>*</sup></Form.Label>
                                        <Form.Control name="street"  
                                            className="mb-0"  
                                            autoComplete="false" 
                                            size="lg" 
                                            type="text" 
                                            placeholder={streetPlaceholder} 
                                            value={this.props.user.address.street} 
                                            onChange={this.props.handleAddressChange} 
                                            required/>
                                        {/* <Form.Control.Feedback type="invalid">
                                            {this.props.validationFields.user_location.message}
                                        </Form.Control.Feedback> */}
                                      
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="formGroupLocationSuite">
                                        <Form.Label> Address 2</Form.Label>
                                        <Form.Control  className="mb-0" size="lg" type="text"  name="suite" placeholder="Apartment, suite, or unit number (Optional)" value={this.props.user.address.suite} onChange={this.props.handleAddressSuiteChange}/>
                                    </Form.Group> 
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col sm={12}>
                                    <Form.Group controlId="formGroupLocationSuite">
                                        <Form.Label> Address 2</Form.Label>
                                        <Form.Control  className="mb-0" size="lg" type="text"  name="suite" placeholder="Apartment, suite, or unit number (Optional)" value={this.props.user.address.suite} onChange={this.props.handleAddressSuiteChange}/>
                                    </Form.Group> 
                                </Col>
                            </Row> */}

                            <Row>
                             
                                <Col sm={12} md={3}>
                                    <Form.Group controlid="formGroupLocationCity">
                                    <Form.Label>City <sup>*</sup></Form.Label>    
                                    <Form.Control 
                                        name="city"  
                                        className="mb-0"  
                                        autoComplete="false" 
                                        size="lg" 
                                        type="text" 
                                        placeholder={cityPlaceholder} 
                                        value={this.props.user.address.city} 
                                        onChange={this.props.handleAddressChange} 
                                        required/>
                                    </Form.Group>
                                </Col>
                          
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="formGroupLocationState">
                                        <Form.Label>{stateLabel}<sup>*</sup></Form.Label>
                                        <Form.Control 
                                            name="state" 
                                            className="mb-0"  
                                            autoComplete="false" 
                                            size="lg" 
                                            as="select" 
                                            value={this.props.user.address.state} 
                                            onChange={this.props.handleAddressChange} 
                                            required>
                                            <option value="">Please Select One</option>

                                            {this.renderStates()}
                                        </Form.Control>

                           
                                      
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="formGroupLocationZip">
                                        <Form.Label>{zipLabel}<sup>*</sup></Form.Label>
                                        <Form.Control 
                                            name="zip"  
                                            className="mb-0"  
                                            autoComplete="false" 
                                            size="lg" 
                                            type="text"
                                            maxLength={zipMaxlength}
                                            placeholder={zipPlaceholder} 
                                            value={this.props.user.address.zip} 
                                            onChange={this.props.handleAddressChange} 
                                            required/>
                                        {/* <Form.Control.Feedback type="invalid">
                                            {this.props.validationFields.user_location.message}
                                        </Form.Control.Feedback> */}
                                      
                                    </Form.Group> 
                                </Col>
                                <Col sm={12} md={2}>
                                    <Form.Group controlId="formGroupLocationSuite">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control  className="mb-0" size="lg" type="text"  name="country" placeholder="" value={country} onChange={this.props.handleAddressSuiteChange} disabled/>
                                    </Form.Group> 
                                </Col>
                            </Row>
                            <div className="required-footnote"><sup>*</sup> Required</div>

                            
                            {/* <Row>
                                <Col sm={12} md={8}>
                                    <Form.Group controlId="formGroupLocation">
                                        <Form.Label>Street address</Form.Label>
                                        <Form.Control isInvalid={this.props.validationFields.user_location.isInvalid} name="street"  onChange={this.props.handleAddressChange}  className="mb-0"  autoComplete="false" size="lg" type="text" placeholder="220 Yonge St" value={this.props.user.address} required/>
                                        <Form.Control.Feedback type="invalid">
                                            {this.props.validationFields.user_location.message}
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-muted">
                                           Please provide your address in the correct format: Street number, City, State, Zip (ex.p&nbsp;220p&nbsp;Yongep&nbsp;St,&nbsp;Toronto,&nbsp;ON&nbsp;M5B&nbsp;2H1)
                                        </Form.Text>
                                    </Form.Group> 
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formGroupLocationSuite">
                                        <Form.Label> Unit number</Form.Label>
                                        <Form.Control  className="mb-0" size="lg" type="text"  name="suite" placeholder="Apartment, suite, or unit number (Optional)" value={this.props.user.address.suite} onChange={this.props.handleAddressSuiteChange}/>
                                    </Form.Group> 
                                </Col>
                            </Row> */}
                        </div>
                        <Row  className="optin-desktop">
                            <div key={"div_" } className="custom-checkbox --small">
                                <input 
                                    type="checkbox" 
                                    name="optin_promotional_email"
                                    checked={this.props.user.optin_promotional_email}
                                    onChange={this.props.handleInputChange} 
                                    
                                    key={"input_"}
                                    id={"optin_promotional_email"}
                                    htmlFor={"optin_promotional_email"} />
                                    <label key={"label_"} htmlFor={"optin_promotional_email"} >
                                        <div className="label_text">I agree to receive promotional email communications from Deal Panda.</div>
                                    </label>
                                    {/* <div className={`custom-label-msg ${ppCustomMsgClass}`} >No worries! You’ll receive an email from PayPal to create an account when you receive your first reward payout. </div> */}
                            </div>
                        </Row>
                        <Row>
                            <Form.Group className="mb-5 apply-step_navigation apply-step_navigation-buttons">
                                <Button type="submit" variant="success" disabled={disableButton} data-question={this.state.question}>Next <FontAwesomeIcon icon={faChevronRight} fixedWidth /> <FontAwesomeIcon icon={faChevronRight} fixedWidth /></Button>
                            </Form.Group> 
                        </Row>

                </Container>
            </>

        )
    }
    
    static propTypes = {
        validationFields: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleAddressChange: PropTypes.func.isRequired,
        handleAddressSuiteChange:PropTypes.func.isRequired,
        processing: PropTypes.bool.isRequired
    }
}