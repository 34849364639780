import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import './../apply.scss';

import Autosuggest from '../../../components/snippets/autosuggest.js';
import AutosuggestList from '../../../i18n/en/autocomplete-industry.json'

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons'


export default class StepIndustry extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            question: "In which industry do you work?",
            value: "",
            autosuggest: {
                placeholder: "Start typing to see a list ",
                list : AutosuggestList
            }
        }
    }

    handleInputChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    render() {

        let disableButton = true
            
        if (this.state.value !== "" ) {
            disableButton = false
        }

        return(
            <Container as="section" className="page-header">
                <h1 className="display-3">{this.state.question}</h1>
                    <Autosuggest
                        name="user_industry"
                        onChange={this.handleInputChange}
                        value= {this.state.value}
                        placeholder={AutosuggestList[0].name}
                        autosuggestProps={this.state.autosuggest}
                    />
                <Form.Group className="mb-5 apply-step_navigation">
                    <Button disabled={disableButton} type="submit" variant="success" name="user_industry" onClick={this.props.handleInputChange} value={this.state.value} data-question={this.state.question} >Next <FontAwesomeIcon icon={faChevronRight} fixedWidth /></Button>
                </Form.Group>    
            </Container>
        )
    }
    
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        user_industry: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired
    }
}