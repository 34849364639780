import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import PreLoader from '../snippets/PreLoader'

import './admin-rewards.scss';

export default class AdminRewards extends Component {

    constructor(props) {
		super(props);
		this.state = {
            rewards: [],
            page: 1,
            resultsPerPage: 20
        }
    }

    componentDidMount = () => {
        this.fetchRewards(this.state.page, this.state.resultsPerPage);
    }

    fetchRewards = (page, resultsPerPage) => {
        const params = {
            page: page,
            limit: resultsPerPage,
            filter: "admin"
        }
        this.props.get("/api/v1/rewards", params).then(response => {
            this.setState({
                rewards: response.results 
            });
        }).catch(err  => {
            console.error(err);
        });
    }

    renderList = () => {
        let lst = [];
        this.state.rewards.forEach(item => {
            
            let styleCard = { 
                backgroundColor: item.color , 
                backgroundImage: "url("+ item.image +")" 
            }
            
            lst.push(
                // inactive
                <tr key={item._id}>
                    <td className="table-data_image">
                        <div className="image-wrapper image-wrapper_rewards" style={styleCard}></div>
                    </td>
                    <td className="table-data_name">{item.name}</td>
                    <td className="text-center">{item.region}</td>
                    <td className="table-data_status">
                        <Badge variant={item.status === "active" ? "success" : "secondary"}>{item.status}</Badge>
                    </td>
                    <td className="text-center">{item.type}</td>
                    <td >
                        <div className="table-data_action-bar">
                            <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Link className="dropdown-item" to={{
                                        pathname:"/admin/rewards/edit/" + item._id, 
                                        state: "edit"
                                        }}>
                                        Edit
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </td>
                </tr>
            );
        });

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="6"><PreLoader /></td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {
        return (
            <React.Fragment>
                {this.state.rewards.length === 0 ? (
                    <PreLoader />
                ) : (
                    <section className="rewards_overview">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="reward_img"></th>
                                    <th className="reward_name">Name</th>
                                    <th className="text-center">Region</th>
                                    <th className="table-data_status">Status</th>
                                    <th className="text-center">Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderList()}
                            </tbody>
                        </Table>
                    </section>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired
    }
}