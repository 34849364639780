export default class ScriptLoader {
    constructor(scripts) {
        this.loaded = [];
        this.load(scripts || []);
    }

    load = (scripts) =>{
        scripts.forEach(src => {
            this.loadScript(src).then(() => {
                this.loaded.push(src);
            });
        });
    }

    loadScript = (src, attr) => {
        if (src === "") {
            return Promise.resolve(true);
        }
        if (this.loaded.indexOf(src) >= 0) {
            return Promise.resolve(src);
        }

        return new Promise((resolve, reject) => {
            let body = document.getElementsByTagName("body")[0];
            let tag = document.createElement("script");
            tag.type = "text/javascript";
            tag.async = false;

            if (attr) {
                for (let a in attr) {
                    tag.setAttribute(a, attr[a]); 
                }
            }

            tag.addEventListener("load", () => {
                resolve(src);
            });
            tag.addEventListener("error", (err) => {
                console.log(err, src);
                reject(src);
            });

            tag.src = src;
            body.appendChild(tag);
            return tag;
        });
    }
}