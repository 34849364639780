import React, { Component } from "react";
import Flags from '../snippets/flags' 
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Pagination from 'react-bootstrap/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import PreLoader from '../snippets/PreLoader'

import './admin-rewards.scss';


export default class AdminRewardsOrders extends Component {

    constructor(props) {
		super(props);
		this.state = {
            orders: [],
            numOrders: 0,
            page: 1,
            resultsPerPage: 20,
            numPages: 1,
            loading: true,
            loadingOrders: []
        }
    }

    componentDidMount = () => {
        this.fetchOrders(this.state.page, this.state.resultsPerPage);
    }

    fetchOrders = (page, resultsPerPage) => {
        const params = {
            page: page,
            limit: resultsPerPage,
            status: "processing",

        }
        return this.props.get("/api/v1/rewards/orders", params).then(response => {
            const numPages = Math.ceil(response.total_results / resultsPerPage);
            this.setState({
                orders: response.results,
                numOrders: response.total_results,
                numPages: numPages,
                page: page,
                loading: false
            });
            return Promise.resolve(response.results);
        }).catch(err  => {
            console.error(err);
            return Promise.reject(err);
        });
    }

    handlePagingClick = (p) => {
        this.fetchOrders(p, this.state.resultsPerPage);
    }

    handleMarkPaid = (orderId) => {
        if (!window.confirm("Confirm this order has been paid")) {
            return false;
        }
        let loadingOrders = this.state.loadingOrders;
        loadingOrders.push(orderId);
        this.setState({loadingOrders: loadingOrders});

        let formData = new FormData();
        formData.append("action", "paid");
        this.props.put("/api/v1/rewards/order/" + orderId, formData).then(response => {
            if (response.result) {
                // remove from results list
                let idx = -1;
                let orders = this.state.orders;
                for (let i=0; i<orders.length; i++) {
                    if (orders[i]._id === orderId) {
                        idx = i;
                        break;
                    }
                }

                if (idx >= 0) {
                    orders.splice(idx, 1);
                    this.setState({
                        orders: orders
                    });
                }
            }
        });
    }

    renderList = () => {
        let lst = [];
        this.state.orders.forEach(item => {
            let isLoading = this.state.loadingOrders.indexOf(item._id) >= 0;
            lst.push(
                // inactive
                <tr key={item._id}>
                    <td data-date={item.created_at}>{dayjs(item.created_at).format("MMMM D, YYYY")}</td>
                    <td className="table-data_name">{item.user_name}</td>
                    <td><Link to={"/admin/users/detail/" + item.user_id}>{item.user_email}</Link></td>
                    <td>{item.paypal_email}</td>
                    <td className="text-center">
                        <Flags region={item.reward_region}/>
                    </td>
                    <td className="text-center">{item.reward_type}</td>
                    <td className="table-data_name">{item.reward_name}</td>
                    <td className="text-center">{item.points/100}</td>
                    <td className="table-data_status">{item.status}</td>
                    
                    
                    <td className="td_button">
                        <Button variant="success" size="sm" onClick={() => {this.handleMarkPaid(item._id)}} disabled={isLoading}>
                            {
                                isLoading ? (
                                    <span><Spinner as="span" animation="border" size="sm" /> Loading</span>
                                ) : (
                                    <span><FontAwesomeIcon icon={faCheckCircle} size="sm"  title="Mark Paid" />Mark as Paid</span>
                                )
                            }
                        </Button>
                        
                    </td>
                </tr>
            );
        });

        if (this.state.orders.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="6">Nothing to do here...</td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {
        let paging = [];

        if (this.state.numPages > 1) {
            for (let n = 1; n <= this.state.numPages; n++) {
                paging.push(
                    <Pagination.Item key={n} active={n === this.state.page} onClick={() => {this.handlePagingClick(n)}} >
                        {n}
                    </Pagination.Item>
                );
            }
        }

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <PreLoader />
                ) : (
                    <section className="rewards_orders">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>PayPal Email</th>
                                    <th className="text-center">Region</th>
                                    <th className="text-center">Type</th>
                                    <th>Reward</th>
                                    <th>Amount</th>
                                    <th className="table-data_status">Status</th>
                                    
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderList()}
                            </tbody>
                        </Table>
                        <div>
                            <Pagination>{paging}</Pagination>
                        </div>
                    </section>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired
    }
}