import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logoAmz from '../../../assets/images/amz-logo.png'


export default class ReviewStepShare extends Component {

    render() {

        let disabledButton = true
            
            if(this.props.hasReviewed !== 2) {
                disabledButton = false
            }

        return (
            <Row>
                <Col md={5} className="product-image"><Image src={this.props.bonusDeal.product_image_url} /></Col>
                <Col md={7} className="product-detail">
                    {/* <div className="point-status point-status_step-2">
                        <span className="additiona-points_title">Additional Panda Points:</span> <PointsStatus points={200}></PointsStatus>
                    </div> */}
                    <h1>Now Share your Experience with Customers</h1>
                    <p >Thank you for sharing your thoughts with us!</p>
                    <p >Your opinions can also help shoppers just like you learn more about the product and decide whether it is right for them.</p>
                    <p className="subtitle"> This product is currently available at the following online retailers:</p>
                    <ul className="brands">
                        <li>  <Image src={logoAmz} /></li>
                    </ul>
                    
                    <Form onSubmit={this.props.handleSubmit}>                        
                            
                            <Form.Label>Have you submitted a review for this product on Amazon already?</Form.Label>
                            <Form.Group  className="review_have-you-submitted">
                                 <Form.Check
                                        type="radio"
                                        label="No"
                                        name="hasReviewed"
                                        id="reviewsubmitted_no"
                                        className="custom-radio"
                                        checked={this.props.hasReviewed === 0}
                                        value={0}
                                        onChange={this.props.handleInputChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Yes"
                                        name="hasReviewed"
                                        id="reviewsubmitted_yes"
                                        onChange={this.props.handleInputChange}
                                        checked={this.props.hasReviewed === 1}
                                        value={1}
                                        className="custom-radio"
                                    />
                                </Form.Group>
                        <Button type="submit" variant="success" className="btn-submit" disabled={disabledButton} block>Share My Experience</Button>
                        <Button variant="link" className="skip-btn" onClick={this.props.handleJumpStep.bind(this, 5)} disabled={this.props.disableSubmit}>Skip this step</Button>
                    </Form>
                </Col>
            </Row>
        );
    }

    static propTypes = {
        bonusDeal: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hasReviewed: PropTypes.number.isRequired,
        userProfile: PropTypes.object.isRequired,
        handleJumpStep: PropTypes.func.isRequired
    };
}