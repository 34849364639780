export default {

    _getPropName(type, param) {
        let output = `_dp_${param.profileId}_${type}_${param.id}`

        if (param["id"] !== undefined ) {
            // Check if data is from a campaign flow  
            output = `_dp_${param.profileId}_${type}_${param.id}`
        } else {
            // Default
            output = `_dp_${param.profileId}_${type}`
        }

        return output
    },
    
    set(type, param) {
        localStorage.setItem(this._getPropName(type, param), JSON.stringify(param)); 
    },

    get(type, param) {
        if (localStorage.getItem(this._getPropName(type, param)) != null) {
            return  JSON.parse(localStorage.getItem(this._getPropName(type, param)))
        } else {
            return null
        }
    },

    clear(type, param) {
        localStorage.removeItem(this._getPropName(type, param));                
    }
}