import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons'

export default class StarRating extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numStars: 5,
            radioStyles: {
                display: "none",
                position: "absolute",
                marginLeft: -9999
            }
        }
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        this.setState({value: value});
    }

    handleInputClick = (e) => {
        e.stopPropagation();
        return;
    }

    renderStars = () => {
        let stars = [];
        for (let i=1; i<=this.state.numStars; i++) {
            let starIcon = this.props.value >= i ? faStarSolid : faStar;

            const input = <input type="radio" 
                            name={this.props.inputName} 
                            value={i} 
                            defaultChecked={this.props.value === i}
                            onClick={this.handleInputClick} 
                            onChange={this.props.onChange}
                            style={this.state.radioStyles}
                            key={"input_" + i}
                            id={"star_" + i}
                            className="star-rating-input" />
            const label = (<label key={"label_" + i} htmlFor={"star_" + i} className="star-rating-label">
                             <span><FontAwesomeIcon icon={starIcon} /></span>
                            </label>);
            stars.push(input);
            stars.push(label);
        }
        return stars;
    }

    renderStarMsg = () => {
        let text = " "

            switch (this.props.value) {
                case 1:
                    text = "Aweful"
                    break;
                case 2:
                    text = "Poor"
                    break;
                case 3:
                    text = "Fair"
                    break;
                case 4:
                    text = "Good"
                    break;
                case 5:
                    text = "Excellent"
                    break;
                default:
                    text = ""
         }

        return  <div className="star-rating-msg">{text}</div>;
    }

    render() {
        let starRatingMsg = ""

            if(this.props.showText) {
                starRatingMsg = 
                this.renderStarMsg()
               
            }

        return (
            <div className="star-rating-container">{this.renderStars()} {starRatingMsg}</div>
        );
    }

    static propTypes = {
        showText: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.number.isRequired,
        inputName: PropTypes.string.isRequired
    };
}