import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default class AdminUserRegion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            feedback: {},
            loading: true,
            email: "",
            region: "US"
        }
    }

    componentDidMount = () => {
        this.setState({
            email: this.props.userProfile.email,
            region: this.props.userProfile.region,
            loading: false
        });
    }

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({[inputName]: inputValue});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let formData = new FormData(e.target);
        formData.append("action", "region");
        this.props.post("/api/v1/user/settings", formData).then(response => {
            if (response.result) {
                // show feedback
                this.setState({
                    loading: false,
                    feedback: {
                        type: "success",
                        message: "settings updated"
                    }
                });
            }
        }).catch(err => {
            err.json().then(response => {
                this.setState({
                    feedback: {
                        type: "danger",
                        message: response.error,
                    },
                    loading: false
                });

            });
        });
    }

    render = () => {
        
        let showAlert = this.state.feedback.message !== null;

        return (
            <section className="reward_create">
                    <Alert show={showAlert} variant={this.state.feedback.type}>{this.state.feedback.message}</Alert>
                    <Container fluid>
                        
                        <Form onSubmit={this.handleSubmit}>
                            
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" value={this.state.email} onChange={this.handleInputChange} required />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Region</Form.Label>
                                <Form.Control as="select" name="region" value={this.state.region} onChange={this.handleInputChange} required>
                                    <option value="US">US</option>
                                    <option value="CA">CA</option>
                                </Form.Control>
                            </Form.Group>
                            
                            <Form.Group className="controller">
                                <Button type="submit" variant="success" disabled={this.state.loading}>Update</Button>
                            </Form.Group>
                        </Form>
                            
                    </Container>
            </section>
        );
    }

    static propTypes = {
        post: PropTypes.func.isRequired,
        userProfile: PropTypes.object.isRequired
    }
}