import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';

export default class ModalDefault extends Component {

    render() {
        let footer = ""

            if (this.props.modal.footer !=="") {
                footer = 
                <Modal.Footer>
                    {this.props.modal.footer}
                </Modal.Footer>
            }
        return (
             <Modal show={this.props.modal.show} size={this.props.modal.size} onHide={this.props.closeModal} className={this.props.modal.className} centered>
                <Modal.Header>
                    <Modal.Title>{this.props.modal.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.modal.body}
                </Modal.Body>
                {footer}
             
            </Modal> 
        );
    }

    static propTypes = {
        modal: PropTypes.object.isRequired,
        closeModal: PropTypes.func.isRequired
    };
}