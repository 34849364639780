import React, { Component } from "react";
import PropTypes from "prop-types";
import Body from '../../utils/utils-body'
import PreLoader from '../snippets/PreLoader'
import './apply.scss';

export default class Apply extends Component {
 
    componentDidMount = () => {     
  
        Body.pageClass("add", `page_apply --redirection`);   
       
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("code")) {
            let path = "/us/apply";
            let search = "";
            let redirect = `https://${window.location.host}/apply`;
            let loginState = urlParams.get("state");
            let profileFields = "";
            if (loginState) {
                loginState = JSON.parse(loginState);
                if (loginState.hasOwnProperty("pathname")) {
                    path = loginState.pathname;
                }
                if (loginState.hasOwnProperty("search")) {
                    search = loginState.search;
                }
                if (loginState.hasOwnProperty("redirect")) {
                    redirect = loginState.redirect;
                }
                if (loginState.hasOwnProperty("profileFields")) {
                    profileFields = loginState.profileFields;
                }
            }
            let formData = new FormData()
            formData.append("code", urlParams.get("code"));
            formData.append("redirect_uri", redirect);
            formData.append("state", urlParams.get("state"));
            if (profileFields !== "") {
                formData.append("profile_fields", profileFields);
            }
            this.props.post("/api/v1/fb/auth/token", formData).then(response => {
                // console.log("fb auth response", response);
                if (response.hasOwnProperty("profile")) {
                    this.props.history.push({
                        pathname: path,
                        search: search,
                        state: {
                            fbProfile: response.profile,
                            fbAccessToken: response.token.access_token
                        }
                    });
                } else if (response.hasOwnProperty("error")) {
                    throw new Error(response.error);
                } else {
                    console.warn("fb auth response", response);
                    throw new Error("unknown error");
                }
            }).catch(err => {
                console.error(err);
                window.location.href = path + search;
            });
        } else {
            this.props.get("/api/v1/user/country", {} ).then(response => {
                let country =  response.country !== null ? response.country : "CA"
                let param = window.location.search !== "" ? window.location.search : ""      
                    // this.props.history.replace(`${`/${country.toLowerCase()}/apply${param}`}`)   
                    window.location.href = `${`/${country.toLowerCase()}/apply${param}`}`;
            }).catch(err => {
                this.props.history.push(`${`/login`}`)
                console.log(err)
            });
        }

    }

    componentWillUnmount = () => {
        Body.pageClass("remove", `page_apply --redirection`);
    }

    render() {
            return (
                <React.Fragment>
                    <PreLoader />
                </React.Fragment>
            )
    }

    static propTypes = {
        history:PropTypes.object,
        get: PropTypes.func.isRequired,
    }
}