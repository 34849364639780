import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

class contentFAQ extends Component {
  render() {
    return (
        <div className="content">
            <Accordion >
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    What are Panda Points?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                    Panda Points are bonus points you can convert to receive popular gift cards like BestBuy, Netflix, Spotify, and iTunes. (Gift card availability may vary.) <br/>
                    You also have the option to redeem your points to your PayPal account. 100 panda points equals  $1.<br/>
                    The minimum required balance to redeem is 2,000 Panda Points.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    How can I earn Panda Points?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body>
                    There are a couple of ways you can earn Panda Points.
                    Inviting friends and family to Deal Panda is one way. Completing additional surveys is another.
                    To learn more visit the <a href="bonus">Rewards</a> page in your account.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    What can I get with Panda Points?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body>
                    Panda Points can be redeemed for popular gift cards like BestBuy, Netflix, Spotify, and iTunes.* <br/>
                    You also have the option to redeem your points to your PayPal account. <br/>
                    *The available  gift card selection changes from time to time.<br/>
                    Please refer to <a href="/redeem">this page</a> for current availability.
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
              
            </Accordion>
        </div>
    );
  }
}

export default contentFAQ;
