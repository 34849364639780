import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import './../apply.scss';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons'


export default class StepWebsites extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            question: "Which websites do you shop online on?",
            websites : [
                "AliExpress",
                "Amazon",
                "eBay",
                "iHerb",
                "Newegg",
                "Rakuten",
                "Target",
                "Walmart"
            ],
            selected: 0,
            selectedAnswer: []
        }
    }

    componentDidMount = () => {
        // this.setState({
        //     selectedAnswer : [...this.state.websites[0]],
        //     selected: 0
        // })
    }

    handleCheckboxChange = (answer, idx) => {
        let selectedAnswer = [...this.state.selectedAnswer]

        if(selectedAnswer.indexOf(answer) !== -1){
            // selectedAnswer.push(answer)
            selectedAnswer.splice(selectedAnswer.indexOf(answer), 1)
        } else{
            selectedAnswer.push(answer)
        }
    
        this.setState({
            selectedAnswer: selectedAnswer
        })
    }

    renderWebsites = () => {
        let output = [];
        
        this.state.websites.forEach(function (value, idx ){
            let checked = false
            
                if(this.state.selectedAnswer.indexOf(value) !== -1){
                    // selectedAnswer.push(answer)
                    checked = true
                } else{
                    checked = false
                }
            
            const input = <input type="checkbox" 
                            name="amz-duration"
                            value={value} 
                            checked={checked}
                            onChange={this.handleCheckboxChange.bind(this, value, idx)} 
                            key={"input_" + idx}
                            id={"amz-duration_" + idx}
                            htmlFor={"duration_" + value} />
            
            const label =   <label key={"label_" + idx} htmlFor={"amz-duration_" + idx} >
                                <div className="label_text">{value}</div>
                            </label>

            output.push(
                <div key={"div_" + idx} className="custom-checkbox">{input}{label}</div>
            );
        }.bind(this))

  
        return output;
    }

    render() {
        let disableButton = true

            if (this.state.selectedAnswer.length > 0 ) {
                disableButton = false
            }
            
        return(
            <Container as="section" className="page-header">
                <h1 className="display-3">{this.state.question}</h1>
                <p className="lead">( Select as many as you like )</p>
                <Form.Group className="mb-3 mt-3 form-group-checkbox">
                    {this.renderWebsites()}
                </Form.Group>    
                <Form.Group className="mb-5 apply-step_navigation">
                    <Button disabled={disableButton} type="submit" variant="success" name="websites" value={this.state.selectedAnswer} data-question={this.state.question} onClick={this.props.handleInputChange} >Next <FontAwesomeIcon icon={faChevronRight} fixedWidth /></Button>
                </Form.Group>    
            </Container>
        )
    }
    
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired
    }
}