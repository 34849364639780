import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import UPLOAD from '../../snippets/upload-files'
import RETAILS from '../../../utils/retails'

import '../Compare.scss';

export default class ProofOfPurchaseStep extends Component {
    focus(query_selector){
        let input = document.querySelector(query_selector);
        input.focus();
    }

    render() {

        let purchaseCta = ""
            

            if (this.props.step.purchaseCta !== "") {
                purchaseCta = <Button variant="light" className={`purchase_cta-button --${this.props.product.deal_channel}`}> {this.props.step.purchaseCta} </Button>
                // purchaseCta = <Button variant="light" className={`purchase_cta-button --${"AMAZON"}`}> Add to Cart</Button>
                
            } else {
                purchaseCta = "Add to Cart"
            }

        return (
            <React.Fragment>
                <Row className="step-detail step-detail--proof-of-purchase">
                    
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">
                            <div className="step-detail_image-wrapper" style={{backgroundImage: `url(${this.props.product.product_image_url})`}}>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current}</h2>
                            <ol>
                                <li>
                                    Continuing from the page you are on, click on {purchaseCta} to purchase the <strong>{this.props.product.deal_name}</strong> from <strong><a className="link--override" href={`https://${RETAILS.getDomain(this.props.product.deal_channel)}`} target="_blank" rel="noopener noreferrer">{RETAILS.getDomain(this.props.product.deal_channel)}</a></strong>.*
                                    <div className="important-text mt-2 mb-3">* Reimbursements are processed after you upload both a screenshot of the completed order and published review. You’ll be fully reimbursed for ${this.props.product.deal_amount} plus applicable taxes and shipping.</div>
                                </li>
                                <li>
                                    Take screenshots of your completed order. Make sure your name, order number and the date are visible.
                                </li>
                                {/* <li>
                                    Proceed to purchase the product and take screenshots of your completed order. Make sure the item, subtotal, delivery, taxes, name, order number, and the date are visible in the screenshots you provide.
                                </li> */}
                                <li>
                                    Upload screenshots of your completed order for verification.
                                    <UPLOAD handleOnSuccess={this.props.handleOnSuccess} disabled={this.props.state.loading} title="Proof of purchase" objectKey={this.props.uploader.key} data={this.props.uploader.screenshots} userProfile={this.props.userProfileId} />
                                    <div className="step_additiona-note"><strong>Questions about the process?</strong>  No worries, we’re here to help and make sure you get reimbursed. <Link to="/contact">Contact us</Link>.</div>
                                </li>
                            </ol>
                           
                        </Col>
                </Row>
            </React.Fragment>   
        );
    }
    static propTypes = {
        state: PropTypes.object.isRequired,
        handleOnSuccess: PropTypes.func.isRequired,
        product: PropTypes.object,
        step: PropTypes.object.isRequired,
        uploader: PropTypes.object.isRequired,
        userProfileId : PropTypes.string.isRequired
    };

}
