import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import dayjs from 'dayjs';

export default class PointsHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            page: 1,
            numPages: 1,
            pageSize: props.pageSize
        }
    }

    componentDidMount = () => {
        this.fetchData(this.state.page, this.state.pageSize);
    }

    fetchData = (page, pageSize) => {
        const params = {
            page: page,
            limit: pageSize
        };
        this.props.get("/api/v1/profile/points", params).then(response => {
            this.setState({
                data: response.results,
                numPages: response.num_pages,
                loading: false
            });
        });
    }

    render() { 
        const columns = [
            {
                Header: 'Date',
                accessor: 'created_at',
                Cell: props => <span className="points_date">{dayjs(props.value).format("MMMM D, YYYY")}</span>
            }, 
            {
                Header: 'Description',
                accessor: 'description',
                Cell: props => <span className='reward_type'>{props.value}</span> // Custom cell components!
            }, 
            {
                Header: 'Points',
                accessor: 'points',
                Cell: props => <span className='points'>{props.value}</span> // Custom cell components!
            }
        ]
         
        return (
            <ReactTable
                data={this.state.data}
                className="-striped"
                columns={columns}
                loading={this.state.loading}
                defaultPageSize={this.state.pageSize}
                showPageSizeOptions = {false}
                sortable = {false}
                style={{
                    height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                }}
                resizable = {false}
                page={this.state.page-1}
                pages={this.state.numPages}
                manual
                onFetchData={(state) => {
                    // updating state appears to trigger another fetch
                    if (!this.state.loading) {
                        const nextPage = state.page + 1;
                        this.setState({loading: true, page: nextPage});
                        this.fetchData(nextPage, state.pageSize);
                    }
                }}
            />
        )
    }

    static propTypes = {
        pageSize: PropTypes.number.isRequired,
        get: PropTypes.func.isRequired
    }
}