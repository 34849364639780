import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ModalForm from '../snippets/modal-form';
import PreLoader from '../snippets/PreLoader'



export default class AdminSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            feedback: {},
            isLoading: true,
            isProcessing: false,
            settings: {
                cooloff_hours: 0,
                contact_emails: [],
                referer_bonus_points: 300
            },
            MWSCredentialsList:[],
            modal: null,
            modalIsLoading: false,
            modalIsProcessing: false
        }
    }

    componentDidMount = () => {
        Promise.all([this.fetchSettings(), this.fetchMWSCredentials()]).then(values => {
            this.setState({
                settings: values[0],
                MWSCredentialsList : values[1],
                isLoading: false,
            });
        });
    }


    fetchSettings() {
        return this.props.get("/api/v1/settings", {filter: "admin"}).then(response => {
            return Promise.resolve(response.results);
        }).catch(err => {
            console.warn(err);
            return Promise.reject(err);
        });
    }

    fetchMWSCredentials() {
        return this.props.get("/api/v1/settings/mwscredentials", {}).then(response => {
            return Promise.resolve(response);
        }).catch(err => {
            console.warn(err);
            return Promise.reject(err);
        });
    }


    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let settings = this.state.settings;
        settings[inputName] = inputValue;
        this.setState({settings: settings});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let formData = new FormData(e.target);
        this.props.post("/api/v1/settings", formData).then(response => {
            if (response.result) {
                // show feedback
                this.setState({
                    loading: false,
                    feedback: {
                        type: "success",
                        message: "settings updated"
                    }
                });
            }
        }).catch(err => {
            err.json().then(response => {
                this.setState({
                    feedback: {
                        type: "danger",
                        message: response.error,
                    },
                    loading: false
                });

            });
        });
    }

    renderAdditionalOption = () => {
        let output = [];
              
        for (let k in this.state.settings) {
            output.push(
                <Form.Group key={"group-"+k}>
                    <Form.Label>{k.replace(/_/g, " ")}</Form.Label>
                    <Form.Control name={k} value={this.state.settings[k]} onChange={this.handleInputChange} />
                </Form.Group>
            );
        }

        return output

    }

    renderMWSCredentials() {
        let output = []

        this.state.MWSCredentialsList.forEach(item => {
            output.push         (
                <tr key={item._id} >
                    <td>
                        {item.name}
                    </td>
                    <td>
                        {item.seller_id}
                    </td>
                    <td>
                        {item.access_key}
                    </td>
                    <td>
                        {item.secret_key}
                    </td>
                    <td>
                        {/* <div className="table-data_action-bar">                            
                            <Dropdown size={"sm"} drop={"down"} className="action-bar_extra">
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" fixedWidth />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Button variant="link" className="dropdown-item" onClick={this.renderMWSCredentialsModal.bind(this, "edit", item)}>
                                        Edit
                                    </Button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                    </td>
                </tr>
            )
        });

        return output
    }

    renderMWSCredentialsModal(type, data) {
        let _id = ""
        let name = ""
        let sellerID= ""
        let accessKey= ""
        let secretKey= ""
        
        if (type === "edit") {
            name = data.name
            sellerID= data.seller_id
            accessKey= data.access_key
            secretKey= data.secret_key
            _id= data._id
        }

        let modal = {
            title: "MWS Credientials",
            type: type,
            size: "md",
            content: 
            <>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="name" defaultValue={name} required/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Seller ID (Merchant Token)</Form.Label>
                    <Form.Control type="text" name="seller_id" defaultValue={sellerID} required/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Access Key</Form.Label>
                    <Form.Control type="text" name="access_key" defaultValue={accessKey} required/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Secret Key</Form.Label>
                    <Form.Control type="text" name="secret_key" defaultValue={secretKey} required/>
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" name="_id" defaultValue={_id} required/>
                </Form.Group>
            </>
        }

        this.setState({
            modal: {...modal}
        });
        
    }

    handleMWSCredentialsChange = (e) => {
        console.log(e)
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({
            MWSCredentialsForm: {
                ...this.state.MWSCredentialsForm,
                [inputName] : inputValue
            }
        });

    }

    handleSubmitMWSCredentials = (e) => {
        e.preventDefault();
        this.setState({
            modalIsProcessing: true,
            isProcessing: true
        });
        let formData = new FormData(e.target);
        this.props.post("/api/v1/settings/mwscredentials", formData).then(response => {
            if (response.result) {
                // show feedback
                this.fetchMWSCredentials().then(values => {
                    this.setState({
                        MWSCredentialsList : values,
                        modalIsProcessing: false,
                        isProcessing: false,
                        modal: null
                    });
                });
            }
        }).catch(err => {
            console.log(err)
            err.json().then(response => {
                console.log("Failed")
                this.setState({
                    feedback: {
                        type: "danger",
                        message: response.error,
                    },
                    loading: false
                });

            });
        });

    }

    closeModal = () => {
        this.setState({
            modal: null
        });
    }

    render = () => {
        let modal = null;

        if (this.state.modal !== null) {
            modal = <ModalForm {...this.state} show={true} closeModal={this.closeModal} title={this.state.modal.title} size={this.state.modal.size} content={this.state.modal.content} submit={this.handleSubmitMWSCredentials}/>;
        }  

        let showAlert = ""
            // if (this.state.feedback.message !== "") {
            //     showAlert = 
            //     <Alert show={showAlert} variant={this.state.feedback.type}>{this.state.feedback.message}</Alert>
            // }

        return (
           <React.Fragment>
                {this.state.isLoading? (
                    <PreLoader />
                ) : (
            <React.Fragment>
                {showAlert}
                <section className="additional_option">
                        <div className="section-header">
                            <h4>Additional Option</h4>
                        </div>
                        <Container fluid>  
                            <Form onSubmit={this.handleSubmit}>
                                
                                {this.renderAdditionalOption()}
                                
                                <Form.Group className="controller">
                                    <Button variant="success" type="submit" size="sm"  disabled={this.state.isLoading}>Save</Button>
                                </Form.Group>
                            </Form>
                        </Container>
                </section>
                <section className="mws_credentials">
                        <div className="section-header">
                            <h4>MWS Credentials</h4>
                            <div className="section-header_action-bar">
                                <Button onClick={this.renderMWSCredentialsModal.bind(this, "create", null)} size="sm" variant="success" className="mr-0" disabled={this.state.isBtnLoading}>Create</Button>
                            </div>
                        </div>
                        <Container fluid>  
                            <Table >
                                <thead>
                                    <tr>
                                        <th className="table-data_name">Name</th>
                                        <th >Seller ID(Merchant Token)</th>
                                        <th >Access Key</th>
                                        <th >Secret Key</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {/* <tfoot>
                                    <tr>
                                        <th colSpan="10"  className="load-more_wrapper">
                                            {button}
                                        </th>
                                    </tr>
                                </tfoot> */}
                                <tbody>
                                    {this.renderMWSCredentials()}
                                </tbody>
                            </Table>
                        </Container>   
                </section>
                {modal}
            </React.Fragment>
            )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        history: PropTypes.object,
        location: PropTypes.object
    }
}