import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class asinURL extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
            region: "CA",
            asin:""
        }
    }


    componentDidMount() {
        if (this.props.asin !== null) {
            this.setState({
                region: this.props.region.toUpperCase(),
                asin: this.props.asin
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        // wait for profile to load before fetching from local storage
        if (prevState.asin === "" ){
                this.setState({
                    region: this.props.region.toUpperCase(),
                    asin: this.props.asin
                })
        }
    }

    render() {
        let url = null

            if (this.state.region === "US") {
                url = `http://amazon.com/dp/${this.state.asin}`
            
            } else if (this.state.region === "CA") {
                url = `http://amazon.ca/dp/${this.state.asin}`
              
            } else if (this.state.region === "DE") {
                url = `http://amazon.de/dp/${this.state.asin}`
            } else {
                url = `http://amazon.ca/dp/${this.state.asin}`
            }

            return (
                <>
                    <a href={url} target="_blank"  rel="noopener noreferrer">{this.state.asin}</a>
                </>
            )
    }
    
    static propTypes = {
        region: PropTypes.string,
        asin: PropTypes.string
    }
}
