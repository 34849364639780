import React, { Component } from "react";
import PropTypes from "prop-types";
import Footer from "./../common/footer"
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Header from "./../common/header"
import Navbar from "react-bootstrap/Navbar";
import StarRating from "../../snippets/star-rating";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import fbLogin from "../../../utils/fb-login";
import Logo from '../../../assets/images/logo_outline.svg'

import './../apply.scss';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faQuoteRight } from '@fortawesome/free-solid-svg-icons'

export default class StepLandingPage extends Component {

     constructor(props) {
        super(props);
        this.state = {
            breakpoint:"",
            campaignContent:"",
            customStyle : {
                mainImgUrl: "",
                mobileImgUrl:"",
                backgroundColor:""
            }
        }
    }

    updateLandingPageImage() {
        const searchParams = new URLSearchParams(window.location.search);
        let facebookCampaign = searchParams.get("utm_content")
        let url = "https://s3.amazonaws.com/dealpanda.com/static/media/dp_registration_hero-image"; //default image
        let bgColor = ""
        let mainImgUrl = ""
        let mobileImgUrl = ""
        let breakpoint = ""
            
        // Use this once we have standarlize how the image url will look like
        if (facebookCampaign !== "" && facebookCampaign !== null) {
            let content = facebookCampaign.split("_");
            url = `https://s3.amazonaws.com/dealpanda.com/static/media/dp_registration_hero-image_${content[1]}`
            bgColor = `#${content[2]}`
        } 
                    
        // width is based on bootstrap breakpoint that is defined in the _variables.scss
        if(window.innerWidth > 992 && window.innerWidth < 1200) {
            // Tablet
            mainImgUrl = `${url}_tablet.png` 
            breakpoint = "tablet"
        } else if (window.innerWidth > 1200) {
            // Desktop
            mainImgUrl = `${url}_desktop.png` 
            breakpoint = "desktop"
        } else {
            // Smartphone
            mainImgUrl = "" 
            mobileImgUrl = `${url}_mobile.png` 
            breakpoint = "mobile"
        }

        if (breakpoint !== "mobile") {
            document.body.style.backgroundColor = bgColor
            document.body.style.backgroundImage = `url("${mainImgUrl}")`
        } else {
            document.body.style.backgroundColor = ""
            document.body.style.backgroundImage = ""
        }

        this.setState({
            customStyle : {
                ...this.state.customStyle,
                mainImgUrl: mainImgUrl,
                backgroundColor:bgColor,
                mobileImgUrl:mobileImgUrl,
            },
            campaignContent: facebookCampaign,
            breakpoint: breakpoint
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
       if (prevState.breakpoint !==  this.state.breakpoint ) {
            this.updateLandingPageImage();
       }
    }

    componentDidMount() {
        this.updateLandingPageImage();
        window.addEventListener("resize", this.updateLandingPageImage.bind(this));
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = ""
        document.body.style.backgroundImage = ""
        window.removeEventListener("resize", this.updateLandingPageImage.bind(this));
    }
    

    scrollTo = (id) => {
        var anchor = document.getElementById("anchor");

            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': (anchor.offsetTop + 40)
            });
    }

    renderSlider() {
            let settings = {
                    className: "center",
                    centerMode: false,
                    infinite: true,
                    // centerPadding: "9%",
                    autoplay: false,
                    slidesToShow: 2,
                    speed: 1200,
                    responsive: [
                         {
                          breakpoint: 1280,
                          settings: {
                            centerMode: true
                          }
                        },
                        {
                          breakpoint: 960,
                          settings: {
                            centerMode: true,
                            slidesToShow: 1
                          }
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            arrows: true,
                            centerMode: true,
                            autoplay: true,
                            pauseOnHover: true,
                            cssEase: "linear",
                            centerPadding: '4%',
                            slidesToShow: 1
                          }
                        }
                      ]
            }

            const testimonials = [
                            {
                                body: "So grateful for the opportunity to try out full-sized quality products. I’ve been exposed to so many new and cool products that I use on a daily basis. The customer service is incredible",
                                name: "Rima",
                                location:"Toronto"
                            },
                            {
                                body: "I’ve been introduced to the best cosmetic and welfare products I've ever used. The design of the website is very friendly to the customers.",
                                name: "Iris",
                                location:"Toronto"
                            },
                            {
                                body: "I love being able to try new products. Customer service has been great! I always get quick responses and they are very helpful! The website is super easy to use!",
                                name: "Chelsea",
                                location:"Oklahoma"
                            },
                            {
                                body: "I get to try a variety of products whilst sharing my opinions. The platform is user friendly and my experience with customer service has always been positive. Thank you Deal Panda!",
                                name: "Anisha ",
                                location:"Toronto"
                            }
                            ]
            let output = []
            testimonials.forEach(function(element, idx){
                output.push(
                        <div className="testimonial" key={idx}>
                            <div className="testimonial_quotes"><FontAwesomeIcon icon={faQuoteRight} size="sm" fixedWidth /> </div>

                            <div className="testimonial-content">
                                <div className="testimonial-body">
                                    <StarRating inputName="reviewRating" value={5} />
                                    <p>{element.body}</p>
                                </div>                            
                                <div className="testimonial_author">
                                    <div className="testimonial_author-image" > {element.name.charAt(0)} </div>
                                    {/* <div className="testimonial_author-image" style={{backgroundImage:"url('https://www.thehindu.com/entertainment/movies/q875nd/article31003065.ece/ALTERNATES/LANDSCAPE_1200/7bm-pg5-Film-column'" }}> </div> */}
                                    <div>
                                        <div className="testimonial_author-name">{element.name} </div>
                                        <div className="testimonial_author-date">from {element.location}</div>
                                        {/* <div className="testimonial_author-name">{element.name} from {element.location}</div> */}
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                )
            })
                        
            return <Slider {...settings}> {output} </Slider>
        }

    render() {
        let desktopLoginMsg = ""
        let mobileLoginMsg =  ""

        if (!this.props.facebookLogin.successful) {
            desktopLoginMsg = <p className="facebook-msg --error --desktop">{this.props.facebookLogin.msg}</p>
            mobileLoginMsg =  <p className="facebook-msg --error --mobile">{this.props.facebookLogin.msg}</p>
        }

        let fbLoginRedirect = encodeURIComponent(`https://${window.location.host}/apply`);
        let fbLoginState = encodeURIComponent(JSON.stringify({
            pathname: window.location.pathname,
            search: window.location.search,
            fbProfileFields: this.props.fbProfileFields
        }));
        let fbLoginURL = `https://www.facebook.com/v8.0/dialog/oauth?client_id=${process.env.REACT_APP_FB_APPID}&redirect_uri=${fbLoginRedirect}&state=${fbLoginState}&scope=${this.props.fbScope}`;

        if (this.props.facebookLogin.successful === false) {
            // prompt for permissions
            fbLoginURL += "&auth_type=rerequest"; 
        }
        // console.log("fbloginurl", fbLoginURL);

        let facebookButton = <div className="fb-login-btn">
            <Button className="btn-fb" href={fbLoginURL}><i className="icon-facebook"></i> Continue with Facebook</Button>
        </div>
        

        return(
            <>
            <div className="background-image --dark" >
                
                {this.state.breakpoint ==="mobile" ? (
                    <Header/>
                ): ""}
                
                <main>
                    <section className="hero-image --mobile" style={{backgroundColor:this.state.customStyle.backgroundColor}}>
                        <img src={this.state.customStyle.mobileImgUrl} alt="Deal Panda"/>
                    </section>
                    <Container as="section" className="">
                        <Row>
                            <Col xs={12} md={12} lg={7} xl={8}>
                                
                                <section className="page-header">
                                    {this.state.breakpoint !=="mobile" ? (
                                         <Navbar.Brand href="/apply"><img src={Logo} rel="Deal Panda" alt="Deal Panda Logo"/></Navbar.Brand>
                                    ): ""}
                                   
                                    <h1 className="display-3">Test Out FREE Products, Share Your Feedback & Earn Rewards</h1>
                                    <p className="lead">Join Deal Panda and earn rewards, free gifts and payouts for participating in marketing tests and sharing your feedback with big brands on their newest products.</p>
                                    <p >(Did we mention it’s <strong>free</strong> and easy to sign&nbsp;up?)</p>
                                    <Button size="sm"  className="cta" variant="success"  onClick={this.scrollTo.bind()}>Start Earning Rewards</Button>
                                </section>
                                

                                <section className="testimonial-list">
                                <h2>Here’s Why Our Members Love Deal Panda</h2>
                                    {this.renderSlider()}
                                </section>
                                
                                <section id="anchor" className="account-creation">
                                    <h2>Create an Account to Join Deal Panda </h2>
                                    <p>Connect with facebook and create your tester profile to&nbsp;get&nbsp;started.<br/> We’ll never post anything to facebook without your permission.</p>
                                    <div className="wrapper">
                                        <div>
                                            {facebookButton}
                                            {mobileLoginMsg}
                                        </div>
                                        <p className="log-in">  Already have an account ? <a href="https://dealpanda.com/">Log in</a> </p>
                                    </div>
                                    {desktopLoginMsg}
                                
                                </section>
                                
                                <Footer gdpr={this.props.gdpr} />
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Container>
                </main>
             
            </div>
            </>
        )
    }
      static propTypes = {
        device: PropTypes.object.isRequired,
        facebookCampaign:PropTypes.string,
        gdpr: PropTypes.bool.isRequired,
        fbScope: PropTypes.string.isRequired,
        fbProfileFields: PropTypes.string.isRequired,
        facebookLogin: PropTypes.object.isRequired
    }
}