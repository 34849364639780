import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal'
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import ContentTNC from './../../snippets/ContentTNC';
import ContentPrivacyPolicy from './../../snippets/ContentPrivacy';
import './../apply.scss';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons'


export default class StepNdaAgreement extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            question: "Mutual Non-Disclosure Agreement",
            show: false,
            type: null
        }
    }

    handleClose = () => this.setState({show:false, type: null});
    handleShow = (type) => this.setState({show:true, type: type});


    render() {

        // Need to redo this next time to make it cleaner
        let agree = false
        let disagree = false
            
            if(this.props.ndaAgreement === "agree") {
                agree = true
                disagree = false
            } else if (this.props.ndaAgreement === "disagree") {
                agree = false
                disagree = true
            }

        let disableButton = true
        let buttonText = "Next"
            
            if (this.props.ndaAgreement !== "" ) {
                disableButton = false
            }

            if (this.props.processing) {
                disableButton = true 
                buttonText = "Submitting..."
            } else {
                buttonText = "Next"
            }

        let modalTitle = ""
        let modalContent = ""

            if(this.state.type === "tnc") {
                modalTitle = "Terms and Condition"
                modalContent = <ContentTNC/>
            } else {
                modalTitle = "Privacy Policy"
                modalContent = <ContentPrivacyPolicy/>
            }
            
        return(
            <>
            <Container as="section" className="page-header">
                <h1 className="display-3">Deal Panda<br/>{this.state.question}</h1>
                <Container fluid="sm">
                    <p >Deal Panda is a market research platform that connects testers with innovative new products from global and emerging brands. Researchers from these brands have developed proprietary test methods, new products and/or services they wish to keep
confidential while testing and launching to market.</p>
                    <p> Deal Panda's Terms and Conditions protect both researchers and testers from having their information shared. This is a mutual agreement in which both parties disclose and protect their information.</p>
                    <p>If we have deemed that you have violated this mutual non-disclosure agreement, your tester account will be immediately suspended and you will be banned from all future tests. </p>
                    <hr/>
                    <p>
                        <strong>I have read and agree to the Mutual Non-Disclosure Agreement, <Button onClick={this.handleShow.bind(this, "tnc")} variant="link">Terms and Condition</Button>, and <Button onClick={this.handleShow.bind(this, "privacy-policy")} variant="link">Privacy Policy</Button>.  And I allow this website to collect and store my submitted data. </strong>
                    </p>
                </Container>
                <Form.Group className="mb-3 mt-3 form-group-radio --right-align">

                    <div className="custom-radio" onClick={this.props.handleInputChange.bind(this, "agree")} >
                        <input type="radio" 
                                    name="user_nda_agreement"
                                    checked={agree}
                                    id={"nda-agreement_agree"}
                                    htmlFor={"nda-agreement"} 
                                    readOnly/>
                    
                        <label htmlFor={"nda-agreement_agree"} >
                            <div className="label_text">I Agree </div>
                        </label>
                    </div>


                    <div className="custom-radio" onClick={this.props.handleInputChange.bind(this, "disagree")} >
                        <input type="radio" 
                                    name="user_nda_agreement"
                                    checked={disagree}
                                    id={"nda-agreement_disagree"}
                                    htmlFor={"nda-agreement"}
                                    readOnly />
                    
                        <label htmlFor={"nda-agreement_disagree"} >
                            <div className="label_text">I Disagree </div>
                        </label>
                    </div>
                    
                       

                    {/* {this.renderCategories()} */}
                </Form.Group>     
                <Form.Group className="mb-5 apply-step_navigation">
                    <Button disabled={disableButton} type="submit" variant="success"  > {buttonText} <FontAwesomeIcon icon={faChevronRight} fixedWidth /></Button>
                </Form.Group>    
            </Container>
             <Modal size="lg" show={this.state.show} onHide={this.handleClose} className="modal-content--copy" centered>
                    <Modal.Header closeButton>{modalTitle}</Modal.Header>
                    <Modal.Body>
                        <Container as="main" >
                            {modalContent}
                        </Container>
                    </Modal.Body>
                    
                </Modal>
            </>
        )
    }
    
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        ndaAgreement : PropTypes.string.isRequired,
        processing: PropTypes.bool.isRequired
    }
}