import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'

export default class ModalForm extends Component {

    render() {
        let msg =  null;
        let modalButton = null;
        let modalClass = null;
        let modalContent = this.props.content;
        let modalTitle = this.props.title;
        let modalSize = this.props.size === "" ? "sm" : this.props.size;

        if (modalTitle === "MWS Credientials" ) {
            let buttonValue = this.props.type === "create" ? "Create" : "Save"
            // success
            modalClass = "modal-mws-credientials";
            modalButton = 
            <React.Fragment>
                <Button variant="link" onClick={this.props.closeModal}>Close</Button>
                <Button variant="primary" type="submit" className="btn-dp" disabled={this.props.modalIsProcessing}>{buttonValue}</Button>
                {/* <p>
                    Remember to complete this task if you did not change the status to shipped. 
                </p> */}
            </React.Fragment>
        } 
            

        return (
            
            <Modal className={"modal-error modal-form" + modalClass } show={this.props.show} size={modalSize} onHide={this.props.closeModal} centered>
                <Form onSubmit={this.props.submit}>
                    <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalContent}
                    </Modal.Body>
                    <Modal.Footer>
                        {modalButton}
                    </Modal.Footer>
                    {msg}
                </Form>
            </Modal>
        );
    }

    static propTypes = {
        size: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
        submit: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.object,
        modalIsProcessing: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired
    };
}