
const retails = [
    {
        name : "AMAZON", 
        logo : "retail-amazon",
        url: "amazon.com"
    },
    {
        name : "TARGET", 
        logo : "retail-target",
        url: "target.com"
    },
    {
        name : "IHERB", 
        logo : "retail-iherb", 
        url: "iHerb.com"
    },
    {
        name:"ANTHROPOLOGIE",
        logo: "retail-anthropologie",
        url:"anthropologie.com"
    },
    {
        name : "PAYPAL", 
        logo : "retail-paypal",
        url: "paypal.com"
    }, 

]

export default {
    
    getLogo(retail, size) {
        

        if(size === "lg") {
            size = "@3x"
        } else if (size === "md") {
            size ="@2x"
        } else {
            size = ""
        }

        let retailValue = retail !== undefined ? retail : "",
            src = ""

            if (retail === undefined || retail === null) {
                src = `https://s3.amazonaws.com/assets.dealpanda.com/retail-unknown.png`
            }else {
                src = `https://s3.amazonaws.com/assets.dealpanda.com/retail-${retailValue.toLowerCase()}${size}.png`
            }

        return src
    },

    getList() {
        let lst = []

            retails.forEach((retail) => {
                if(retail.name !== "paypal") {
                    lst.push(retail.name)
                }
            })
        
        return lst
    },

    getDomain(retail) {
        let domain = ""

            retails.forEach((value) => {
                if(value.name === retail) {
                    domain = value.url
                }
            })
        
        return domain
    }

    
}
