import React, { Component } from "react";
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Card from 'react-bootstrap/Card'
import PandaCoin from './../snippets/panda-coin'
import Flags from '../snippets/flags' 
import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import adminRegions from '../../utils/admin-regions'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Switch from "react-switch";
import IMAGE from '../../utils/image'
import RETAILS from '../../utils/retails'
import Util from '../../utils/utils'
import Currency from '../../utils/currency'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCheckCircle, faCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import userType from '../../i18n/en/user-type.json'

import './admin-deals-create.scss';

const amazonSisterBrands = [
    {name:"Viva Natural", value:"viva_natural"},
    {name:"Viva Naturals Organic", value:"viva_naturals_organic"},
    {name:"Island Fresh", value:"island_fresh"},
    {name:"Emerald Cove Beauty", value:"emeral_cove_beauty"},
    {name:"​Dwell+Good", value:"dwell_good"},
    {name:"Matrix Gaming", value:"matrix_gaming"},
    {name:"June Berry", value:"june_berry"},
    {name:"Hair Sensation Pro", value:"hair_sansation_pro"},
    {name:"The Motherhood Collection", value:"the_motherhood_collection"},
    {name:"Dream Baby Monitor Pro", value:"dream_baby_monitor_pro"},
    {name:"Cloud Baby Bassinet", value:"cloud_baby_bassinet"},
    {name:"Rain Guard", value:"rain_guard"},
    {name:"Vivid Lighting", value:"vivid_lighting"},
    {name:"Sweet Sleep Baby Monitor Pro", value:"sweet_sleep_baby_monitor_pro"}
]



export default class AdminDealsCreate extends Component {
    
    PRODUCTQUESTIONS = [
        "----Select Question----",
        "Take a look at the reviews. What is the title of the review which is most helpful in making a purchase decision and why?",
        "After reviewing the product images, description, and reviews, do you have any unanswered questions about this product? If so, then what are they?",
        "Take a look at the reviews. Of the negative reviews, what is the title of the one that is most concerning to you?"
    ]

    COMPETITORQUESTIONS = [
        "----Select Question----",
        "Look at the product images in the gallery. Compare them to the images in the customer review section. Do you feel that the product images accurately represent what the customer receives?",
        "Which of the product images is most helpful in making a purchasing decision and why?",
        "Which of the product features in the description is most important to you as a buyer?"
    ]

    BADGES = {
        best_seller: "Best Seller",
        panda_choice: "Panda's Choice",
        hot_deal: "Hot Deal",
        new: "New",
        recruiting: "Recruiting",
        ending_soon: "Ending Soon",
        last_chance: "Last Chance"
    };

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/deals/edit/:id",
            exact: true,
            strict: false
        });
        // this._list = null;
		this.state = {
            id: match ? match.params.id : null,
            editMode : false,
            retailList : RETAILS.getList(),
            edit:{},
            maxLength:{
                dealName: 50,
                dealDescription: 200
            },
            MWSCredentialsList: [],
            amazonSisterBrands:[],
            deal: {
                deal_channel:"AMAZON",
                deal_type: "buy_first",
                deal_name: "",
                deal_asin: "",
                deal_product_id:"",
                alternate_asins: [],
                deal_keywords: {},
                deal_amount: 0,
                deal_badge: "",
                deal_coming_soon: false,
                deal_description: "",
                deal_invited_emails: [],
                deal_quantity: 0,
                deal_question: "",
                deal_region: "CA",
                deal_risk_level: "",
                deal_status: "inactive",
                deal_amazon_sister_brand_name:"",
                purchase_cta:"Add to cart",
                points: 0,
                product_name: "",
                product_image_in_search_url: "",
                product_image_url: ""
            },
            keywordRows: [{
                keyword: "",
                limit: 0
            }],
            competitorRows: [{
                title: "",
                image: "",
                question: ""
            }],
            compare_competitor_enabled: false,
            compare_competitor: {
                main_image : "",
                carousel_image : ""
            },
            amazon_seller_name: [],
            relatedDeals:[],
            block_testers_from_deal:[],
            feedback: {},
            loading: false
        }
    }

    objectToString = (value) => {
        let object = {}

        for(var key in value){
            if (value.hasOwnProperty(key)){
                if (value[key]){
                    object[key] = (value[key]).toString()
                }
            }
        }
        return object
    }

    componentDidMount = () => {
        // Check if in edit mode 

        let edit = false;
            if (this.props.location.state === "edit" || window.location.href.indexOf("edit") > -1) {
               edit = true
            } 

        // Fetch single Deal
        if (this.state.id) {
            this.fetchDeal(this.state.id).then(deal => {
                let keywordRows = [];
                let competitorRows = [];
                for (let key in deal.deal_keywords) {
                    keywordRows.push({
                        keyword: key,
                        limit: deal.deal_keywords[key]
                    });
                }
                deal.competitors.forEach(item => {
                    competitorRows.push({
                        title: item.title,
                        image: item.image,
                        question: item.question
                    });
                });

                let compare_competitor = ""
                    if (deal.compare_competitor && Object.keys(deal.compare_competitor).length !== 0) {
                        if (deal.compare_competitor !== "" && typeof deal.compare_competitor === "object" && Object.keys(deal.compare_competitor).length > 0) {
                            compare_competitor = {
                                compare_competitor_enabled: true,
                                compare_competitor: {
                                    ...deal.compare_competitor
                                }
                            }
                        }
                    }

                this.setState({
                    deal: deal,
                    block_testers_from_deal: deal.block_testers_from_deal || [],
                    amazon_seller_name: deal.deal_amazon_seller_name || [],
                    keywordRows: keywordRows,
                    competitorRows: competitorRows,
                    ...compare_competitor,
                    edit: {
                        ...this.state.edit,
                        deal: {...deal},
                        keywordRows:  JSON.stringify(keywordRows) ,
                        competitorRows: JSON.stringify(competitorRows),
                        amazon_seller_name: JSON.stringify(deal.deal_amazon_seller_name || []),
                        block_testers_from_deal: JSON.stringify(deal.block_testers_from_deal) || [], 
                        compare_competitor_enabled: deal.compare_competitor && Object.keys(deal.compare_competitor).length !== 0,
                        ...compare_competitor
                    }
                });
            });
        }

        /*
            Fetch additonal Data
        */
        Promise.all([this.fetchDeals(1, 100),  this.fetchMWSCredentials(), ]).then(values => {
            this.setState({
                editMode : edit,
                relatedDeals: values[0],
                MWSCredentialsList : values[1],
                amazonSisterBrands : amazonSisterBrands // Hardcoded Data listed above located on the top
            });
        });

    }

    fetchDeal(dealId) {
        return this.props.get("/api/v1/deal?deal_id=" + dealId, {filter: "admin"}).then(response => {
            if (response.deal) {
                return Promise.resolve(response.deal);
            } else {
                return Promise.reject({});
            }
        }).catch(err => {
            console.warn(err);
            return Promise.reject(err);
        });
    }

    fetchDeals = (page, limit) => {
        const params = {
            page: page,
            limit: limit,
            filter: "admin"
        }
        return this.props.get("/api/v1/deals", params).then(response => {
            return Promise.resolve(response.deals);
        }).catch(err => {
          
            return Promise.resolve(err);
        });
    }

    fetchMWSCredentials() {
        return this.props.get("/api/v1/settings/mwscredentials", {}).then(response => {
            return Promise.resolve(response);
        }).catch(err => {
            console.warn(err);
            return Promise.reject(err);
        });
    }

 

    compareObject = (data, cloned) => {
         if (this.state.editMode) {
            let newData = this.objectToString(data)
            let clonedData = this.objectToString(cloned)
            let changes = ""
            if( JSON.stringify(newData) !== JSON.stringify(clonedData)) {
                changes = true
            } else {
                changes = false
            }
            this.setState({
                    edit : {
                        ...this.state.edit, 
                        changes: changes
                    }
            });
        }
    }

    compareArray = (data, cloned) => {
        if (this.state.editMode) {
            let changes = ""
            // cloned been stringify on fetch
            if( JSON.stringify(data) !== cloned) {
                changes = true
            } else {
                changes = false
            }
            this.setState({
                edit : {
                    ...this.state.edit, 
                    changes: changes
                }
            });
        }
    }

    compareBoolean = (data, cloned) => {
        if (this.state.editMode) {
            let changes = ""
            // cloned been stringify on fetch
            if( data !== cloned) {
                changes = true
            } else {
                changes = false
            }
            this.setState({
                edit : {
                    ...this.state.edit, 
                    changes: changes
                }
            });
        }
    }

    handleAmazonSisterBrand = (e) => {
      
        const inputValue = e.target.value;
        let brandName = ""
        this.state.amazonSisterBrands.forEach(brand => {
            if (brand.name === inputValue) {
                brandName = brand.name
            }
        });

        this.setState({
            deal: {
                ...this.state.deal,
                deal_amazon_sister_brand_name: brandName
            }
        })

        
    }

    handleKeywordChange = (e) => {
        const idx = parseInt(e.target.getAttribute("data-index"));
        let keywordRows = this.state.keywordRows;
        keywordRows[idx].keyword = e.target.value;
        this.setState({keywordRows: keywordRows},
            this.compareArray(keywordRows, this.state.edit.keywordRows)
        );
    }


    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        let deal = this.state.deal;
        deal[inputName] = inputValue;
        this.setState({deal: deal},
            this.compareObject(deal, this.state.edit.deal)
        );

        if (inputName === "product_image_url" && inputValue !== "") {
            this.getImgSize(inputName, inputValue)
        }
 
    }

    async getImgSize(key, imgURL) {
        let newURL = await IMAGE.getUrlWithSize(imgURL)

            this.setState({
                deal : {
                    ...this.state.deal, 
                    [key]: newURL
                }
            })
    }

    handleKeywordLimitChange = (e) => {
        const idx = parseInt(e.target.getAttribute("data-index"));
        let keywordRows = this.state.keywordRows;
        keywordRows[idx].limit = e.target.value ? parseInt(e.target.value) : "";
        this.setState({keywordRows: keywordRows},
            this.compareArray(keywordRows, this.state.edit.keywordRows)
        );
    }

    handleAddMoreKeyword = (e) => {
        e.preventDefault();
        let keywordRows = this.state.keywordRows;
        keywordRows.push({
            keyword: "",
            limit: 1
        });
        this.setState({keywordRows: keywordRows},
            this.compareObject(keywordRows, this.state.edit.keywordRows)
        );
    }

    handleSubstractKeyword = (idx) => {
        let keywordRows = this.state.keywordRows;
            keywordRows.splice(idx, 1);
            this.setState({keywordRows: keywordRows},
                this.compareObject(keywordRows, this.state.edit.keywordRows)
            );
    }

    handleCompetitorChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        const idx = parseInt(e.target.getAttribute("data-index"));
        let competitorRows = this.state.competitorRows;

        console.log(`Name : ${inputName}`, `value : ${inputValue}`)
        if (inputName === "competitor_titles") {
            competitorRows[idx].title = inputValue;
        } else if (inputName === "competitor_images") {
            competitorRows[idx].image = inputValue;
        } else if(inputName === "competitor_questions") {
            competitorRows[idx].question = inputValue;
        }

        this.setState({competitorRows: competitorRows},
            this.compareArray(competitorRows, this.state.edit.competitorRows)
        );
    }

    handleCompareCompetitorChange = (e) => {
        let compareCompetitor = {}
        if (e === null) {
            compareCompetitor = {
                ...this.state.compare_competitor,
                main_image : "",
                carousel_image : ""
            }
        } else {
            const inputName = e.target.name;
            const inputValue = e.target.value;

            compareCompetitor = {
                    ...this.state.compare_competitor,
                    [inputName] : inputValue
                }
        }

        this.setState({
                compare_competitor: compareCompetitor
            }, this.compareObject(this.state.compare_competitor, this.state.edit.compare_competitor));

    }

    handleAddMoreCompetitors = (e) => {
        e.preventDefault();
        let competitorRows = this.state.competitorRows;
        competitorRows.push({
            title: "",
            image: "",
            question: ""
        });
        this.setState({competitorRows: competitorRows, compare_competitor_enabled: false},
            this.compareObject(competitorRows, this.state.edit.competitorRows), this.handleCompareCompetitorChange(null)
        );
    }

    handleSubstractCompetitors = (idx) => {
        let competitorRows = this.state.competitorRows;
            competitorRows.splice(idx, 1);
            this.setState({competitorRows: competitorRows, compare_competitor_enabled: false},
                this.compareObject(competitorRows, this.state.edit.competitorRows)
            );
    }

    handleRelatedDeals = (e) => {
        let list = this.state.block_testers_from_deal   
        let targetName = e.target.name
        let targetValue = e.target.value
        let targetChecked = e.target.checked

        if (targetChecked) {
                list.push({[targetName]: targetValue})
                this.setState({
                    block_testers_from_deal: list
                }, this.compareArray(list, this.state.edit.block_testers_from_deal))
        } else {
                const index = list.findIndex(obj => obj.deal_id === targetValue);

                if (index > -1) {
                    list.splice(index, 1);
                }
                this.setState({
                    block_testers_from_deal: list
                }, this.compareArray(list, this.state.edit.block_testers_from_deal))
        }
    }    

    handleInviteEmailChange = (e) => {
        const text = e.target.value;
        let emails = [];
        if (text !== null) {
            emails = text.split("\n");
        }
        let deal = this.state.deal;
        deal.deal_invited_emails = emails;
        this.setState({deal: deal},
            this.compareObject(deal, this.state.edit.deal)
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let formData = new FormData(e.target);
        // formData.append('deal_amazon_brand_name', this.state.deal.deal_amazon_brand_name);
        formData.append('deal_amazon_sister_brand_name', this.state.deal.deal_amazon_sister_brand_name);
        formData.append('deal_channel', this.state.deal.deal_channel);
        formData.append('deal_region', this.state.deal.deal_region);
        formData.append('deal_type', this.state.deal.deal_type);
      
        
        // formData.append('deal_amazon_brand_id', this.state.deal.deal_amazon_brand_id);
        formData.append('deal_amazon_seller_name', JSON.stringify(this.state.amazon_seller_name));
        formData.append('block_testers_from_deal', JSON.stringify(this.state.block_testers_from_deal) );
        formData.append('block_testers_from_deal', JSON.stringify(this.state.block_testers_from_deal) );
        if (this.state.compare_competitor_enabled) {
            formData.append('compare_competitor', JSON.stringify(this.state.compare_competitor));
        }
        
        
        if (this.state.id === null) {
            this.props.post("/api/v1/deal", formData).then(response => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/admin/deals"
                    });
                }
            }).catch(err => {
                if (err.hasOwnProperty("json")) {
                    err.json().then(response => {
                        this.setState({
                            feedback: {
                                type: "danger",
                                message: response.error,
                            },
                            loading: false
                        });
        
                    });
                } else {
                    console.warn(err);
                    this.setState({
                        feedback: {
                            type: "danger",
                            message: "an error occurred",
                        },
                        loading: false
                    });
                }
            });
        } else {
            this.props.put("/api/v1/deal/" + this.state.id, formData).then(response => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/admin/deals"
                    });
                }
            }).catch(err => {
                console.log(err);
                err.json().then(response => {
                    this.setState({
                        feedback: {
                            type: "danger",
                            message: response.error,
                        },
                        loading: false
                    });
    
                });
            });
        }
    }

    renderOptions = (option) => {
        let output = []
        let list = []

            if (option === "user-type") {
                list = userType
            } 

            list.forEach(function (user, i){
                if (user.key !== "RISKY") {
                    output.push(
                        <option key={i} value={user.key}>{user.value}</option>
                    )
                }
            })

            return output
    }

    handleAmazonSeller = (value) => {
        // const inputValue = e.target.value;

        let sellers = this.state.amazon_seller_name;
    
        if (sellers.some(seller => seller.seller_id === value.seller_id)) {
            const index = sellers.findIndex(seller => seller.seller_id === value.seller_id);
            if (index > -1) {
                sellers.splice(index, 1);
            }
        }else {
            sellers.push(
                value
            )
        }

        this.setState({
            amazon_seller_name : sellers
        }, this.compareObject(sellers, this.state.edit.amazon_seller_name));   

    }

    
    renderAmazonSeller = () => {
        let output = []
        
            this.state.MWSCredentialsList.forEach((seller, idx) => {

                let checked = this.state.amazon_seller_name.some(e => e.seller_id === seller.seller_id) ? true : false;
                
                let param = {
                    seller_id: seller.seller_id,
                    name: seller.name
                }

                output.push(
                 <Form.Group key={idx} controlId={idx}>
                    <label className={`deal_create_amzon-seller-name_items ${checked ? "--is-selected": ""}`} id={seller.seller_id}>
                        <div className="left">
                            <input name="deal_id" value={seller.seller_id} checked={checked} type="checkbox" label={seller.name} onChange={this.handleAmazonSeller.bind(this,param)} />
                            <span className="checkmark"></span>
                            {/* <div className="item-image" style={{backgroundImage:`url(${seller.product_image_url})`}}></div> */}
                            <div className="item-info">
                                <div className="item-name">{seller.name}</div>
                            
                            </div>
                        </div>
                        <div className="right">
                            <FontAwesomeIcon  size="3x" icon={checked ? faCheckCircle : faCircle} fixedWidth />
                        </div>
                        
                    </label>
            
                </Form.Group>
                )
            })

            return output
    }

    renderAmazonBrand = () => {
        let output = []
        
            this.state.amazonSisterBrands.forEach(brand => {
                output.push(
                    <option value={brand.name} key={brand.name} >{brand.name.replace("-", " ")}</option>
                )
            });

            return output
    }

    rennderDealBadge = (deal) => {
        let name = "";
        let className = ""
        let output = null

        if (deal === "panda_choice") {
            name = "Panda's Choice";
            className = "black";
        } else if  (deal === "new") {
            name = <React.Fragment><strong>New</strong></React.Fragment>
            className = "green";
        } else if  (deal === "recruiting") {
            name = <React.Fragment><strong>Recruiting</strong></React.Fragment>
            className = "black";
        } else if  (deal === "ending_soon") {
            name = <React.Fragment><strong>Ending</strong> Soon</React.Fragment>
            className = "red";
        } else if  (deal === "last_chance") {
            name = <React.Fragment><strong>Last</strong> Chance</React.Fragment>
            className = "orange";
        } else if  (deal === "hot_deal") {
            name = <React.Fragment><strong>Hot</strong> Deal</React.Fragment>
            className = "red";
        } else if  (deal === "best_seller") {
            name = <React.Fragment><strong>Best</strong> Seller</React.Fragment>
            className = "orange";
        }

        if ( deal !== "")  {
            output = 
            <div className={ "deal-badge badge--" + className }>
                {name}
            </div>
        }

        return output;
    }


    renderTilePlaceholder = () => {

            let dealImageURLClass = ""
            let dealImageURL = "https://via.placeholder.com/150";
                
                if (this.state.deal.product_image_url !== "") {
                    dealImageURLClass = IMAGE.getImageSizeClass(this.state.deal.product_image_url)
                    dealImageURL = this.state.deal.product_image_url
                }

            let dealName = "Deal Name"
                if (this.state.deal.deal_name !== ""){
                    dealName = this.state.deal.deal_name
                }

            let dealStatusClass = ""

                if (this.state.deal.deal_status === "active") {
                    dealStatusClass = "--active";
                } else {
                    dealStatusClass = "--inactive"
                }
            
        
            let buttonVariant = "success"
            let buttonClassValue = "" 
            let buttonText = "Start Survey" 
                
                if (this.state.deal.deal_coming_soon.toString().toLowerCase() === "true") {
                    buttonVariant = "link"
                    buttonText= "Recruiting Soon"
                    buttonClassValue = "--pending";
                } 
            

            let cornerRibbon = ""
                    // If 25 days has passed don't show ribbon
                    if (this.state.deal.deal_status === "active") {
                        cornerRibbon = <div className="corner-ribbon"><div className="ribbon-green">New</div></div>
                    }      


   
        return (
            <div className="deal-deck card-deck">


                <Card  className={"deal-item " + dealStatusClass } >
                    <div className="inactive-message">Deal is inactive , users will not see this on the homepage</div>
                        <div className="content-wrapper">
                                {cornerRibbon}
    
                                <div className={`card-image ${dealImageURLClass}`}>    
                                    <Card.Img variant="top" src={dealImageURL} alt={dealImageURL}/>
                                </div>
                                
                                <Card.Body>
                                    <div className="card-body_top-items">
                                        <div className="card-body_top-items_mobile-wrapper">
                                            <div className="card-body_mobile-image">
                                                <Card.Img variant="top" src={dealImageURL} alt={dealImageURL}/>
                                            </div>
                                            <div className="card-body_mobile-description">
                                                <div className="card-body_tags">
                                                    <div className="tags_left-side"> 
                                                        <img className={`tags_left-side --${this.state.deal.deal_channel}`} alt={this.state.deal.deal_channel} src={RETAILS.getLogo(this.state.deal.deal_channel, "sm")}/> 
                                                    </div>
                                                    <div className="tags_right-side">
                                                        {/* Need to finish this if there is mobile and desktop surveys */}
                                                        {/* {this.getTags(deal)} */}
                                                    </div>
                                                </div>
                                                <Card.Title>{Util.trunc(dealName, 60)}</Card.Title>
                                                <div className="deal_content deal_content--reward">
                                                    <h5 className="deal_content-title">Reward Value</h5>
                                                    <div className="reimburserment">
                                                        <span > {Currency.getCurrency(this.props.userProfile.region)}{this.state.deal.deal_amount}<sup>*</sup></span> + <span className="dp-points"><PandaCoin/>{this.state.deal.points }</span>
                                                    </div>
                                                    <div className="disclaimer">*Full reimbursement on product cost and earn valuable Panda Points.</div>
                                                </div>
                                                <div className="deal_content deal_content--description">
                                                    <h5 className="deal_content-title">Product Description</h5>
                                                    <p>{Util.trunc(this.state.deal.deal_description, 250)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </Card.Body>
                                <div className="card-body_bottom-items">
                                        {/* {this.dealButton(deal)} */}
                                        <Button variant={buttonVariant} className={`cta ${buttonClassValue}`} block>{buttonText}</Button>
                               </div>
                    </div>
                </Card>


               
            </div>


        )
    }

    handleCompareCompetitorToggle = (checked)=>{
            this.setState({
                compare_competitor_enabled: checked
            }, this.compareBoolean(checked, this.state.edit.compare_competitor_enabled))
    }


   

    renderAmazonAdditionalOptions() {
        let relatedDeals = []

        this.state.relatedDeals.forEach((deal, idx) => {
            let checked = this.state.block_testers_from_deal.some(e => e.deal_id === deal._id) ? true : false

            let riskLevel = ""
                if (deal.deal_risk_level === "NOT_RISKY") {
                    riskLevel =
                    <Badge variant="light">Regular </Badge>
                } else if (deal.deal_risk_level === "VIVA_REVIEWERS") {
                    riskLevel =
                    <Badge variant="primary">Viva<br/>Reviewers</Badge> 
                } else if (deal.deal_risk_level === "TRIAL") {
                    riskLevel =
                    <Badge variant="warning">{deal.deal_risk_level}</Badge> 
                } else {
                    riskLevel =
                    <Badge variant="secondary">{deal.deal_risk_level} </Badge> 
                }

            relatedDeals.push(
                <Form.Group key={idx} controlId={idx}>
                    <label className={`deal_create_related-deals_items --${checked ? "is-selected": ""}`} id={deal._id}>
                        <div className="left">
                            <input name="deal_id" value={deal._id} checked={checked} type="checkbox" label={deal.product_name} onChange={this.handleRelatedDeals} />
                            <span className="checkmark"></span>
                            <div className="item-image" style={{backgroundImage:`url(${deal.product_image_url})`}}></div>
                            <div className="item-info">
                                <div className="item-name">{deal.product_name}</div>
                                <div className="info-additional-detail">
                                    <small className="item-region"><Flags region={deal.deal_region}/></small>
                                    <small className="item-user-type">{riskLevel}</small>
                                    <small><strong>Asin:</strong> {deal.deal_asin}</small>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <FontAwesomeIcon  size="3x" icon={checked ? faCheckCircle : faCircle} fixedWidth />
                        </div>
                        
                    </label>
 
                </Form.Group>
            );
        });

        let output = 
            <>
            {/* <Form.Group>
                    <Form.Label>AMAZON SELLER NAME</Form.Label>
                    <Form.Control as="select" value={this.state.deal.deal_amazon_brand_id} onChange={this.handleAmazonSeller} required>
                        <option value="" >Select</option>
                        {this.renderAmazonSeller()}
                    </Form.Control>
            </Form.Group> */}

            <Form.Label>AMAZON SELLER NAME</Form.Label>
            <Form.Group className="deal_create_amzon-seller-name">
                {/* {relatedDeals} */}
                    {this.renderAmazonSeller()}
            </Form.Group>

            <Form.Group className="deal_create_amazon-brand">
                    <Form.Label>AMAZON BRAND NAME</Form.Label>
                    <Form.Control as="select" value={this.state.deal.deal_amazon_sister_brand_name} onChange={this.handleAmazonSisterBrand} required>
                        <option value="" >Select</option>
                        {this.renderAmazonBrand()}
                    </Form.Control>
            </Form.Group>
            
            <Form.Label>Related Deals</Form.Label>
            <Form.Text className="text-muted">
                Prevent users from seeing this deal if they had already redeemed a related product.    
            </Form.Text>
            <Form.Group className="deal_create_related-deals">
                {relatedDeals}
            </Form.Group>

            </>
        
        return output
    }

   

    render = () => {
        
        let showAlert = Object.keys(this.state.feedback).length !== 0;        
        let imageThumb = <img id="product-image" src={this.state.deal.product_image_url} alt={this.state.deal.product_name} height={50} />
        let searchThumb = <img src={this.state.deal.product_image_in_search_url} alt={this.state.deal.product_name} height={50} />

        let kwRows = [];
        this.state.keywordRows.forEach((row, idx) => {
            let isCategory = this.state.deal.deal_type === "CFBR" ? true : false
            let required = true
            let button = null 

                if(idx + 1 === this.state.keywordRows.length && this.state.keywordRows.length === 1) {
                    button = 
                    <>
                        <Button variant="disabled" className="btn-link" >-</Button>
                        <Button variant="light" className="btn-link" onClick={this.handleAddMoreKeyword}>+</Button>
                    </>
                }  else if (idx + 1 === this.state.keywordRows.length && this.state.keywordRows.length > 1) {
                    button = 
                    <>
                        <Button variant="danger" className="btn-link --delete"  onClick={this.handleSubstractKeyword.bind(this, idx)}>-</Button>
                        <Button variant="light" className="btn-link" onClick={this.handleAddMoreKeyword}>+</Button>
                    </>
                } else {
                    button = 
                    <>
                        <Button variant="danger" className="btn-link --delete"  onClick={this.handleSubstractKeyword.bind(this, idx)}>-</Button>
                        <Button variant="light" className="btn-link" onClick={this.handleAddMoreKeyword}>+</Button>
                    </>
                }   
            
            if(idx > 0) {
                required = false;
            }

            let placeholderText = isCategory ? "category":"keyword"

            kwRows.push(
                <Row key={idx}>
                    <Col md={7} lg={8} >
                            <Form.Control name="keywords" value={row.keyword} onChange={this.handleKeywordChange} placeholder={`${placeholderText}-${idx + 1}`} data-index={idx} required={required}/>
                    </Col>    
                    <Col md={2} lg={2}>
                            <Form.Control name="keywords_limit" value={row.limit} onChange={this.handleKeywordLimitChange} data-index={idx} type="number" />
                    </Col>
                    <Col md={3} lg={2} className="deal_create_add-keywords_buttons">
                            {!isCategory  ? button : ""}
                    </Col>
                </Row>
            );
        });

        let editButton = "Submit"
        let editButtonClass = ""    

            if (this.state.editMode) {
                editButton = "save"
            } 

            if (this.state.edit.changes) {
                editButtonClass = "--edit-changes"
            } else {
                editButtonClass = ""
            }
        
        let competitorQuestions = [];
        for (let b in this.COMPETITORQUESTIONS) {
            competitorQuestions.push(
                <option value={this.COMPETITORQUESTIONS[b]} key={b}>{this.COMPETITORQUESTIONS[b]}</option>
            );
        }

        let competitors = [];
        this.state.competitorRows.forEach((row, idx) => {
            let required = true
            let button = null 

                if(idx + 1 === this.state.competitorRows.length && this.state.competitorRows.length > 0) {
                    button = 
                    <Button variant="light" className="btn-link" onClick={this.handleAddMoreCompetitors} disabled={competitors.length >= 6}>+</Button>
                    
                }  else {
                    button = 
                    <Button variant="danger" className="btn-link  --delete" onClick={this.handleSubstractCompetitors.bind(this, idx)} disabled={competitors.length >= 6}>-</Button>
                }   

                if(idx > 0) {
                    required = false;
                }

            competitors.push(
                <div className="competitor-section" key={idx}>
                    <Row>
                        <Col md={10} lg={11}>
                      
                            <h4 className="competitor_name">
                                Competitor #{idx+1} 
                            </h4>

                            <Row>
                                <Col>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control name="competitor_titles" value={row.title} data-index={idx} onChange={this.handleCompetitorChange} required={required}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* FIXME : Need to finish this to have a dropdown option */}
                                    <Form.Label>Question</Form.Label>
                                    {/* <Form.Control name="competitor_questions" value={row.question} data-index={idx} onChange={this.handleCompetitorChange} required={required}/> */}
                                    <Form.Control as="select" name="competitor_questions" value={row.question} data-index={idx} onChange={this.handleCompetitorChange} required={required}>
                                        {competitorQuestions}
                                    </Form.Control>
                                </Col>
                            </Row>  
                            <Row>
                                <Col>
                                    <Form.Label>Search Display Image URL</Form.Label>
                                    <InputGroup>
                                        <div className="competitor_input-wrapper">
                                            <Form.Control name="competitor_images" value={row.image} data-index={idx} onChange={this.handleCompetitorChange} required={required}/>    
                                            <InputGroup.Append className="pl-1">
                                                <img src={row.image} alt={row.image} height={50} />
                                            </InputGroup.Append>
                                        </div>
                                        <Form.Text className="text-muted --tooltip">
                                                <p className="mb-0">Your screenshot image of the product must include the product price.</p>
                                                <OverlayTrigger
                                                    placement={"right"}
                                                    overlay={
                                                    <Tooltip id={`tooltip-top`}>
                                                        <img alt="screenshot" src="https://s3.amazonaws.com/assets.dealpanda.com/admin_create-deal_comp-screenshots.png" />
                                                        <br/>
                                                        <br/>
                                                        <img alt="screenshot" src="https://s3.amazonaws.com/assets.dealpanda.com/admin_create-deal_comp-screenshots-2.png" />
                                                    </Tooltip>
                                                    }
                                                >
                                                     <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                                                </OverlayTrigger>
                                        </Form.Text>
                                    </InputGroup>
                                      
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} lg={1} className="text-center">
                            {button}
                        </Col>
                    </Row>
                </div>
            );
        });


        let emails = this.state.deal.deal_invited_emails;
        if (emails) {
            emails = emails.join("\n");
        } else {
            emails = "";
        }

        let badgeOptions = [];
        for (let b in this.BADGES) {
            badgeOptions.push(
                <option value={b} key={b}>{this.BADGES[b]}</option>
            );
        }

        let retailOptions = [];
        for (let b in this.state.retailList) {

            if (this.state.retailList[b].toUpperCase() !== "PAYPAL") {
                retailOptions.push(
                    <option value={this.state.retailList[b].toUpperCase()} key={b}>{this.state.retailList[b].toUpperCase()}</option>
                );
            }
        }

        let productQuestions = [];
        // console.log(this.PRODUCTQUESTIONS)
        for (let b in this.PRODUCTQUESTIONS) {
            productQuestions.push(
                <option value={this.PRODUCTQUESTIONS[b]} key={b}>{this.PRODUCTQUESTIONS[b]}</option>
            );
        }


        // Dynamic Flow changes 
        let dealType = ""
        let dealProductID = ""
        let dealAdditionalOptions = ""
        let dealAlternateASINS = ""
        let dealRegion = ""
            if (this.state.deal.deal_channel === "AMAZON") {
                dealType = 
                <>
                    <option value="buy_first">User buys first (before staff pays)</option>
                    {/* <option value="SCCA">SCCATC (Search Competitor Compare Add To Cart)</option> */}
                </>
                dealProductID = 
                <>
                    <Form.Group>
                        <Form.Label>Product ASIN <sup>*</sup></Form.Label>
                        <Form.Control name="deal_asin" value={this.state.deal.deal_asin} onChange={this.handleInputChange} required />
                    </Form.Group>
                </>
                dealAdditionalOptions = this.renderAmazonAdditionalOptions()
                dealAlternateASINS = 
                <>
                    <Col>
                        <Form.Group>
                            <Form.Label>Alternate ASINs (comma delimited)</Form.Label>
                            <Form.Control name="alternate_asins" value={this.state.deal.alternate_asins} onChange={this.handleInputChange} />
                            <span>ASINs in this field will also be accepted as valid order.</span>
                        </Form.Group>
                    </Col>
                </>
                dealRegion = adminRegions.options("site")
            } else {
                dealType = 
                <>
                    <option value="SFBR">SFBR (Search-Find-Buy-Review)</option>
                    <option value="CFBR">CFBR (Category-Find-Buy-Review)</option>
                </>
                dealProductID = 
                <>
                    <Form.Group>
                        <Form.Label>Product ID <sup>*</sup></Form.Label>
                        <Form.Control name="deal_product_id" value={this.state.deal.deal_product_id} onChange={this.handleInputChange} required />
                    </Form.Group>
                </>
                dealRegion = 
                <>
                    <option value="US">US</option>
                </>
            }

        
        let dealIsPending = ""

            if(this.state.deal.deal_status === "active") {
                dealIsPending = 
                <>
                    <Form.Group>
                        <Form.Label>Recruit Testers Now?</Form.Label>
                        <Form.Control as="select" name="deal_coming_soon" value={this.state.deal.deal_coming_soon} onChange={this.handleInputChange}>
                            <option value={false}>Yes</option>
                            <option value={true}>No</option>
                        </Form.Control>
                    </Form.Group>
                </>
            }

        
        let compareCompetitor = ""
            if (this.state.compare_competitor_enabled) {
                let lastCompetitor = this.state.competitorRows.length - 1
                compareCompetitor  =
                <>
                <p className="compare-competitors-header_desscription">
                    This feature will ask the user to add the <strong>{this.state.competitorRows[lastCompetitor].title}</strong> to cart and complete a purchase. You may only add this for the last competitor in the survey: <strong>Competitor #{this.state.competitorRows.length}</strong>.
                </p>
                <Form.Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>
                                Main Image (170px x 465px)  
                                <OverlayTrigger
                                    placement={"bottom"}
                                    overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <img alt=""  src="https://s3.amazonaws.com/assets.dealpanda.com/admin_compare_competitor-main-example-2.png" />
                                    </Tooltip>
                                    }
                                >
                                        <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                                </OverlayTrigger>               
                            </Form.Label>
                            <Form.Text className="text-muted ">
                             Screenshot of the product in the widget that you want the user to purchase. <br/>
                             Click <a href="https://docs.google.com/document/d/17bZdZI7uN7JYnmqb4U_auTa_VSNNWwjU9BwIOgkJdnE/edit?usp=sharing" rel="noopener noreferrer"  target="_blank">here</a> for example.       
                           
                            </Form.Text>
                            <InputGroup>
                                <Form.Control name="main_image"  value={this.state.compare_competitor.main_image} onChange={this.handleCompareCompetitorChange} />    
                                <InputGroup.Append className="pl-1">
                                    <img  alt="" src={this.state.compare_competitor.main_image} height={50} />
                                </InputGroup.Append>
                          
                            </InputGroup>
                               
                        </Form.Group>     
                        
                    </Col>
                    <Col>
                        <Form.Group>

            
                            <Form.Label>
                                Carousel Image (1166px x 479px) 
                                <OverlayTrigger
                                    placement={"bottom"}
                                    overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <img alt="screenshot" width="500" src="https://s3.amazonaws.com/assets.dealpanda.com/admin_compare_competitor-carousel-example.png" />
                                    </Tooltip>
                                    }
                                >
                                        <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                                </OverlayTrigger>   
                            </Form.Label>
                            <Form.Text className="text-muted ">
                             Screenshot of widget. Exclude Widget title. 
                             <br/>
                             Click <a href="https://docs.google.com/document/d/17bZdZI7uN7JYnmqb4U_auTa_VSNNWwjU9BwIOgkJdnE/edit?usp=sharing" rel="noopener noreferrer"  target="_blank">here</a> for example.          
                                   
                            </Form.Text>
                            <InputGroup>
                                <Form.Control  name="carousel_image" value={this.state.compare_competitor.carousel_image} onChange={this.handleCompareCompetitorChange} />
                                <InputGroup.Append className="pl-1">
                                    <img  alt=""  src={this.state.compare_competitor.carousel_image} height={50} />
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>     
                        
                    </Col>
                </Form.Row>
                </>
            }

        return (
            
            <section  className={"deal_create " + (this.state.editMode ? "--edit-mode" : null)}>
                    <Alert show={showAlert} variant={this.state.feedback.type}>{this.state.feedback.message}</Alert>
                    <Form onSubmit={this.handleSubmit} className="deal_create_form">
                        <Form.Row>
                            <Col>
                                    <h4 >Deal Status</h4>
                                    <Form.Group>
                                        <Form.Label>Display Survey on Dashboard </Form.Label>
                                        <Form.Control as="select" name="deal_status" value={this.state.deal.deal_status} onChange={this.handleInputChange} required>
                                            <option value="active">Yes</option>
                                            <option value="inactive">No</option>
                                        </Form.Control>
                                    </Form.Group>
                                    {dealIsPending}
                                    <h4 className="mt-5">Design Tile</h4>
                                    <Form.Group>
                                        <Form.Label>Deal Channel<sup>*</sup></Form.Label>
                                        <InputGroup>
                                             <Form.Control as="select" name="deal_channel" value={this.state.deal.deal_channel} onChange={this.handleInputChange} disabled={this.state.editMode}>
                                                {retailOptions}
                                            </Form.Control>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Deal Type </Form.Label>
                                        <Form.Control as="select" name="deal_type" value={this.state.deal.deal_type} onChange={this.handleInputChange}  disabled={this.state.editMode} required>
                                            {dealType}
                                        </Form.Control>
                                    </Form.Group>
                                    
                                    <Form.Group>
                                            <Form.Label>Region / Marketplace</Form.Label>
                                            <Form.Control name="deal_region" as="select" value={this.state.deal.deal_region} onChange={this.handleInputChange}  disabled={this.state.editMode} required>
                                                {dealRegion}
                                            </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Product Image URL <sup>*</sup></Form.Label>
                                        <InputGroup>
                                            <Form.Control name="product_image_url" value={this.state.deal.product_image_url} onChange={this.handleInputChange} required />    
                                            <InputGroup.Append className="pl-1">
                                                {imageThumb}
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <Form.Text className="text-muted --tooltip">
                                                <p>Your screenshot image of the product should include ONLY the product. You may use a tool like <a href="https://imgur.com/" target="_blank" rel="noopener noreferrer">imgur</a> to upload the image and generate a URL. 
                                                    <OverlayTrigger
                                                        placement={"right"}
                                                        trigger="click"
                                                        overlay={
                                                        <Tooltip id={`tooltip-top`} className="deal_create_product-image-tool-tip">
                                                                <img alt="screenshot" src="https://i.imgur.com/sHQ362N.jpg" />
                                                                <br/>
                                                                <img alt="screenshot" src="https://i.imgur.com/FDsxELo.jpg" />
                                                            
                                                        </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                                                    </OverlayTrigger>
                                                </p>
                                                
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Deal Name  (Max Characters- {this.state.maxLength.dealName} )<sup>*</sup></Form.Label>
                                        <Form.Control name="deal_name" maxLength={this.state.maxLength.dealName} value={this.state.deal.deal_name} onChange={this.handleInputChange} required />
                                        <Form.Text className="text-muted">
                                            <div>
                                                Users will see this as the deal title
                                            </div>
                                            <div>
                                                {this.state.deal.deal_name.length}/{this.state.maxLength.dealName}
                                            </div>
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Deal Description (Max Characters - {this.state.maxLength.dealDescription} )<sup>*</sup></Form.Label>
                                        <Form.Control as="textarea" rows="5" name="deal_description" maxLength={this.state.maxLength.dealDescription} value={this.state.deal.deal_description} onChange={this.handleInputChange} required />
                                        <Form.Text className="text-muted">
                                            <div>
                                            Users will see this as the deal description
                                            </div>
                                            <div>
                                                {this.state.deal.deal_description.length}/{this.state.maxLength.dealDescription}
                                            </div>
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Rebate Amount</Form.Label>
                                                <Form.Control name="deal_amount" value={this.state.deal.deal_amount} onChange={this.handleInputChange} required type="number" min="0" step="0.01" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Panda Points</Form.Label>
                                                <Form.Control name="points" value={this.state.deal.points} onChange={this.handleInputChange} required type="number" min="0" />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                 
                                    {/* <Form.Group>
                                        <Form.Label>Badge (optional)</Form.Label>
                                        <Form.Control as="select" name="deal_badge" value={this.state.deal.deal_badge} onChange={this.handleInputChange}>
                                            <option value="">No Badge</option>
                                            {badgeOptions}
                                        </Form.Control>
                                    </Form.Group> */}
                            </Col>
                            <Col>
                                <div className="deal-deck-wrapper">
                                    <div>
                                        {this.renderTilePlaceholder()}
                                    </div>
                                </div>
                            </Col>
                        </Form.Row>

                        <h4>Deal Flow</h4>
                        <Form.Row>
                            <Col>
                                 <Form.Row>
                                        <Col>
                                           <Form.Group>
                                                <Form.Label>Product Name <sup>*</sup></Form.Label>
                                                <Form.Control name="product_name" value={this.state.deal.product_name} onChange={this.handleInputChange} required />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            {dealProductID}
                                        </Col>
                                        {dealAlternateASINS}
                                   
                                    </Form.Row>


                                <Form.Group>
                                    <Form.Label>Product Image Search URL <sup>*</sup></Form.Label>
                                    <InputGroup>
                                        <Form.Control name="product_image_in_search_url" value={this.state.deal.product_image_in_search_url} onChange={this.handleInputChange} required />    
                                        <InputGroup.Append className="pl-1">
                                            {searchThumb}
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Text className="text-muted --tooltip">
                                            <p>Your screenshot of the preferred search image for the product. You must include the product price.</p>
                                            <OverlayTrigger
                                                    placement={"right"}
                                                    overlay={
                                                    <Tooltip id={`tooltip-top`} className="deal_create_product-image-tool-tip">
                                                        <img alt="screenshot" src="https://s3.amazonaws.com/assets.dealpanda.com/admin_create-deal_comp-screenshots.png" />
                                                        <br/>
                                                        <img alt="screenshot" src="https://s3.amazonaws.com/assets.dealpanda.com/admin_create-deal_comp-screenshots-2.png" />
                                                    </Tooltip>
                                                 
                                                    }
                                                >
                                                     <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
                                                </OverlayTrigger>
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Product Question <sup>*</sup></Form.Label>
                                    {/* <Form.Control name="deal_question" value={this.state.deal.deal_question} onChange={this.handleInputChange} required /> */}
                                    <Form.Control as="select" name="deal_question" value={this.state.deal.deal_question} onChange={this.handleInputChange}>
                                        {productQuestions}
                                    </Form.Control>
                                </Form.Group>
                                <br/>
                                

                                <Form.Label>Competitors (Minimum 1)<sup>*</sup></Form.Label>
                                <Form.Group className="deal_create_add-competitors">
                                    {competitors}
                                    
                                    
                                    <div className="compare-competitors">
                                        <div className={`compare-competitors-header ${this.state.compare_competitor_enabled ? "--on": ""}`}>
                                                <h6>Ask user to checkout using 'Compare with similar items' for competitor #{this.state.competitorRows.length}</h6>
                                                <div className={`toggle-switch ${this.state.compare_competitor_enabled ? "--checked": ""}`}>
                                                    <Switch  
                                                        onChange={this.handleCompareCompetitorToggle} 
                                                        checked={this.state.compare_competitor_enabled} 
                                                        handleDiameter={15}
                                                        height={21}
                                                        width={40}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                    />
                                                </div>
                                           
                                        </div>
                                        {compareCompetitor}
                                    </div>
                                </Form.Group>



                                <br/>
                                <Form.Label>{this.state.deal.deal_type === "CFBR" ? "Category" : "Keywords"} (Minimum 1)<sup>*</sup></Form.Label>
                                <Form.Group className="deal_create_add-keywords">
                                    <Row>
                                        <Col md={7} lg={8}>{this.state.deal.deal_type === "CFBR" ? "Category" : "Keyword"}</Col>
                                        <Col md={2} lg={2}>Daily Limit</Col>
                                        <Col md={3} lg={2}></Col>
                                    </Row>
                                    {kwRows}
                                </Form.Group>
                                
                            </Col>
                        </Form.Row>

                        <h4>Additional Options</h4>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Purchase CTA *</Form.Label>
                                    <Form.Control name="purchase_cta" value={this.state.deal.purchase_cta} onChange={this.handleInputChange} placeholder="Add to cart" required/>
                                </Form.Group>

                             
                                <Form.Group>
                                    <Form.Label>Quantity (Total units to give out)</Form.Label>
                                    <Form.Control name="deal_quantity" value={this.state.deal.deal_quantity} onChange={this.handleInputChange} required type="number" min="1" />
                                </Form.Group>
                                  
                                <Form.Group>
                                    <Form.Label>Risk Level</Form.Label>
                                    <Form.Control as="select" name="deal_risk_level" value={this.state.deal.deal_risk_level} onChange={this.handleInputChange}>
                                        {this.renderOptions("user-type")}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="deal_invited_emails">
                                    <Form.Label>Invited Emails (optional)</Form.Label>
                                    <Form.Control  as="textarea" name="deal_invited_emails" value={emails} onChange={this.handleInviteEmailChange} />
                                </Form.Group>

                                {/* AMAZON Aadditional Options ONLY */}
                                {dealAdditionalOptions}

                                <Form.Group className={"controller " + editButtonClass} >
                                    { this.state.editMode ? (
                                        <Link to="/admin/deals" className="btn btn-outline-dismiss btn-cancel">Cancel</Link>
                                    ) : null}
                                    <Button variant="success" type="submit" disabled={this.state.loading}>{editButton}</Button>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Form>
            </section>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        userProfile: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object
    }
}