import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import RETAILS from '../../../utils/retails'
import dayjs from 'dayjs'


export default class ModalReviewCountdown extends Component {
   
 
    render() {

            let localizedDate = dayjs(this.props.futureDate).format("MMMM D")
            let remainingLocalizedDate = this.props.remainingDate
            let header = ""
            let buttonText = ""
            let copyText = ""

                if(dayjs().isSame(dayjs(this.props.submittedDate), 'second')) {
                    header = 
                    <>
                        <Modal.Title>Thank you!</Modal.Title>
                        <p>Just one more step to go.</p>
                    </>
                    buttonText = "Save Progress & Complete Later" 
                    copyText = 
                    <p>
                        Wait for your FREE <strong>{this.props.product.deal_name}</strong> to arrive and try it out over the next {remainingLocalizedDate} day{remainingLocalizedDate > 1 ? "s" : ""}. Then, leave your review on  <strong>{RETAILS.getDomain(this.props.product.deal_channel)}</strong> and get reimbursed
                        {/* You’ve got {remainingLocalizedDate} day{remainingLocalizedDate > 1 ? "s" : ""} left to test out your <strong>{this.props.product.deal_name}</strong>.
                         Please return to this survey on {localizedDate}  to complete the remaining steps and get your reimbursement.  */}
                    </p>
                } else {
                    header = 
                    <>
                        <Modal.Title>Oh hey,<br/>you’re here early!</Modal.Title>
                    </>
                    buttonText = `I’ll be back on ${localizedDate}!`
                    copyText = 
                    <p>
                        You’ve still got {remainingLocalizedDate} day{remainingLocalizedDate > 1 ? "s" : ""} left to test out your FREE <strong>{this.props.product.deal_name}</strong> 
                        before you can leave your review and get reimbursed.
                    </p>
                }

            return (
                <>
                   
                    <Modal show={this.props.showModal} hide="false"  backdrop="static" className="modal-review-countdown">
                        <Modal.Header>
                            {header}
                        </Modal.Header>
                        <Modal.Body>
                            <div className="review-countdown_sub-title">
                                    Please return in:
                            </div>
                            <div className="review-countdown">
                           
                                <div className="review-countdown_number">{remainingLocalizedDate}</div>
                                <div className="review-countdown_description">
                                    <div>Day{remainingLocalizedDate > 1 ? "s" : ""}</div>
                                    <div>({localizedDate})</div>
                                </div>
                            </div>
                            {copyText}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="btn-dp" size="lg" onClick={this.props.handleClick} block>
                                {buttonText}
                            </Button>
                            <div className="step_additiona-note"><strong>Got Questions?</strong> <Link to="/contact">Contact us</Link>.</div>
                        </Modal.Footer>

                        {/* <div>Admin</div> */}
                    </Modal>
               
                </>
            )
    }

    static propTypes = {
        showModal : PropTypes.bool.isRequired,
        futureDate: PropTypes.string.isRequired,
        submittedDate: PropTypes.string,
        remainingDate: PropTypes.number.isRequired,
        product: PropTypes.object.isRequired,
        handleClick: PropTypes.func.isRequired,
        proofOfPurchaseSubmitted: PropTypes.string.isRequired
    };

}


