import React, { Component } from 'react';
import PropTypes from "prop-types";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import '../Compare.scss';

const question = "Look at the product images in the gallery. Compare them to the images in the customer review section. Do you feel that the product images accurately represent what the customer receives?"

export default class CompareCompetitorStep extends Component {

    render() {
        let lastCompetitor = this.props.product.competitors.length - 1 
        let directionalText = "down"

            if (this.props.product.competitors[lastCompetitor].question.trim() === question.trim()) {
                directionalText = "up"
            }
        return (
            <React.Fragment>
                <Row className="step-detail step-detail--compare-competitor">
                        <Col xs={12} md={{ span: 5, offset: 1 }} className="step-detail_image">           
                            <div className="step-detail_image-wrapper" >
                                <div>
                                    <p>Compare with Similar items</p>
                                    <img alt="" src={this.props.product.compare_competitor.carousel_image}/>
                                    <div  className="compare-competitor_main-image" style={{backgroundImage: `url(${this.props.product.compare_competitor.main_image})`}}></div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="step-detail_steps">
                            <h2 className="steps_number">Step {this.props.step.current} </h2>
                            <ol>
                                <li>
                                    Scroll {directionalText} to locate the Compare with similar items.
                                </li>
                                <li>
                                
                                    Locate the <strong>{(this.props.product.competitors[this.props.product.competitors.length - 1]).title}</strong> here.
                                </li>
                                <li>
                                    From here, click on&nbsp;&nbsp;
                                    <Button variant="light" className={`purchase_cta-button --AMAZON`}> Add to Cart </Button>
                                </li>
                            </ol>
                        </Col>
                </Row>
            </React.Fragment>   
                
        );
    }

    static propTypes = {
        handleInputChange: PropTypes.func.isRequired,
        step: PropTypes.object.isRequired,
        product: PropTypes.object.isRequired
    };

}

