import React, { Component } from "react";
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Badge from 'react-bootstrap/Badge'
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import PreLoader from '../snippets/PreLoader'
import dayjs from 'dayjs'

import './admin-bonus-invite.scss';


export default class AdminBonusInvite extends Component {

    constructor(props) {
        super(props);
        const match = matchPath(props.location.pathname, {
            path: "/admin/bonus/invite/:id",
            exact: true,
            strict: false
        });
        this.state = {
            id: match ? match.params.id : null,
            bonusDeal: {
                name: "......",
                acl: []
            },
            elligibleUsers: [],
            selectedUsers: [],
            highlightSelectedUsers: [],
            loading: true,
            submitDisabled: true,
            feedback: null
        }
    }

    componentDidMount = () => {
        this.fetchBonusDeal(this.state.id).then(bonusDeal => {
            this.fetchElligibleUsers(this.state.id).then(elligibleUsers => {
                // acl may contain emails not in elligible users list
                let checked = [];
                elligibleUsers.forEach(item => {
                    if (bonusDeal.acl.indexOf(item.email) >= 0) {
                        checked.push(item.email);
                    }
                });
                this.setState({
                    bonusDeal: bonusDeal,
                    elligibleUsers: elligibleUsers,
                    selectedUsers: checked,
                    loading: false
                });

            }).catch(err => {
                err.then(e => {
                    this.setState({
                        feedback: <Alert variant="danger">{e.error}</Alert>,
                        loading: false
                    });
                });
            });
        }).catch(err => {
            console.warn(err);
        });
    }

    fetchBonusDeal = (bonusDealId) => {
        return this.props.get("/api/v1/deals/bonus/" + bonusDealId, {}).then(response => {
            return Promise.resolve(response.result);
        });
    }

    fetchElligibleUsers = (bonusDealId) => {
        return this.props.get("/api/v1/deals/bonus/" + bonusDealId + "/invite", {}).then(response => {
            return Promise.resolve(response.result);
        }).catch(err => {
            return Promise.reject(err.json());
        });
    }

    clearHighlight = () => {
        this.setState({highlightSelectedUsers: []});
    }

    handleCheckUser = (e) => {
        let checked = this.state.selectedUsers;
        const idx = checked.indexOf(e.target.value);
        
        if (idx >= 0) {
            checked.splice(idx, 1);
        } else {
            checked.push(e.target.value);
        }

        let highlight = this.state.highlightSelectedUsers;
        const idxHighlight = highlight.indexOf(e.target.value);
        if (idxHighlight >= 0) {
            highlight.splice(idxHighlight, 1);
        } else {
            highlight.push(e.target.value);
        }

        let submitDisabled = this.state.submitDisabled;
        if (checked.length === 0) {
            submitDisabled = true;
        } else {
            submitDisabled = false;
        }


        this.setState({selectedUsers: checked, submitDisabled: submitDisabled, highlightSelectedUsers:highlight });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);
        const emails = formData.getAll("emails");
        if (emails.length === 0) {
            return false;
        }
        this.setState({submitDisabled: true});
        this.props.post("/api/v1/deals/bonus/" + this.state.id + "/invite", formData).then(response => {
            if (response.result) {
                this.props.history.push({
                    pathname: "/admin/bonus"
                });
            }
        });
    }

    renderUsers = (elligibleUsers, selectedUsers, highlightSelectedUsers) => {
        let lst = [];
        


        elligibleUsers.forEach(item => {
            let hiddenInput = null;
                if (item.claimed) {
                    hiddenInput = <Form.Control type="hidden" name="emails" value={item.email} />
                }
            
            let invitation = "-"
                if (selectedUsers.indexOf(item.email) >= 0) {
                    let status = "warning"
                    let text = "invited"
                        if (item.claimed) {
                            status  = "success"
                            text = "completed"
                        }
                        invitation = <Badge variant={status}>{text}</Badge>
                }
            
            

            let highlight = ""
                if (highlightSelectedUsers.indexOf(item.email) >= 0) {
                    highlight = "--highlight"
                } 

            let completion = "-"
                if (item.claimed_at != null) {
                    completion = dayjs(item.claimed_at).format("MMMM D, YYYY")
                }

            let daysPassed = "-"
                if (item.created_at != null) {
                    daysPassed = dayjs().diff(item.created_at, "days")
                }
            
            lst.push(
                <tr key={item.email} className={highlight}>
                    <td>
                        <Form.Check type="checkbox" name="emails" label={item.name} id={"user-" + item.email} value={item.email} onChange={this.handleCheckUser} checked={selectedUsers.indexOf(item.email) >= 0} disabled={item.claimed}  />
                        {hiddenInput}
                    </td>
                    <td className="table-email"><label htmlFor={"user-" + item.email}><Link to={"/admin/users/detail/" + item.user_id}>{item.email}</Link></label></td>
                    <td className="text-center">{daysPassed}</td>
                    <td className="text-center">{dayjs(item.created_at).format("MMMM D, YYYY")}</td>
                    <td className="table-data_status"> {invitation} </td>
                    <td className="text-center">{completion}</td>
                </tr>
            );
        });

        if (elligibleUsers.length === 0 && this.state.loading) {
            lst.push(
                <tr key="preloader">
                    <td colSpan="5"><PreLoader /></td>
                </tr>
            );
        }

        return lst;
    }

    render = () => {
         
        let showController = "controller "
            if ( this.state.highlightSelectedUsers.length !== 0) {
                showController += "--show"
            } 
        return (

             <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <div className="bonus_invite">
                        <p className="sub-page_breadcrumb">Manage Bonus Invitation</p>
                        <h2>{this.state.bonusDeal.name}</h2>
                        <Form onSubmit={this.handleSubmit}>
                            <section>
                                    {this.state.feedback}  
                                        <Form.Group>
                                            <Form.Label>Elligible Users</Form.Label>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th className="table-email">Eamil</th>
                                                        <th className="text-center">Days Passed</th>
                                                        <th className="text-center">Elligible Date</th>
                                                        <th className="table-data_status">Status</th>
                                                        <th className="text-center">Complete Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{this.renderUsers(this.state.elligibleUsers, this.state.selectedUsers, this.state.highlightSelectedUsers)}</tbody>
                                            </Table>
                                        </Form.Group>
                            </section>
                            <div className={showController}>
                                {/* <Button  variant="link" onClick={this.clearHighlight} ><strong>Clear</strong></Button> */}
                                <div className="change-verbiage"> {this.state.highlightSelectedUsers.length} {this.state.highlightSelectedUsers.length >= 2 ? "Changes" : "Change"}</div>
                                <Button  variant="success" type="submit" disabled={this.state.submitDisabled}>Submit</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        history: PropTypes.object
    }
}