import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link, NavLink} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import Logo from '../../assets/images/logo.svg'
import Col from 'react-bootstrap/Col'
import PreLoader from '../snippets/PreLoader'
import Button from 'react-bootstrap/Button';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUserCircle} from '@fortawesome/free-solid-svg-icons'

import '../layout/Header.scss';

export default class Header extends Component {
    constructor(props) {
		super(props);
		this.state = {
            preloader: true,
            navExpanded: false, 
            notifications: {}
        }
    }

    componentDidMount = () => {
        this.fetchNotifications().then(data => {
            this.setState({notifications: data});
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.userProfile !== this.props.userProfile) {
            this.setState({userProfile: this.props.userProfile, preloader: false});
        }
    }

    fetchNotifications = () => {
        return this.props.get("/api/v1/header/notifications", {}).then(response => {
            return Promise.resolve(response);
        });
    }

    handleLogout = (e) => {
        e.preventDefault();
        this.props.logout().then(response => {
            if (response !== false) {
                window.location = "/login";
            }
        });
    }

    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
      }

      
    handleNavClose = () => {
        this.setState({ navExpanded: false });
    }
    
    renderStats() {

        // let inviteLink = null 

        //     if (["RISKY"].indexOf(this.props.userProfile.riskLevel) === -1) {
        //         inviteLink = <NavDropdown.Item  as={NavLink} to="/invite">Invite a Friend</NavDropdown.Item>
        //     }

        let profileImage = "";
        if (this.props.userProfile.hasOwnProperty("fb_picture") && this.props.userProfile.fb_picture !== null) {
            profileImage = this.props.userProfile.fb_picture;
        }

        return (
        
            <React.Fragment>
                <div className="navbar-nav_stats">
                    <div className="stats_current-points"> { this.state.preloader ? <PreLoader /> : this.props.userProfile.points}</div>
                    <div className="stats_pending-points">pending: { this.state.preloader ? <PreLoader /> : this.props.userProfile.points_pending}</div>
                </div>
                <NavDropdown style ={ { backgroundImage: "url(" + profileImage + ")" } } id="collasible-nav-dropdown" className={ profileImage !== "" ? "navbar-nav_profile" : "navbar-nav_profile no-profile"} alignRight>
                    <div className="navbar-nav_stats navbar-nav_stats--mobile-only " >
                        <div className="stats_current-points_title">Points</div>
                        <div className="stats_current-points"> { this.state.preloader ? <PreLoader /> : this.props.userProfile.points}</div>
                        <div className="stats_pending-points">pending: { this.state.preloader ? <PreLoader /> : this.props.userProfile.points_pending}</div>
                    </div>
                    <NavDropdown.Item  as={NavLink} to="/profile">Profile</NavDropdown.Item>
                    {/* {inviteLink} */}
                    <NavDropdown.Item  as={NavLink} to="/redeem">Redeem Panda Points</NavDropdown.Item>
                    {this.props.userProfile.role === "ADMIN" ? (
                        <NavDropdown.Item as={NavLink} to="/admin" target="_blank">Dashboard (Admin Only)</NavDropdown.Item>
                    ) : null }
                                
                    <Button variant="link" className="dropdown-item" onClick={this.handleLogout.bind()}>Logout</Button>
                </NavDropdown>
            </React.Fragment>
        )
    }

    renderMainNav = () => {
        const path = [{"label":"Tests", "link": "tests"},
                      {"label":"Contact Us", "link": "contact"}];

        let currentPage = window.location.pathname;
        let links = [];
            
            path.forEach(function (item,index) {
                let classValue = "";
                if(currentPage === "/" + item.link) {
                    classValue = "nav-link nav-link--current"
                } else {
                    classValue = "nav-link"
                }

                let linkNotification = null;
                if (this.state.notifications.hasOwnProperty(item.link) && this.state.notifications[item.link]) {
                    linkNotification = <span className="notification">{this.state.notifications[item.link]}</span>
                }

                links.push(
                    <Link  key={index} className={classValue} to={"/" + item.link} onClick={this.handleNavClose.bind()}>
                        {item.label}
                        {linkNotification}
                    </Link>
                )
            }.bind(this));   

        return <Nav>{links}</Nav>
    }

    render() {
        return (
        <header>
            <Navbar expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className="fixed-top">
                <Container>
                    <Col className="navbar-nav_left-wrapper" md={12} lg={2} >
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Link  className="navbar-brand" to="/tests">
                            <img className="nav_logo" src={Logo} alt="Deal Panda Logo"/>
                        </Link>
                        <div className="navbar-nav_stats-wrapper_mobile">
                            {this.renderStats()}
                        </div>
                    </Col>
                    
                    <Col className="navbar-nav_right-wrapper" md={12} lg={10} >
                        <Navbar.Collapse id="responsive-navbar-nav">
                            {this.renderMainNav()}
                        </Navbar.Collapse>
                        <div className="navbar-nav_stats-wrapper">
                            {this.renderStats()}
                        </div>
                    </Col>
                </Container>
            </Navbar>
        </header>
        );
    }
    static propTypes = {
        logout: PropTypes.func.isRequired,
        userProfile:  PropTypes.object.isRequired,
        get: PropTypes.func
    };

}