import React, { Component } from 'react';
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Body from '../../utils/utils-body'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import PreLoader from '../snippets/PreLoader'
import CompareStepWalkthrough from './compare/step-walkthrough-buy-first'
import CompareStepNda from './compare/step-nda'
import CompareStepKeywordSearch from './compare/step-keyword-search'
import CompareStepCompetitors from './compare/step-competitors'
import CompareStepOurProduct from './compare/step-our-product'
import CompareStepCompleteBuyFirst from './compare/step-complete-buy-first'
import CompareStepPaypalVerification from './compare/step-paypal-verification-buy-first'
import CompareStepOrderNumber from './compare/step-order-number'
import CompareStepCompareCompetitor from './compare/step-compare-competitor'

import trackingLoader from './../../utils/tracking-loader'
import ModalAmzProfileRequest from '../snippets/modal-amz-profile-request'
import LOCAL_STORAGE from '../../utils/local-storage'
import AMAZON from '../../utils/amazon'
import  { Redirect } from 'react-router-dom'
import './Compare.scss';
             
export default class CompareAmazonSurvey extends Component {
    constructor(props) {
		super(props);
		this.state = {
            validated: false,
            preloader: true,
            id: this.props.match.params.id,
            step : {
                // need to clean this after launch and remove unnessary stuff
                nda_agreement:"",
                current: 1,
                competitorStart : 2,
                competitorEnd : 0,
                competitorAnswers: [],
                orderNumber: "",
                paypalEmail: "",
                dealAnswer: "",
                dealKeyword:"",
                totalQuestions: "",
                totalSteps: 0,
                compare_competitor: null
            },
            pages : [
                "walkthrough",
                "nda",
                "start",
                "competitor",
                "our-product",
                "paypal",
                "order-number",
                "complete"
            ],
            currentPage: "walkthrough",
            trackPage: "",
            product: {},
            userProfile: {},
            requestAmzUrl: false,
            submitted: false
        }
    }
    
    componentDidMount = () => {

        // Work on remove fetch deals from here and pull from parent level
        Body.pageClass("add", `page_compare page_compare-${this.state.currentPage}`)
        
        if (this.props.device.isMobile) {
            this.props.history.push({
                pathname: "/tests"
            });
        } else {
            this.fetchDeal(this.props.deal);
            this.setState({userProfile : this.props.userProfile})
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
    
        Body.pageClass("add", `page_compare page_compare-${this.state.currentPage}`)

        if (this.props.userProfile !== prevProps.userProfile && this.props.userProfile){
            this.setState({userProfile: this.props.userProfile});
        }

        const pageChanged = this.state.currentPage !== prevState.currentPage ? true : false;
        if (pageChanged || (!pageChanged && this.state.currentPage === this.state.pages[0] && !this.state.preloader)) {
            trackingLoader.trackPageview(window.location.pathname + "/" + this.state.currentPage, this.state.currentPage);               
        }

    }

    updateStorage = () => {
         let param =  { 
                step: this.state.step.current , 
                deal_keyword: this.state.step.dealKeyword,
                deal_answer: this.state.step.dealAnswer, 
                comp_answer: this.state.step.competitorAnswers, 
                order_number:this.state.step.orderNumber,
                current_page:this.state.currentPage,
                current_competitor:this.state.step.currentCompetitor,
            }

            this.props.setStorage(param)
    }

    getStorage = () => {
        let savedDeal = LOCAL_STORAGE.get("survey", {id: this.state.id , profileId: this.props.userProfile._id });
        
        if (savedDeal !== null) {
            let step = {...this.state.step}
                step.current = savedDeal.step !== null ? savedDeal.step : 1
                step.orderNumber = savedDeal.order_number !== null ? savedDeal.order_number : ""
                step.dealAnswer = savedDeal.deal_answer !== undefined ? savedDeal.deal_answer : ""
                // step.dealKeyword = savedDeal.deal_keyword
                step.competitorAnswers = savedDeal.comp_answer !== null ? savedDeal.comp_answer : []
                step.currentCompetitor = savedDeal.current_competitor !== null ? savedDeal.current_competitor : 0
                
                this.setState({
                    step,
                    currentPage : savedDeal.current_page 
                });
        } else {
            let step = {...this.state.step}
            this.setState({
                    step,
                    currentPage :  "walkthrough"
                }); 
        }
    }

    updateStep = (value) => {
        let step = {...this.state.step}
            step.current = value;
            this.setState({step}, () => this.updateStorage());
            trackingLoader.trackEvent("checkout-progress", {
                user_type:this.props.userProfile.riskLevel,
                deal_id: this.props.match.params.id,
                deal_title: this.state.product.deal_name,
                checkout_step: value,
                event_category: "Compare Flow",
                event_label: value,
                currency: "USD",
                value: this.state.product.deal_amount
            });   

            trackingLoader.trackEvent(`user-type`, {
                deal_id: this.state.id,
                deal_title: this.state.product.deal_name,
                event_category: "Compare Flow",
                event_label: this.props.userProfile.riskLevel,
            });   
             
    }

    fetchDeal = (response) => {
            
            // Uncomment this before push 
            
            if ((response.cool_off && response.deal_status === 'active') || response.deal_status === "out_of_stock" || (response.deal_coming_soon && this.state.userProfile.role !== "ADMIN")  ){
                this.setState({
                    redirect_to: '/'
                });
            }
            // init competitor answer array
            let numCompetitors = response.competitors.length;
            let currentCompetitor = 0 
            let totalSteps = 4  + numCompetitors //need refactor this to be dynamic
            let competitorAnswers = [];
            
            for (let c=0; c<numCompetitors; c++) {
                competitorAnswers.push({
                    answer: ""
                });
            }            
            
            // check if this flow is utlizing competitor compare flow
            let pages = this.state.pages
                if (response.compare_competitor && Object.keys(response.compare_competitor).length !== 0) {
                    if (response.compare_competitor !== "") {
                        const index = pages.indexOf("our-product")
                                pages[index] = "compare-competitor"
                    }
                }

            let state = this.state;
                state.product = response
                state.preloader = false
                state.step = {...state.step}
                state.step.currentCompetitor = currentCompetitor
                state.step.totalSteps = totalSteps
                state.step.competitorAnswers = competitorAnswers
                state.step.dealKeyword = response.deal_keyword
                state.step.paypalEmail = this.props.userProfile.payPalAccount
                state.step.pages = pages

                // Send user to last page
                if(["submitted", 'paid'].indexOf(response.deal_status) >= 0) {
                    this.setState({
                        submitted : true,
                        currentPage:"complete"
                    })
                } else  {
                    this.setState({state}, () => this.getStorage());
                }

                this.handleCompetitorSteps(this.state.step.competitorStart)          

                // console.log("fetch deal:", response.deal_keyword)
    }


    handleInputChange = (e) => {
        let inputValue = e.target.value;
        let inputName = e.target.name;
        let step = this.state.step;    

            // Competitors 
            if (inputName === "compAnswer") {
                let competitorNum = e.target.getAttribute("data-num");
                    step.competitorAnswers[competitorNum].answer = inputValue;
            } else if (inputName === "paypalEmail") {
                step[inputName] = inputValue.toLowerCase();
            } else {
                step[inputName] = inputValue
            }

            this.setState({
                step: step
            });
            
    }

    handleCompetitorSteps = (currentStep) => { 
            this.setState({
                step: {
                    ...this.state.step,
                    competitorEnd: currentStep + this.state.product.competitors.length - 1
                }
            })
    }      

    handleCheckoutChange = (event) => {
        let step = {...this.state.step}
        step.dealAnswer = event.target.value
        this.setState({step});
    }

    handleOrderNumberChange = (event) =>{
        document.querySelector('input#orderNumber').setCustomValidity("")
        let step = {...this.state.step};
        let url = event.target.value.trim();
        try{
            url = new URL(url);
            let order_number = url.searchParams.get('orderId') || url.searchParams.get('orderID');
            step.orderNumber = order_number;
            this.setState({step});
            // console.log(step.orderNumber);
        }catch (error){
            console.log(error)
        }
    }

    handleUpdatePaypal = () => {
         let paypalEmail = this.state.userProfile.payPalAccount;
            
            if (paypalEmail === null || paypalEmail === "") {
                paypalEmail = this.state.userProfile.email;
            }
            
            this.setState({
                step:{
                    ...this.state.step,
                    paypalEmail: paypalEmail
                }
            })
    }



    getNextPage(count) {
        let pages = this.state.pages
        let nextPage = ""
            pages.forEach(function(step, idx){                
                if (step === this.state.currentPage) {
                    nextPage = pages[idx+count]
                }
            }.bind(this))

        
        window.scrollTo(0, 0);

        return nextPage
    }

    getPreviousPage(count) {
        let pages = this.state.pages
        let nextPage = ""

            pages.forEach(function(step, idx){
                
                if (step === this.state.currentPage) {
                    nextPage = pages[idx-count]
                }
             }.bind(this))

            return nextPage
    }

    handlePreviousStep = () => {
        window.scrollTo(0,0);
        if (this.state.validated){
            this.setState({
                validated: false
            });
        }

        // Check if it is competitor Steps
        if (this.state.currentPage === "competitor" ) {
            
            if (this.state.step.current > this.state.step.competitorStart && this.state.step.current <= this.state.step.competitorEnd) {
                this.setState({
                    step : {
                        ...this.state.step,
                        current: this.state.step.current  - 1,
                        currentCompetitor: this.state.step.currentCompetitor - 1
                    }
                }, () => this.updateStorage())
            }  else {
                let previousPage = this.getPreviousPage(1)  
                this.setState({
                    currentPage: previousPage,
                    step : {
                        ...this.state.step,
                        current: this.state.step.current  - 1
                    }
                }, () => this.updateStorage())   
            }

        } else {
            let previousPage = this.getPreviousPage(1)
                if (this.state.step.current !== 1) {
                    this.updateStep(this.state.step.current  - 1);
                }
                this.setState({
                    currentPage: previousPage
                })
        }
        

    }

    handleNextStep = (event) => {
        event.preventDefault();        
        window.scrollTo(0,0);
        
        let nextPage = this.getNextPage(1)

            if (this.state.currentPage === "paypal") {
                this.handleUpdatePaypal()
            }

            this.updateStep(this.state.step.current + 1);
            this.setState({currentPage: nextPage})
    }

    handleWalkthrough = () => {

            if((this.state.userProfile.riskLevel !== "DEMO" && this.state.userProfile.amazon_profile === null) || this.state.userProfile.amazon_profile === undefined  ) {
                // this.setState({currentPage: "request-amz-url"})
                this.setState({
                    requestAmzUrl: true
                })
            } else {
                let nextPage = this.getNextPage(1)
                    this.setState({currentPage: nextPage})
            }
    }

    requestAmzUrlResponse = (response) => {
    
            if (response) {
                this.setState({
                    currentPage: "nda"
                })
                
            } else {
                this.setState({
                    requestAmzUrl: false
                })
            }
    }

    handleNdaSubmit = (value) => {

        let nextPage = value === "disagree" ? "complete" : this.getNextPage(1)

            this.setState({
                step: {
                    ...this.state.step, 
                    nda_agreement: value
                },
                currentPage: nextPage
            })

            if (value === "disagree") {
                trackingLoader.trackPageview("/tests/complete_compare_deal_disagree"); 
            }
 
    }

    handleCompetitorSubmit = (event) => {
        event.preventDefault();

        let obj = {}
            if(this.state.step.currentCompetitor + 1 !== this.state.product.competitors.length) {
                obj = {
                    step: {
                        ...this.state.step, 
                        current: this.state.step.current + 1,
                        currentCompetitor: this.state.step.currentCompetitor + 1
                    }
                }
                
            } else {
                obj = {
                    currentPage: this.getNextPage(1),
                    step: {
                        ...this.state.step,
                        current: this.state.step.current + 1
                    }
                }
            }
            this.setState({
                 ...obj
            }, () => this.updateStorage())
    }

    handleAddProductSubmit = (event) => {
        event.preventDefault();        
        let nextPage = this.getNextPage(1)
            
        this.setState({currentPage: nextPage},this.updateStep(this.state.step.current + 1))
    }

    handleOrderNumberSubmit = (event) =>{
        event.preventDefault();
        const ret = AMAZON.validateOrderNumber(this.state.step.orderNumber);

        this.setState({
            validated: true
        });

        if (ret){
            this.setState({
                submitted: true,
            }); 
            let formData = new FormData();
            formData.append("order_number", this.state.step.orderNumber);
            formData.append("paypal_email", this.state.step.paypalEmail);
            formData.append("deal_answer", this.state.step.dealAnswer);
            formData.append("deal_keyword", this.state.step.dealKeyword);    
            this.state.step.competitorAnswers.forEach(item => {
                formData.append("answers", item.answer);
            });
            this.props.post("/api/v1/deal/" + this.state.id + "/claim", formData).then(response => {
                if (response.result) {
                    this.setState({
                        step:{
                            ...this.state.step,
                            current: this.state.step.current + 1
                        },
                        currentPage: "complete"
                    }, this.props.clearStorage())

                    trackingLoader.trackEvent("complete_compare_deal", {
                        deal_id: this.props.match.params.id,
                        deal_title: this.state.product.deal_name,
                        event_category: "Compare Flow",
                        event_label: this.state.product.deal_name,
                        currency: "USD",
                        value: this.state.product.deal_amount
                    });

                    trackingLoader.trackPageview("/tests/complete_compare_deal"); 
            
                }
            }).catch(err => {
                this.setState({
                    submitted: false,
                    validated: false
                }); 

                trackingLoader.trackEvent("exception", {
                    description: "deal submit failed",
                    fatal: false
                });

                // FIXME something went wrong, show the user some feedback
                if (err instanceof Response) {
                    if (err.status === 409) {
                        // duplicate, let them go through
                        let step = {...this.state.step}
                        step.current = this.state.step.current + 1
                        this.setState({step}, () =>  this.props.clearStorage());
                    } else {
                        console.warn(err.status);
                        // FIXME show proper error modal or alert
                        alert("There was an error processing your request, please try again later or contact us for help.");
                    }
                } else {
                    console.log(err);
                }
            }); 

        }else{
            document.querySelector('input#orderNumber').setCustomValidity("Invalid URL.")
        }
    }

    renderFlow = () => {
        let currentStep = this.state.currentPage 
        let body = null;
        let renderNextPage = null
        let progressNumber = this.state.step.current / (this.state.step.totalSteps + 1) * 100
        let progressBar = (Math.round( progressNumber/5 )*5).toFixed();
        let progressBarNext = "Next";
        let formNavigation = null
        let formHeader = null

            if(currentStep === "walkthrough") {
                body = 
                <CompareStepWalkthrough 
                    {...this.state}
                    product = {this.state.product}
                    handleWalkthrough={this.handleWalkthrough.bind()}
                />
            } else if (currentStep === "nda") {
                body = 
                <CompareStepNda 
                    {...this.state}
                    product = {this.state.product}
                    handleNdaSubmit={this.handleNdaSubmit}
                />
            } else if (currentStep === "start") {
                renderNextPage = this.handleNextStep.bind(this)
                body = 
                    <CompareStepKeywordSearch 
                        {...this.state}
                    />
            } else if (currentStep === "complete") {
                body = 
                <CompareStepCompleteBuyFirst 
                    // {...this.state}
                    user={this.props.userProfile}
                    nda_agreement= {this.state.step.nda_agreement}
                    step = {{currentPage: this.state.currentPage}}
                    product = {this.state.product}
                    
                />
            } else if (currentStep === "order-number" ){
                progressBarNext = "Submit";
                renderNextPage = this.handleOrderNumberSubmit.bind(this)
                body =  <CompareStepOrderNumber 
                    {...this.state} 
                    handleNext={this.handleNextStep.bind(this)}
                    handleOrderNumberChange={this.handleOrderNumberChange} 
                />
            } else if  (currentStep === "our-product" ) {
                renderNextPage = this.handleAddProductSubmit.bind(this)
                body = <CompareStepOurProduct 
                                {...this.state}
                                handleInputChange={this.handleInputChange} 
                            />
            } else if (currentStep === "paypal" ) {
                renderNextPage = this.handleNextStep.bind(this)
                body = <CompareStepPaypalVerification 
                    {...this.props}
                    {...this.state} 
                    handleInputChange={this.handleInputChange} 
                />
            } else if (currentStep === "compare-competitor" ) {
                renderNextPage = this.handleNextStep.bind(this)
                body = <CompareStepCompareCompetitor 
                    {...this.props}
                    {...this.state} 
                    handleInputChange={this.handleInputChange} 
                />
            }   else if (currentStep === "competitor" ) {
                    renderNextPage = this.handleCompetitorSubmit.bind(this)
                    body = <CompareStepCompetitors
                                    {...this.state}
                                    handleInputChange={this.handleInputChange} 
                                />          
            } 

            let hideNavigation = ["walkthrough","nda", "complete", "request-amz-url"]
                if (!hideNavigation.includes(currentStep)) {
                    formNavigation = 
                    <Row className="instruction-navigation">
                            <Col xs={3} md={{ span: 2, offset: 1 }}  >
                                <Button className={"btn btn-dp btn-previous" + (this.state.step.current === 1 ? "disabled" : null)} onClick={this.handlePreviousStep.bind(this)} block>Previous</Button>
                            </Col>
                            <Col xs={6} md={7}>
                                <ProgressBar animated striped now={progressBar} />
                                <span className={"progress-bar_label progress-bar_label--" + (this.state.step.current === (this.state.step.totalSteps + 1) ? "complete" : null)} >{progressBar}%</span>
                            </Col>
                            <Col xs={3} md={2}>
                                <Button className={"btn btn-dp btn-next"} type="submit" block  disabled={this.state.submitted}>{progressBarNext}</Button>
                            </Col>
                    </Row>

                    formHeader = 
                    <React.Fragment>
                            <Row  className="product-detail">
                                <Col xs={2} md={1}  className="product-detail_image">
                                    <div className="product-detail_image-wrapper" > 
                                       <div className="product-image" style={{backgroundImage: "url(" + this.state.product.product_image_url + ")"}}>
                                       </div>
                                    </div>       
                                    
                                </Col>
                                <Col xs={10} md={10}  className="product-detail_text">
                                        <h1>{this.state.product.deal_name}</h1>
                                </Col>
                            </Row>
                        </React.Fragment>

                }

            // Track Page Event
            if (this.state.trackPage !== currentStep) {
                
                // For HJ Tracking
                trackingLoader.trackEvent(`test-product-${currentStep}`, {
                    deal_id: this.state.id,
                    deal_title: this.state.product.deal_name,
                    event_category: "hotjar",
                    event_label: `${this.state.id}: ${this.state.product.deal_name}`
                });  
                this.setState({
                    trackPage: currentStep
                })
            }

            
            let noValidate = false
                if ((["order-id","request-amz-url"]).includes(currentStep)) {
                    noValidate = true
                } else {
                    noValidate = false
                }

            return ( 
                <Form onSubmit={renderNextPage} noValidate={noValidate} validated={this.state.validated}>
                    {formHeader} {body} {formNavigation}
                </Form> )
    }

    render() {
        
        let output = null

        if (this.state.redirect_to){
            this.setState({
                redirect_to: false
            });    
            return <Redirect to={this.state.redirect_to}/>;
        } else if (this.state.preloader) {
            output = <PreLoader/>
        } else {
            output = this.renderFlow();
        }

  
        return (
            <>
            <Container as="main" className={!this.state.preloader ? "questionnaire deal_compare" : "questionnaire deal_compare loading"}>
                {output}
            </Container>
            <ModalAmzProfileRequest {...this.props} response={this.requestAmzUrlResponse} show={this.state.requestAmzUrl}/>
            </>
        );
    }

    static propTypes = {
        device: PropTypes.object.isRequired,
        userProfile : PropTypes.object.isRequired,
        fetchProfile: PropTypes.func.isRequired,
        match:  PropTypes.object.isRequired,
        post: PropTypes.func.isRequired,
        get : PropTypes.func.isRequired,
        history: PropTypes.object,
        deal: PropTypes.object.isRequired,
        clearStorage: PropTypes.func.isRequired,
        setStorage: PropTypes.func.isRequired
    };

}
