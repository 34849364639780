import React, { Component } from 'react';
import PropTypes from "prop-types";
import Switch from "react-switch";

export default class adminSwitchToggle extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
            checked: false
        }
    }

    componentDidMount() {
        let isAdmin = false

            if (this.props.isAdmin !== "" || this.props.isAdmin === undefined) {
                isAdmin = this.props.isAdmin
            } else if (this.props.userRole === "ADMIN") {
                isAdmin = true
            } 
            
            this.setState({checked: isAdmin},this.enableCopyAndPaste(isAdmin))
    }


    componentDidUpdate = (prevProps) => {
    
        if (this.props.isAdmin !== prevProps.isAdmin) {
            this.setState({checked: this.props.isAdmin },this.enableCopyAndPaste(this.props.isAdmin ))
        }
    }

    handleChange = (value) => {
        this.setState({
            checked:value,
        },this.props.handleAdminPrivileges(value),this.enableCopyAndPaste(value))
    }

    enableCopyAndPaste(bool) {
        document.oncontextmenu=function(){return bool};

        let element = document.getElementById("root")
            if (bool) {
                element.classList.add("--role-admin");
            }else {
                element.classList.remove("--role-admin");
            }
    }

    render() {
 
            return (
                <>
                <div className={`toggle-switch --admin-privilege ${this.props.customClasses}`}>
                        <span className="toggle_switch_label">{this.state.checked ? "Admin" : "Guest"} mode</span>
                        <Switch  
                            onChange={this.handleChange} 
                            checked={this.state.checked} 
                            handleDiameter={15}
                            height={25}
                            width={40}
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div>

                </>
            )
    }
    
    static propTypes = {
        isAdmin: PropTypes.bool,
        userRole: PropTypes.string.isRequired,
        handleAdminPrivileges: PropTypes.func.isRequired,
        customClasses: PropTypes.string.isRequired,
    }
}
