import React, { Component } from "react";
import PropTypes from "prop-types";
import Body from '../../utils/utils-body'
import { Switch, Route, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PreLoader from '../snippets/PreLoader'
import AdminMobileNav from './admin-mobile-nav'
import AdminSidebar from './admin-sidebar'
import AdminBonus from './admin-bonus'
import AdminBonusCreate from './admin-bonus-create'
import AdminBonusInvite from './admin-bonus-invite'
import AdminBonusManage from './admin-bonus-manage'
import AdminRewards from './admin-rewards'
import AdminRewardsCreate from './admin-rewards-create'
import AdminRewardsOrders from './admin-rewards-orders'
import AdminSettings from './admin-settings'
import AdminUserRegion from './admin-user-region'
import AdminDeals from './admin-deals'
import AdminManageDeals from './admin-deals-manage'
import AdminUsers from './admin-users'
import AdminUsersDetail from './admin-users-detail'
import AdminDealsCreate from './admin-deals-create'
import AdminDealsExport from './admin-deals-export'
import AdminUsersExport from './admin-users-export'
import AdminAmzProfileStats from './admin-reports-amzprofilestats'

// import AdminEmailBlast from './admin-email-blast'
// import AdminEmailCampaignCreate from './admin-email-campaign-create'
// import AdminEmailCampaigns from './admin-email-campaigns'
import AdminInvites from './admin-invites'
import AdminInvitesHistory from './admin-invites-history'

import './admin.scss';


export default class Admin extends Component {
    constructor(props) {
		super(props);
		this.state = {
            darkMode: true,
            navLink: [
                {
                    category: "Bonus",
                    links: [
                        {
                            title: "Manage Bonus",
                            path: "/admin/bonus"
                        },
                        {
                            title: "Create Bonus",
                            path: "/admin/bonus/create"
                        }
                    ]
                },
                {
                    category: "Deals",
                    links: [
                        {
                            title: "Manage Deals",
                            path: "/admin/deals"
                        },
                        {
                            title: "Create Deal",
                            path: "/admin/deals/create"
                        },
                        {
                            title: "Report",
                            path: "https://datastudio.google.com/open/1Q54PYFMwQrjiG5ijhAuFc4JuXOEn_qUs"
                        },
                        {
                            title: "Export",
                            path: "/admin/deals/export"
                        }
                    ]
                },
                // {
                //     category: "Email",
                //     links: [
                //         {
                //             title: "Campaigns",
                //             path: "/admin/email/campaigns"
                //         },
                //         {
                //             title: "Create Campaign",
                //             path: "/admin/email/campaign/create"
                //         },
                //         {
                //             title: "Email Blast",
                //             path: "/admin/email/blast"
                //         }
                //     ]
                // },
                {
                    category: "Rewards",
                    links: [
                        {
                            title: "Manage Rewards",
                            path: "/admin/rewards"
                        },
                        {
                            title: "Create Reward",
                            path: "/admin/rewards/create"
                        },
                        {
                            title: "Orders",
                            path: "/admin/rewards/orders",
                            badge: null
                        }
                    ]
                },
                {
                    category: "Users",
                    links: [
                        {
                            title: "Users",
                            path: "/admin/users"
                        },
                        // {
                        //     title: "Change Region",
                        //     path: "/admin/user/region"
                        // },
                        {
                            title: "Manage Invites",
                            path: "/admin/invites"
                        },
                        {
                            title: "Invite Stats",
                            path: "/admin/invites/history"
                        },
                        {
                            title: "Amz Profile Stats",
                            path: "/admin/reports/amzprofilestats"
                        },
                        {
                            title: "Export",
                            path: "/admin/users/export"
                        }
                    ]
                },
                {
                    category: "Settings",
                    links: [
                        {
                            title: "Settings",
                            path: "/admin/settings"
                        }
                    ]
                }
                
            ]
        }
    }
    componentDidMount = () => {
        Body.pageClass("add", `page_admin`);
        document.oncontextmenu=function(){return true};
        this.fetchOrdersCount("processing").then(total => {
            let navLink = [];
            this.state.navLink.forEach(linkGroup => {
                if (linkGroup.category === "Rewards") {
                    linkGroup.links.forEach(item => {
                        if (item.title === "Orders") {
                            item.badge = total;
                        }
                    });
                }
                navLink.push(linkGroup);
            });
            
            this.setState({
                navLink: navLink
            });
        });
    }


    handleDarkMode = (value) => {
        this.setState({darkMode:value});
    }

    handleSubPage = () => {
        let page = (this.props.location.pathname.split('/'))[3]
        const subPages = ["edit", "invite", "manage", "detail", "p-level-2"]    

        return subPages.indexOf(page) > -1 ? true : false ;
    }

    fetchOrdersCount = (status) => {
        const params = {
            status: status
        }
        return this.props.get("/api/v1/rewards/orders/count", params).then(response => {
            return Promise.resolve(response.total);
        });
    }

    renderDashboard = () => {
        let subPage = this.handleSubPage();
        let containerClass = ""
        let subNavBreakpoint = null;
        let mainBreakpoint = null

            if (subPage) {
                subNavBreakpoint = 1;
                mainBreakpoint = 11;
                containerClass = "admin_subpage"
            } else if (window.innerWidth  < 768 ) {
                subNavBreakpoint = 2;
                mainBreakpoint = 12;
            } else {
                subNavBreakpoint = 2;
                mainBreakpoint = 10;
            }
            
        return (
            <React.Fragment>
                <AdminMobileNav {...this.props} subPage={subPage} darkMode={this.state.darkMode} handleDarkMode={this.handleDarkMode} links={this.state.navLink}/>
                <Container fluid className={"admin_wrapper" + (this.state.darkMode ? " admin_wrapper--dark" : "")  + " " + containerClass} >
                    <Row>
                        <Col as="div" md={subNavBreakpoint} className="admin_sidebar  d-none d-sm-none d-md-block">
                            <AdminSidebar {...this.props} subPage={subPage} darkMode={this.state.darkMode} handleDarkMode={this.handleDarkMode} links={this.state.navLink}/>
                        </Col>
                        <Col as="main" md={mainBreakpoint}>
                        <Switch location={this.props.location}>
                            <Route path="/admin/bonus" exact render={() => <AdminBonus {...this.props} {...this.state} />} />
                            <Route path="/admin/bonus/create" exact render={(props) => <AdminBonusCreate {...this.props} {...this.state} from={this.props.location} history={props.history} />} />
                            <Route path="/admin/bonus/edit/:id" exact render={(props) => <AdminBonusCreate {...this.props} {...this.state} from={this.props.location} history={props.history} />} />
                            <Route path="/admin/bonus/invite/:id" exact render={(props) => <AdminBonusInvite {...this.props} {...this.state} from={this.props.location} history={props.history} />} />
                            <Route path="/admin/bonus/manage/:id" exact render={(props) => <AdminBonusManage {...this.props} {...this.state} from={this.props.location} history={props.history} />} />
                            <Route path="/admin/rewards" exact render={() => <AdminRewards {...this.props} {...this.state} />} />
                            <Route path="/admin/rewards/edit/:id" exact render={(props) => <AdminRewardsCreate {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/rewards/create" exact render={(props) => <AdminRewardsCreate {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/rewards/orders" exact render={(props) => <AdminRewardsOrders {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/deals" exact render={() => <AdminDeals {...this.props} {...this.state} />} />
                            <Route path="/admin/deals/edit/:id" exact render={(props) => <AdminDealsCreate {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/deals/create" exact render={(props) => <AdminDealsCreate {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/deals/export" exact render={(props) => <AdminDealsExport {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/users/export" exact render={(props) => <AdminUsersExport {...this.props} {...this.state} history={props.history} />} />                            
                            <Route path="/admin/deals/manage/:id" exact render={() => <AdminManageDeals {...this.props} {...this.state} />} />
                            <Route path="/admin/settings" exact render={(props) => <AdminSettings {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/user/region" exact render={(props) => <AdminUserRegion {...this.props} {...this.state} history={props.history} />} />
                            <Route path="/admin/users" exact render={(props) => <AdminUsers {...this.props} {...this.state} history={props.history}/>} />
                            <Route path="/admin/users/detail/:id" exact render={() => <AdminUsersDetail {...this.props} {...this.state} key={Date.now()} />} />
                            <Route path="/admin/invites" exact render={(props) => <AdminInvites {...this.props} {...this.state} history={props.history}/>} />
                            <Route path="/admin/invites/history" exact render={(props) => <AdminInvitesHistory {...this.props} {...this.state} history={props.history}/>} />
                            {/* <Route path="/admin/email/blast" exact render={(props) => <AdminEmailBlast {...this.props} {...this.state} history={props.history}/>} /> */}
                        {/* <Route path="/admin/email/campaign/create" exact render={(props) => <AdminEmailCampaignCreate {...this.props} {...this.state} history={props.history}/>} />
                        <Route path="/admin/email/campaign/edit/:id" exact render={(props) => <AdminEmailCampaignCreate {...this.props} {...this.state} history={props.history}/>} />
                        <Route path="/admin/email/campaigns" exact render={(props) => <AdminEmailCampaigns {...this.props} {...this.state} history={props.history}/>} /> */}
                            <Route path="/admin/reports/amzprofilestats" exact render={(props) => <AdminAmzProfileStats {...this.props} {...this.state} history={props.history}/>} />
                            <Route render={() => <Redirect to="/admin/deals" />} />
                        </Switch>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }

    render() {
        if (this.props.userProfile === undefined || !this.props.userProfile.hasOwnProperty("role")) {
            return <PreLoader />;
        } else if (this.props.userProfile.role !== "ADMIN") {
            window.location.href = "/tests";
        } else {
            return this.renderDashboard();
        }

    }

    static propTypes = {
        userProfile: PropTypes.object.isRequired,
        get: PropTypes.func.isRequired,
        post: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired
    }
}