import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from 'react-bootstrap/Table';
import PreLoader from '../snippets/PreLoader'

import './admin-users.scss';

export default class AdminInvitesHistory extends Component {

    constructor(props) {
		super(props);
		this.state = {
            inviteHistory: [],
            page: 1,
            resultsPerPage: 20,
            showMore: true,
            isBtnLoading: false, 
            isLoading: true,
            deletedInvites: [],
            approvedInvites: []
        }
    }

    componentDidMount = () => {
        Promise.all([this.fetchInviteHistory(1, 100), this.fetchInviteCount()]).then(results => {
            let inviteSources = {}
            results[0].forEach(item => {
                if (!inviteSources.hasOwnProperty(item.source)) {
                    inviteSources[item.source] = {}
                }
                if (!inviteSources[item.source].hasOwnProperty(item.status)) {
                    inviteSources[item.source][item.status] = 0
                }

                inviteSources[item.source][item.status] = item.count;
            });

            results[1].forEach(item => {
                let source = item.source === null ? "unknown": item.source;
                let status = item.status === null ? "pending": item.status;
                if (!inviteSources.hasOwnProperty(source)) {
                    inviteSources[source] = {}
                }
                if (!inviteSources[source].hasOwnProperty(status)) {
                    inviteSources[source][status] = 0
                }

                inviteSources[source][status] = item.count;
            });

            this.setState({
                inviteHistory: inviteSources,
                isLoading: false
            });
        });
    }

    fetchInviteHistory = (page, resultsPerPage) => {
        const params = {
            page: page,
            limit: resultsPerPage
        }
        return this.props.get("/api/v1/invitehistory", params).then(response => {
            return Promise.resolve(response.results);
        }).catch(err  => {
            console.error(err);
            return Promise.reject([]);
        });
    }

    fetchInviteCount = () => {
        return this.props.get("/api/v1/invitecount", {}).then(response => {
            return Promise.resolve(response.results);
        }).catch(err  => {
            console.error(err);
            return Promise.reject([]);
        });
    }
    

    handleInputChange = (e) => {
        const inputName = e.target.name;
        const inputValue = e.target.value;
        this.setState({[inputName]: inputValue});
    }

    renderList = () => {
        let lst = [];
        
        let totalRegisterd = 0;
        let totalRejected = 0;
        let totalPending = 0;
        let totalInvited = 0;

        for (let s in this.state.inviteHistory) {
            let item = this.state.inviteHistory[s];

                console.log(item)
                totalRegisterd = totalRegisterd + (item.hasOwnProperty("created") ? item.created : 0)
                totalRejected = totalRejected + (item.hasOwnProperty("rejected") ? item.rejected : 0)
                totalPending = totalPending + (item.hasOwnProperty("pending") ? item.pending : 0)
                totalInvited = totalInvited + (item.hasOwnProperty("invited") ? item.invited : 0)
            lst.push(
                <tr key={s}>
                    <td >{s}</td>
                    <td className="text-center">{item.hasOwnProperty("pending") ? item.pending : 0}</td>
                    <td className="text-center">{item.hasOwnProperty("invited") ? item.invited : 0}</td>
                    <td className="text-center">{item.hasOwnProperty("created") ? item.created : 0}</td>
                    <td className="text-center">{item.hasOwnProperty("rejected") ? item.rejected : 0}</td>
                </tr>
            );
        }

        lst.push(
            <tr key="total">
                <td >Total</td>
                <td className="text-center">{totalPending}</td>
                <td className="text-center">{totalInvited}</td>
                <td className="text-center">{totalRegisterd}</td>
                <td className="text-center">{totalRejected}</td>
            </tr>
        );

        if (lst.length === 0) {
            lst.push(
                <tr key="preloader">
                    <td  colSpan="3"><em>No items found</em></td>
                </tr>                
            );
        }

        return lst;
    }

    render = () => {
        

       

        return (
            <React.Fragment>
                {this.state.isLoading ? (
                    <PreLoader />
                ) : (
                    <React.Fragment>
                    
                    <section className="admin_invite-stats">
                        {/* <h3>Deal Panda Users</h3> */}
                        <Table className="-highlight">
                            <thead>
                                <tr>
                                    <th>Source</th>
                                    <th className="text-center">Pending</th>
                                    <th className="text-center">Invited</th>
                                    <th className="text-center">Registered</th>
                                    <th className="text-center">Rejected</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {this.renderList()}
                            </tbody>
                        </Table>
                    </section>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    static propTypes = {
        get: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        put: PropTypes.func.isRequired,
        history: PropTypes.object,
        userProfile: PropTypes.object.isRequired
    }
}