import React, { Component } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import './../apply.scss';

// Font-Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default class StepPPAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: "Add your PayPal Account Email"
        }
    }

    render() {
        let disableButton = true
        let ppCustomMsgClass = ""
        let emailInvalid = false
        

        // this.props.validationFields.user_paypal.isInvalid

            if (this.props.user_paypal.email !== "" || this.props.user_paypal.need_paypal_account) {
                disableButton = false
            }

            if (this.props.validationFields.user_paypal.isInvalid && !this.props.user_paypal.need_paypal_account) {
                emailInvalid = true
            } else {
                emailInvalid = false
            }

            if (this.props.user_paypal.need_paypal_account) {
                ppCustomMsgClass = "--show"
            }

        return(
            <Container as="section" className="page-header">
                <h1 className="display-3">{this.state.question}</h1>
                <p className="lead">Enter your PayPal email so brands can start paying you for your opinions.</p>
                <Form.Group className="mb-3 mt-3">
                    <Form.Control isInvalid={emailInvalid} className="mb-0" size="lg" type="email" disabled={this.props.user_paypal.need_paypal_account} placeholder="Email associated to your PayPal Account" name="user_paypal" value={this.props.user_paypal.email} onChange={this.props.handleInputChange} data-question={this.state.question} required/>
                    <Form.Control.Feedback type="invalid">
                        {this.props.validationFields.user_paypal.message}
                    </Form.Control.Feedback>

                    <div key={"div_" } className="custom-checkbox --small">
                        <input 
                            type="checkbox" 
                            name="need_paypal_account"
                            value={this.props.user_paypal.need_paypal_account} 
                            checked={this.props.user_paypal.need_paypal_account}
                            onChange={this.props.handleInputChange} 
                            data-question={this.state.question}
                            key={"input_"}
                            id={"paypal_account"}
                            htmlFor={"paypal_account"} />
                            <label key={"label_"} htmlFor={"paypal_account"} >
                                <div className="label_text">I don’t have a PayPal account</div>
                            </label>
                             <div className={`custom-label-msg ${ppCustomMsgClass}`} >No worries! You’ll receive an email from PayPal to create an account when you receive your first reward payout. </div>
                      </div>
                </Form.Group>     
                <Form.Group className="mb-5 apply-step_navigation apply-step_navigation-buttons">
                    <Button type="submit" variant="success" disabled={disableButton}>Next <FontAwesomeIcon icon={faChevronRight} fixedWidth /> <FontAwesomeIcon icon={faChevronRight} fixedWidth /></Button>
                </Form.Group>    
            </Container>
        )
    }
    
    static propTypes = {
        validationFields: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        validation: PropTypes.object,
        handleInputChange: PropTypes.func.isRequired,
        processing: PropTypes.bool.isRequired,
        disabledButton: PropTypes.bool,
        user_paypal : PropTypes.object.isRequired
        
    }
}