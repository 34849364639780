import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/main.scss';

// ANY META TAG PLEASE ADD TO PUBLIC > INDEX.HTML

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
