import React, { Component } from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import '../layout/modal-global-message.scss';

export default class ModalGlobalMessage extends Component {

    pandaPoints = () => {
        return (
            <i className="icon-points">
                <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span>
            </i>
        )
    }

    render() {

        return (
            <Modal 
                show={this.props.showModal}
                className="modal_global-message" 
                onHide={this.props.modalHide}
                backdrop="static"
                keyboard={false}
                centered
                >
                <Modal.Header>
                    <Modal.Title>Welcome Back<span className="modal-title_name">{this.props.userProfile.name}!</span></Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <p>We've been working hard to bring you new and exciting tests to participate in, and now we're back with some great, new
features too!</p>
                        <h6>What's New ?</h6>
                        <ol>
                            <li>We've got <strong>MORE</strong> of the same great product test shopping surveys you're used to, only the rewards are bigger and better than ever. Check back regularly for new and exciting tests.</li>
                            <li> New tests include rewards where you keep the products you test. And it’s completely <strong>FREE</strong>*!</li>
                            <li>Earn and collect <strong>Panda Points</strong> for each deal you redeem. Then, trade your Panda Points in for gift cards to popular retailers such as Starbucks, Best Buy , Netflix and more**. The more points you
                            earn, the bigger the rewards!</li>
                            <li>Some surveys will require completion on desktop and some on mobile.</li>
                        </ol>
                        <p className="footnote">
                            *Shipping charges not included. (Remember, Prime members get free shipping!) Limit one per household. <br/>
                            **Gift card availability may vary.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn btn-dp" onClick={this.props.modalHide}>
                            Let's Start 
                        </Button>
                    </Modal.Footer>
            </Modal>
        );
    }
    static propTypes = {
        userProfile:  PropTypes.object.isRequired,
        showModal: PropTypes.bool.isRequired,
        modalShow: PropTypes.func.isRequired,
        modalHide: PropTypes.func.isRequired
    };

}