import Form from 'react-bootstrap/Form'
import React from 'react'

class PasteDisabledInput extends React.Component {
    returnFalse(e){
        e.preventDefault();
        return false;
    }
    render () {
      return  <Form.Control 
                as={this.props.as}
                minLength={this.props.minLength}
                type={this.props.type} 
                name={this.props.name}
                placeholder={this.props.placeholder} 
                value={this.props.value}
                onChange={this.props.onChange}
                data-num={this.props.dataNum}
                required={this.props.required}
                id={this.props.id}
                isInvalid={this.props.isInvalid}
                onCopy={this.returnFalse}
                onDrag={this.returnFalse}
                onDrop={this.returnFalse}
                onPaste={this.returnFalse}
                />
    }
  }
  export default PasteDisabledInput